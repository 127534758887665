

/**
 * Simple class that manages the busy state of a component
 */
export class BusyState {

    public busyObserver: (busy: boolean) => void = null;

    private busyCount: number = 0;
    private timer: any = null;

    constructor() {
    }

    protected incrementBusy(): void {
        this.busyCount = this.busyCount + 1;

        if (this.busyCount == 1) {
            setTimeout(this.notifyBusy.bind(this), 500);
            //this.notifyBusy();
        }
    }

    protected decrementBusy(): void {

        if (this.busyCount > 0) {
            this.busyCount = this.busyCount - 1;
        }

        if (this.busyCount == 0) {
            this.notifyBusy();
        }
    }

    protected isBusy(): boolean {
        return this.busyCount > 0;
    }

    private notifyBusy() : void {
        if (this.busyObserver) {
            this.busyObserver(this.isBusy());
        }
    }
}
