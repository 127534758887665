import type { EntityId } from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ITaskApplication<T> {
    application: T;
    applicationId: string;

    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail: string;
}

@Serializable()
export class TaskApplication implements ITaskApplication<EntityId> {
    @JsonProperty() application: EntityId = null;
    @JsonProperty() applicationId: string = '';

    @JsonProperty() applicantFirstName: string = '';
    @JsonProperty() applicantLastName: string = '';
    @JsonProperty() applicantEmail: string = '';

    static getQueryFields(): string[] {
        return [
            'application',
            'applicationId',      // Read-only
            'applicantFirstName',      // Read-only
            'applicantLastName',      // Read-only
            'applicantEmail'      // Read-only
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            'applicationId',      // Read-only
            'applicantFirstName',      // Read-only
            'applicantLastName',      // Read-only
            'applicantEmail'      // Read-only
        ]
    }
}