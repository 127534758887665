import map from "lodash/map";
import trim from "lodash/trim";
import replace from "lodash/replace";

import {ReportColumn} from "./ReportColumn";
import {IReportConverter} from "./IReportConverter";

export class ReportSpec {

    title: string;
    id: string;
    forceQuotes: boolean;

    protected readonly columns: Array<ReportColumn> = [];

    /**
     * Create a report
     * @param title
     * @param forceQuotes  Force quotes for PetFinder exportz
     */
    constructor(title: string, forceQuotes: boolean = false) {
        this.title = title;
        this.id = title.replace(/ /g, '_').toLowerCase();
        this.forceQuotes = forceQuotes;
    }

    addColumn(columnName: string, property: string | Array<string>, converter?: IReportConverter) {
        let column = new ReportColumn(columnName, property, converter);

        this.columns.push(column);
    }

    getColumnHeaders() : Array<string> {
        return map(this.columns, (value, indexOrKey, collection) => {
            if (this.forceQuotes) {
                return `"${value.columnName}"`;
            } else {
                return value.columnName;
            }
        });
    }

    getColumnValues(entity: any) {
        let columnValues = [];

        for (let column of this.columns) {

            let columnValue = column.getDisplayValue(entity);

            if (columnValue !== null) {
                columnValues.push(columnValue.trim());
            }
        }

        return columnValues;
    }

    show() : any {
        this.load({});
    }

    load(parameters: any) {
    }

    buildCsvReport(entities: Array<any>) : string {
        let results = [];       // Array of rows, where row is an array of column values

        results.push(this.getColumnHeaders());

        // Now parse entities
        for (let entity of entities) {
            let columnValues = this.getColumnValues(entity);

            let escapedValues = map(columnValues, (value) => {
                return this.csvEscape(value);
            });

            results.push(escapedValues);
        }

        return results.join('\n');
    }

    buildCsvReportGQL(json: any) : string {
        let results = [];       // Array of rows, where row is an array of column values

        results.push(json.header);

        // Now output rows
        for (let row of json.data) {
            let escapedValues = map(row, (value) => {
                return this.csvEscape(value.toString());
            });

            results.push(escapedValues);
        }

        return results.join('\n');
    }

    /**
     *  Need to escape embedded commas in double quotes
     *
     * @param value
     * @returns {*}
     */
    csvEscape(value: string) {
        // Trim it
        value = trim(value);

        // Make sure there are no cr/lf/tabs
        if (this.forceQuotes) {
            // This is really petfinder export
            value = replace(value, /\r\n/g, ". ");
            value = replace(value, /\r/g, ". ");
            value = replace(value, /\n/g, ". ");
            value = replace(value, /\t/g, " ");
        } else {
            value = replace(value, /\r\n/g, ". ");
            value = replace(value, /\r/g, ". ");
            value = replace(value, /\t/g, " ");
        }


        // If double-quotes are used to enclose fields, then a double-quote appearing inside a field must
        // be escaped by preceding it with another double quote
        if (value.includes('"')) {
            value = value.split('"').join('""');
        }

        if (value.includes(',') || value.includes('\n') || this.forceQuotes) {
            return `"${value}"`
        } else {
            return value;
        }
    }
}