import { Weight } from "../common/Weight";
import {EnumElement, Enum} from "../../Enum";
import { AnimalSpecies, AnimalSpeciesEnum } from "./AnimalSpeciesEnum";

export class AnimalSize extends EnumElement {}

export class AnimalSizeEnum extends Enum<AnimalSize> {

    static readonly EXTRA_SMALL = new AnimalSize('EXTRA_SMALL', 'Extra Small');
    static readonly SMALL = new AnimalSize('SMALL', 'Small');
    static readonly MEDIUM = new AnimalSize('MEDIUM', 'Medium');
    static readonly LARGE = new AnimalSize('LARGE', 'Large');
    static readonly EXTRA_LARGE = new AnimalSize('EXTRA_LARGE', 'Extra Large');

    static readonly ELEMENTS = [
        AnimalSizeEnum.EXTRA_SMALL,
        AnimalSizeEnum.SMALL,
        AnimalSizeEnum.MEDIUM,
        AnimalSizeEnum.LARGE,
        AnimalSizeEnum.EXTRA_LARGE
    ];
    static readonly INSTANCE = new AnimalSizeEnum();

    private constructor() {
        super(AnimalSizeEnum.ELEMENTS);
    }

    static translateSizeFromWeight(species: AnimalSpecies, weight: Weight): AnimalSize {
        if (species === AnimalSpeciesEnum.DOG) {
            if (weight.roundedPounds < 15) {
                return AnimalSizeEnum.EXTRA_SMALL;
            } else if (weight.roundedPounds < 31) {
                return AnimalSizeEnum.SMALL;
            } else if (weight.roundedPounds < 51) {
                return AnimalSizeEnum.MEDIUM;
            } else if (weight.roundedPounds < 71) {
                return AnimalSizeEnum.LARGE;
            } else {
                return AnimalSizeEnum.EXTRA_LARGE;
            }
        } else if (species === AnimalSpeciesEnum.CAT) {
            if (weight.roundedPounds < 5) {
                return AnimalSizeEnum.EXTRA_SMALL;
            } else if (weight.roundedPounds < 11) {
                return AnimalSizeEnum.SMALL;
            } else if (weight.roundedPounds < 16) {
                return AnimalSizeEnum.MEDIUM;
            } else if (weight.roundedPounds < 21) {
                return AnimalSizeEnum.LARGE;
            } else {
                return AnimalSizeEnum.EXTRA_LARGE;
            }
        } else if (species === AnimalSpeciesEnum.GUINEA_PIG) {
            if (weight.roundedPounds < 1) {
                return AnimalSizeEnum.EXTRA_SMALL;
            } else if (weight.roundedPounds < 2) {
                return AnimalSizeEnum.SMALL;
            } else if (weight.roundedPounds < 3) {
                return AnimalSizeEnum.MEDIUM;
            } else if (weight.roundedPounds < 4) {
                return AnimalSizeEnum.LARGE;
            } else {
                return AnimalSizeEnum.EXTRA_LARGE;
            }
        } else if (species === AnimalSpeciesEnum.RABBIT) {
            if (weight.roundedPounds < 2) {
                return AnimalSizeEnum.EXTRA_SMALL;
            } else if (weight.roundedPounds < 5) {
                return AnimalSizeEnum.SMALL;
            } else if (weight.roundedPounds < 8) {
                return AnimalSizeEnum.MEDIUM;
            } else if (weight.roundedPounds < 11) {
                return AnimalSizeEnum.LARGE;
            } else {
                return AnimalSizeEnum.EXTRA_LARGE;
            }
        } else {
            throw new Error(`Unknown species: ${species}`);
        }
    }
}
