import {Container, computedFrom} from 'aurelia-framework';

import {Action} from '../actions/Action';
import {ArpModal} from '../views/arp-modal';
import {CustomFilterView} from './arp-custom-filter';
import {NullFilterCategory} from './NullFilterCategory';
import {FilterCategory} from "./FilterCategory";
import {InstantFilterCategory} from "./InstantFilterCategory";
import {IQuery} from "../ArpResource";
import {ArpViewState} from "../ArpViewState";
import {each, find } from 'lodash';

export interface ResourceFilter {
    filterActive: boolean;

    configureResource(resource: IQuery);
}

export class ArpFilterBar implements ResourceFilter {

    public readonly applyFilterAction: Action;
    public wideMode: boolean = false;
    public inView: boolean = false;

    private selectedCategory = null;
    private selectedCategoryLabel = "";
    private defaultCategory: InstantFilterCategory;

    // Instant categories show up in the filter bar menu.  Customize categories show as tabs in the customize modal.
    private readonly categories: Array<FilterCategory> = [];
    private readonly instantCategories: Array<InstantFilterCategory> = [];
    private readonly customizeCategories: Array<FilterCategory> = [];

    private readonly modalService: ArpModal;
    private readonly viewState: ArpViewState;
    private readonly allItemsFilterCategory: NullFilterCategory = new NullFilterCategory("All Items");
    private readonly customizeFilterCategory: NullFilterCategory = new NullFilterCategory("Customize...");

    constructor(viewState?: ArpViewState) {
        this.viewState = viewState;

        this.addDefaultCategory(this.allItemsFilterCategory);
        // this.instantCategories = [ this.allItemsFilterCategory ];
        // this.defaultCategory = this.allItemsFilterCategory;

        this.modalService = Container.instance.get(ArpModal);

        // This is the action that actually triggers our parent view to refresh using the active filter
        this.applyFilterAction = new Action().withLabel("Apply Filter");
    }

    addDefaultCategory(category: FilterCategory) {
        this.addCategory(category);

        if (category instanceof InstantFilterCategory) {
            category.activate();
            this.defaultCategory = category;
            this.setSelectedCategory(category);
        } else {
            throw new Error("Only instant filters can be default!")
        }
    }

    addCategory(category: FilterCategory) {
        this.categories.push(category);

        if (category instanceof InstantFilterCategory) {
            this.instantCategories.push(category);
        } else {
            this.customizeCategories.push(category);
        }
    }

    /**
     * Invoked when the view that contains the extension is attached to the DOM.
     */
    attached() {
        // Add the Customize... to the "menu"
        if (this.customizeCategories.length > 0) {
            this.instantCategories.push(this.customizeFilterCategory);
        }

        // NOTE: Restore state is called before attached, so we need to infer the selectedCategory
        // from the active categories!
        //this.updateSelectedCategory();
    }

    updateSelectedCategory() {
        let activeCategory = this.getActiveCategory();

        if (activeCategory == null) {
            this.setSelectedCategory(this.defaultCategory);
            this.performApplyInstantFilter(this.defaultCategory);
        } else if (activeCategory.instantFilter) {
            this.setSelectedCategory(activeCategory);
        } else {
            this.setSelectedCategory(this.customizeFilterCategory);
        }
    }

    getActiveCategory() {
        for (let category of this.categories) {
            if (category.active) {
                return category;
            }
        }

        return null;
    }

    setSelectedCategory(category: FilterCategory) {
        this.selectedCategory = category;

        if (category === this.customizeFilterCategory) {

            let labels = [];
            for (let customCategory of this.customizeCategories) {
                if (customCategory.active) {
                    labels.push(customCategory.label);
                }
            }

            this.selectedCategoryLabel = labels.length > 1 ? `Filtered by: Multiple` : `Filtered by: ${labels[0]}`;
        } else {
            this.selectedCategoryLabel = category.label;
        }

    }

    @computedFrom('selectedCategory')
    get filterActive() {
        return this.selectedCategory != this.allItemsFilterCategory;
    }

    clickCategory(newCategory) {
        if (newCategory === this.customizeFilterCategory) {
            this.showFilter(newCategory, this.selectedCategory);
        } else if (newCategory.instantFilter) {
            if (newCategory != this.selectedCategory) {
                this.performApplyInstantFilter(newCategory);
            }
        }
    }

    configureResource(resource: IQuery) {
        for (let category of this.categories) {
            category.configureResource(resource);
        }
    }

    showFilter(newCategory: FilterCategory, oldCategory: FilterCategory) {

        let customFilter = new CustomFilterView();

        for (let customCategory of this.customizeCategories) {
            customCategory.saveFilters();
            customFilter.addCategory(customCategory);
        }

        // TODO? How to clear?
        let clearAction = new Action().withLabel("Clear Filters").withPerformCallback((action: Action) => this.performClearFilter());
        let applyAction = new Action().withLabel("Apply").withPerformCallback((action: Action) => this.performApplyCustomFilter());
        let cancelAction = new Action().withLabel("Cancel").withPerformCallback((action: Action) => this.performCancel());

        this.modalService.show("Filter", customFilter, [applyAction, cancelAction], [clearAction], this.wideMode);
    }

    clearAll() {
        for (let category of this.categories) {
            category.clearFilters();
        }
    }

    clearAllInstant() {
        for (let category of this.categories) {
            if (category.instantFilter) {
                category.clearFilters();
            }
        }
    }

    performApplyInstantFilter(category) {
        // TODO: This causes the ArpListPage to reload twice, figure out how to trigger before the page loads

        // Check category before clearing as this will change it's state via observation
        if (category.active) {
            this.clearAll();

        } else {
            this.clearAll();
            category.activate();        // Has no effect on NullFilterCategory
        }

        this.setSelectedCategory(category);
        this.saveState();
        this.applyFilterAction.perform();
        return true;
    }

    performApplyCustomFilter() {
        this.clearAllInstant();

        this.updateSelectedCategory();
        this.modalService.hide();

        this.saveState();
        this.applyFilterAction.perform();
        return true;
    }

    performClearFilter() {

        for (let customCategory of this.customizeCategories) {
            customCategory.clearFilters();
        }
        this.setSelectedCategory(this.allItemsFilterCategory);
        return true;
    }

    performCancel() {
        this.modalService.hide();

        for (let customCategory of this.customizeCategories) {
            customCategory.restoreFilters();
        }
        return true;
    }

    saveState() {

        let filterData = {
            filters: []
        };

        for (let category of this.categories) {
            for (let filter of category.filters) {
                if (filter.isActive()) {
                    filterData.filters.push({
                        category: category.label,
                        filterKey: filter.getStateKey(),
                        filterState: filter.getState()
                    });
                }
            }
        }

        if (this.viewState) {
            this.viewState.save('filterData', filterData);
        }
    }

    restoreState() {

        if (this.viewState) {
            let filterData = this.viewState.restore('filterData') as any;

            if (filterData) {
                if (filterData.filters.length > 0) {
                    each(filterData.filters, (data) => {
                        let category = find(this.categories, ['label', data.category]);

                        if (category) {
                            let filter = find(category.filters, (it) => {
                                return it.getStateKey() === data.filterKey;
                            });

                            if (filter) {
                                filter.setState(data.filterState);
                            }

                            category.updateActive();
                        }
                    });
                } else {
                    // Searching mode, so need all items
                    this.clearAll();
                    this.allItemsFilterCategory.active = true;
                }
            }

            let activeCategory = this.getActiveCategory();

            if (activeCategory == null) {
                this.setSelectedCategory(this.defaultCategory);
            } else if (activeCategory.instantFilter) {
                this.setSelectedCategory(activeCategory);
            } else {
                this.setSelectedCategory(this.customizeFilterCategory);
            }
        }
    }
}

