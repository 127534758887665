
import {Container, ViewResources} from 'aurelia-framework';
import {StandardValidator, ValidationMessageProvider} from 'aurelia-validation';

export class ArpValidator extends StandardValidator {
    
    private dynamicDisplayNameCallbacks: Map<string, any>;

    constructor() {
        super(Container.instance.get(ValidationMessageProvider), Container.instance.get(ViewResources));

        this.dynamicDisplayNameCallbacks = new Map<string, any>();
        (<any>this).getMessage = this.myGetMessage;
    }

    setDynamicDisplayNameCallback(propertyName, fn) {
        this.dynamicDisplayNameCallbacks.set(propertyName, fn);
    }

    getDynamicDisplayName(propertyName, displayName) {

        if (this.dynamicDisplayNameCallbacks.has(propertyName)) {
            return this.dynamicDisplayNameCallbacks.get(propertyName)();
        } else {
            return this.privateMessageProvider.getDisplayName(propertyName, displayName);
        }
    }

    myGetMessage(rule, object, value) {
        let expression = rule.message || this.privateMessageProvider.getMessage(rule.messageKey);
        let _a = rule.property, propertyName = _a.name, displayName = _a.displayName;
        if (propertyName !== null) {
            //displayName = this.messageProvider.getDisplayName(propertyName, displayName);
            displayName = this.getDynamicDisplayName(propertyName, displayName);
        }
        let overrideContext = {
            $displayName: displayName,
            $propertyName: propertyName,
            $value: value,
            $object: object,
            $config: rule.config,
            $getDisplayName: this.privateDisplayName
        };
        return expression.evaluate({ bindingContext: object, overrideContext: overrideContext }, this.privateLookupFunctions);
    };

    get privateMessageProvider() {
        return (<any>this).messageProvider;
    }

    get privateLookupFunctions() {
        return (<any>this).lookupFunctions;
    }

    get privateDisplayName() {
        return (<any>this).getDisplayName;
    }
}
