import {EnumElement, Enum} from "../../../Enum";

export class CatSize extends EnumElement {}

export class CatSizeEnum extends Enum<CatSize> {

    static readonly SMALL = new CatSize('SMALL', 'Small (< 8 pounds)');
    static readonly MEDIUM = new CatSize('MEDIUM', 'Medium (9-15 pounds)');
    static readonly LARGE = new CatSize('LARGE', 'Large (15+ pounds)');

    static readonly ELEMENTS = [
        CatSizeEnum.SMALL,
        CatSizeEnum.MEDIUM,
        CatSizeEnum.LARGE
    ];
    static readonly INSTANCE= new CatSizeEnum();

    private constructor() {
        super(CatSizeEnum.ELEMENTS);
    }
}


export class CatSizeMetric extends EnumElement {}

export class CatSizeMetricEnum extends Enum<CatSizeMetric> {

    static readonly SMALL = new CatSizeMetric('SMALL', 'Small (< 3.5 kg)');
    static readonly MEDIUM = new CatSizeMetric('MEDIUM', 'Medium (3.5-7 kg)');
    static readonly LARGE = new CatSizeMetric('LARGE', 'Large (7+ kg)');

    static readonly ELEMENTS = [
        CatSizeMetricEnum.SMALL,
        CatSizeMetricEnum.MEDIUM,
        CatSizeMetricEnum.LARGE
    ];
    static INSTANCE = new CatSizeMetricEnum();

    private constructor() {
        super(CatSizeMetricEnum.ELEMENTS);
    }
}
