import {SpeciesMetadata} from "../SpeciesMetadata";
import {AnimalCharacteristics, AnimalCharacteristicsEnum} from "../AnimalCharacteristicsEnum";
import {MedicalProcedure, MedicalProcedureEnum} from "../medical/MedicalProcedureEnum";

export function dogMetadata(): SpeciesMetadata {
    return new SpeciesMetadata(breeds, colors,characteristics,  medications, procedures, vaccines);
}

const breeds: Array<string> = [
    'Hound Mix',
    'Shepherd Mix',
    'Pit Bull Mix',
    'Terrier Mix',
    'Retriever Mix',

    'Affenpinscher',
    'Akita',
    'American Bulldog',
    'American Staffordshire Terrier',
    'Aruba Cunucu',
    'Australian Cattle Dog',
    'Australian Heeler',
    'Australian Shepherd',
    'Basenji',
    'Basset Hound',
    'Beagle',
    'Bernese Mountain Dog',
    'Bichon Frise',
    'Bloodhound',
    'Border Collie',
    'Boston Terrier',
    'Boxer',
    'Bull Terrier',
    'Cairn Terrier',
    'Cane Corso',
    'Catahoula Leopard',
    'Cavalier King Charles Spaniel',
    'Chihuahua',
    'Chow Chow',
    'Cocker Spaniel',
    'Coonhound',
    'Dachshund',
    'Dalmatian',
    'Doberman Pinscher',
    'English Foxhound',
    'French Bulldog',
    'German Shepherd',
    'German Shorthaired Pointer',
    'Golden Retriever',
    'Great Dane',
    'Great Pyrenees',
    'Greyhound',
    'Havanese',
    'Italian Greyhound',
    'Jack Russell Terrier',
    'Labrador Retriever',
    'Lhasa Apso',
    'Maltese',
    'Mastiff',
    'Miniature Greyhound',
    'Miniature Pinscher',
    'Miniature Poodle',
    'Miniature Schnauzer',
    'Newfoundland',
    'Pekingese',
    'Pembroke Welsh Corgi',
    'Pit Bull Terrier',
    'Plott Hound',
    'Pomeranian',
    'Poodle',
    'Pointer',
    'Pug',
    'Rat Terrier',
    'Rhodesian Ridgeback',
    'Rottweiler',
    'Saint Bernard',
    'Shar Pei',
    'Shetland Sheepdog',
    'Shiba Inu',
    'Shih Tzu',
    'Siberian Husky',
    'Springer Spaniel',
    'Standard Schnauzer',
    'Vizsla',
    'Weimaraner',
    'West Highland Terrier',
    'Whippet',
    'Yorkshire Terrier'
];

const colors: Array<string> = [
    "Apricot / Beige",
    "Black",
    "Tricolor (Brown, Black, & White)",
    "Brindle",
    "Brown / Chocolate",
    "Golden",
    "White / Cream",
    "Gray / Blue / Silver",
    "Merle (Blue)",
    "Yellow / Tan / Blond / Fawn",
    "Red / Chestnut / Orange",
    "Sable",
    "Bicolor",
    "Merle (Red)",
    "Harlequin"
];

const characteristics: Array<AnimalCharacteristics> = [
    AnimalCharacteristicsEnum.AGGRESSIVE_PEOPLE,
    AnimalCharacteristicsEnum.AGGRESSIVE_DOGS,
    AnimalCharacteristicsEnum.AGGRESSIVE_CATS,
    AnimalCharacteristicsEnum.BARKING,
    AnimalCharacteristicsEnum.BEGGING,
    AnimalCharacteristicsEnum.CHEWING,
    AnimalCharacteristicsEnum.CRATE_TRAINED,
    AnimalCharacteristicsEnum.DIGGING,
    AnimalCharacteristicsEnum.DOMINATING,
    AnimalCharacteristicsEnum.HOUSE_TRAINED,
    AnimalCharacteristicsEnum.JUMPING_UP,
    AnimalCharacteristicsEnum.LEASH_PULLING,
    AnimalCharacteristicsEnum.LEASH_REACTIVE,
    AnimalCharacteristicsEnum.MARKING_SPRAYING,
    AnimalCharacteristicsEnum.NOISE_ANXIETY,
    AnimalCharacteristicsEnum.RESOURCE_GUARDING_PEOPLE,
    AnimalCharacteristicsEnum.RESOURCE_GUARDING_DOGS,
    AnimalCharacteristicsEnum.SEPARATION_ANXIETY,
    AnimalCharacteristicsEnum.SHY,
    AnimalCharacteristicsEnum.SPECIAL_NEEDS,
    AnimalCharacteristicsEnum.VACCINATED,
];

const procedures: Array<MedicalProcedure> = [
    MedicalProcedureEnum.BLOODWORK,
    MedicalProcedureEnum.DENTAL,
    MedicalProcedureEnum.EAR_EXAM,
    MedicalProcedureEnum.EUTHANASIA,
    MedicalProcedureEnum.FECAL_PARASITE_TEST,
    MedicalProcedureEnum._4DX_TEST,
    MedicalProcedureEnum.HEARTWORM_TEST,
    MedicalProcedureEnum.RINGWORM_TEST,
    MedicalProcedureEnum.MICROCHIP_IMPLANT,
    MedicalProcedureEnum.NEUTER,
    MedicalProcedureEnum.OFFICE_EXAM,
    MedicalProcedureEnum.RESPIRATORY_CHECK,
    MedicalProcedureEnum.PARVO_TEST,
    MedicalProcedureEnum.SKIN_SCRAPING,
    MedicalProcedureEnum.SPAY,
];

const medications: Map<string, Array<string>> = new Map([
    [
        "Dewormer", [
            "Albon",
            "Drontal",
            "Droncit",
            "Panacur",
            "Pyrantel",
            "Sentinel Spectrum",
            "Strongid"
        ]
    ],
    [
        "Heartworm Preventative", [
            "Advantage",
            "Heartgard",
            "Iverhart",
            "Interceptor",
            "Nexgard",
            "Sentinel",
            "Sentinel Spectrum",
            "Trifexis",
            "Tri-heart",
            "Revolution"
        ]
    ],
    [
        "Heartworm Treatment", [
            "Doxycycline",
            "Ivermectin",
            "Prednisone"
        ]
    ],
    [
        "Flea/Tick", [
            "Activyl",
            "Advantage",
            "Capstar",
            "Comfortis",
            "Frontline",
            "K9 Advantix",
            "Revolution",
            "Sentinel Spectrum",
            "Sentry",
            "Triflexis"
        ]
    ],
    [
        "Antibiotic", [
            "Clavamox",
            "Convenia",
            "Doxycycline"
        ]
    ],
    [
        "Other", [
        ]
    ]
]);

const vaccines: Array<string> = [
    "Bordetella",
    "Bordetella Booster",
    "Canine Influenza",
    "DA2PP",
    "DA2PP Booster",
    "DHPP 1",
    "DHPP 2",
    "DHPP 3",
    "DHPP 4",
    "DHPP Booster",
    "Lepto",
    "Rabies",
    "Rabies Booster"
];

