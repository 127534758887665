import {Container} from "aurelia-framework";

import {WebApi} from "../WebApi";
import {ArpResource} from "../ArpResource";
import {FuzzySearcher} from "../FuzzySearcher";
import {SelectMatch} from "./arp-select";
import {GQLQuery} from "../QueryBuilder";
import {isString, isUndefined, sortBy } from "lodash";

export abstract class SelectDatasource {
    searcher: FuzzySearcher;

    choiceSet = new Set<string>();
    preferredSet = new Set<string>();
    resourceType: string;
    property: string;
    changeMarker: number = 1;           // Used to trigger validation when choice list changes

    private webApi: WebApi;

    protected constructor(resourceType: string, property: string, predefinedChoices: Array<string>) {
        this.resourceType = resourceType;
        this.property = property;
        this.webApi = Container.instance.get(WebApi);
        this.choiceSet = new Set(predefinedChoices);
        this.preferredSet = new Set(predefinedChoices);
        this.searcher = new FuzzySearcher();
    }

    preload(): Promise<void> {
        let resource = this.buildResource();
        return this.queryRemote(resource);
    }

    private buildResource(): ArpResource {
        let resource = new ArpResource(this.resourceType);

        resource.withGroup({
            _id: `$${this.property}`,
            count: {$sum: 1}
        });

        return resource;
    }

    async gqlQuery(query: GQLQuery) : Promise<void> {

        let json = await this.webApi.executeGQLQuery(query);

        for (let item of json) {
            this.choiceSet.add(item);
        }

        this.markChanged();
    }

    async queryRemote(resource: ArpResource) : Promise<void> {

        let json = await this.webApi.getJSON(resource);

        for (let item of json) {

            // TODO: Don't add ""
            if (item._id) {
                // NOTE: _id is not an mongo objectId, it is the a unique string value
                this.choiceSet.add(item._id);
            }
        }

        this.markChanged();
    }

    addChoice(choice: string): void {

        this.choiceSet.add(choice);
        this.markChanged();
    }

    setChoices(choices: Array<string>): void {
        this.choiceSet.clear();

        if (choices) {
            for (let choice of choices) {
                this.choiceSet.add(choice);
            }
        }

        this.markChanged();
    }

    match(query: string): Array<SelectMatch> {

        let matchingChoices: Array<SelectMatch> = [];
        let choices: Array<string> = Array.from(this.choiceSet);

        if (!isUndefined(query) && query != null && query.length > 0) {

            let results = this.searcher.search(query, choices);

            for (let result of results) {
                let modelValue = choices[result.refIndex];
                let displayValue = this.searcher.getStyledValue(modelValue, result);

                let match = {
                    modelValue: modelValue,
                    displayValue: this.formatDisplayValue(displayValue, modelValue),
                    selected: false
                } as SelectMatch;

                matchingChoices.push(match);
            }

        } else {
            // Show all choices
            for (let result of sortBy(choices)) {
                matchingChoices.push({
                    modelValue: result,
                    displayValue: this.formatDisplayValue(result, result),
                    selected: false
                });
            }
        }

        return matchingChoices;
    }

    formatDisplayValue(displayValue: string, modelValue: string) {
        let preferred: boolean = this.preferredSet.has(modelValue);
        if (preferred) {
            return `<p class="arp-typeahead-preferred">${displayValue}</p>`;
        } else {
            return `<p>${displayValue}</p>`;
        }
    }

    markChanged() {
        this.changeMarker = this.changeMarker + 1;
    }

    isExactMatch(query): boolean {
        return this.choiceSet.has(query);
    }

    isValid(displayValue): boolean {
        return isString(displayValue) && (displayValue.length > 0);
    }

}

