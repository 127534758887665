import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IRabbitPreferences {
    sizeAsAdult: Array<string>;         // RabbitSizeEnum
    age: Array<string>;                 // AnimalAgeEnum
    energyLevel: Array<string>;         // AnimalEnergyLevelEnum
    goodWith: Array<string>;            // AnimalGoodWithEnum
}

@Serializable()
export class RabbitPreferences implements IRabbitPreferences {

    @JsonProperty() sizeAsAdult: Array<string> = [];         // RabbitSizeEnum
    @JsonProperty() age: Array<string> = [];                 // AnimalAgeEnum
    @JsonProperty() energyLevel: Array<string> = [];         // AnimalEnergyLevelEnum
    @JsonProperty() goodWith: Array<string> = [];            // AnimalGoodWithEnum

    static getQueryFields(): string[] {
        return [
            'sizeAsAdult',
            'age',
            'energyLevel',
            'goodWith'
        ]
    }
}