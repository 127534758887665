import {IEntity, Entity, EntityId} from "../common/IEntity";
import {CategoryMetadata, ICategoryMetadata} from "./ICategoryMetadata";
import {JsonProperty, Serializable} from "typescript-json-serializer";


// Template is what to display, not what is saved with the user data
export interface IApplicationTemplate<T> extends IEntity<T> {
    type: string;       // ApplicationTypeEnum

    allowWriteIns: boolean;
    requireAnimal: boolean;
    
    welcome: string;
    categories: Array<ICategoryMetadata>;
    disclaimer: string;
}

@Serializable()
export class ApplicationTemplateEntity extends Entity implements IApplicationTemplate<EntityId> {
    @JsonProperty() type: string = '';       // ApplicationTypeEnum

    @JsonProperty() allowWriteIns: boolean = true;
    @JsonProperty() requireAnimal: boolean = true;

    @JsonProperty() welcome: string = '';
    @JsonProperty({ type: CategoryMetadata }) categories: Array<CategoryMetadata> = [];
    @JsonProperty() disclaimer: string = '';

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'allowWriteIns',
            'requireAnimal',
            'type',
            'welcome',
            ...CategoryMetadata.getQueryFields().map(it => `categories.${it}`),
            'disclaimer'
        ]
    }

    // Readonly fields from the API perspective
    static getReadOnlyFields(): string[] {
        return [
        ];
    }    
}

@Serializable()
export class ApplicationTemplateDetails extends ApplicationTemplateEntity {
    static getQueryFields(): string[] {
        return [
            ...ApplicationTemplateEntity.getQueryFields()
        ]
    }
}

export type IApplicationTemplateSummaryPartial = Omit<ApplicationTemplateEntity,
    'createdAt' |
    'updatedAt' |
    'allowWriteIns' |
    'requireAnimal' |
    'welcome' |
    'categories' |
    'disclaimer'
    >

@Serializable()
export class ApplicationTemplateSummary extends Entity implements IApplicationTemplateSummaryPartial {

    @JsonProperty() type: string = '';             // ApplicationTypeEnum

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'type'
        ]
    }
}