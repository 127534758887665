import {Enum, EnumElement} from "../../Enum";

export class UserRole extends EnumElement {}

/**
 * Defines the Roles a User can have in the Sparkie application.
 */
export class UserRoleEnum extends Enum<UserRole> {

    /**
     * Represents an unauthenticated user.  Used only to force the framework to commit the denied grants.  No user
     * will ever be assigned this role.
     */
    static readonly UNAUTHENTICATED = new UserRole('UNAUTHENTICATED', 'Unauthenticated');

    /**
     * A user that can view all resources EXCEPT for other Users.  Unable to make changes to anything other than their
     * own User account.
     */
    static readonly USER = new UserRole('USER', 'User');

    /**
     * A user that has limited access (create, read, update) of all resources EXCEPT for Tenant settings.  Can't
     * delete any resource.
     */
    static readonly MANAGER = new UserRole('MANAGER', 'Manager');

    /**
     * Represents a principle of the Rescue that has full create, read, update & delete access to all resources,
     * including Tenant settings.
     */
    static readonly ADMIN = new UserRole('ADMIN', 'Admin');

    /**
     * Account that can't be deleted, represents the owner of the rescue.
     */
    static readonly OWNER = new UserRole('OWNER', 'Owner');

    static readonly ELEMENTS = [
        UserRoleEnum.UNAUTHENTICATED,
        UserRoleEnum.USER,
        UserRoleEnum.MANAGER,
        UserRoleEnum.ADMIN,
        UserRoleEnum.OWNER
    ];
    static readonly INSTANCE = new UserRoleEnum();

    private constructor() {
        super(UserRoleEnum.ELEMENTS);
    }
}
