import {Container} from 'aurelia-framework';

import {Observer} from '../Observer';
import {ArpResource, IQuery} from "../ArpResource";
import {FilterBase} from "./FilterBase";

// TODO: Rename this to FilterGroup
export class FilterCategory {

    label: string;
    id: string;
    active: boolean = false;
    instantFilter: boolean = false;
    filters: Array<FilterBase> = [];
    private observer: Observer;

    constructor(label: string) {
        this.label = label;
        this.id = label.replace(/ /g, '_').toLowerCase();
        this.active = false;

        this.observer = Container.instance.get(Observer);
    }

    addFilter<T extends FilterBase>(filter: T) : T {

        filter.observe(this);

        this.filters.push(filter);

        return filter;
    }

    /**
     * Invoked when the databinding engine binds the view.
     *
     * @param bindingContext
     * @param overrideContext
     */
    bind(bindingContext: any, overrideContext: any) {
        this.updateActive();
    }

    unbind() {
        this.observer.unObserveAll();
    }

    configureResource(resource: IQuery) {
        for (let filter of this.filters) {
            if (filter.isActive()) {
                filter.configureResource(resource);
            }
        }
    }

    clearFilters() {
        for (let filter of this.filters) {
            filter.clearFilter();
        }

        this.updateActive();
    }

    saveFilters() {
        for (let filter of this.filters) {
            filter.save();
        }
    }

    restoreFilters() {
        for (let filter of this.filters) {
            filter.restore();
        }
    }

    updateActive() {
        let newState = false;

        for (let filter of this.filters) {
            if (filter.isActive()) {
                newState = true;
                break;
            }
        }

        this.active = newState;
    }
}

