import {IReportConverter} from "@sparkie/shared-model/src";

export class YesNoConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {
        return modelValue ? "Yes" : "No";
    }
}

