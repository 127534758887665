import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IWebsitePortalSettings {
    enabled: boolean;
}

@Serializable()
export class WebsitePortalSettings implements IWebsitePortalSettings {
    @JsonProperty() enabled: boolean = false;

    static getQueryFields(): string[] {
        return [
            'enabled'
        ]
    }
}

