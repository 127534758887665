import {Enum, EnumElement} from "../../Enum";

export class WeightUnits extends EnumElement {}

export class WeightUnitsEnum extends Enum<WeightUnits> {

    static readonly IMPERIAL = new WeightUnits('IMPERIAL', 'Imperial');
    static readonly METRIC = new WeightUnits('METRIC', 'Metric');

    static readonly ELEMENTS = [
        WeightUnitsEnum.IMPERIAL,
        WeightUnitsEnum.METRIC
    ];
    static readonly INSTANCE = new WeightUnitsEnum();

    private constructor() {
        super(WeightUnitsEnum.ELEMENTS);
    }
}

