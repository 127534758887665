/**
 * Returns the thumbnail file name based on the original file name, width, and height.  Will convert
 * the file extension to jpg.
 *
 * @param fileName - The original file name.
 * @param width - The width of the thumbnail.
 * @returns The thumbnail file name.
 */
export function getThumbnailName(fileName: string, width: number): string {
    let thumbnailFileName;
    const terms = fileName.split(".");

    if (terms.length >= 2) {
        const nameIndex = terms.length - 2;
        const extensionIndex = terms.length - 1;
        terms[nameIndex] = `${terms[nameIndex]}_thumb${width}`;
        terms[extensionIndex] = "jpg";
        thumbnailFileName = terms.join(".");
    } else {
        thumbnailFileName = `${fileName}_thumb${width}.jpg`;
    }

    return thumbnailFileName;
}

export function makeJpegFileName(fileName: string): string {

    const terms = fileName.split(".");

    if (terms.length === 1) {
        return `${fileName}.jpg`;
    } else {
        const extensionIndex = terms.length - 1;
        terms[extensionIndex] = "jpg";
        return terms.join(".");
    }
}

/**
 * Converts a canvas element contents to a Blob object.
 *
 * @param canvas - The HTMLCanvasElement to be converted.
 * @param type - The MIME type of the resulting Blob.
 * @param quality - The quality of the resulting image.
 * @returns A Promise that resolves to a Blob object representing the canvas content.
 */
export async function toBlob(canvas: HTMLCanvasElement, type: string, quality: number = 0.92): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob: Blob) => {
            resolve(blob);
        }, type, quality);
    });
}

/**
 * Converts a Blob object to an ArrayBuffer.
 *
 * @param {Blob} blob - The Blob object to be converted.
 * @returns {Promise<ArrayBuffer>} A promise that resolves to an ArrayBuffer.
 */
export async function toArrayData(blob: Blob): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as ArrayBuffer);
        };
        reader.readAsArrayBuffer(blob);
    });
}
