/**
 * Specifies the available logging levels.
 */
export enum LogLevel {
    /**
     * No logging.
     */
    none,

    /**
     * Log only error messages.
     */
    error,

    /**
     * Log API error messages or above.
     */
    apiError,

    /**
     * Log warnings messages or above.
     */
    warn,

    /**
     * Log navigation messages or above.
     */
    nav,

    /**
     * Log informational messages or above.
     */
    info,

    /**
     * Log all messages.
     */
    debug
}

