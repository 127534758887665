import {EnumElement, Enum} from "../../../Enum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class FelvFivComboTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {
        let felv;
        let fiv;

        switch (subType) {
            case FelvFivComboTestOutcomeEnum.FELV_POSITIVE.view:
                felv = ConditionStatusEnum.POSITIVE;
                fiv = ConditionStatusEnum.NEGATIVE;
                break;

            case FelvFivComboTestOutcomeEnum.FIV_POSITIVE.view:
                felv = ConditionStatusEnum.NEGATIVE;
                fiv = ConditionStatusEnum.POSITIVE;
                break;

            case FelvFivComboTestOutcomeEnum.BOTH_POSITIVE.view:
                felv = ConditionStatusEnum.POSITIVE;
                fiv = ConditionStatusEnum.POSITIVE;
                break;

            case FelvFivComboTestOutcomeEnum.BOTH_NEGATIVE.view:
                felv = ConditionStatusEnum.NEGATIVE;
                fiv = ConditionStatusEnum.NEGATIVE;
                break;

            default:
                felv = ConditionStatusEnum.UNKNOWN;
                fiv = ConditionStatusEnum.UNKNOWN;
                break;
        }

        outcomes.push(MedicalConditionEnum.FELV.createCondition(felv));
        outcomes.push(MedicalConditionEnum.FIV.createCondition(fiv));
    }
}

export class FelvFivComboTestOutcomeEnum extends Enum<FelvFivComboTestOutcome> {

    static readonly BOTH_NEGATIVE = new FelvFivComboTestOutcome('BOTH_NEGATIVE', 'Both negative');
    static readonly FELV_POSITIVE = new FelvFivComboTestOutcome('FELV_POSITIVE', 'FeLV positive');
    static readonly FIV_POSITIVE = new FelvFivComboTestOutcome('FIV_POSITIVE', 'FIV positive');
    static readonly BOTH_POSITIVE = new FelvFivComboTestOutcome('BOTH_POSITIVE', 'Both positive');

    static readonly ELEMENTS = [
        FelvFivComboTestOutcomeEnum.BOTH_NEGATIVE,
        FelvFivComboTestOutcomeEnum.FELV_POSITIVE,
        FelvFivComboTestOutcomeEnum.FIV_POSITIVE,
        FelvFivComboTestOutcomeEnum.BOTH_POSITIVE
    ];
    static readonly INSTANCE = new FelvFivComboTestOutcomeEnum();

    private constructor() {
        super(FelvFivComboTestOutcomeEnum.ELEMENTS);
    }
}
