import {EnumElement, Enum} from "../../../Enum";

export class MedicalRecordType extends EnumElement {}

export class MedicalRecordTypeEnum extends Enum<MedicalRecordType> {

    static readonly MEDICATION = new MedicalRecordType('MEDICATION', 'Medication');
    static readonly VACCINATION = new MedicalRecordType('VACCINATION', 'Vaccination');
    static readonly PROCEDURE = new MedicalRecordType('PROCEDURE', 'Procedure');
    static readonly OTHER = new MedicalRecordType('OTHER', 'Other Medical');

    static readonly ELEMENTS = [
        MedicalRecordTypeEnum.MEDICATION,
        MedicalRecordTypeEnum.VACCINATION,
        MedicalRecordTypeEnum.PROCEDURE,
        MedicalRecordTypeEnum.OTHER
    ];
    static readonly INSTANCE = new MedicalRecordTypeEnum();

    private constructor() {
        super(MedicalRecordTypeEnum.ELEMENTS);
    }
}
