/**
 * These are EXTERNAL integrations.  Website portal and application portal are INTERNAL and not defined here.
 */
import {Address, IAddress} from "../common/IAddress";

export interface IIntegration {
    type: string;
    enabled: boolean;

    shelterName: string;        // Name of the shelter as seen by the integration.  Used to name the csv file in some integrations. Not used in others.
    shelterId: string;          // Functions as the FTP username and used to name the csv file
    password?: string;          // This only exists in the UI, it's not defined in the db schema
    passwordHash?: string;      // This is what is stored in the database

    // For chip integrations
    registeredFirstName: string;
    registeredLastName: string;
    registeredAddress: IAddress;
    registeredEmail: string;
    registeredPhone: string;

    checkedDate: Date;          // Informational, doesn't factor in deciding to update
    uploadedDate: Date;
    uploadedMessage: string;
}

export class Integration implements IIntegration {
    type: string = '';
    enabled: boolean = false;

    shelterName: string = '';        // Name of the shelter as seen by the integration.  Used to name the csv file in some integrations. Not used in others.
    shelterId: string = '';          // Functions as the FTP username and used to name the csv file
    password?: string = '';          // This only exists in the UI, it's not defined in the db schema
    passwordHash?: string = '';      // This is what is stored in the database

    registeredFirstName: string = '';
    registeredLastName: string = '';
    registeredAddress: Address = new Address();
    registeredEmail: string = '';
    registeredPhone: string = '';

    checkedDate: Date = null;          // Informational, doesn't factor in deciding to update
    uploadedDate: Date = null;
    uploadedMessage: string = '';

    constructor(type: string = "", enabled: boolean = true) {
        this.type = type;
        this.enabled = enabled;
    }

    static getQueryFields(): string[] {
        return [
            'type',
            'enabled',
            'shelterName',
            'shelterId',
            'registeredFirstName',
            'registeredLastName',
            ...Address.getQueryFields().map(it => `registeredAddress.${it}`),
            'registeredEmail',
            'registeredPhone',
            'checkedDate',
            'uploadedDate',
            'uploadedMessage'
        ]
    }
}