import {EnumElement, Enum} from "../../../Enum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class FivTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {
        let outcome;

        switch (subType) {
            case FivTestOutcomeEnum.FIV_POSITIVE.view:
                outcome = ConditionStatusEnum.POSITIVE;
                break;

            case FivTestOutcomeEnum.FIV_NEGATIVE.view:
                outcome = ConditionStatusEnum.NEGATIVE;
                break;

            default:
                outcome = ConditionStatusEnum.UNKNOWN;
                break;
        }

        outcomes.push(MedicalConditionEnum.FIV.createCondition(outcome));
    }
}

export class FivTestOutcomeEnum extends Enum<FivTestOutcome> {

    static readonly FIV_POSITIVE = new FivTestOutcome('FIV_POSITIVE', 'FIV positive');
    static readonly FIV_NEGATIVE = new FivTestOutcome('FIV_NEGATIVE', 'FIV negative');

    static readonly ELEMENTS = [
        FivTestOutcomeEnum.FIV_POSITIVE,
        FivTestOutcomeEnum.FIV_NEGATIVE
    ];
    static readonly INSTANCE= new FivTestOutcomeEnum();

    private constructor() {
        super(FivTestOutcomeEnum.ELEMENTS);
    }
}
