import {buildKey, buildMap, IntegrationMetadata} from "../IntegrationMetadata";

export function catIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}

function translateBreed(sparkieBreed: string): string {
    let breedKey = buildKey(sparkieBreed);

    let petFinderBreed = catBreedMap.get(breedKey);

    if (petFinderBreed) {
        return petFinderBreed;
    } else {
        switch (sparkieBreed) {
            default:
                return "";
        }
    }
}

function translateColor(sparkieColor: string): string {
    if (catColors.has(sparkieColor)) {
        return sparkieColor;
    }
    switch (sparkieColor) {
        default:
            return "";
    }
}

const catBreeds = new Set<string>([
    "Abyssinian",
    "American Bobtail",
    "American Curl",
    "American Shorthair",
    "American Wirehair",
    "Applehead Siamese",
    "Balinese",
    "Bengal",
    "Birman",
    "Bombay",
    "British Shorthair",
    "Burmese",
    "Burmilla",
    "Calico",
    "Canadian Hairless",
    "Chartreux",
    "Chausie",
    "Chinchilla",
    "Cornish Rex",
    "Cymric",
    "Devon Rex",
    "Dilute Calico",
    "Dilute Tortoiseshell",
    "Domestic Long Hair",
    "Domestic Medium Hair",
    "Domestic Short Hair",
    "Egyptian Mau",
    "Exotic Shorthair",
    "Extra-Toes Cat / Hemingway Polydactyl",
    "Havana",
    "Himalayan",
    "Japanese Bobtail",
    "Javanese",
    "Korat",
    "LaPerm",
    "Maine Coon",
    "Manx",
    "Munchkin",
    "Nebelung",
    "Norwegian Forest Cat",
    "Ocicat",
    "Oriental Long Hair",
    "Oriental Short Hair",
    "Oriental Tabby",
    "Persian",
    "Pixiebob",
    "Ragamuffin",
    "Ragdoll",
    "Russian Blue",
    "Scottish Fold",
    "Selkirk Rex",
    "Siamese",
    "Siberian",
    "Silver",
    "Singapura",
    "Snowshoe",
    "Somali",
    "Sphynx / Hairless Cat",
    "Tabby",
    "Tiger",
    "Tonkinese",
    "Torbie",
    "Tortoiseshell",
    "Turkish Angora",
    "Turkish Van",
    "Tuxedo",
    "York Chocolate",
]);

const catBreedMap = buildMap(catBreeds);

const catColors = new Set<string>([
    "Black",
    "Black & White / Tuxedo",
    "Cream Point",
    "Blue Cream",
    "Blue Point",
    "Brown / Chocolate",
    "Dilute Calico",
    "Buff / Tan / Fawn",
    "Calico",
    "Dilute Tortoiseshell",
    "Chocolate Point",
    "Cream / Ivory",
    "Flame Point",
    "Gray / Blue / Silver",
    "Lilac Point",
    "Orange / Red",
    "Seal Point",
    "Smoke",
    "Tabby (Brown / Chocolate)",
    "Tabby (Buff / Tan / Fawn)",
    "Tabby (Gray / Blue / Silver)",
    "Tabby (Leopard / Spotted)",
    "Tabby (Orange / Red)",
    "Tabby (Tiger Striped)",
    "Torbie",
    "Tortoiseshell",
    "White",
    "Gray & White",
    "Orange & White",
    "Buff & White"
]);
