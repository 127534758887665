import {FileUpload, IFileUpload} from "./IFileUpload";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IPhotoUpload extends IFileUpload {
    smallThumbnailUrl: string;
    mediumThumbnailUrl: string;
}

@Serializable()
export class PhotoUpload extends FileUpload implements PhotoUpload {
    @JsonProperty() smallThumbnailUrl: string = '';
    @JsonProperty() mediumThumbnailUrl: string = '';

    static getQueryFields(): string[] {
        return [
            ...FileUpload.getQueryFields(),
            'smallThumbnailUrl',
            'mediumThumbnailUrl'
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            ...FileUpload.getReadOnlyFields()
        ];
    }
}

export interface IHasPhotos {
    photos: Array<IPhotoUpload>;
}

