
/**
 * ViewModel for presenting messages to the user.
 */
export class ArpMessage {

    description: string;
    details: Array<string> = [];

    constructor(description: string) {
        this.description = description;
    }

    addDetail(detail: string) {
        this.details.push(detail)
    }
}


