import {JsonProperty, Serializable} from "typescript-json-serializer";
import {IMedicalConditionStatus, MedicalConditionStatus} from "./IMedicalConditionStatus";
import type { EntityId } from "../common/IEntity";

export interface IAnimalMedical<T> {
    veterinarian: T;
    veterinarianName: string;

    // TODO: Make enum and move out
    health: string; // Healthy, Treatable - rehabilitate, Treatable - manage, Unhealthy and Untreatable

    conditions: Array<IMedicalConditionStatus>;
}

@Serializable()
export class AnimalMedical implements IAnimalMedical<EntityId> {
    @JsonProperty() veterinarian: EntityId = null;
    @JsonProperty() veterinarianName: string = '';
    @JsonProperty() health: string = ''; // Healthy, Treatable - rehabilitate, Treatable - manage, Unhealthy and Untreatable
    @JsonProperty({ type: MedicalConditionStatus })
    conditions: Array<MedicalConditionStatus> = [];

    static getQueryFields(): string[] {
        return [
            'veterinarian',
            'veterinarianName',
            'health',
            ...MedicalConditionStatus.getQueryFields().map(it => `conditions.${it}`)
        ];
    }
}