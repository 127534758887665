import {IMedicalRecord} from "../common/IMedicalRecord";
import {IMiscRecord} from "../common/IMiscRecord";
import {ITask} from "../task/ITask";
import {MedicalRecordTypeEnum} from "../animal/medical/MedicalRecordTypeEnum";
import {TaskTypeEnum} from "../task/TaskTypeEnum";
import {TrainingTypeEnum} from "../animal/TrainingTypeEnum";


export class TaskFormatter {

    static getSummary<T>(task: ITask<T>) : string {

        // Used as the first line of the detail/description column in task tables

        switch (task.taskType) {
            case TaskTypeEnum.BASIC.model:
            case TaskTypeEnum.APPLICATION.model:
                // No summary needed for basic tasks
                return "";

            case TaskTypeEnum.GROOMING.model:
                // No summary needed for grooming tasks (only the details field is used)
                return "";

            case TaskTypeEnum.TRAINING.model:
                return this.getTrainingSummary(task.miscRecord);

            case TaskTypeEnum.MEDICAL.model:
                return this.getMedicalSummary(task.medicalRecord);

            default:
                return "";
        }
    }

    static getDetails<T>(task: ITask<T>) : string {

        // Used as the second line of the detail/description column in task tables

        switch (task.taskType) {
            case TaskTypeEnum.BASIC.model:
            case TaskTypeEnum.APPLICATION.model:
                return task.description;

            case TaskTypeEnum.GROOMING.model:
                return task.miscRecord.details;

            case TaskTypeEnum.TRAINING.model:
                return task.miscRecord.details;

            case TaskTypeEnum.MEDICAL.model:
                return task.medicalRecord.note;

            default:
                return "";
        }
    }

    static getMedicalSummary<T>(medicalRecord: IMedicalRecord<T>) : string {
        switch (medicalRecord.category) {
            // Medication -> Medication Type -> Medication Brand - > Dosage
            case MedicalRecordTypeEnum.MEDICATION.model:
                if (medicalRecord.subType && medicalRecord.details) {
                    return `${medicalRecord.type} (${medicalRecord.subType}, ${medicalRecord.details})`;
                } else if (medicalRecord.subType) {
                    return `${medicalRecord.type} (${medicalRecord.subType})`;
                } else {
                    return `${medicalRecord.type} (Generic)`;
                }

            // Vaccination -> Vaccine Type -> XXXXXX -> Rabies Tag
            case MedicalRecordTypeEnum.VACCINATION.model:
                if (medicalRecord.details) {
                    return `${medicalRecord.type} (Tag Number: ${medicalRecord.details})`;
                } else {
                    return `${medicalRecord.type}`;
                }

            // Procedure -> Procedure Type -> Outcome -> XXXXXX
            case MedicalRecordTypeEnum.PROCEDURE.model:
                if (medicalRecord.subType) {
                    return `${medicalRecord.type} (${medicalRecord.subType})`;
                } else {
                    return `${medicalRecord.type}`;
                }

            // Other Medical -> XXXXXX -> XXXXXX -> Detail
            case MedicalRecordTypeEnum.OTHER.model:
                return `${medicalRecord.details}`;
        }

        return '';
    }

    static getTrainingSummary<T>(miscRecord: IMiscRecord<T>) : string {
        return TrainingTypeEnum.INSTANCE.fromModel(miscRecord.subType).view;
    }


}