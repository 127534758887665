import $ from 'jquery';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';

import {autoinject, bindable, Container, customElement} from 'aurelia-framework';

import {WebApi} from '../WebApi';
import {UploadResource} from '../UploadResource';
import {ArpLogger, ArpLogManager} from "..";

@customElement('arp-file-upload')
@autoinject()
export class ArpFileUploadCustomElement {

    @bindable id;
    @bindable theFile;      // the-file in the template!

    private element: Element;
    private webApi: any;
    private logger: ArpLogger;
    private options: any;
    private dropzone: Dropzone;
    private resolve: any;
    private reject: any;

    constructor(element: Element) {
        this.element = element;
        this.webApi = Container.instance.get(WebApi);
        this.logger = ArpLogManager.getLogger("arp-file-upload");
    }

    attached() {
        this.logger.debug(`attached`);

        this.options = {
            clickable: true,
            dictDefaultMessage: "Drop files or click here to upload",
            method: "put",
            maxFiles:1,
            autoQueue: true, // Make sure the files aren't queued until manually added
            autoProcessQueue: false,
            maxFilesize: 20,                // MB
            thumbnailWidth: 80,
            thumbnailHeight: 80,
            previewTemplate: document.getElementById('preview-template').innerHTML,
            url: (files) => this.getUrl(files),
            accept: (file, done) => this.accept(file, done),
            sending: (file, xhr, formData) => this.sending(file, xhr, formData),
//            sent: (file) => this.sent(file),
            complete: (file) => this.complete(file)
        };

        this.dropzone = new Dropzone(this.element.children[0] as HTMLElement, this.options);

        if (this.theFile.url) {
            this.addExisting(this.theFile);
        }

        this.dropzone.on("maxfilesexceeded", (file) => this.maxfilesexceeded(file));
    }

    addExisting(existingFile) {
        // Create the mock file:
        let mockFile = {
            url: existingFile.url,
            name: existingFile.filename,
            size: 12345
        };

        // Call the default addedfile event handler
        this.dropzone.emit("addedfile", mockFile);
        $("[data-dz-progressregion]").hide();
    }

    maxfilesexceeded(file) {
        this.dropzone.removeAllFiles();
        this.dropzone.addFile(file);
    }

    accept(file, done) {
        this.logger.debug(`accept ${JSON.stringify(file)}`);

        // `/types/file/action/sign?file_name=${file.name}&file_type=${file.type}&bucket=private&id=${this.id}`;
        let resource = new UploadResource()
            .withAction('sign')
            .withQueryParameter('id', this.id)
            .withQueryParameter('file_name', file.name)
            .withQueryParameter('file_type', file.type)
            .withQueryParameter('bucket', 'private');

        return this.webApi.postJSON(resource)
            .then(parsedResponse => {

                file.signedUrl = parsedResponse.signedUrl;              // This is the url to use when sending the file
                this.theFile.url = parsedResponse.uploadFilePath;       // This is the url path on the server
                this.theFile.filename = file.name;                      // This is the original file name

                done();     // Passed in to allow async accepting
            });
    }

    getUrl(files) {
        // There should always be only a single file!
        if (files.length === 1) {
            let file = files[0];
            return file.signedUrl;
        }

        return "";
    }

    sending(file, xhr, formData) {
        let _send = xhr.send;
        xhr.send = function() {
            _send.call(xhr, file);
        };

        // xhr.onreadystatechange = function() { // Call a function when the state changes.
        //     if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
        //         // Request finished. Do processing here.
        //     }
        // }
    }

    complete(file) {
        if (file.previewElement) {
            file.previewElement.classList.add("dz-complete");
        }

        if (file.status === 'error') {

            if (this.reject) {
                this.reject(new Error(file.previewElement.innerText));
            }
        } else {
            this.logger.debug(`complete ${JSON.stringify(this.theFile)}`);

            if (this.resolve) {
                this.resolve(file);
            }
        }
    }

    startUpload() {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;

            this.dropzone.processQueue();
        });
    }
}

