import { SessionManager } from "auth/SessionManger";
import { Appender } from "../Appender";
import { Logger } from "../ArpLogger";
import { LogLevel } from "../LogLevel";

/*
 * An implementation of the Appender interface.
 */
export class ConsoleLogAppender implements Appender {
    private readonly logLevel: LogLevel;

    constructor(readonly sessionManager: SessionManager) {
        this.logLevel = this.sessionManager.sessionState?.isDeveloperMode ? LogLevel.debug : LogLevel.warn;
    }

    /**
     * Appends a debug log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    debug(logger: { id: string }, ...rest: any[]): void {
        if (this.logLevel >= LogLevel.debug) {
            console.debug(`DEBUG [${logger.id}]`, ...rest);
        }
    }

    /**
     * Appends an info log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    info(logger: Logger, ...rest: any[]): void {
        if (this.logLevel >= LogLevel.info) {
            console.info(`INFO  [${logger.id}]`, ...rest);
        }
    }

    /**
     * Appends an nav log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    nav(logger: Logger, ...rest: any[]): void {
        if (this.logLevel >= LogLevel.nav) {
            console.info(`NAV   [${logger.id}]`, ...rest);
        }
    }

    /**
     * Appends a warning log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    warn(logger: Logger, ...rest: any[]): void {
        if (this.logLevel >= LogLevel.warn) {
            console.warn(`WARN  [${logger.id}]`, ...rest);
        }
    }

    /**
     * Appends an api error log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    apiError(logger: Logger, ...rest: any[]): void {
        if (this.logLevel >= LogLevel.apiError) {
            console.error(`ERROR [${logger.id}]`, ...rest);
        }
    }

    /**
     * Appends an error log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    error(logger: Logger, ...rest: any[]): void {
        if (this.logLevel >= LogLevel.error) {
            console.error(`ERROR [${logger.id}]`, ...rest);
        }
    }

    /**
     * Increases indentation of subsequent lines by two spaces.
     * If one or more `label`s are provided, those are printed first without the additional indentation.
     */
    group(logger: Logger, ...rest: any[]): void {
        console.group(`INFO  [${logger.id}]`, ...rest);
    }

    /**
     * The `console.groupCollapsed()` function is an alias for {@link console.group()}.
     */
    groupCollapsed(logger: Logger, ...rest: any[]): void {
        console.groupCollapsed(`INFO  [${logger.id}]`, ...rest);
    }

    /**
     * Decreases indentation of subsequent lines by two spaces.
     */
    groupEnd(): void {
        console.groupEnd();
    }
}
