import kebabCase from "lodash/kebabCase";
import isEmpty from "lodash/isEmpty";

const dataTestId = 'data-test-id';

export type DataTestId = {
    [dataTestId]?: string;
}

/**
 * Construct an test identifier for an element in the format required by the e2e automation code.
 * 
 * @param id the identifier for the element
 * @param parentId the id of the parent element, if any
 * 
 * @example 
 * <div {...makeTestId('my-id')} />
 * 
 * @returns a test id object that can be spread into a JSX element
 */
export function makeTestId(id: string, parentId?: DataTestId): DataTestId {
    
    const mappedId = makeId(id);

    return isEmpty(id) ? {} : {
        [dataTestId]: [parentId?.[dataTestId], mappedId].filter(Boolean).join('-')
    };
}

/**
 * 
 * @param value Given a string, return a kebab cased string.
 * 
 * @example 
 * makeId('My String') => 'my-string'
 * makeId('MyString') => 'my-string'
 * makeId('__My_String__') => 'my-string'
 * 
 * @returns the id string
 */
export function makeId(...args: string[]): string {
    return args
        .map(it => kebabCase(it))
        .join('-');
}