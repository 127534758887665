import {HttpClient, HttpResponseMessage, RequestBuilder} from "aurelia-http-client";
import {ArpLogger, ArpLogManager} from "../arp-framework";

/**
 * Service for accessing the Sparkie client authentication API (hosted at /auth/**
 */
export class ClientAuthentication {

    private logger: ArpLogger = ArpLogManager.getLogger(`auth`);;
    private httpClient: HttpClient = new HttpClient();

    constructor() {
        this.httpClient.configure((builder: RequestBuilder) => {
            builder.withHeader('Content-Type', 'application/json');
            builder.withHeader('sparkie-client', 'web-app');
            builder.withInterceptor({
                response: (message: HttpResponseMessage) => {
                    const serverVersion = message.headers.get("sparkie-release-version");

                    if (serverVersion !== SPARKIE_VERSION) {
                        this.logger.info(`Force Version Mismatch: server: ${serverVersion} != client: ${SPARKIE_VERSION}`);

                        window.localStorage.removeItem("version-update-needed");
                        window.location.reload();
                    }

                    return message;
                }
            });
        });
    }

    async login(username: string, userId: string, password: string) : Promise<HttpResponseMessage> {
        let resource = `/auth/login`;
        let body = {
            username: username,
            userId: userId,
            password: password
        };

        let response: HttpResponseMessage = await this.httpClient.post(resource, body);

        localStorage.setItem('csrf', response.content.csrf);

        return Promise.resolve(response);
    }

    logout() {
        return this.httpClient
            .post('/auth/logout', {});
    }

    forgot(username: string, userId: string) {
        return this.httpClient
            .post('/auth/forgot', { username: username, userId: userId });
    }

    resetPassword(token: string, password: string, passwordConfirm: string) {
        return this.httpClient
            .post(`/auth/reset/${token}`, {
                password: password,
                confirm: passwordConfirm
            });
    }
}
