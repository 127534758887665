import {IQuestionMetadata, QuestionMetadata} from "../common/IQuestionMetadata";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ICategoryMetadata {
    displayName: string;
    questions: Array<IQuestionMetadata>
}

@Serializable()
export class CategoryMetadata implements ICategoryMetadata {
    @JsonProperty() displayName: string = '';
    @JsonProperty({ type: QuestionMetadata }) questions: Array<QuestionMetadata> = [];

    static getQueryFields(): string[] {
        return [
            'displayName',
            ...QuestionMetadata.getQueryFields().map(it => `questions.${it}`)
        ]
    }
}