import {EnumElement, Enum} from "../../Enum";

export class TaskType extends EnumElement {}

export class TaskTypeEnum extends Enum<TaskType> {

    static readonly MEDICAL = new TaskType('MEDICAL', 'Medical');
    static readonly BASIC = new TaskType('BASIC', 'Basic');
    static readonly GROOMING = new TaskType('GROOMING', 'Grooming');
    static readonly TRAINING = new TaskType('TRAINING', 'Training');
    static readonly APPLICATION = new TaskType('APPLICATION', 'Application');

    // Admin, PreAdoption, PostAdoption

    static readonly ELEMENTS = [
        TaskTypeEnum.MEDICAL,
        TaskTypeEnum.BASIC,
        TaskTypeEnum.GROOMING,
        TaskTypeEnum.TRAINING,
        TaskTypeEnum.APPLICATION
    ];
    static readonly INSTANCE = new TaskTypeEnum();

    private constructor() {
        super(TaskTypeEnum.ELEMENTS);
    }
}
