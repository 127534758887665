import {JsonProperty} from "typescript-json-serializer";

/**
 * Data that is sent in a Transfer invitation.  Also returned from the decode GQL
 */
export interface ITransferInvitation {
    invitationId: string;
    destinationTenantId: string;              // Destination tenant id
    destinationTenantName: string;            // Destination tenant id
    destinationUsername: string;         // User in Destination tenant (The inviter)
    destinationUserFirst: string;
    destinationUserLast: string;
    sourceAdminUsername: string;
}

export class TransferInvitation implements ITransferInvitation {

    @JsonProperty() invitationId: string = '';
    @JsonProperty() destinationTenantId: string = '';
    @JsonProperty() destinationTenantName: string = '';
    @JsonProperty() destinationUsername: string = '';
    @JsonProperty() destinationUserFirst: string = '';
    @JsonProperty() destinationUserLast: string = '';
    @JsonProperty() sourceAdminUsername: string = '';

    static getQueryFields(): string[] {
        return [
            'destinationTenantId',
            'destinationTenantName',
            'destinationUsername',
            'destinationUserFirst',
            'destinationUserLast',
            'sourceAdminUsername'
        ]
    }
}
