import {bindable, customElement} from 'aurelia-framework';

@customElement("arp-switch")
@bindable("value")
export class ArpSwitch {

    @bindable label;

    constructor() {
    }

    bind(bindingContext, overrideContext) {
    }

    valueChanged(newValue, oldValue) {
        this.toString();
    }
}

