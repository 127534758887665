import { Decimal128 } from "bson";

export class Weight {
    readonly weightInBaseUnits: number; // Ounces or Grams

    constructor(weightInBaseUnits: number) {
        this.weightInBaseUnits = weightInBaseUnits;
    }

    get pounds(): number {
        return Math.abs(Math.trunc(this.weightInBaseUnits / 16));
    }

    get roundedPounds(): number {
        return Math.round(this.weightInBaseUnits / 16);
    }

    get kilograms(): number {
        return Math.abs(Math.trunc(this.weightInBaseUnits / 1000));
    }

    get roundedKilograms(): number {
        return Math.round(this.weightInBaseUnits / 1000);
    }

    get ounces(): number {
        return Math.abs(this.weightInBaseUnits % 16);
    }

    get grams(): number {
        return Math.abs(this.weightInBaseUnits % 1000);
    }

    toString(): string {
        return `${this.pounds} lbs ${this.ounces} oz`;
    }

    toImperialString(): string {
        return `${this.pounds} lbs ${this.ounces} oz`;
    }

    toMetricString(): string {
        return `${this.kilograms} kg ${this.grams} g`;
    }

    toDecimal128(): Decimal128 {
        return Decimal128.fromString(this.weightInBaseUnits.toString());
    }

    /**
     * Parse strings of the form "1.44 lbs"
     * 
     * @param input 
     * @returns 
     */
    static parseDecimalString(input: string): Weight {
        const terms = input.replace("lbs", "").replace("lb", "").trim().split(".");

        const pounds = parseInt(terms[0], 10);

        if (isNaN(pounds)) {
            throw new Error(`Invalid weight string: ${input}`);
        }

        const fraction = terms.length === 2 ? parseInt(terms[1], 10) : 0

        if (isNaN(fraction)) {
            throw new Error(`Invalid weight string: ${input}`);
        }

        const weightInOunces = (pounds * 16) + Math.round(fraction / 100 * 16);
        return new Weight(weightInOunces);
    }

    static fromDecimal128(weightInBaseUnits: Decimal128): Weight {
        let weightInBaseUnitsString = weightInBaseUnits.toString();

        return new Weight(parseInt(weightInBaseUnitsString, 10));
    }

    static fromPoundsAndOunces(pounds: number, ounces: number): Weight {
        let weightInOunces = pounds * 16 + ounces;
        return new Weight(weightInOunces);
    }

    static fromKilogramsAndGrams(kilograms: number, grams: number): Weight {
        let weightInGrams = kilograms * 1000 + grams;
        return new Weight(weightInGrams);
    }
}
