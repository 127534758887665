import create from 'zustand';

import {ApplicationTemplateEntity, TenantEntity, UserEntity} from "@sparkie/shared-model/src";
import {ServerMode} from "@sparkie/shared-model/src";
import {UserRole} from "@sparkie/shared-model/src";

export interface ISessionState {
    activeTenant: TenantEntity;
    activeUser: UserEntity;
    activeRole: UserRole;
    applicationVersion: string;
    serverMode: ServerMode;
}

export const useSessionStore = create<ISessionState>(() => ({
    activeTenant: null,
    activeUser: null,
    activeRole: null,
    applicationVersion: null,
    serverMode: null
}));

/**
 *  A place to store application global parameters
 */
export class SessionState {

    public readonly FACEBOOK_URL: string = 'https://www.facebook.com/sharer/sharer.php?u=';
    public readonly STORAGE_URL: string  = 's3.amazonaws.com';
    public readonly PRODUCT_NAME: string  = 'Sparkie';

    // TODO: These are set to null on a logout, which causes exceptions, make private and use safe getters instead.
    activeTenant: TenantEntity = null;
    activeUser: UserEntity = null;
    activeRole: UserRole = null;
    applicationVersion: string;
    serverMode: ServerMode;

    constructor() {
    }

    isProductionMode() : boolean {
        return this.serverMode && this.serverMode.isProductionMode();
    }

    isDeveloperMode() : boolean {
        return this.serverMode && this.serverMode.isDeveloperMode();
    }

    isAuthenticated() {
        return this.activeUser !== null && this.activeTenant !== null;
    }

    getPublicStorageBucket() : string {

        let bucket = this.isProductionMode() ? 'sparkie-app-public' : 'sparkie-dev-public';

        return `https://${bucket}.${this.STORAGE_URL}`;
    }

    getPrivateStorageBucket() : string {

        let bucket = this.isProductionMode() ? 'sparkie-app-private' : 'sparkie-dev-private';

        return `https://${bucket}.${this.STORAGE_URL}`;
    }

    getPortalUrl(template: ApplicationTemplateEntity): string {
        let tenantId = this.activeTenant._id;
        if (this.activeTenant.applicationPortal.enabled) {
            return `${window.location.host}/application?t=${tenantId}&m=${template.type}`;
        } else {
            return null;
        }
    }
}