import {EnumElement, Enum} from "@sparkie/shared-model/src";

export class CreateMode extends EnumElement {}

export class CreateModeEnum extends Enum<CreateMode> {

    static readonly NEW = new CreateMode('NEW', 'New');
    static readonly DUPLICATE = new CreateMode('DUPLICATE', 'Duplicate');
    static readonly TRANSLATE = new CreateMode('TRANSLATE', 'Translate');

    static readonly ELEMENTS = [
        CreateModeEnum.NEW,
        CreateModeEnum.DUPLICATE,
        CreateModeEnum.TRANSLATE
    ];
    static readonly INSTANCE = new CreateModeEnum();

    private constructor() {
        super(CreateModeEnum.ELEMENTS);
    }
}


