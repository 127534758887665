import {EnumElement, Enum} from "../../Enum";

export class Plan extends EnumElement {

    static getInCarePlan(inCare: number): Plan {
        // Our tiers are as follows and if you want to pay the year upfront, we offer a 20% discount on the monthly rate)
        // 0-25 animals per month = $25
        // 26-50 animals per month = $50
        // 51-100 animals per month = $75
        // 101+ animals per month = $100
        if (inCare <= 25) {
            return PlanEnum.SMALL;
        } else if (inCare <= 50) {
            return PlanEnum.MEDIUM;
        } else if (inCare <= 100) {
            return PlanEnum.LARGE;
        } else {
            return PlanEnum.EXTRA_LARGE;
        }
    }

    static getIntakesPlan(intakes: number): Plan {
        // Our tiers are as follows and if you want to pay the year upfront, we offer a 20% discount on the monthly rate)
        if (intakes <= 10) {
            return PlanEnum.SMALL;
        } else if (intakes <= 20) {
            return PlanEnum.MEDIUM;
        } else if (intakes <= 30) {
            return PlanEnum.LARGE;
        } else {
            return PlanEnum.EXTRA_LARGE;
        }
    }

    static getAdoptionsPlan(adoptions: number): Plan {
        // Our tiers are as follows and if you want to pay the year upfront, we offer a 20% discount on the monthly rate)
        if (adoptions <= 10) {
            return PlanEnum.SMALL;
        } else if (adoptions <= 20) {
            return PlanEnum.MEDIUM;
        } else if (adoptions <= 30) {
            return PlanEnum.LARGE;
        } else {
            return PlanEnum.EXTRA_LARGE;
        }
    }
}

export class PlanEnum extends Enum<Plan> {

    static readonly TRIAL = new Plan('TRIAL', 'Trial');
    static readonly SMALL = new Plan('SMALL', 'Small');
    static readonly MEDIUM = new Plan('MEDIUM', 'Medium');
    static readonly LARGE = new Plan('LARGE', 'Large');
    static readonly EXTRA_LARGE = new Plan('EXTRA_LARGE', 'Extra Large');
    static readonly ARCHIVED = new Plan('ARCHIVED', 'Archived');

    static readonly ELEMENTS = [
        PlanEnum.TRIAL,
        PlanEnum.SMALL,
        PlanEnum.MEDIUM,
        PlanEnum.LARGE,
        PlanEnum.EXTRA_LARGE,
        PlanEnum.ARCHIVED
    ];
    static readonly INSTANCE = new PlanEnum();

    private constructor() {
        super(PlanEnum.ELEMENTS);
    }
}

