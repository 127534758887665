import {EnumElement, Enum} from "../../../Enum";
import {MedicalRecordTypeEnum} from "./MedicalRecordTypeEnum";
import {ConditionStatus} from "./ConditionStatusEnum";
import {MedicalProcedureEnum} from "./MedicalProcedureEnum";
import {HeartwormTestOutcome} from "./HeartwormTestOutcomeEnum";
import {ParvoTestOutcome} from "./ParvoTestOutcomeEnum";
import {FecalParasiteTestOutcome} from "./FecalParasiteTestOutcomeEnum";
import {FourDxTestOutcome} from "./FourDxTestOutcomeEnum";
import {FelvFivComboTestOutcome} from "./FelvFivComboTestOutcomeEnum";
import {RingwormTestOutcome} from "./RingwormTestOutcomeEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";
import {FelvTestOutcome} from "./FelvTestOutcomeEnum";
import {FivTestOutcome} from "./FivTestOutcomeEnum";
import {SkinScrapingTestOutcome} from "./SkinScrapingTestOutcomeEnum";
import {RespiratoryCheckOutcome} from "./RespiratoryCheckOutcome";
import {FelineTripleTestOutcome} from "./FelineTripleTestOutcomeEnum";

import {IMedicalRecord} from "../../common/IMedicalRecord";

export class MedicalCondition extends EnumElement {

    /**
     * NOTE: Called by the backend to help in updating medical conditions
     *
     * @param medicalRecord
     */
    public static getProcedureOutcome<T>(medicalRecord: IMedicalRecord<T>) : Array<IMedicalConditionStatus> {
        let outcomes: Array<IMedicalConditionStatus> = [];

        if (medicalRecord.category === MedicalRecordTypeEnum.PROCEDURE.model) {
            switch (medicalRecord.type) {
                case MedicalProcedureEnum.HEARTWORM_TEST.view:
                    HeartwormTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.PARVO_TEST.view:
                    ParvoTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.RINGWORM_TEST.view:
                    RingwormTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.FECAL_PARASITE_TEST.view:
                    FecalParasiteTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum._4DX_TEST.view:
                    FourDxTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.FELINE_TRIPLE_TEST.view:
                    FelineTripleTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.FELV_FIV_COMBO_TEST.view:
                    FelvFivComboTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.FELV_TEST.view:
                    FelvTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.FIV_TEST.view:
                    FivTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                // TODO: SKI SCRAPING was missing and needs a migration
                case MedicalProcedureEnum.SKIN_SCRAPING.view:
                    SkinScrapingTestOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.RESPIRATORY_CHECK.view:
                    RespiratoryCheckOutcome.getOutcomes(medicalRecord.subType, outcomes);
                    break;

                case MedicalProcedureEnum.MICROCHIP_IMPLANT.view:
                case MedicalProcedureEnum.NEUTER.view:
                case MedicalProcedureEnum.SPAY.view:

                case MedicalProcedureEnum.BLOODWORK.view:
                case MedicalProcedureEnum.DENTAL.view:
                case MedicalProcedureEnum.EAR_EXAM.view:
                case MedicalProcedureEnum.EUTHANASIA.view:
                case MedicalProcedureEnum.OFFICE_EXAM.view:
                default:
                    break;
            }
        }

        return outcomes;
    }

    public createCondition(status: ConditionStatus) : IMedicalConditionStatus {
        return { condition: this.model, status: status.model }
    }
}

export class MedicalConditionEnum extends Enum<MedicalCondition> {

    static readonly HEARTWORM = new MedicalCondition('HEARTWORM', 'Heartworm');
    static readonly PARVO = new MedicalCondition('PARVO', 'Parvo');
    static readonly RINGWORM = new MedicalCondition('RINGWORM', 'Ringworm');
    static readonly LYME = new MedicalCondition('LYME', 'Lyme');
    static readonly EHRLICHIOSIS = new MedicalCondition('EHRLICHIOSIS', 'Ehrlichiosis');
    static readonly ANAPLASMOSIS = new MedicalCondition('ANAPLASMOSIS', 'Anaplasmosis');
    static readonly FELV = new MedicalCondition('FELV', 'FeLV');
    static readonly FIV = new MedicalCondition('FIV', 'FIV');
    static readonly FECAL_PARASITES = new MedicalCondition('FECAL_PARASITES', 'Fecal Parasites');
    static readonly KENNEL_COUGH = new MedicalCondition('KENNEL_COUGH', 'Kennel Cough');
    static readonly UPPER_RESPIRATORY_INFECTION = new MedicalCondition('UPPER_RESPIRATORY_INFECTION', 'Upper Respiratory Infection');
    static readonly PNEUMONIA = new MedicalCondition('PNEUMONIA', 'Pneumonia');
    static readonly RESPIRATORY_INFECTION_OTHER = new MedicalCondition('RESPIRATORY_INFECTION_OTHER', 'Respiratory Infection (Other)');
    static readonly MANGE_DEMODEX = new MedicalCondition('MANGE_DEMODEX', 'Mange (Demodex)');
    static readonly MANGE_SARCOPTES = new MedicalCondition('MANGE_SARCOPTES', 'Mange (Sarcoptes)');
    static readonly SKIN_PARASITES = new MedicalCondition('SKIN_PARASITES', 'Skin Parasites');

    static readonly ELEMENTS = [
        MedicalConditionEnum.HEARTWORM,
        MedicalConditionEnum.PARVO,
        MedicalConditionEnum.RINGWORM,
        MedicalConditionEnum.LYME,
        MedicalConditionEnum.EHRLICHIOSIS,
        MedicalConditionEnum.ANAPLASMOSIS,
        MedicalConditionEnum.FELV,
        MedicalConditionEnum.FIV,
        MedicalConditionEnum.FECAL_PARASITES,
        MedicalConditionEnum.KENNEL_COUGH,
        MedicalConditionEnum.UPPER_RESPIRATORY_INFECTION,
        MedicalConditionEnum.PNEUMONIA,
        MedicalConditionEnum.RESPIRATORY_INFECTION_OTHER,
        MedicalConditionEnum.MANGE_DEMODEX,
        MedicalConditionEnum.MANGE_SARCOPTES,
        MedicalConditionEnum.SKIN_PARASITES
    ];
    static readonly INSTANCE = new MedicalConditionEnum();

    private constructor() {
        super(MedicalConditionEnum.ELEMENTS);
    }
}
