import $ from 'jquery';

import {bindable, autoinject, customElement} from 'aurelia-framework';

@autoinject()
@bindable("value")
@customElement("arp-phone-editor")
export class ArpPhoneEditor {

    hasValue;
    phoneTypes = ['Home', 'Work', 'Mobile', 'Other', 'Main', 'Home Fax', 'Work Fax', ''];
    private element: Element;
    private value: any;

    constructor(element: Element) {
        this.element = element;
        this.value = this.newInstance();
    }

    newInstance() {
        let newInstance = {
            number: "",
            type: ""
        };

        return newInstance;
    }

    bind(bindingContext, overrideContext) {
        // NOTE: this.value is a phone { number: xxx, type: xxx }
        this.hasValue = this.value.number;
    }

    attached() {
        $(this.element).focusin((event) => {

            this.hasValue = true;
        });

        $(this.element).focusout((event) => {

            this.hasValue = this.value.number;
        });

        //this.numberInput = $(this.element).find("input").get(0);
        //this.typeInput = $(this.element).find('.typeahead').get(0);
    }
}