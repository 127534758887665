import $ from 'jquery';

import './arp-tag-editor-impl';
import './arp-tag-editor.css';

import {autoinject, transient, bindable, customElement} from 'aurelia-framework';

@bindable("value")
@customElement("arp-tag-editor")
@transient()
@autoinject()
export class ArpTagEditor {

    items = [];
    placeholder = "";
    private readonly element: Element;
    private value: any;

    constructor(element: Element) {
        this.element = element;
    }

    bind(bindingContext, overrideContext) {
        // Some of our properties are defined in html, and are not bound.  So we just pull those values
        this.placeholder = this.placeholder || $(this.element.children[0]).attr('placeholder');
    }

    attached() {
        let editor = $(this.element) as any;
        editor.tagEditor({
            initialTags: this.value,
            placeholder: this.placeholder,
            forceLowercase: false,
            onChange: (field, editor, tags) => this.handleChange(field, editor, tags)
        });
    }

    handleChange(field, editor, tags) {
        this.value.splice(0, this.value.length);      // Clear array
        Array.prototype.push.apply(this.value, tags);
    }
}
