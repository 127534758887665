import {JsonProperty, Serializable} from "typescript-json-serializer";
import type { EntityId } from "../common/IEntity";

export interface IAnimalMicrochip<T> {

    // Chip Information
    number: string;
    company: string;

    // Registration Information
    // TODO: is there need to register with multiple registries?
    useIntegration: string;     // IntegrationTypeEnum

    // Status
    registeredTo: T; // Either the Tenant id or the Person id
    registeredDate: Date;
    registeredStatus: string;
}

@Serializable()
export class AnimalMicrochip<T = EntityId> implements IAnimalMicrochip<EntityId> {
    @JsonProperty() number: string = '';
    @JsonProperty() company: string = '';

    @JsonProperty() useIntegration: string = '';     // IntegrationTypeEnum
    @JsonProperty() registeredTo: EntityId = null;
    @JsonProperty() registeredDate: Date = null;
    @JsonProperty() registeredStatus: string = '';

    static getQueryFields(): string[] {
        return [
            'number',
            'company',
            'useIntegration',
            'registeredTo',
            'registeredDate',
            'registeredStatus'
        ];
    }
}