import {EnumElement, Enum} from "@sparkie/shared-model/src";
import isArray from "lodash/isArray";

/**
 * Simple class that converts between ArpEnum name & display strings
 */
export class ArpEnumFormatValueConverter {

    /**
     * Convert from entity to view.
     *
     * @param value - the model property of the enumeration, like "IN_TRANSIT"
     * @param enumeration - the Enum derived enum, bind using  ${var | arpEnumFormat:enumInstance}
     * @returns the display value of the enumeration, like 'In Transit'
     */
    toView(value: string, enumeration: Enum<EnumElement> | Array<EnumElement>) : string {

        if (!enumeration) {
            return value;
        } else if (isArray(value)) {
            throw new Error("Can't handle array conversion");
            // Handle converting an array of values!
//            return map(value, bind(enumeration.view, enumeration));
        } else if (isArray(enumeration)) {
            let element = enumeration.find((element) => element.model === value)
            //let element = enumeration.fromModel(value);
            return element ? element.view : value;
        } else {
            let element = enumeration.fromModel(value);
            return element ? element.view : value;
        }
    }

    /**
     * Convert from view to entity.
     *
     * @param value - the display value of the enumeration, like 'In Transit'
     * @param enumeration - the Enum derived enum, bind using  ${var | arpEnumFormat:enumInstance}
     * @returns the name property of the enumeration, like "IN_TRANSIT"
     */
    fromView(value: string, enumeration: Enum<EnumElement>) {

        // Value is the enum display property!
        var enumValue = enumeration.fromView(value);

        if (enumValue) {
            return enumValue.model;
        } else {
            return value;
        }
    }
}
