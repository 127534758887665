import {Logger} from "./ArpLogger";
import {Appender} from "./Appender";
import {LogLevel} from "./LogLevel";

// TODO: Rename this to LogManager.

/**
 * The log manager.
 */

export class ArpLogManager {
    private static readonly loggers: Map<string, Logger> = new Map();
    private static readonly appenders: Array<Appender> = [];
    private static readonly logLevel = LogLevel.debug;

    static initialize(appenders: Array<Appender>) : void {
        ArpLogManager.appenders.push(...appenders);
    }

    static getLogger(id: string): Logger {
        return ArpLogManager.loggers.get(id) || ArpLogManager.createLogger(id);
    }

    private static createLogger(id: string): Logger {
        const logger = new Logger(id, ArpLogManager.appenders);
        ArpLogManager.loggers.set(id, logger);

        return logger;
    }
}
