import moment from "moment";


export class Age {
    public static formatAsAge(dateOfBirth: any, asOf: Date = new Date()): string {

        if (dateOfBirth) {
            let asOfMoment = moment(asOf);
            let monthsOld = asOfMoment.diff(dateOfBirth, 'months');

            if (monthsOld >= 12) {

                // 12+ months = N years M months (don't show months when zero)
                let yearsOld = Math.floor(monthsOld / 12);
                let yearsString = yearsOld === 1 ? 'year' : 'years';

                let additionalMonths = monthsOld % 12;
                let additionMonthsString = additionalMonths === 1 ? 'month' : 'months';

                if (additionalMonths == 0) {
                    return `${yearsOld} ${yearsString}`;
                } else {
                    return `${yearsOld} ${yearsString} ${additionalMonths} ${additionMonthsString}`;
                }

            } else if (monthsOld >= 5) {

                // 5 - 12 months = N months
                return `${monthsOld} months`;

            } else {

                // 2 -12 weeks = N weeks
                let weeksOld = asOfMoment.diff(dateOfBirth, 'weeks');

                if (weeksOld >= 2) {
                    return `${weeksOld} weeks`;
                } else {
                    // 1 - 14 days = N days
                    let daysOld = asOfMoment.diff(dateOfBirth, 'days');

                    if (daysOld == 1) {
                        return `${daysOld} day`;
                    } else {
                        return `${daysOld} days`;
                    }
                }
            }
        } else {
            return "Unknown";
        }
    }
}