import { ImageBuffer, JPEG_MIME_TYPE } from "./ImageBuffer";
import { rotate } from "./image_rotate";
import { toArrayData, toBlob } from "./image_utils";

/**
 * Represents the information needed to resize an image.
 *
 * See drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
 *
 */
export type ResizeInfo = {
    /**
     * The x-axis coordinate of the top left corner of the sub-rectangle of the source image to
     * draw into the destination context. Use the 3- or 5-argument syntax to omit this argument.
     */
    sx: number;

    /**
     * The y-axis coordinate of the top left corner of the sub-rectangle of the source image
     * to draw into the destination context. Use the 3- or 5-argument syntax to omit this argument.
     */
    sy: number;

    /**
     * The width of the sub-rectangle of the source image to draw into the destination context. If
     * not specified, the entire rectangle from the coordinates specified by sx and sy to the bottom-right
     * corner of the image is used. Use the 3- or 5-argument syntax to omit this argument. A negative value
     * will flip the image.
     */
    sWidth: number;

    /**
     * The height of the sub-rectangle of the source image to draw into the destination context. Use
     * the 3- or 5-argument syntax to omit this argument. A negative value will flip the image.
     */
    sHeight: number;

    /**
     * The x-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
     */
    dx: number;

    /**
     * The y-axis coordinate in the destination canvas at which to place the top-left corner of the source image.
     */
    dy: number;

    /**
     * The width to draw the image in the destination canvas. This allows scaling of the drawn image. If not
     * specified, the image is not scaled in width when drawn. Note that this argument is not included in
     * the 3-argument syntax.
     */
    dWidth: number;

    /**
     * The height to draw the image in the destination canvas. This allows scaling of the drawn image. If not
     * specified, the image is not scaled in height when drawn. Note that this argument is not included in
     * the 3-argument syntax.
     */
    dHeight: number;
};

/**
 * Calculates the image size to fit within the specified minimum dimension while preserving the aspect ratio.
 *
 * This function calculates the new dimensions of the image based on the provided
 * current width, current height, and minimum dimension. It ensures that the aspect ratio
 * is maintained by adjusting the width and height proportionally so that the image fits
 * within the specified minimum dimension without distortion.
 *
 * @param currentWidth - The current width of the image.
 * @param currentHeight - The current height of the image.
 * @param min - The minimum dimension (either width or height) to resize the image to.
 * @returns A `ResizeInfo` object containing the source and destination dimensions for the resized image.
 */
export function resizePreserveAspectRatio(currentWidth: number, currentHeight: number, min: number): ResizeInfo {
    const aspectRatio = currentWidth / currentHeight;

    let width = currentWidth;
    let height = currentHeight;

    if (width > height) {
        const maxWidth = currentWidth - min;
        if (width > maxWidth) {
            height = min;
            width = Math.round(height * aspectRatio);
        }
    } else {
        const maxHeight = currentHeight - min;
        if (height > maxHeight) {
            width = min;
            height = Math.round(width / aspectRatio);
        }
    }
    const resizeInfo: ResizeInfo = {
        sx: 0,
        sy: 0,
        sWidth: currentWidth,
        sHeight: currentHeight,
        dx: 0,
        dy: 0,
        dWidth: width,
        dHeight: height,
    };

    return resizeInfo;
}

export function resize(currentWidth: number, currentHeight: number, newWidth: number, newHeight: number): ResizeInfo {
    const srcRatio = currentWidth / currentHeight;
    const trgRatio = newWidth / newHeight;
    const resizeInfo: ResizeInfo = {
        sx: 0,
        sy: 0,
        sWidth: currentWidth,
        sHeight: currentHeight,
        dx: 0,
        dy: 0,
        dWidth: 0,
        dHeight: 0,
    };

    // Don't scale up
    if (currentHeight < newHeight || currentWidth < newWidth) {
        resizeInfo.dHeight = resizeInfo.sHeight;
        resizeInfo.dWidth = resizeInfo.sWidth;
    } else {
        if (srcRatio > trgRatio) {
            resizeInfo.sHeight = currentHeight;
            resizeInfo.sWidth = resizeInfo.sHeight * trgRatio;
        } else {
            resizeInfo.sWidth = currentWidth;
            resizeInfo.sHeight = resizeInfo.sWidth / trgRatio;
        }
        resizeInfo.dWidth = newWidth;
        resizeInfo.dHeight = newHeight;
    }

    resizeInfo.sx = (currentWidth - resizeInfo.sWidth) / 2;
    resizeInfo.sy = (currentHeight - resizeInfo.sHeight) / 2;

    return resizeInfo;
}

export async function createJpegThumbnail(imageBuffer: ImageBuffer, width: number, height: number): Promise<ImageBuffer> {
    const imageBlob = new Blob([new DataView(imageBuffer.data)], { type: imageBuffer.mimeType });
    const image = await createImageBitmap(imageBlob);

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const resizeInfo = resize(image.width, image.height, width, height);

    const context = canvas.getContext("2d");

    // DID THE BROWSER ROTATE THIS IMAGE WHEN IT LOADED INTO THE ELEMENT?  IF SO WE DON"T NEED TO ROTATE
    if (image.width === imageBuffer.exifData.exifWidth && image.height === imageBuffer.exifData.exifHeight) {
        // Not intuitive, but the context transformation must occur before drawing the images
        rotate(canvas, context, imageBuffer.exifData.exifOrientation);
    }

    context.drawImage(
        image,
        resizeInfo.sx,
        resizeInfo.sy,
        resizeInfo.sWidth,
        resizeInfo.sHeight,
        resizeInfo.dx,
        resizeInfo.dy,
        resizeInfo.dWidth,
        resizeInfo.dHeight
    );

    const blob = await toBlob(canvas, JPEG_MIME_TYPE);
    const data = await toArrayData(blob);

    return new ImageBuffer(data, JPEG_MIME_TYPE);
}

