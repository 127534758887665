import {Decimal128} from "bson";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import {AnimalAvailabilityEnum} from "./AnimalAvailabilityEnum";
import {AnimalStatusEnum} from "./AnimalStatusEnum";
import type { EntityId } from "../common/IEntity";

export interface IAnimalStatusRecord<T> {

    status: string;       // AnimalStatusEnum.model is stored here!!!
    availability: string; // AnimalAvailabilityEnum.model

    start: Date;
    end: Date;                            // Calculated by back end when guardians change.
    amount: Decimal128;

    // NOTE: Can only have a person or an agency or a transferTenant, NOT BOTH!!!
    // TODO: Validate that one of these is defined!
    // For AnimalStatus.hasPerson() and AnimalStatus.requiresPerson()
    person: T;
    personFullName: string;
    personLastName: string;
    personPrimaryPhone: string;
    personPrimaryEmail: string;

    // For AnimalStatus.hasAgency()
    agency: T;
    agencyName: string;
    agencyPrimaryPhone: string;
    agencyPrimaryEmail: string;

    // For transfers
    transferTenant: T;
    transferTenantName: string;

    // Either the personLastName or agencyName, depending on which is defined
    guardianSort: string;

    note: string;
}

@Serializable()
export class AnimalStatusRecord implements IAnimalStatusRecord<EntityId> {
    @JsonProperty() status: string = null;       // AnimalStatusEnum.model is stored here!!!
    @JsonProperty() availability: string = null; // AnimalAvailabilityEnum.model

    @JsonProperty() start: Date = new Date();
    @JsonProperty() end: Date = null;                            // Calculated by back end when guardians change.
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    amount: Decimal128 = null;

    // NOTE: Can only have a person or an agency, NOT BOTH!!!
    // TODO: Validate that one of these is defined!
    @JsonProperty() person: EntityId = null;
    @JsonProperty() personFullName: string = '';
    @JsonProperty() personLastName: string = '';
    @JsonProperty() personPrimaryPhone: string = '';
    @JsonProperty() personPrimaryEmail: string = '';

    @JsonProperty() agency: EntityId = null;
    @JsonProperty() agencyName: string = '';
    @JsonProperty() agencyPrimaryPhone: string = '';
    @JsonProperty() agencyPrimaryEmail: string = '';

    @JsonProperty() transferTenant: EntityId = null;
    @JsonProperty() transferTenantName: string = '';

    // Either the personLastName or agencyName, depending on which is defined
    @JsonProperty() guardianSort: string = '';

    @JsonProperty() note: string = '';

    static getQueryFields(): string[] {
        return [
            'status',
            'availability',
            'start',
            'end',
            'amount',
            'person',
            'personFullName',
            'personLastName',
            'personPrimaryPhone',
            'personPrimaryEmail',
            'agency',
            'agencyName',
            'agencyPrimaryPhone',
            'agencyPrimaryEmail',
            'transferTenant',
            'transferTenantName',
            'guardianSort',
            'note'
        ]
    }
}

export function isAdoptable(animalStatusRecord: IAnimalStatusRecord<EntityId>): boolean {
    switch (animalStatusRecord.availability) {
        case AnimalAvailabilityEnum.AVAILABLE.model:
            return true;

        case AnimalAvailabilityEnum.FOSTER_ONLY.model:
        case AnimalAvailabilityEnum.NOT_AVAILABLE.model:
        case AnimalAvailabilityEnum.ADOPTION_IN_PROGRESS.model:
        case AnimalAvailabilityEnum.ON_HOLD.model:
        default:
            return false;

        // TODO: Lint this so there is an error if enums are added
    }
}

export function isFosterable(animalStatusRecord: IAnimalStatusRecord<EntityId>): boolean {

    switch (animalStatusRecord.availability) {
        case AnimalAvailabilityEnum.AVAILABLE.model:
            // Don't show animals that are already in Foster
            return animalStatusRecord.status != AnimalStatusEnum.FOSTER.model;

        case AnimalAvailabilityEnum.FOSTER_ONLY.model:
            return true;

        case AnimalAvailabilityEnum.NOT_AVAILABLE.model:
        case AnimalAvailabilityEnum.ADOPTION_IN_PROGRESS.model:
        case AnimalAvailabilityEnum.ON_HOLD.model:
        default:
            return false;

        // TODO: Lint this so there is an error if enums are added
    }
}

export function createDefaultAnimalStatusRecord<T extends IAnimalStatusRecord<any>>(): T {
    return new AnimalStatusRecord() as any as T;
}