import type { EntityId } from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

// This is really
export interface IPersonApplicationSummary<T> {
    application: T;

    // All of this data is cached from the application to support search/filter
    applicationId: string;
    created: Date;
    state: string;                      // ApplicationStateEnum
    type: string;                       // ApplicationTypeEnum
}

@Serializable()
export class PersonApplicationSummary implements IPersonApplicationSummary<EntityId> {
    @JsonProperty() application: EntityId = null;

    // All of this data is cached from the application to support search/filter
    @JsonProperty() applicationId: string = '';
    @JsonProperty() created: Date = null;
    @JsonProperty() state: string = '';                      // ApplicationStateEnum
    @JsonProperty() type: string = '';                     // ApplicationTypeEnum

    static getQueryFields(): string[] {
        return [
            'application',
            'applicationId',
            'created',
            'state',
            'type'
        ]
    }
}