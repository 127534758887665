import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IAddress {
    line1: string;
    line2: string;
    city: string;
    stateProvinceCounty: string;
    zipOrPostCode: string;
    country: string;     // https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
}

@Serializable()
export class Address implements IAddress {
    @JsonProperty() line1: string = '';
    @JsonProperty() line2: string = '';
    @JsonProperty() city: string = '';
    @JsonProperty() stateProvinceCounty: string = '';
    @JsonProperty() zipOrPostCode: string = '';
    @JsonProperty() country: string = '';

    static getQueryFields(): string[] {
        return [
            'line1',
            'line2',
            'city',
            'stateProvinceCounty',
            'zipOrPostCode',
            'country'
        ]
    }
}
