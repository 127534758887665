import {EnumElement, Enum} from "../../Enum";

export class AnimalHealth extends EnumElement {}

export class AnimalHealthEnum extends Enum<AnimalHealth> {

    static readonly HEALTHY = new AnimalHealth('HEALTHY', 'Healthy');
    static readonly REHABILITATE = new AnimalHealth('REHABILITATE', 'Treatable - rehabilitate');
    static readonly MANAGE = new AnimalHealth('MANAGE', 'Treatable - manage');
    static readonly UNTREATABLE = new AnimalHealth('UNTREATABLE', 'Unhealthy and Untreatable');

    static readonly ELEMENTS = [
        AnimalHealthEnum.HEALTHY,
        AnimalHealthEnum.REHABILITATE,
        AnimalHealthEnum.MANAGE,
        AnimalHealthEnum.UNTREATABLE
    ];
    static readonly INSTANCE = new AnimalHealthEnum();

    private constructor() {
        super(AnimalHealthEnum.ELEMENTS);
    }
}
