import {validateTrigger, ValidationController} from "aurelia-validation";
import {ArpValidator} from "./ArpValidator";
import {autoinject} from "aurelia-framework";
import {BootstrapFormRenderer} from "./BootstrapFormRenderer";
import {ValidateResult} from "aurelia-validation/src/validate-result";
import {Observer} from "../Observer";
import {ArpLogger, ArpLogManager} from "..";
import isEmpty from "lodash/isEmpty";

@autoinject()
export class ArpValidationController extends ValidationController {

    isValid: boolean;
    observer: Observer;
    formRenderer: BootstrapFormRenderer = new BootstrapFormRenderer();
    private logger: ArpLogger;

    constructor(vc: ValidationController) {
        super(new ArpValidator(), (<any>vc).propertyParser);

        this.logger = ArpLogManager.getLogger(`arp-validation`);
        this.validateTrigger = validateTrigger.changeOrBlur;
        (<any>this).processResultDelta = this.myProcessResultDelta;
    }

    async attach() {
        this.logger.debug("attach()");

        // We need to validate before the first render.  This will add the text "is required" in muted grey text
        // after any property that is invalid.
        await this.validate()

        this.logger.debug("addRenderer()");
        this.addRenderer(this.formRenderer);

        this.logger.debug("initialized = true");
        this.formRenderer.initialized = true;
    }

    /**
     * Define a validation dependency between two properties.  Observes the source and when it changes will validate
     * the target property by name.
     *
     * @param instance
     * @param sourceProperty
     * @param targetProperty
     */
    setValidationDependency(instance, sourceProperty, targetProperty) {
        this.observer.observe(instance, sourceProperty, () => {

            if (isEmpty(targetProperty)) {
                return this.validate();
            } else {
                return this.validate({
                    object: instance,
                    propertyName: targetProperty
                });
            }
        });
    }

    /**
     * Define a validation dependency between two properties
     *
     * @param sourceInstance
     * @param sourceProperty
     * @param targetInstance
     * @param targetProperty
     */
    setValidationIndirectDependency(sourceInstance, sourceProperty, targetInstance, targetProperty) {
        this.observer.observe(sourceInstance, sourceProperty, () => {

            if (isEmpty(targetProperty)) {
                this.validate();
            } else {
                this.validate({
                    object: targetInstance,
                    propertyName: targetProperty
                });
            }
        });
    }

    myProcessResultDelta(kind: 'validate' | 'reset', oldResults: ValidateResult[], newResults: ValidateResult[]) {
        this.processResultDeltaFixed(kind, oldResults, newResults);

        // TODO: Did I override all this shit just to be able to observe if there are errors?
        this.isValid = this.errors.length === 0;
    }

    setDynamicDisplayName(propertyName, fn) {
        this.privateValidator.setDynamicDisplayNameCallback(propertyName, fn);
    }

    processResultDeltaFixed(kind: 'validate' | 'reset', oldResults: ValidateResult[], newResults: ValidateResult[]) {
        // prepare the instruction.
        let instruction = {
            kind: kind,
            render: [],
            unrender: []
        };
        // create a shallow copy of newResults so we can mutate it without causing side-effects.
        newResults = newResults.slice(0);

        let _loop_1 = function (oldResult) {
            // get the elements associated with the old result.
            let elements = this_1.privateElements.get(oldResult);
            // remove the old result from the element map.
            this_1.privateElements.delete(oldResult);
            // create the unrender instruction.
            instruction.unrender.push({ result: oldResult, elements: elements });
            // determine if there's a corresponding new result for the old result we are unrendering.
            let newResultIndex = newResults.findIndex(function (x) { return x.rule === oldResult.rule && x.object === oldResult.object && x.propertyName === oldResult.propertyName; });
            if (newResultIndex === -1) {
                // no corresponding new result... simple remove.
                this_1.privateResults.splice(this_1.privateResults.indexOf(oldResult), 1);
                if (!oldResult.valid) {
                    this_1.errors.splice(this_1.errors.indexOf(oldResult), 1);
                }
            }
            else {
                // there is a corresponding new result...
                let newResult = newResults.splice(newResultIndex, 1)[0];

                // get the elements that are associated with the new result.
                let elements_1 = this_1.privateGetAssociatedElements(newResult);
                this_1.privateElements.set(newResult, elements_1);

                // create a render instruction for the new result.
                instruction.render.push({ result: newResult, elements: elements_1 });

                // do an in-place replacement of the old result with the new result.
                // this ensures any repeats bound to this.results will not thrash.
                this_1.privateResults.splice(this_1.privateResults.indexOf(oldResult), 1, newResult);

                let existingErrorIndex = this_1.errors.indexOf(oldResult);

                if (newResult.valid) {

                    if (existingErrorIndex != -1) {
                        this_1.errors.splice(existingErrorIndex, 1);
                    }
                }
                else {
                    if (existingErrorIndex != -1) {
                        this_1.errors.splice(existingErrorIndex, 1, newResult);
                    } else {
                        this_1.errors.push(newResult);
                    }
                }
            }
        };

        let this_1 = this;

        // create unrender instructions from the old results.
        for (let _i = 0, oldResults_1 = oldResults; _i < oldResults_1.length; _i++) {
            let oldResult = oldResults_1[_i];
            _loop_1(oldResult);
        }

        // create render instructions from the remaining new results.
        for (let _a = 0, newResults_1 = newResults; _a < newResults_1.length; _a++) {
            let result = newResults_1[_a];
            let elements = this.privateGetAssociatedElements(result);
            instruction.render.push({ result: result, elements: elements });
            this.privateElements.set(result, elements);
            this.privateResults.push(result);
            if (!result.valid) {
                this.errors.push(result);
            }
        }

        // render.
        for (let _b = 0, _c = this.privateRenderers; _b < _c.length; _b++) {
            let renderer = _c[_b];
            renderer.render(instruction);
        }
    }

    get privateValidator() {
        return (<any>this).validator;
    }

    get privateRenderers() {
        return (<any>this).renderers;
    }

    get privateElements() {
        return (<any>this).elements;
    }

    get privateResults() {
        return (<any>this).results;
    }

    private privateGetAssociatedElements(result) {
        return (<any>this).getAssociatedElements(result);
    }
}
