import * as _ from 'lodash';

import {bindable, customElement} from 'aurelia-framework';
import {Action, ActionList} from "../index";

@customElement("arp-header-bar")
export class ArpHeaderBar {

    @bindable models: Array<Action | ActionList> = [];

    constructor() {
    }

    isButton(model: Action | ActionList) {
        return model instanceof Action;
    }

    isMenu(model: Action | ActionList) {
        return model instanceof ActionList;
    }
}

