import {Enum, EnumElement} from "../../Enum";

export class VolunteerHoursPerWeek extends EnumElement {}

export class VolunteerHoursPerWeekEnum extends Enum<VolunteerHoursPerWeek> {

    static readonly LIGHT = new VolunteerHoursPerWeek('LIGHT', '< 5 hours');
    static readonly MODERATE = new VolunteerHoursPerWeek('MODERATE', '5-10 hours');
    static readonly HEAVY = new VolunteerHoursPerWeek('HEAVY', '10+ hours');

    static readonly ELEMENTS = [
        VolunteerHoursPerWeekEnum.LIGHT,
        VolunteerHoursPerWeekEnum.MODERATE,
        VolunteerHoursPerWeekEnum.HEAVY
    ];
    static readonly INSTANCE = new VolunteerHoursPerWeekEnum();

    private constructor() {
        super(VolunteerHoursPerWeekEnum.ELEMENTS);
    }
}
