import {EnumElement, Enum} from "../../Enum";
import {AnimalSpecies, AnimalSpeciesEnum} from "../animal/AnimalSpeciesEnum";


export class ApplicationType extends EnumElement {
    isSupported() {
        switch (this) {
            case ApplicationTypeEnum.DOG_ADOPT:
            case ApplicationTypeEnum.CAT_ADOPT:
            case ApplicationTypeEnum.RABBIT_ADOPT:
            case ApplicationTypeEnum.GUINEA_PIG_ADOPT:
            case ApplicationTypeEnum.DOG_FOSTER:
            case ApplicationTypeEnum.CAT_FOSTER:
            case ApplicationTypeEnum.RABBIT_FOSTER:
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER:
            case ApplicationTypeEnum.SURRENDER:
            case ApplicationTypeEnum.VOLUNTEER:
                return true;
            case ApplicationTypeEnum.LEGACY:
            default:
                return false;
        }
    }

    hasSpecies() {
        switch (this) {
            case ApplicationTypeEnum.DOG_ADOPT:
            case ApplicationTypeEnum.CAT_ADOPT:
            case ApplicationTypeEnum.DOG_FOSTER:
            case ApplicationTypeEnum.CAT_FOSTER:
            case ApplicationTypeEnum.RABBIT_ADOPT:
            case ApplicationTypeEnum.RABBIT_FOSTER:
            case ApplicationTypeEnum.GUINEA_PIG_ADOPT:
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER:
                return true;
            case ApplicationTypeEnum.SURRENDER:
            case ApplicationTypeEnum.VOLUNTEER:
            default:
                return false;
        }
    }

    hasAnimal() {
        switch (this) {
            case ApplicationTypeEnum.DOG_ADOPT:
            case ApplicationTypeEnum.CAT_ADOPT:
            case ApplicationTypeEnum.DOG_FOSTER:
            case ApplicationTypeEnum.CAT_FOSTER:
            case ApplicationTypeEnum.RABBIT_ADOPT:
            case ApplicationTypeEnum.RABBIT_FOSTER:
            case ApplicationTypeEnum.GUINEA_PIG_ADOPT:
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER:
            case ApplicationTypeEnum.SURRENDER:
                    return true;
            case ApplicationTypeEnum.VOLUNTEER:
            default:
                return false;
        }
    }

    public getSpecies(): AnimalSpecies {
        switch (this) {
            case ApplicationTypeEnum.DOG_ADOPT:
            case ApplicationTypeEnum.DOG_FOSTER:
                return AnimalSpeciesEnum.DOG;

            case ApplicationTypeEnum.CAT_ADOPT:
            case ApplicationTypeEnum.CAT_FOSTER:
                return AnimalSpeciesEnum.CAT;

            case ApplicationTypeEnum.RABBIT_ADOPT:
            case ApplicationTypeEnum.RABBIT_FOSTER:
                return AnimalSpeciesEnum.RABBIT;

            case ApplicationTypeEnum.GUINEA_PIG_ADOPT:
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER:
                return AnimalSpeciesEnum.GUINEA_PIG;

            default:
                throw new Error("No species for Application")
        }
    }

    public getTypeLabel(): string {
        switch (this) {
            case ApplicationTypeEnum.DOG_ADOPT:
            case ApplicationTypeEnum.CAT_ADOPT:
            case ApplicationTypeEnum.RABBIT_ADOPT:
            case ApplicationTypeEnum.GUINEA_PIG_ADOPT:
                return "Adoption";

            case ApplicationTypeEnum.DOG_FOSTER:
            case ApplicationTypeEnum.CAT_FOSTER:
            case ApplicationTypeEnum.RABBIT_FOSTER:
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER:
                return "Foster";

            case ApplicationTypeEnum.VOLUNTEER:
                return "Volunteer";

            case ApplicationTypeEnum.SURRENDER:
                return "Surrender";

            default:
                return "";
        }
    }

    public isAdoptionApplication(): boolean {
        switch (this) {
            case ApplicationTypeEnum.DOG_ADOPT:
            case ApplicationTypeEnum.CAT_ADOPT:
            case ApplicationTypeEnum.RABBIT_ADOPT:
            case ApplicationTypeEnum.GUINEA_PIG_ADOPT:
                return true;

            default:
                return false;
        }
    }

    public isFosterApplication(): boolean {
        switch (this) {
            case ApplicationTypeEnum.DOG_FOSTER:
            case ApplicationTypeEnum.CAT_FOSTER:
            case ApplicationTypeEnum.RABBIT_FOSTER:
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER:
                return true;

            default:
                return false;
        }
    }

    public isVolunteerApplication(): boolean {
        switch (this) {
            case ApplicationTypeEnum.VOLUNTEER:
                return true;

            default:
                return false;
        }
    }
}

export class ApplicationTypeEnum extends Enum<ApplicationType> {

    static readonly CAT_ADOPT = new ApplicationType('CAT_ADOPT', 'Cat Adoption');
    static readonly DOG_ADOPT = new ApplicationType('DOG_ADOPT', 'Dog Adoption');
    static readonly RABBIT_ADOPT = new ApplicationType('RABBIT_ADOPT', 'Rabbit Adoption');
    static readonly GUINEA_PIG_ADOPT = new ApplicationType('GUINEA_PIG_ADOPT', 'Guinea Pig Adoption');
    static readonly CAT_FOSTER = new ApplicationType('CAT_FOSTER', 'Cat Foster');
    static readonly DOG_FOSTER = new ApplicationType('DOG_FOSTER', 'Dog Foster');
    static readonly RABBIT_FOSTER = new ApplicationType('RABBIT_FOSTER', 'Rabbit Foster');
    static readonly GUINEA_PIG_FOSTER = new ApplicationType('GUINEA_PIG_FOSTER', 'Guinea Pig Foster');
    static readonly SURRENDER = new ApplicationType('SURRENDER', 'Surrender');
    static readonly VOLUNTEER = new ApplicationType('VOLUNTEER', 'Volunteer');
    static readonly LEGACY = new ApplicationType('LEGACY', 'Legacy');

    static readonly ELEMENTS = [
        ApplicationTypeEnum.CAT_ADOPT,
        ApplicationTypeEnum.DOG_ADOPT,
        ApplicationTypeEnum.RABBIT_ADOPT,
        ApplicationTypeEnum.GUINEA_PIG_ADOPT,
        ApplicationTypeEnum.CAT_FOSTER,
        ApplicationTypeEnum.DOG_FOSTER,
        ApplicationTypeEnum.RABBIT_FOSTER,
        ApplicationTypeEnum.GUINEA_PIG_FOSTER,
        ApplicationTypeEnum.SURRENDER,
        ApplicationTypeEnum.VOLUNTEER,
        ApplicationTypeEnum.LEGACY
    ];
    static readonly INSTANCE = new ApplicationTypeEnum();

    private constructor() {
        super(ApplicationTypeEnum.ELEMENTS);
    }

    // TODO: Function to extract species when it matters (won't for Volunteer or Surrender)
    adoptionFor(species: string): ApplicationType {
        switch (species) {
            case AnimalSpeciesEnum.DOG.model:
                return ApplicationTypeEnum.DOG_ADOPT;
            case AnimalSpeciesEnum.CAT.model:
                return ApplicationTypeEnum.CAT_ADOPT;
            case AnimalSpeciesEnum.RABBIT.model:
                return ApplicationTypeEnum.RABBIT_ADOPT;
            case AnimalSpeciesEnum.GUINEA_PIG.model:
                return ApplicationTypeEnum.GUINEA_PIG_ADOPT;
            default:
                return null;
        }
    }

    fosterFor(species: string): ApplicationType {
        switch (species) {
            case AnimalSpeciesEnum.DOG.model:
                return ApplicationTypeEnum.DOG_FOSTER;
            case AnimalSpeciesEnum.CAT.model:
                return ApplicationTypeEnum.CAT_FOSTER;
            case AnimalSpeciesEnum.RABBIT.model:
                return ApplicationTypeEnum.RABBIT_FOSTER;
            case AnimalSpeciesEnum.GUINEA_PIG.model:
                return ApplicationTypeEnum.GUINEA_PIG_FOSTER;
            default:
                return null;
        }
    }
}
