import {Enum, EnumElement} from "../Enum";

export class ModelOperation extends EnumElement {}

/**
 * Enumeration that represent the Operations allowed by the Sparkie application.
 */
export class ModelOperationEnum extends Enum<ModelOperation> {

    static readonly READ = new ModelOperation('READ', 'Read');
    static readonly CREATE = new ModelOperation('CREATE', 'Create');
    static readonly UPDATE = new ModelOperation('UPDATE', 'Update');
    static readonly DELETE = new ModelOperation('DELETE', 'Delete');

    static readonly ELEMENTS = [
        ModelOperationEnum.READ,
        ModelOperationEnum.CREATE,
        ModelOperationEnum.UPDATE,
        ModelOperationEnum.DELETE
    ];
    static readonly INSTANCE = new ModelOperationEnum();

    private constructor() {
        super(ModelOperationEnum.ELEMENTS);
    }
}
