import {EnumElement, Enum} from "../../Enum";

export class AnimalEnergyLevel extends EnumElement {}

export class AnimalEnergyLevelEnum extends Enum<AnimalEnergyLevel> {

    static readonly LOW = new AnimalEnergyLevel('LOW', 'Low');
    static readonly MEDIUM = new AnimalEnergyLevel('MEDIUM', 'Medium');
    static readonly HIGH = new AnimalEnergyLevel('HIGH', 'High');

    static readonly ELEMENTS = [
        AnimalEnergyLevelEnum.LOW,
        AnimalEnergyLevelEnum.MEDIUM,
        AnimalEnergyLevelEnum.HIGH
    ];
    static readonly INSTANCE = new AnimalEnergyLevelEnum();

    constructor() {
        super(AnimalEnergyLevelEnum.ELEMENTS);
    }
}
