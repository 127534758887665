import {buildKey, buildMap, IntegrationMetadata} from "../IntegrationMetadata";

export function rabbitIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}


function translateBreed(sparkieBreed: string): string {
    let breedKey = buildKey(sparkieBreed);

    let petFinderBreed = rabbitBreedMap.get(breedKey);

    if (petFinderBreed) {
        return petFinderBreed;
    } else {
        switch (sparkieBreed) {
            case "Bunny Rabbit":
            case "Hotot":
                return "Other/Unknown";
            case "Angora Rabbit":
                return "Angora, English";
            case "Chinchilla":
                return "Chinchilla, Standard";
            case "Dwarf Eared":
                return "Dwarf";
            case "Lop Eared":
                return "Lop-Eared";
            case "English Lop":
                return "Lop, English";
            case "French Lop":
                return "Lop, French";
            case "Holland Lop":
                return "Lop, Holland";
            default:
                return "";
        }
    }
}

function translateColor(sparkieColor: string): string {
    if (rabbitColors.has(sparkieColor)) {
        return sparkieColor;
    }
    switch (sparkieColor) {
        case "Silver Marten":
            return "Grey/Silver";
        case "Blue / Gray":
            return "Blue";
        case "Tortoiseshell":
            return "Tortoise";
        case "Orange / Red":
            return "Orange";
        case "Brown / Chocolate":
            return "Chocolate";
        default:
            return "";
    }
}

const rabbitBreeds = new Set<string>([
    "American",
    "American Fuzzy Lop",
    "American Sable",
    "Angora, English",
    "Angora, French",
    "Angora, Giant",
    "Angora, Satin",
    "Belgian Hare",
    "Beveren",
    "Blanc de Hotot",
    "Britannia Petite",
    "Californian",
    "Champagne D'Argent",
    "Checkered Giant",
    "Chinchilla, American",
    "Chinchilla, Giant",
    "Chinchilla, Standard",
    "Cinnamon",
    "Creme D'Argent",
    "Dutch",
    "Dwarf",
    "Dwarf Hotot",
    "English Spot",
    "Flemish Giant",
    "Florida White",
    "Harlequin",
    "Havana",
    "Himalayan",
    "Jersey Wooly",
    "Lilac",
    "Lionhead",
    "Lop-Eared",
    "Lop, English",
    "Lop, French",
    "Lop, Holland",
    "Mini Lop",
    "Mini Rex",
    "Mini Satin",
    "Netherland Dwarf",
    "New Zealand",
    "Other/Unknown",
    "Palomino",
    "Polish",
    "Rex",
    "Rhinelander",
    "Satin",
    "Silver",
    "Silver Fox",
    "Silver Marten",
    "Tan",
    "Thrianta",
]);

const rabbitBreedMap = buildMap(rabbitBreeds);

const rabbitColors = new Set<string>([
    "Sable",
    "Albino or Red-Eyed White",
    "Blue",
    "Black",
    "Blond/Golden",
    "Chinchilla",
    "Chocolate",
    "Cinnamon",
    "Copper",
    "Cream",
    "Dutch",
    "Fawn",
    "Grey/Silver",
    "Harlequin",
    "Lilac",
    "Multi",
    "Orange",
    "Red",
    "Agouti",
    "Siamese",
    "Tan",
    "Tortoise",
    "Tri-color",
    "White",
]);
