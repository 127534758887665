import {AppRouter} from "aurelia-router";
import {Container, NewInstance} from "aurelia-framework";
import {ValidationController} from "aurelia-validation";

import {Action} from "../actions/Action";
import {ArpEntity} from "../ArpEntity";
import {ArpModal} from "./arp-modal";
import {Observer} from "../Observer";
import {ArpValidationController} from "../validation/ArpValidationController";
import {EntityId, IEntity} from "@sparkie/shared-model/src";
import {SessionManager} from "../../auth/SessionManger";
import {ArpRepository} from "../ArpRepository";
import {IParentView} from "./IParentView";
import {ArpLogger} from "../log/ArpLogger";
import {ArpLogManager} from "../log/ArpLogManager";
import {NavigationLocation} from "../NavigationLocation";
import {BusyState} from "./BusyState";
import {ViewStateManager} from "../ViewStateManager";
import { stringifyError } from "error/ErrorHandler";

/**
 * Base for ViewModels that edit properties of a single entity.
 *
 * NOTE: This class always loads or creates a new entity for editing, and then saves the changes. A parent view must
 * be refreshed to display the changes.
 */
export abstract class ArpInstanceEditor<T extends IEntity<EntityId>> extends BusyState {

    protected readonly entityId: string;
    protected readonly parentView: IParentView;
    protected readonly observer: Observer;
    protected readonly modalService: ArpModal;
    protected readonly repository: ArpRepository<any, any, any>;
    protected readonly sessionManager: SessionManager;

    entity: T;
    originalEntity: T;

    validationController: ArpValidationController;
    updateAction: Action;
    title: string;
    isEditMode: boolean = false;
    isCreateMode: boolean = false;
    wideMode: boolean = false;
    preloadPromises: Array<Promise<any>> = [];
    datasourceRefreshCount: number = 0;

    protected editorActions: Array<Action>;
    protected logger: ArpLogger;
    protected navigationLocation: NavigationLocation;

    // Sometime the postXXX is handled by the editor, sometimes it's parent, and sometimes it depends on where
    // it is launched from (Task and Record editors).
    protected postCreate: (id: string) => any;
    public postEdit: (id: string) => any;

    /**
     * Create an editor for either a new default instance, or an existing instance (which will be loaded)
     * .
     * @param parentView
     * @param entityId null for new instance, or the entity id for an existing id
     * @param repositoryKey
     */
    protected constructor(parentView: IParentView, entityId: EntityId, repositoryKey: any) {
        super();

        this.parentView = parentView;
        this.entityId = entityId;

        this.repository = Container.instance.get(repositoryKey);
        this.observer = Container.instance.get(Observer);
        this.modalService = Container.instance.get(ArpModal);
        this.sessionManager = Container.instance.get(SessionManager);

        // For now default to the parent view (doesn't need to implement)
        if (parentView) {
            this.postCreate = (id) => { return parentView.postCreate(id) };
            this.postEdit = (id) => { return parentView.postEdit(id); }
        }


        this.updateAction = this.createUpdateAction();

        this.editorActions = [this.updateAction, this.modalService.cancelAction];
    }

    get router() : AppRouter {
        return Container.instance.get(ViewStateManager).router;
    }

    createUpdateAction() : Action {
        return new Action()
            .withPerformCallback((action: Action) => this.performUpdate())
            .withVisible(this.canUpdate);
    }

    /**
     * If a view-model has an activate method, the compose element will call it and pass in the model as a
     * parameter. The activate method can even return a Promise to cause the composition process to wait
     * until after some async work is done before actually databinding and rendering into the DOM.
     */
    async activate(model) {
        this.logger.nav(`activate(${this.entityId})`);

        try {
            this.incrementBusy();

            if (this.navigationLocation == null) {
                // Default to just 'edit'...
                this.navigationLocation = new NavigationLocation(this.repository.resource.model, this.title);
            }

            this.navigationLocation.trackAction('show');

            // TODO: Rename this to initPreloadPromises
            this.preload(this.preloadPromises);

            await Promise.all(this.preloadPromises);

            if (this.entityId) {
                // Note that we always get fresh data when editing an existing instance
                // Note that we are returning a promise here
                await this.loadInstance();
            } else {
                await this.newInstance();
            }

        } catch (ex) {
            // Not sure why, but this exception isn't caught by our global exception handler.
            this.logger.error(ex?.message, stringifyError(ex));
            throw ex;
        } finally {
            this.decrementBusy();
            this.logger.nav(`activate(${this.entityId}) complete`);
        }

        return null;
    }

    /**
     *  If the view-model implements the created callback it is invoked next. At this point in time, the view has
     *  also been created and both the view-model and the view are connected to their controller. The created
     *  callback will receive the instance of the "owningView". This is the view that the component is declared
     *  inside of. If the component itself has a view, this will be passed second.
     *
     * @param owningView
     * @param myView
     */
    created(owningView, myView) {
        this.logger.debug("created()");
        this.validationController = myView.container.get(NewInstance.of(ArpValidationController).as(ValidationController));
        this.validationController.observer = this.observer;
    }

    /**
     * Invoked when the databinding engine binds the view.
     *
     * @param bindingContext
     * @param overrideContext
     */
    bind(bindingContext, overrideContext) {
        this.logger.debug("bind()");

        this.configureValidation();

        this.observer.bind(this.validationController, 'isValid', this.updateAction, 'enabled');

        this.observer.observeArray(this.validationController.errors, () => {
            for (let error of this.validationController.errors) {
                this.logger.debug(`validation error: ${error.propertyName} = ${error.toString()}`);
            }
        });
    }

    /**
     * Invoked when the view that contains the extension is attached to the DOM.
     */
    attached() {
        this.logger.debug("attached()");
        return this.validationController.attach()
    }

    unbind() {
        this.observer.unObserveAll();
    }

    setEditMode(name) {
        this.navigationLocation = new NavigationLocation(this.repository.resource.model, `edit-${name}`);
        this.logger = ArpLogManager.getLogger(this.navigationLocation.getLoggerId());
        this.isEditMode = true;
        this.title = `Edit ${name}`;
        this.updateAction.withLabel("Update");
        this.updateAction.withLocation(this.navigationLocation);
    }

    setCreateMode(name) {
        this.navigationLocation = new NavigationLocation(this.repository.resource.model, `create-${name}`);
        this.logger = ArpLogManager.getLogger(this.navigationLocation.getLoggerId());
        this.isCreateMode = true;
        this.title = `New ${name}`;
        this.updateAction.withLabel("Create");
    }

    /**
     * Override this and add any promises that should be resolved before activation should be considered complete
     * @param preloadPromises
     */
    preload(preloadPromises: Array<Promise<any>>) : void {
    }

    /**
     * Override to configure the validationController for this view
     */
    configureValidation() : void {
    }

    async newInstance() : Promise<T> {
        this.logger.debug("newInstance()");
        this.originalEntity = new ArpEntity({}) as any as T;
        this.entity = this.repository.createDefaultEntity();

        return this.entity;
    }

    async loadInstance() : Promise<T> {
        this.logger.debug("loadInstance()");
        this.entity = await this.repository.loadEntityGQL(this.entityId);
        this.originalEntity = new ArpEntity(this.entity) as any as T;

        return this.entity;
    }

    async saveInstance() : Promise<string> {
        return this.repository.saveEntity(this.entity)
    }

    show() {
        if (this.updateAction === null) {
            throw new Error("Can't show model view: missing updateAction");
        }

        this.modalService.show(this.title, this, this.editorActions, [], this.wideMode );
    }

    async performUpdate() : Promise<any> {

        try {
            this.incrementBusy();

            this.preUpdate();

            let id = await this.saveInstance();

            this.postUpdate(id);

        } finally {
            this.decrementBusy();
            this.hide();
        }
    }

    hide() {
        this.modalService.hide();
    }

    get canUpdate() : boolean {
        return this.sessionManager.canUpdateAny(this.repository.resource);
    }

    preUpdate() : void {
    }

    postUpdate(newId) {

        if (this.entityId === null && this.postCreate) {
            return this.postCreate(newId);
        } else if (this.entityId !== null && this.postEdit) {
            return this.postEdit(this.entityId);
        }
    }

    navigateTo(location) {
        this.router.navigate(location);
    }

    /**
     * Clear the contents of an array in a manner that doesn't break observation callbacks
     *
     * @param anArray
     */
    safeClearArray(anArray: any[]) {
        if (anArray.length > 0) {
            anArray.splice(0, anArray.length);
        }
    }

    /**
     * Reset the contents of an array with new contents in a manner that doesn't break observation callbacks
     *
     * @param anArray
     * @param newContents
     */
    safeAssignArray(anArray: any[], newContents: any[]) {
        this.safeClearArray(anArray);

        if (newContents.length) {
            Array.prototype.push.apply(anArray, newContents);
        }
    }
}
