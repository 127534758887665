import moment from 'moment';

import {FilterBase} from './FilterBase';
import {IQuery} from "../ArpResource";

export class DateFilter extends FilterBase {

    from: Date;
    to: Date;
    active: boolean = false;

    constructor(propertyName: string, initialFrom?: Date, initialTo?: Date) {

        super(propertyName);

        this.from = initialFrom ? initialFrom : moment().subtract(1, "month").toDate();
        this.to = initialTo ? initialTo : moment().toDate();
    }

    observe(parent: any) {
        parent.observer.observe(this, "from", () => {
            parent.updateActive();
        });

        parent.observer.observe(this, "to", () => {
            parent.updateActive();
        });

        parent.observer.observe(this, "active", () => {
            parent.updateActive();
        });
    }

    isActive() : boolean {
        return this.active;
    }
    
    clearFilter() : void {
        this.active = false;
    }

    configureResource(resource: IQuery) {

        if (this.from && this.to) {
            resource.withDatePropertyBetween(this.propertyName, moment(this.from).startOf('day').toDate(), moment(this.to).endOf('day').toDate());
        } else if (this.from && !this.to) {
            resource.withDatePropertyAfter(this.propertyName, moment(this.from).startOf('day').toDate());
        } else if (!this.from && this.to) {
            resource.withDatePropertyBefore(this.propertyName, moment(this.to).startOf('day').toDate());
        }
    }

    save() {
        this.saved = {
            from: this.from ? this.from.valueOf() : null,
            to: this.to ? this.to.valueOf() : null,
            active: this.active
        };
    }

    getStateKey() : string {
        return `${this.propertyName}`;
    }

    getState() : any {
       return  {
           from: this.from ? this.from.valueOf() : null,
           to: this.to ? this.to.valueOf() : null
       };
    }

    setState(state: any) {
        this.from.setTime(state.from);
        this.to.setTime(state.to);
        this.active = true;
    }

    restore() {
        this.from.setTime(this.saved.from);
        this.to.setTime(this.saved.to);
        this.active = this.saved.active;
    }
}

