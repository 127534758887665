import {IReportConverter} from "./IReportConverter";
import moment from "moment";

export class NotesConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {
        return modelValue
            .map((it: any) => `${moment(it.date).format("YYYY-MM-DD")} ${it.addedBy} ${it.note}`)
            .join(", ");
    }
}
