import type { EntityId } from "../common/IEntity";
import {IMedicalRecord, MedicalRecord} from "../common/IMedicalRecord";
import {IMiscRecord, MiscRecord} from "../common/IMiscRecord";
import {Entity, IEntity} from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import {ITaskPerson, TaskPerson} from "./ITaskPerson";
import {ITaskApplication, TaskApplication} from "./ITaskApplication";
import {ITaskAnimal, TaskAnimal} from "./ITaskAnimal";

export interface ITask<T> extends IEntity<T>{
    taskType: string;    // TaskTypeEnum
    description: string;
    created: Date;
    due: Date;
    started: Date;
    completed: Date;

    // Coordinator
    coordinator: T;
    coordinatorFullName: string;
    coordinatorLastName: string;
    coordinatorPrimaryEmail: string;

    // Assignee
    person: T;

    // Include the person data locally to support full text query
    personFullName: string;
    personLastName: string;
    personPrimaryEmail: string;
    personNotificationsEnabled: boolean;

    personLastNotified: Date;

    // Regarding Person
    regardingPerson: ITaskPerson<T>;
    regardingAnimal: ITaskAnimal<T>;
    regardingApplication: ITaskApplication<T>;

    medicalRecord: IMedicalRecord<T>;
    miscRecord: IMiscRecord<T>;
}

@Serializable()
export class TaskEntity extends Entity implements ITask<EntityId> {

    @JsonProperty() taskType: string = null;    // TaskTypeEnum
    @JsonProperty() description: string = '';
    @JsonProperty() created: Date = new Date();
    @JsonProperty() due: Date = null;
    @JsonProperty() started: Date = null;
    @JsonProperty() completed: Date = null;

    // Coordinator
    @JsonProperty() coordinator: EntityId = null;
    @JsonProperty() coordinatorFullName: string = '';
    @JsonProperty() coordinatorLastName: string = '';
    @JsonProperty() coordinatorPrimaryEmail: string = '';

    // Assignee
    @JsonProperty() person: EntityId = null;

    // Include the person data locally to support full text query
    @JsonProperty() personFullName: string = '';
    @JsonProperty() personLastName: string = '';
    @JsonProperty() personPrimaryEmail: string = '';
    @JsonProperty() personNotificationsEnabled: boolean = false;

    @JsonProperty() personLastNotified: Date = null;

    @JsonProperty() regardingPerson: TaskPerson = new TaskPerson();
    @JsonProperty() regardingAnimal: TaskAnimal = new TaskAnimal();
    @JsonProperty() regardingApplication: TaskApplication = new TaskApplication();
    @JsonProperty() medicalRecord: MedicalRecord = null;
    @JsonProperty() miscRecord: MiscRecord = null;

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'taskType',
            'description',
            'created',
            'due',
            'started',
            'completed',
            'coordinator',
            'coordinatorFullName',      // Read-only
            'coordinatorLastName',      // Read-only
            'coordinatorPrimaryEmail',      // Read-only
            'person',
            'personFullName',      // Read-only
            'personLastName',      // Read-only
            'personPrimaryEmail',      // Read-only
            'personNotificationsEnabled',
            'personLastNotified',
            ...TaskPerson.getQueryFields().map(it => `regardingPerson.${it}`),
            ...TaskAnimal.getQueryFields().map(it => `regardingAnimal.${it}`),
            ...TaskApplication.getQueryFields().map(it => `regardingApplication.${it}`),
            ...MedicalRecord.getQueryFields().map(it => `medicalRecord.${it}`),
            ...MiscRecord.getQueryFields().map(it => `miscRecord.${it}`)
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            ...Entity.getReadOnlyFields(),
            'coordinatorFullName',      // Read-only
            'coordinatorLastName',      // Read-only
            'coordinatorPrimaryEmail',      // Read-only
            'personFullName',      // Read-only
            'personLastName',      // Read-only
            'personPrimaryEmail',      // Read-only
            ...TaskPerson.getReadOnlyFields().map(it => `regardingPerson.${it}`),
            ...TaskAnimal.getReadOnlyFields().map(it => `regardingAnimal.${it}`),
            ...TaskApplication.getReadOnlyFields().map(it => `regardingApplication.${it}`),
            ...MedicalRecord.getReadOnlyFields().map(it => `medicalRecord.${it}`),
            ...MiscRecord.getReadOnlyFields().map(it => `miscRecord.${it}`)
        ]
    }
}

//
// TaskDetails
//

@Serializable()
export class TaskDetails extends TaskEntity {
    // These are projected by the server
    @JsonProperty() status: string = '';           // TaskStatusEnum

    static getQueryFields(): string[] {
        return [
            ...TaskEntity.getQueryFields(),
            'status'
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            ...TaskEntity.getReadOnlyFields(),
            'status'
        ]
    }
}

//
// TaskSummary
//

@Serializable()
export class TaskSummary extends TaskDetails {

    // These are all mapped by TaskFormatter
    @JsonProperty() summaryDescription: string = '';
    @JsonProperty() details: string = '';
    @JsonProperty() notifyStatusMessage: string = '';
    @JsonProperty() notifyStatusGlyph: string = '';         /// TODO: Do this with a converter?
    @JsonProperty() notifyStatusError: boolean = false;

    static getQueryFields(): string[] {
        return [
            ...TaskDetails.getQueryFields()
        ]
    }
}

// Used by Person and Animal details to hold related Tasks
export interface ITaskSummaries extends IEntity<EntityId> {
    tasks: Array<TaskSummary>;
}

export function createDefaultTask<T extends ITask<any>>(): T {
    return new TaskEntity() as any as T;
}
