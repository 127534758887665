import $ from 'jquery';         // Must come before datetimepicker
import moment from 'moment';    // Must come before datetimepicker

import 'eonasdan-bootstrap-datetimepicker';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

import {inject, customElement, bindable} from 'aurelia-framework';

@inject(Element)
@bindable("value")
@customElement("arp-date-picker")
export class ArpDatePicker {

    @bindable format = "ll";
    @bindable extraFormats = ["LL", "L", "l"];
    @bindable enabled = true;
    private element: Element;
    private options: any;
    private value: string;
    private datePickerElement: any;     // This is the wrapper div element
    private dtpApi: any;

    constructor(element: Element) {
        this.element = element; // This is the <arp-date-picker> element
        this.options = {
            format: this.format,
            extraFormats: this.extraFormats,
            showClose: true,
            showTodayButton: true
        }
    }

    bind(bindingContext, overrideContext) {
    }

    enabledChanged() {
        let inputElement = $(this.datePickerElement).find('input').get(0);

        if (this.enabled) {
            $(inputElement).removeAttr('disabled');
        } else {
            $(inputElement).attr('disabled', 'disable');
        }
    }

    attached() {
        this.datePickerElement = $(this.element).find('.input-group.date').get(0);

        let datePickerApi = $(this.datePickerElement) as any;
        datePickerApi.datetimepicker(this.options);

        // All functions are accessed via the data attribute
        this.dtpApi = $(this.datePickerElement).data('DateTimePicker');

        if (this.value) {
            this.setControlValue(this.value);
        }

        $(this.datePickerElement).find('input').blur((e: any) => {
            this.blurInput(e);
        });

        // Fired when the date is changed.
        $(this.datePickerElement).on("dp.change", (e: any) => {
            // date in the event is already a moment instance!
            if (e.date) {
                this.setLocalValue(e.date.toDate());
            } else {
                this.setLocalValue(null);
            }
        });

        this.enabledChanged();
    }

    /**
     * Called when the input element loses focus
     *
     * NOTE: The blur, focus, load and unload events do not bubble so you'll need to use the trigger binding
     * command to subscribe to these events
     *
     * @param event
     */
    blurInput(event) {
        // To support validation we need to send a blur event from the Element bound to this custom element (a span).
        this.element.dispatchEvent(new CustomEvent('blur', {
            detail: { value: event.target.value }, bubbles: true
        }));

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
        return true;
    }

    setLocalValue(newDateValue) {
        if (!this.sameDate(this.value, newDateValue)) {
            this.value = newDateValue;
        }
    }

    setControlValue(newDateValue) {
        let newMomentValue = moment(newDateValue);
        let controlMomentValue = this.dtpApi.date();

        if (!this.sameDate(newDateValue, controlMomentValue)) {
            this.dtpApi.date(newMomentValue);
        }
    }

    sameDate(a, b) {
        if (!a || !b) {
            return false;
        } else {
            let ma = moment(a).startOf('day');
            let mb = moment(b).startOf('day');
            return ma.isSame(mb);
        }
    }
}