import {QuestionMetadata} from "../common/IQuestionMetadata";
import type {IQuestionMetadata} from "../common/IQuestionMetadata";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IQuestionEntry {
    questionMetadata: IQuestionMetadata;
    answer: string;
}

@Serializable()
export class QuestionEntry implements IQuestionEntry {
    @JsonProperty() questionMetadata: IQuestionMetadata = new QuestionMetadata();
    @JsonProperty() answer: string = '';

    static getQueryFields(): string[] {
        return [
            ...QuestionMetadata.getQueryFields().map(it => `questionMetadata.${it}`),
            'answer'
        ]
    }
}