import moment from 'moment';

/**
 * How to do date & time in Sparkie.
 *
 *  When the UI gets the current time via moment() or Date.now we get the current time @ GMT.  And when we ask
 *  to display it it will use the timezone of the browser.
 *
 *  and this is good!
 *
 *  Also when the UI calls moment().startOf('day') we get the start of day in the timezone of the browser.
 *
 *  and this is good!
 *
 *  When the server calls moment() we also get the current time @ GMT.
 *
 *  and this is good!
 *
 *  HOWEVER, when we import (parse) a date on the server that doesn't contain time zone it will assume UTC.
 *
 *  and this is BAD, cause if we import after 7PM Date will refer to the previous day.
 *
 */
export class ArpDateFormatValueConverter {
    toView(value, size = 'large', empty='') {
        if (value) {

            // Dates on the server are UTC, and since we never show the time we should ignore the time zone
            switch (size) {
                case 'time':
                    return moment(value).format('hh:mm:ss A');
                case 'date-time':
                    return moment(value).format('MM/DD/YYYY hh:mm:ss A');
                case 'small':
                    return moment(value).format('MM/DD/YYYY');
                case 'large':
                default:
                    return moment(value).format('ll');
            }
        }
        else {
            return empty;
        }
    }
}
