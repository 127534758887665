import {IReportConverter} from "./IReportConverter";

export class AnimalNameConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {

        if (!!modelInstance && modelInstance.adoptedName) {
            return `${modelValue} (nka ${modelInstance.adoptedName})`;

        } else {
            return modelValue;
        }
    }
}

