import {EnumElement, Enum} from "../../Enum";

export class AnimalCoat extends EnumElement {}

export class AnimalCoatEnum extends Enum<AnimalCoat> {

    // NOTE: These must match the PF Coat Lengths exactly!!!
    static readonly SHORT = new AnimalCoat('SHORT', 'Short');
    static readonly MEDIUM = new AnimalCoat('MEDIUM', 'Medium');
    static readonly LONG = new AnimalCoat('LONG', 'Long');
    static readonly WIRE = new AnimalCoat('WIRE', 'Wire');
    static readonly HAIRLESS = new AnimalCoat('HAIRLESS', 'Hairless');
    static readonly CURLY = new AnimalCoat('CURLY', 'Curly');

    static readonly ELEMENTS = [
        AnimalCoatEnum.SHORT,
        AnimalCoatEnum.MEDIUM,
        AnimalCoatEnum.LONG,
        AnimalCoatEnum.WIRE,
        AnimalCoatEnum.HAIRLESS,
        AnimalCoatEnum.CURLY
    ];
    static readonly INSTANCE = new AnimalCoatEnum();

    private constructor() {
        super(AnimalCoatEnum.ELEMENTS);
    }
}
