import { EnumElement, Enum } from "@sparkie/shared-model/src";

export class SortDirection extends EnumElement {

    toggle(): SortDirection {
        switch (this) {

            case SortDirectionEnum.ASCENDING:
                return SortDirectionEnum.DESCENDING;

            case SortDirectionEnum.DESCENDING:
                return SortDirectionEnum.NONE;

            case SortDirectionEnum.NONE:
                return SortDirectionEnum.ASCENDING;

            default:
                return null;
        }
    }

    getFontAwesomeIcon(): string {
        switch (this) {

            case SortDirectionEnum.NONE:
                return 'fa-sort';

            case SortDirectionEnum.ASCENDING:
                return 'fa-sort-asc';

            case SortDirectionEnum.DESCENDING:
                return 'fa-sort-desc';

            default:
                return null;
        }
    }

    getDbSortOrder(): number | null {
        switch (this) {

            case SortDirectionEnum.ASCENDING:
                return 1;

            case SortDirectionEnum.DESCENDING:
                return -1;

            case SortDirectionEnum.NONE:
            default:
                return null;
        }
    }

    sortActive(): boolean {
        switch (this) {

            case SortDirectionEnum.ASCENDING:
            case SortDirectionEnum.DESCENDING:
                return true;

            case SortDirectionEnum.NONE:
            default:
                return false;
        }
    }
}

export class SortDirectionEnum extends Enum<SortDirection> {

    static readonly NONE = new SortDirection('NONE', 'None');
    static readonly ASCENDING = new SortDirection('ASCENDING', 'Ascending');
    static readonly DESCENDING = new SortDirection('DESCENDING', 'Descending');

    static readonly ELEMENTS = [
        SortDirectionEnum.NONE,
        SortDirectionEnum.ASCENDING,
        SortDirectionEnum.DESCENDING
    ];
    static readonly INSTANCE = new SortDirectionEnum();

    private constructor() {
        super(SortDirectionEnum.ELEMENTS);
    }
}

