import {Container} from 'aurelia-framework';

import {WebApi} from '../WebApi';
import {isEmpty } from 'lodash';
import {QueryBuilder} from "../QueryBuilder";
import {EntityId} from "@sparkie/shared-model/src";
import {IDatasourceEntity} from "./arp-typeahead";

export abstract class ArpTypeaheadDatasource<T extends IDatasourceEntity> {

    private totalMatches: number = 0;

    // A Map object iterates entries, keys, and values in the order of entry insertion.
    private readonly loadedEntities: Map<string, T> = new Map();

    protected readonly webApi: WebApi;

    protected constructor() {
        this.webApi = Container.instance.get(WebApi);
    }

    abstract buildCountQuery(searchText: string): QueryBuilder;
    abstract buildQuery(searchText: string): QueryBuilder;
    abstract getDisplayValue(entity: T): string;
    abstract getMenuValue(entity: T): string;

    create(displayValue: string): Promise<T> {
        throw new Error("Datasource missing create function");
    }

    canCreate(displayValue: string): boolean {
        return true;
    }

    async load(query: string, pageSize: number): Promise<boolean> {

        this.clear();

        if (!isEmpty(query)) {
            await this.updateCount(query);
            await this.updateMatches(query, pageSize, 0);
        }

        return true;
    }

    async loadMore(query: string, pageSize: number): Promise<boolean> {

        if (!isEmpty(query)) {
            await this.updateCount(query);
            await this.updateMatches(query, pageSize, this.loadedEntities.size);
        }

        return true;
    }

    private async updateCount(query: string) {
        const q = this
            .buildCountQuery(query)
            .buildCountQuery();

        const response = await this.webApi.executeGQLQuery(q);
        this.totalMatches = response as number;
    }

    private async updateMatches(query: string, limit: number, skip: number) {
        const q = this
            .buildQuery(query)
            .withLimit(limit)
            .withSkip(skip)
            .buildManyQuery();

        const response: Array<T> = await this.webApi.executeGQLQuery(q);

        for (let entity of response) {

            entity.menuValue = this.getMenuValue(entity);
            entity.displayValue = this.getDisplayValue(entity);
            entity.styledMenuValue = "";

            if (this.loadedEntities.has(entity._id)) {
                // TODO:
                console.log(entity.menuValue);
            }
            this.loadedEntities.set(entity._id, entity);
        }

        // TODO:
        console.log(`Datasource has ${this.loadedEntities.size} entries`);
    }

    getMatchingEntities(): Array<T> {
        return Array.from(this.loadedEntities.values());
    }

    getTotalMatches(): number {
        return this.totalMatches;
    }

    /**
     * NOTE: This only works while searching.
     *
     * @param entityId
     * @protected
     */
    protected getEntity(entityId: EntityId): T {

        return this.loadedEntities.get(entityId);
    }

    clear() {
        this.totalMatches = 0;
        this.loadedEntities.clear();
    }

    getModelValue(entity: T): EntityId {
        return entity._id;
    }

    validateEntity(entityId: EntityId, entityName) {

        let valid = false;

        if (entityName != null && entityName.length > 0) {
            valid = entityId != null && entityId.length > 0;
        }

        return valid;
    }

    protected buildSafeRegexTerm(query: string, fromStart: boolean = true): any {
        // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex/6969486#6969486
        const safeQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        const term = fromStart ? `^${safeQuery}` : `${safeQuery}`;

        return {
            $regex: term,
            $options: 'i'
        }
    }
}

