import {IQuestionEntry, QuestionEntry} from "./IQuestionEntry";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IApplicationCategory {
    displayName: string;
    questions: Array<IQuestionEntry>;
}

@Serializable()
export class ApplicationCategory implements IApplicationCategory {
    @JsonProperty() displayName: string = '';
    @JsonProperty({ type: QuestionEntry }) questions: Array<QuestionEntry> = [];

    static getQueryFields(): string[] {
        return [
            'displayName',
            ...QuestionEntry.getQueryFields().map(it => `questions.${it}`),
        ]
    }
}