import {Action} from "./Action";
import {bindable} from "aurelia-framework";


export class ActionList {
    @bindable actions: Array<Action>
    @bindable glyph: string = 'glyphicon-plus';

    constructor(actions: Array<Action>, glyph: string = 'glyphicon-plus') {
        this.actions = actions;
        this.glyph = glyph;
    }

    update() {
        for (let action of this.actions) {
            action.update();
        }
    }
}