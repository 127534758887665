import {ITenant, ITenantEntityPartial, TenantEntity} from "../../tenant/ITenant";
import {IRegisterAnimal, MicrochipUploadValidatorBase} from "./MicrochipUploadValidatorBase";
import {IntegrationTypeEnum} from "../../tenant/IntegrationTypeEnum";
import {FoundAnimalsUploadValidator} from "./FoundAnimalsUploadValidator";

export function createMicrochipValidator<T>(animal: IRegisterAnimal<T>, tenant: ITenantEntityPartial<T>): MicrochipUploadValidatorBase<T> {
    switch (animal.microchip.useIntegration) {
        case IntegrationTypeEnum.FOUND_ANIMALS.model:
            return new FoundAnimalsUploadValidator(animal, tenant);
        default:
            return new MicrochipUploadValidatorBase(null, animal, tenant);
    }
}
