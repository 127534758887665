import {Enum, EnumElement} from "../../Enum";

export class TransportDistance extends EnumElement {}

export class TransportDistanceEnum extends Enum<TransportDistance> {

    static readonly LOCAL: TransportDistance = new TransportDistance('LOCAL', '< 25 miles');
    static readonly UP_TO_50_MILES: TransportDistance = new TransportDistance('UP_TO_50_MILES', '25-50 miles');
    static readonly UP_TO_100_MILES: TransportDistance = new TransportDistance('UP_TO_100_MILES', '51-100 miles');
    static readonly LONG_DISTANCE: TransportDistance = new TransportDistance('LONG_DISTANCE', '100+ miles');

    static readonly ELEMENTS = [
        TransportDistanceEnum.LOCAL,
        TransportDistanceEnum.UP_TO_50_MILES,
        TransportDistanceEnum.UP_TO_100_MILES,
        TransportDistanceEnum.LONG_DISTANCE
    ];
    static readonly INSTANCE = new TransportDistanceEnum();

    private constructor() {
        super(TransportDistanceEnum.ELEMENTS);
    }
}

export class TransportDistanceMetric extends EnumElement {}

export class TransportDistanceMetricEnum extends Enum<TransportDistanceMetric> {

    static readonly LOCAL = new TransportDistanceMetric('LOCAL', '< 25 km');
    static readonly UP_TO_50_MILES = new TransportDistanceMetric('UP_TO_50_MILES', '25-50 km');
    static readonly UP_TO_100_MILES = new TransportDistanceMetric('UP_TO_100_MILES', '51-100 km');
    static readonly LONG_DISTANCE = new TransportDistanceMetric('LONG_DISTANCE', '100+ km');

    static readonly ELEMENTS = [
        TransportDistanceMetricEnum.LOCAL,
        TransportDistanceMetricEnum.UP_TO_50_MILES,
        TransportDistanceMetricEnum.UP_TO_100_MILES,
        TransportDistanceMetricEnum.LONG_DISTANCE
    ];
    static readonly INSTANCE = new TransportDistanceMetricEnum();

    constructor() {
        super(TransportDistanceMetricEnum.ELEMENTS);
    }
}
