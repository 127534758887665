import {inject, bindable, customElement} from 'aurelia-framework';

import {Observer} from '../Observer';
import {Enum, EnumElement} from "@sparkie/shared-model/src";
import { isUndefined, map } from 'lodash';

@customElement("arp-stringlist")
@inject(Observer)
export class ArpStringList {

    @bindable value: Array<string>;           // A string array
    @bindable enumeration: Enum<EnumElement>;
    @bindable emptyText: string;      // NOTE: use 'empty-text' to set in template!

    displayValue: string;
    private observer: Observer;

    constructor(observer: Observer) {
        this.observer = observer;
    }

    bind(bindingContext: any, overrideContext: any) {
        if (!isUndefined(this.value)) {
            this.observer.observeArray(this.value, (changes) => {
                this.update();
            });
        }

        this.update();
    }

    update() {
        if (this.value && this.value.length > 0) {
            if (this.enumeration) {
                this.displayValue = map(this.value, (value) => {
                    let enumElement: EnumElement = this.enumeration.fromModel(value);
                    return enumElement ? enumElement.view : value;
                }).join(', ');
            } else {
                this.displayValue = this.value.join(', ');
            }
        } else {
            this.displayValue = this.emptyText;
        }
    }

    unbind() {
        this.observer.unObserveAll();
    }
}
