import {PLATFORM} from "aurelia-pal";
import {Container, useView} from "aurelia-framework";
import {AppRouter} from "aurelia-router";

import {Action} from "../actions/Action";
import {ArpViewState} from "../ArpViewState";
import {ArpModal} from "./arp-modal";
import {Observer} from "../Observer";
import {ArpMessage} from "./arp-message";
import {ArpDeleteMessage} from "./arp-delete-message";
import {ArpDownload} from "../reports/arp-download";
import {ArpPager} from "./arp-pager";
import {ArpSearch} from "./arp-search";
import {ArpSort} from "./arp-sort";
import {ArpFilterBar} from "../filters/arp-filter-bar";
import {ArpScroller} from "./arp-scroller";
import {ArpRepository, IDependency} from "../ArpRepository";
import {SessionManager} from "../../auth/SessionManger";
import {EntityId, IEntity, ModelResource} from "@sparkie/shared-model/src";
import {ReportBar} from "../reports/ReportBar";
import {ClientReportSpec} from "../reports/ClientReportSpec";
import {ArpLogger} from "../log/ArpLogger";
import {ArpLogManager} from "../log/ArpLogManager";
import {NavigationLocation} from "../NavigationLocation";
import {ViewStateManager} from "../ViewStateManager";
import {ActionList, IParentView} from "..";
import { find, isEmpty } from "lodash";

@useView(PLATFORM.moduleName('./arp-list-page.html'))
export class ArpListPage<T extends IEntity<EntityId>, S extends IEntity<EntityId>, D extends IEntity<EntityId>> implements IParentView {

    repository: ArpRepository<T, S, D>;
    navigationLocation: NavigationLocation;
    viewState: ArpViewState;

    protected title: string;
    protected entityTable: any;         // TODO: This is the viewModel for the table view
    protected logger: ArpLogger;
    protected entities: Array<S>;
    protected scrollerModel: ArpScroller;
    protected pagerModel: ArpPager;
    protected sortModel: ArpSort;
    protected searchModel: ArpSearch;
    protected filterBarModel: ArpFilterBar;
    protected reportBarModel: ReportBar;
    //protected viewBarModel: ViewBar;
    protected downloadModel: ArpDownload;
    protected downloadSpec: ClientReportSpec;

    protected readonly sessionManager: SessionManager;
    protected readonly vsm: ViewStateManager;
    protected readonly observer: Observer;
    protected readonly modalService: ArpModal;
    protected readonly resource: ModelResource;

    private readonly tableActions: Array<Action>;
    private readonly headerActions: Array<Action | ActionList>;

    constructor(resource: ModelResource, repository: ArpRepository<T, S, D>) {

        this.repository = repository;
        this.resource = resource;

        this.vsm = Container.instance.get(ViewStateManager);
        this.observer = Container.instance.get(Observer);
        this.modalService = Container.instance.get(ArpModal);
        this.sessionManager = Container.instance.get(SessionManager);
        this.viewState = new ArpViewState();

        this.navigationLocation = new NavigationLocation(resource.model, 'list');
        this.logger = ArpLogManager.getLogger(this.navigationLocation.getLoggerId());

        this.entities = [];
        this.tableActions = [];
        this.headerActions = [];

        this.scrollerModel = new ArpScroller(this.viewState);
        this.pagerModel = new ArpPager(this.viewState);
        this.searchModel = new ArpSearch(this.viewState);
        this.sortModel = new ArpSort(this.viewState);
        this.filterBarModel = new ArpFilterBar(this.viewState);
        this.reportBarModel = new ReportBar(this.navigationLocation);

        this.title = this.repository.resource.pluralView();

        this.downloadSpec = new ClientReportSpec(this.title);
        this.downloadModel = new ArpDownload(this.downloadSpec);
        this.downloadModel.title = this.title;
        this.downloadModel.sortModel = this.sortModel;
        this.downloadModel.searchModel = this.searchModel;
        this.downloadModel.filterBarModel = this.filterBarModel;
        this.downloadModel.repository = this.repository;

        this.createHeaderActions(this.headerActions);
        this.createTableActions(this.tableActions);
    }

    async loadInstance(entityId: any): Promise<any> {
        this.logger.error("loadInstance not implemented.");
    }

    async refreshInstance(): Promise<any> {
        this.logger.error("refreshInstance not implemented.");
    }

    get router() : AppRouter {
        return this.vsm.router;
    }

    /**
     * Implement this hook if you want to perform custom logic just before your view-model is displayed. You can
     * optionally return a promise to tell the router to wait to bind and attach the view until after you finish your work.
     *
     * @param params
     * @param routeConfig
     * @param navigationInstruction
     */
    activate(params, routeConfig, navigationInstruction) {
        this.logger.nav(`activate with ${JSON.stringify(params)}`);
        this.navigationLocation.trackAction('show');

        this.observer.observe(this.sortModel, 'changeMarker', (newValue, oldValue) => this.sortTermChange(newValue, oldValue));
        this.observer.observe(this.pagerModel, 'currentPage', (newValue, oldValue) => this.currentPageChange(newValue, oldValue));

        this.searchModel.searchAction.withPerformCallback((action: Action) => {return this.performSearch()});
        this.searchModel.placeholderText = `Search for ${this.title.toLowerCase()}...`;

        this.filterBarModel.applyFilterAction.withPerformCallback((action: Action) => { return this.performApplyFilter()});

        this.entityTable.entities = this.entities;
        this.entityTable.actions = this.tableActions;

        // Restore view state that effects the REST calls
        this.sortModel.restoreState();
        this.searchModel.restoreState();
        this.pagerModel.restoreState();
        this.filterBarModel.restoreState();

        return this.refresh();
    }

    /**
     * Invoked when the databinding engine binds the view.
     *
     * @param bindingContext
     * @param overrideContext
     */
    bind(bindingContext, overrideContext) {
        this.logger.debug("bind()");
    }

    attached() {
        this.logger.debug("attached()");

        // Restore presentation view state
        this.vsm.setActiveViewState(this.viewState);
        this.scrollerModel.restoreState();
    }

    detached() {
        this.logger.debug("detached()");
        this.vsm.setActiveViewState(null);
    }

    unbind() {
        this.logger.debug("unbind()");
        this.observer.unObserveAll();
    }

    currentPageChange(newValue, oldValue) {
        this.loadEntities();
        this.scrollerModel.scrollToTop();
    }

    sortTermChange(newValue, oldValue) {
        this.pagerModel.setCurrentPage(0);
        this.loadEntities();
    }

    //
    // Common Actions
    //

    createHeaderActions(headerActions: Array<Action | ActionList>) : void {

        // Default is to allow New operations
        headerActions.push(this.createNewAction());
    }

    refreshHeaderMenus() {
        for (let action of this.headerActions) {
            action.update();
        }
    }

    createTableActions(tableActions: Array<Action>) : void {

        // Default is to allow Edit and Delete
        tableActions.push(this.createEditAction());
        tableActions.push(this.createDeleteAction());
    }

    performSearch() : boolean {
        this.logger.info("Searching for " + this.searchModel.searchText);

        this.sortModel.updateSort(this.searchModel.searchActive);
        this.refresh();
        return true;
    }

    performApplyFilter() : boolean {
        this.logger.info("Filtering");
        this.refresh();

        return true;
    }
    
    navigateTo(location) {
        this.router.navigate(location);
    }

    createEditAction() : Action {
        return new Action()
            .withLabel( this.canUpdateAny ? "Edit" : "View")
            .withPerformCallback((action: Action) => this.performEdit(action))
            .withVisible(this.repository.hasDetailsPage ? this.canReadAny : this.canUpdateAny);
    }

    createNewAction(label: string = "") : Action {
        return new Action()
            .withLabel(label)
            .withGlyph('glyphicon-plus')
            .withPerformCallback((action: Action) => this.performNew(action))
            .withVisible(this.canCreateAny);
    }

    createDeleteAction() : Action {
        return new Action()
            .withLabel("Delete")
            .withPerformCallback((action: Action) => this.performDelete(action))
            .withVisible(this.canDeleteAny);
    }

    performEdit(action: Action) : void {
    }

    performNew(action: Action) : void {
        this.showInstance(null, {});
    }

    performDelete(action: Action) : void {
        this.deleteEntity(action.target);
    }

    /**
     * Call to launch an edit for a new or existing entity showInstance.
     *
     * @param entityId of the instance, or null to create a new entity
     */
    showInstance(entityId, params: any) {
        let editor = this.createInstanceEditor(entityId, params);

        if (editor == null) {
            throw new Error("No createInstanceEditor implemented for " + this.repository.resource.route());
        } else {
            editor.show();
        }
    }

    /**
     * Create a modal editor used to edit the properties of entity
     *
     * @param entityId
     * @returns {null}
     */
    createInstanceEditor(entityId: string, params: any) {
        return null;
    }

    /**
     * Called after an instance editor has created a new entity.
     */
    postCreate(entityId: string) {

        if (this.repository.hasDetailsPage) {
            this.navigateTo(`app/${this.repository.resource.route()}/` + entityId);
            return null;
        } else {
            return this.refresh();
        }
    }

    /**
     * Called after an instance editor has made changes to a new or existing entity.
     */
    postEdit(entityId: string) {
        let entity = find(this.entities, (it) => { return it._id.toString() === entityId });
        return this.repository.refreshSummaryEntityGQL(entity);
    }

    /**
     * Refresh the contents of this list view.  We must first get the count, based on the current search & filter,
     * and then request only the current page's data (in correct sort order).
     *
     * @returns {Promise<void>}
     */
    async refresh() : Promise<void> {
        await this.loadEntityCount();
        await this.loadEntities();

        this.refreshHeaderMenus();
    }

    /**
     * Loads the current entity count, based on the current search and filter.
     *
     * @returns {Promise<number>}
     */
    async loadEntityCount() : Promise<number> {
        const queryBuilder = this.repository.createQueryBuilder();

        this.searchModel.configureResource(queryBuilder);
        this.filterBarModel.configureResource(queryBuilder);

        let count = await this.repository.loadCountGQL(queryBuilder);

        this.pagerModel.setItemCount(count);

        return Promise.resolve(count);
    }

    /**
     * Load entities for the page based on the current page number, search and sort configuration.
     */
    async loadEntities() : Promise<Array<S>> {
        const queryBuilder = this.repository.createQueryBuilder();

        this.pagerModel.configureResource(queryBuilder);
        this.sortModel.configureResource(queryBuilder, this.searchModel.searchActive);
        this.searchModel.configureResource(queryBuilder);
        this.filterBarModel.configureResource(queryBuilder);

        return this.repository.loadSummaryEntitiesGQL(queryBuilder, this.entities);
    }

    async deleteEntity(entity: T | S) {
        const dependencies: Array<IDependency> = await this.repository.getDependencies(entity as T);

        let canDelete = isEmpty(dependencies);

        if (canDelete) {
            return this.showDeleteConfirmation(entity)
        } else {
            return this.showDeleteNotAllowed(dependencies);
        }
    }

    showDeleteConfirmation(entity: T | S) {
        let description = "Are you sure you want to delete?";
        let confirmationView = new ArpMessage(description);

        let deleteAction = new Action()
            .withLabel("Delete")
            .withPerformCallback(() => {
                return this.repository.deleteEntity(entity)
                    .then(httpResponseMessage => {
                        this.pagerModel.setItemCount(this.pagerModel.itemCount - 1);
                        return this.refresh();
                    })
                    .then(() => {
                        this.modalService.hide();
                    });
            });

        this.modalService.show("Confirm", confirmationView, [deleteAction, this.modalService.cancelAction]);
    }

    showDeleteNotAllowed(dependencies: Array<IDependency>) {
        let message = this.repository.processDependencies(dependencies);
        let errorView = new ArpDeleteMessage(message, dependencies);

        this.modalService.show("Error", errorView, [this.modalService.closeAction]);
    }

    // RBAC
    get canReadAny() : boolean {
        return this.sessionManager.canReadAny(this.resource);
    }

    get canUpdateAny() : boolean {
        return this.sessionManager.canUpdateAny(this.resource);
    }

    get canCreateAny() : boolean {
        return this.sessionManager.canCreateAny(this.resource);
    }

    get canDeleteAny() : boolean {
        return this.sessionManager.canDeleteAny(this.resource);
    }
}
