import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface INote {
    date: Date;
    addedBy: string;
    note: string;
}

@Serializable()
export class Note implements INote {
    @JsonProperty() date: Date = new Date();
    @JsonProperty() addedBy: string = '';
    @JsonProperty() note: string = '';

    static getQueryFields(): string[] {
        return [
            'date',
            'addedBy',
            'note'
        ]
    }
}

export interface IHasNotes {
    notes: Array<INote>;
}
