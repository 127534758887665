import {Enum, EnumElement} from "../../Enum";

export class DaysOfWeek extends EnumElement {}

export class DaysOfWeekEnum extends Enum<DaysOfWeek> {

    static readonly MONDAY = new DaysOfWeek('MONDAY', 'Monday');
    static readonly TUESDAY = new DaysOfWeek('TUESDAY', 'Tuesday');
    static readonly WEDNESDAY = new DaysOfWeek('WEDNESDAY', 'Wednesday');
    static readonly THURSDAY = new DaysOfWeek('THURSDAY', 'Thursday');
    static readonly FRIDAY = new DaysOfWeek('FRIDAY', 'Friday');
    static readonly SATURDAY = new DaysOfWeek('SATURDAY', 'Saturday');
    static readonly SUNDAY = new DaysOfWeek('SUNDAY', 'Sunday');

    static readonly ELEMENTS = [
        DaysOfWeekEnum.MONDAY,
        DaysOfWeekEnum.TUESDAY,
        DaysOfWeekEnum.WEDNESDAY,
        DaysOfWeekEnum.THURSDAY,
        DaysOfWeekEnum.FRIDAY,
        DaysOfWeekEnum.SATURDAY,
        DaysOfWeekEnum.SUNDAY
    ];
    static readonly INSTANCE = new DaysOfWeekEnum();

    private constructor() {
        super(DaysOfWeekEnum.ELEMENTS);
    }
}
