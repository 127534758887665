import {bindable, customElement} from 'aurelia-framework';

@customElement("arp-multi-check")
export class ArpMultiCheck {

    @bindable choices = [];
    @bindable selection;        // This must be a String array

    constructor() {
    }
}
