import {ITenant, ITenantEntityPartial} from "../../tenant/ITenant";
import {IntegrationTypeEnum} from "../../tenant/IntegrationTypeEnum";
import {IRegisterAnimal, MicrochipUploadValidatorBase} from "./MicrochipUploadValidatorBase";

export class FoundAnimalsUploadValidator<T> extends MicrochipUploadValidatorBase<T> {

    constructor(entity: IRegisterAnimal<T>, tenant: ITenantEntityPartial<T>) {
        super(IntegrationTypeEnum.FOUND_ANIMALS, entity, tenant);
    }

    get hasValidMicrochipNumber(): boolean {
        return isValidMicrochipNumber(this.animal.microchip.number);
    }
}

/**
 * Must be 9, 10, or 15 digits. Incomplete or invalid microchip numbers will not be registered.
 *
 *   - Do not include any suffixes, such as the small “981” on some barcode stickers
 *   - Microchip numbers with leading zeros should be entered with dashes: ex: 012-345-678
 *
 * Valid Chip Formats for uploading:
 *    9-digits: numeric only - Chips with leading zeros - upload with dashes (012-345-678)
 *    10-digits: alpha-numeric (A-F letters include in #)
 *    15-digits numeric only
 *
 * @param microchipNumber
 */
export function isValidMicrochipNumber(microchipNumber: string): boolean {

    const trimmed = microchipNumber.trim();

    switch (trimmed.length) {
        case 9:
            return [...trimmed].every(it => isIntegerDigit(it));
        case 10:
            return [...trimmed].every(it => isHexDigit(it));
        case 15:
            return [...trimmed].every(it => isIntegerDigit(it));
        default:
            return false;
    }
}

const UTF_0: number = 48;
const UTF_9: number = 57;
const UTF_A: number = 65;
const UTF_F: number = 70;

export function isIntegerDigit(s: string): boolean {
    const charCode = s.charCodeAt(0)
    return charCode >= UTF_0 && charCode <= UTF_9;
}

export function isHexDigit(s: string): boolean {
    const charCode = s.charCodeAt(0)
    return charCode >= UTF_0 && charCode <= UTF_9 || charCode >= UTF_A && charCode <= UTF_F;
}