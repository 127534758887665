import {Container, computedFrom} from 'aurelia-framework';

import {Action} from '../actions/Action';
import {ArpModal} from './arp-modal';
import {Observer} from '../Observer';
import {SessionManager} from "../../auth/SessionManger";
import {EntityId, IEntity, ModelResource} from "@sparkie/shared-model/src";
import {IParentView} from "./IParentView";
import {ArpLogger, ArpLogManager} from "..";
import {IViewModel} from "./IViewModel";
import { makeId } from 'arp-framework-react/TestId';

/**
 * Base for ViewModels that display a single entity.
 */
export abstract class ArpInstanceViewer<T extends IEntity<EntityId>> implements IViewModel {

    readonly title: string;
    readonly id: string;
    description: string;
    headerStyle: string = 'h2';

    protected entity: T;

    protected readonly parentView: IParentView;
    protected readonly headerActions : Array<Action> = [];
    protected readonly modalService: any;
    protected readonly observer: Observer;
    protected readonly logger: ArpLogger;
    protected readonly sessionManager: SessionManager;
    protected readonly modelResource: ModelResource;

    protected constructor(parentView: IParentView, entity: T, title: string, modelResource: ModelResource) {

        this.parentView = parentView;       // TODO: Define "interface" for functions we call on parentView
        this.entity = entity;
        this.title = title;
        this.id = makeId(title);
        this.modelResource = modelResource;

        this.modalService = Container.instance.get(ArpModal);
        this.observer = Container.instance.get(Observer);
        this.sessionManager = Container.instance.get(SessionManager);

        this.createHeaderActions(this.headerActions);

        this.logger = ArpLogManager.getLogger(`arp-instance-viewer-${title.toLowerCase()}`);
    }

    get isEnabled() : boolean {
        return true;
    }

    createHeaderActions(headerActions: Array<Action>) {
    }

    createEditAction(): Action {
        return new Action()
            .withLabel("Edit")
            .withPerformCallback((action: Action) => this.showInstanceEditor())
            .withVisible(this.canUpdateAny);
    }

    createInstanceEditor() : any {
        return null;
    }

    showInstanceEditor() : boolean {
        let editor = this.createInstanceEditor();
        editor.show();

        return true;
    }

    unbind() {
        this.observer.unObserveAll();
    }

    get canUpdateAny() : boolean {
        return this.sessionManager.canUpdateAny(this.modelResource);
    }
}
