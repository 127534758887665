import {Enum, EnumElement} from "../../Enum";

export class ServerMode extends EnumElement {

    isProductionMode() : boolean {
        switch (this) {
            case ServerModeEnum.PRODUCTION:
                return true;
            default:
                return false;
        }
    }

    isDeveloperMode() : boolean {
        switch (this) {
            case ServerModeEnum.PRODUCTION:
                return false;
            default:
                return true;
        }
    }
}

export class ServerModeEnum extends Enum<ServerMode> {

    static readonly PRODUCTION = new ServerMode('PRODUCTION', 'Production');
    static readonly STAGING = new ServerMode('STAGING', 'Staging');
    static readonly DEVELOPMENT = new ServerMode('DEVELOPMENT', 'Development');

    static readonly ELEMENTS = [
        ServerModeEnum.PRODUCTION,
        ServerModeEnum.STAGING,
        ServerModeEnum.DEVELOPMENT
    ];
    static readonly INSTANCE = new ServerModeEnum();

    private constructor() {
        super(ServerModeEnum.ELEMENTS);
    }
}


