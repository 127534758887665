import {AnimalSpecies, AnimalSpeciesEnum} from "../animal/AnimalSpeciesEnum";
import {IntegrationMetadata} from "./IntegrationMetadata";
import {dogIntegrationMetadata} from "./adoptAPet/DogMetadata";
import {catIntegrationMetadata} from "./adoptAPet/CatMetadata";
import {rabbitIntegrationMetadata} from "./adoptAPet/RabbitMetadata";
import {guineaPigIntegrationMetadata} from "./adoptAPet/GuineaPigMetadata";

export function getAdoptAPetMetadata(species: AnimalSpecies): IntegrationMetadata {
    switch (species) {
        case AnimalSpeciesEnum.DOG:
            return dogIntegrationMetadata();
        case AnimalSpeciesEnum.CAT:
            return catIntegrationMetadata();
        case AnimalSpeciesEnum.RABBIT:
            return rabbitIntegrationMetadata();
        case AnimalSpeciesEnum.GUINEA_PIG:
            return guineaPigIntegrationMetadata();
        default:
            return null;
    }
}

