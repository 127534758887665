import {ArpViewState} from "../ArpViewState";
import {ArpLogger, ArpLogManager} from "..";
import isString from "lodash/isString";

const FIRST_CC = 0x00AB;
const PREVIOUS_CC = 0x2039;
const NEXT_CC = 0x203A;
const LAST_CC = 0x00BB;

export class ArpPagerButton {

    pageNumber: number;
    label: string;
    enabled: boolean;
    active: boolean;
    id: string;

    constructor(labelOrPageNumber: string | number) {

        if (isString(labelOrPageNumber)) {
            this.pageNumber = -1;
            this.label = labelOrPageNumber;

            switch (this.label.charCodeAt(0)) {
                case FIRST_CC:
                    this.id = 'first';
                    break;
                case PREVIOUS_CC:
                    this.id = 'prev';
                    break;
                case NEXT_CC:
                    this.id = 'next';
                    break;
                case LAST_CC:
                    this.id = 'last';
                    break;
            }
        } else {
            this.updatePageNumber(labelOrPageNumber);
        }
        this.enabled = false;
        this.active = false;
    }

    updatePageNumber(pageNumber: number) {
        this.pageNumber = pageNumber;
        this.label = (1 + pageNumber).toString();
        this.id = this.label;
    }
}

export class ArpPager {
    static readonly _firstButton = new ArpPagerButton(String.fromCharCode(FIRST_CC));
    static readonly _previousButton = new ArpPagerButton(String.fromCharCode(PREVIOUS_CC));
    static readonly _nextButton = new ArpPagerButton(String.fromCharCode(NEXT_CC));
    static readonly _lastButton = new ArpPagerButton(String.fromCharCode(LAST_CC));

    public itemCount: number;

    private viewState: ArpViewState;
    private maxDirectButtons: number;
    private itemsPerPage: number;
    private pageCount: number;
    private currentPage: number;
    private firstIndexOnPage: number;
    private lastIndexOnPage: number;
    private _numDirectButtons: number;
    private _pageButtons: Array<ArpPagerButton>;
    private _logger: ArpLogger;

    constructor(viewState: ArpViewState, itemsPerPage: number = 25, maxDirectButtons: number = 5) {

        this.viewState = viewState;

        // Treat these as public readonly properties
        this.maxDirectButtons = maxDirectButtons;      // Must be an odd number
        this.itemsPerPage = itemsPerPage;
        this.pageCount = 1;                 // One based
        this.itemCount = 0;                 // Zero based
        this.currentPage = 0;               // Zero based

        this.firstIndexOnPage = 0;
        this.lastIndexOnPage = 0;

        this._numDirectButtons = 0;
        this._pageButtons = [];

        this._logger = ArpLogManager.getLogger("arp-pager");
    }

    setItemCount(numItems) {
        this._logger.debug("setItemCount to " + numItems);
        this.itemCount = numItems;
        this.pageCount = Math.ceil(numItems / this.itemsPerPage);

        this._pageButtons.splice(0, this._pageButtons.length);        // Clear existing buttons
        this._numDirectButtons = Math.min(this.maxDirectButtons, this.pageCount);

        this._pageButtons.push(ArpPager._firstButton);
        this._pageButtons.push(ArpPager._previousButton);

        for (let i = 0; i < this._numDirectButtons; i++) {
            this._pageButtons.push(new ArpPagerButton(i));
        }

        this._pageButtons.push(ArpPager._nextButton);
        this._pageButtons.push(ArpPager._lastButton);

        if (this.currentPage >= this.pageCount) {
            this.setCurrentPage(this.pageCount-1);
        }

        this._updatePageIndexes();
        this._setupDirectButtons();
        this._updateButtonState();
    }

    setCurrentPage(newValue) {
        this.currentPage = Math.max(0, newValue);
        this._logger.debug("setCurrentPage to " + this.currentPage);

        this._updatePageIndexes();
        this._setupDirectButtons();
        this._updateButtonState();

        this.saveState();
    }

    _updatePageIndexes() {
        //
        if (this.itemCount === 0) {
            this.firstIndexOnPage = 0;
            this.lastIndexOnPage = 0;
        } else {
            let offset = this.currentPage * this.itemsPerPage;
            this.firstIndexOnPage = 1 + offset;
            this.lastIndexOnPage = Math.min(this.itemCount, offset + this.itemsPerPage);
        }
   }

    configureResource(resource) {
        resource.withLimit(this.itemsPerPage);

        if (this.currentPage > 0) {
            resource.withSkip(this.currentPage * this.itemsPerPage);
        }
    }

    saveState() {
        this.viewState.save('pagerData', {
            currentPage: this.currentPage,
        });
    }

    restoreState() {
        let pagerData = this.viewState.restore('pagerData');

        if (pagerData) {
            this.setCurrentPage(pagerData.currentPage);
        }
    }

    _setupDirectButtons() {
        /**
         *  If all buttons fit
         *      show all buttons
         *      don't center buttons
         *  Else
         *      if currentPage is at position 1 or 5 AND is not last or first
         *          recenter buttons so that current page is at index 3
         */
        if (this.pageCount > this._numDirectButtons) {
            let currentDirectButtonIndex = this._getCurrentDirectButton();

            if (currentDirectButtonIndex == -1) {
                this._reloadDirectButtons();
            } else if (currentDirectButtonIndex === 0 && this.currentPage > 0) {
                this._reloadDirectButtons();
            } else if (currentDirectButtonIndex === (this._numDirectButtons - 1) && this.currentPage < (this.pageCount - 1)) {
                this._reloadDirectButtons();
            }
        }
    }

    _updateButtonState() {
        ArpPager._firstButton.enabled = (this.currentPage > 0);
        ArpPager._previousButton.enabled = (this.currentPage > 0);
        ArpPager._nextButton.enabled = (this.currentPage < this.pageCount - 1);
        ArpPager._lastButton.enabled = (this.currentPage < this.pageCount - 1);

        for (let i = 0; i < this._numDirectButtons; i++) {
            let pageButton = this._pageButtons[i + 2];
            pageButton.active = (pageButton.pageNumber === this.currentPage);
            pageButton.enabled = true;
        }
    }

    _getCurrentDirectButton() {
        for (let i = 0; i < this._numDirectButtons; i++) {
            let pageButton = this._pageButtons[i + 2];

            if (pageButton.pageNumber === this.currentPage) {
                return i;
            }
        }

        return -1;
    }

    _reloadDirectButtons() {

        // Put the current page in the middle of the buttons, unless it is at the beginning or end
        let offset = Math.floor(this._numDirectButtons / 2);
        let startPageNumber = 0;

        if (this.currentPage <= offset) {
            startPageNumber = 0;
        } else if (this.currentPage >= this.pageCount - 1 - offset) {
            startPageNumber = this.pageCount - this._numDirectButtons;
        } else {
            startPageNumber = this.currentPage - offset;
        }

        for (let i = 0; i < this._numDirectButtons; i++) {
            let pageButton = this._pageButtons[i + 2];
            pageButton.updatePageNumber(startPageNumber++);
        }
    }

    _pageClick(pageButton) {
        if (pageButton.enabled) {
            if (pageButton === ArpPager._firstButton) {
                this.setCurrentPage(0);
            } else if (pageButton === ArpPager._previousButton) {
                this.setCurrentPage(this.currentPage - 1);
            } else if (pageButton === ArpPager._nextButton) {
                this.setCurrentPage(this.currentPage + 1);
            } else if (pageButton === ArpPager._lastButton) {
                this.setCurrentPage(this.pageCount - 1);
            } else {
                this.setCurrentPage(pageButton.pageNumber);
            }
        }
    }
}

