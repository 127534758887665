import isString from "lodash/isString";
import {IResource} from "./IResource";

/*
 Collection Resource
 URI PATTERN: /api/types/{resourceType}/instances
 EXAMPLE:     /api/types/datastore/instances
 GET:         Get a list of instances by type
 POST:        Create a new instance of this type
 Instance Resource
 URI PATTERN: /api/instances/{resourceType}/{id}
 EXAMPLE:     /api/instances/datastore/datastore-12
 GET:         Get a specific instance
 DELETE:      Delete a specific instance
 Instance Action
 URI PATTERN: /api/instances/{resourceType}/{id}/action/{actionName}
 EXAMPLE:     /api/instances/datastore/datastore-12/action/cancel
 POST:        Perform modify or other action on a specific instance.
 Class Action
 URI PATTERN: /api/types/{resourceType}/action/{actionName}
 EXAMPLE:     /api/types/datastore/action/unmap
 POST:        Perform an action at the class level (service action)
 */
export class UploadResource implements IResource {

    private resource: string;
    private application: string;
    private entityId: string;
    private file: string;
    private action: string;
    private query: Map<string, any>;

    // Sign for download    POST /s3/instances/file/:entity/:file/action/sign
    // Sign for upload      POST /s3/types/file/action/sign
    // Delete file          DELETE /s3/instances/file/:entity/:file

    constructor() {
        this.resource = 'file';
        this.application = "/s3";
        this.entityId = "";
        this.file = "";
        this.action = "";
        this.query = new Map<string, any>();
    }

    withEntityId(entityId) {
        this.entityId = entityId;
        return this;
    }

    withFile(file) {
        this.file = file;
        return this;
    }

    withAction(action) {
        this.action = action;
        return this;
    }

    withQueryParameter(key, value) {
        if (isString(value)) {
            this.query.set(key, encodeURIComponent(value));
        } else {
            this.query.set(key, encodeURIComponent(JSON.stringify(value)));
        }
        return this;
    }

    withTextQuery(value) {
        /*

         TODO: Sort by score (but work with ArpSort also?)
         db.articles.find(
         { $text: { $search: "cake" } },
         { score: { $meta: "textScore" } }
         ).sort( { score: { $meta: "textScore" } } )
         */

        let query = { $text: { $search: value } };

        this.withQueryParameter("query", query);
    }

    get resourcePath() : string {
        // Sign for download    POST /s3/instances/file/:entity/:file/action/sign
        // Sign for upload      POST /s3/types/file/action/sign
        // Delete file          DELETE /s3/instances/file/:entity/:file
        let pathElements = [this.application];

        this.entityId ? pathElements.push('instances') : pathElements.push('types');
        pathElements.push(this.resource);

        if (this.entityId) {
            pathElements.push(this.entityId);
            pathElements.push(this.file);
        }

        if (this.action) {
            pathElements.push('action');
            pathElements.push(this.action);
        }

        let path = pathElements.join('/');

        if (this.query.size > 0) {
            let terms = [];

            for (let entry of Array.from(this.query.entries())) {
                terms.push(entry[0] + '=' + entry[1]);
            }

            return [path, terms.join('&')].join('?');

        } else {
            return path;
        }
    }
}
