import {isNull, isUndefined, trim } from "lodash";

export class ArpTrimValueConverter {

    toView(value) {
        if (!isUndefined(value) && !isNull(value)) {
            return trim(value);
        } else {
            return value;
        }
    }

    fromView(value) {
        if (!isUndefined(value) && !isNull(value)) {
            return trim(value);
        } else {
            return value;
        }
    }
}
