import {
    getCumulativeDaysUnavailable,
    IAnimal,
    IReportConverter
} from "@sparkie/shared-model/src";

export class DaysUnavailableConverter<T> implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {

        const animal = modelInstance as IAnimal<T>;
        const cumulativeDaysUnavailable = getCumulativeDaysUnavailable(animal);
        return cumulativeDaysUnavailable.toString();
    }
}
