import {JsonProperty, Serializable} from "typescript-json-serializer";
import type { EntityId } from "../common/IEntity";

// TODO: Intake doesn't really work, this needs to be modelled as part of animal status as
// an animal can leave the rescue and be returned, which is not modelled as an intake
export interface IAnimalIntake<T> {
    mode: string;
    date: Date;

    agencyName: string;
    agency: T;

    personFullName: string;
    person: T;

    transferTenant: T;
    transferTenantName: string;
}

@Serializable()
export class AnimalIntake implements IAnimalIntake<EntityId> {
    @JsonProperty() mode: string = '';
    @JsonProperty() date: Date = new Date();
    @JsonProperty() agencyName: string = '';
    @JsonProperty() agency: EntityId = null;

    @JsonProperty() personFullName: string = '';
    @JsonProperty() person: EntityId = null;

    @JsonProperty() transferTenant: EntityId = null;
    @JsonProperty() transferTenantName: string = '';

    static getQueryFields(): string[] {
        return [
            'mode',
            'date',
            'agencyName',
            'agency',
            'personFullName',
            'person',
            'transferTenant',
            'transferTenantName'
        ]
    }
}