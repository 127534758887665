import isEmpty from "lodash/isEmpty";

import {ITenant, ITenantEntityPartial} from "../../tenant/ITenant";
import {AnimalStatus, AnimalStatusEnum} from "../../animal/AnimalStatusEnum";
import {IntegrationType} from "../../tenant/IntegrationTypeEnum";
import {Integration} from "../../tenant/IIntegration";
import {isValidPhoneNumber} from "./isValidPhoneNumber";
import { IAnimalIntake } from "../../animal/IAnimalIntake";
import { IAnimalStatusRecord } from "../../animal/IAnimalStatusRecord";
import { IAnimalMicrochip } from "../../animal/IAnimalMicrochip";
import { IPersonSummaryPartial } from "../../person/IPerson";

// What we get from the database
export interface IRegisterAnimal<T> {
    _id: T;
    updatedAt: Date;
    intake: IAnimalIntake<T>;
    microchip: IAnimalMicrochip<T>;
    name: string;
    animalId: string;
    currentGuardian: IAnimalStatusRecord<T>;
    currentPerson: IPersonSummaryPartial;
    breed: string;
    breed2: string;
    dateOfBirth: Date;
    gender: string;
    species: string;
//    tenantId: string;
}

export class MicrochipUploadValidatorBase<T> {

    constructor(readonly integrationType: IntegrationType, readonly animal: IRegisterAnimal<T>, readonly tenant: ITenantEntityPartial<T>) {
    }

    //
    // Overall Status
    //

    /**
     * Determines if the profile and tenant are both configured correctly is completecan be part of an upload
     */
    get profileValidForUpload(): boolean {
        const [canUpload, ] = this.state;
        return canUpload;
    }

    /**
     * Determines when to alert the user that the Profile Configuration needs attention due to errors.
     */
    get profileHasError(): boolean {
        return this.status.startsWith("Error:");
    }

    /**
     * Contains the text that describes the microchip registration status for an animal.
     */
    get status(): string {

        const [, status] = this.state;
        return status;
    }

    get isRegistered(): boolean {
        if (this.requiresRegistrationToGuardian) {
            return this.equalAndNotNull(this.animal.microchip.registeredTo?.toString(), this.animal.currentGuardian?.person.toString())
        } else if (this.requiresRegistrationToRescue) {
            return this.equalAndNotNull(this.animal.microchip.registeredTo?.toString(), this.tenant._id.toString());
        } else {
            return false;
        }
    }

    //
    // Condition Checks
    //

    /**
     * The current upload and status of this validator.
     */
    private get state(): [ boolean, string ] {

        // Not configured for upload
        if (!this.isUploadConfigured) {
            return [ false, ""];
        }

        // Upload not needed
        if (!this.guardianStatusSupportsRegistration) {
            return [ false, this.animal.microchip.registeredStatus ];
        }

        // Already registered
        if (this.isRegistered) {
            return [true, this.animal.microchip.registeredStatus ];
        }

        // The integration this animal uses is not configured for the rescue
        if (!this.isIntegrationConfigured) {
            return [false, "Error: Integration not configured" ];
        }

        // The integration this animal uses is configured for the rescue but currently disable
        if (!this.isIntegrationEnabled) {
            return [ false, "Error: Integration disabled" ];
        }

        if (!this.hasValidMicrochipNumber) {
            return [ false, "Error: Microchip number invalid" ];
        }

        if (this.requiresRegistrationToRescue) {
            if (!this.hasValidRescueFirstName) {
                return [ false, "Error: Integration missing first name" ];
            }

            if (!this.hasValidRescueLastName) {
                return [ false, "Error: Integration missing last name" ];
            }

            if (!this.hasRescuePhone) {
                return [ false, "Error: Integration missing phone number" ];
            }

            if (!this.hasValidRescuePhone) {
                return [ false, "Error: Integration has invalid phone number" ];
            }

            if (!this.hasValidRescueEmail) {
                return [ false , "Error: Integration missing email address" ];
            }
        } else if (this.requiresRegistrationToGuardian) {
            if (!this.hasValidGuardianFirstName) {
                return [ false, "Error: Current guardian missing first name" ];
            }

            if (!this.hasValidGuardianLastName) {
                return [ false, "Error: Current guardian missing last name" ];
            }

            if (!this.hasGuardianPhone) {
                return [ false, "Error: Current guardian missing phone number" ];
            }

            if (!this.hasValidGuardianPhone) {
                return [ false, "Error: Current guardian has invalid phone number" ];
            }

            if (!this.hasValidGuardianEmail) {
                return [ false, "Error: Current guardian missing email address" ];
            }
        }

        return [ true, "Registration pending" ];
    }

    private get isUploadConfigured(): boolean {
        return !isEmpty(this.animal.microchip.useIntegration);
    }

    private get isIntegrationConfigured(): boolean {
        let integration = this.tenant.integrations.find(it => it.type === this.integrationType.model);
        return integration != null;
    }

    private get isIntegrationEnabled(): boolean {
        let integration = this.tenant.integrations.find(it => it.type === this.integrationType.model);
        return integration?.enabled as boolean;
    }

    private get guardianStatusSupportsRegistration(): boolean {
        if (this.currentGuardianStatus == null) {
            return false;
        } else {
            return this.requiresRegistrationToGuardian || this.requiresRegistrationToRescue;
        }
    }

    get hasValidMicrochipNumber(): boolean {
        return false;
    }

    private get hasGuardian(): boolean {
        return this.animal.currentPerson != null;
    }

    private get hasValidGuardianFirstName(): boolean {
        return this.hasGuardian &&
            !isEmpty(this.animal.currentPerson.name.first);
    }

    private get hasValidGuardianLastName(): boolean {
        return this.hasGuardian &&
            !isEmpty(this.animal.currentPerson.name.last);
    }

    private get hasGuardianPhone(): boolean {
        return this.hasGuardian &&
            this.animal.currentPerson.phone.length > 0 &&
            !isEmpty(this.animal.currentPerson?.phone[0].number);
    }

    private get hasValidGuardianPhone(): boolean {
        return this.hasGuardian &&
            this.animal.currentPerson.phone.length > 0 &&
            !isEmpty(this.animal.currentPerson.phone[0].number) &&
            isValidPhoneNumber(this.animal.currentPerson.phone[0].number);
    }

    private get hasValidGuardianEmail(): boolean {
        return this.hasGuardian &&
            this.animal.currentPerson.email.length > 0 &&
            !isEmpty(this.animal.currentPerson.email[0].address);
    }

    private get hasValidRescueFirstName(): boolean {
        return !isEmpty(this.activeIntegration?.registeredFirstName);
    }

    private get hasValidRescueLastName(): boolean {
        return !isEmpty(this.activeIntegration?.registeredLastName);
    }

    private get hasRescuePhone(): boolean {
        return !isEmpty(this.activeIntegration?.registeredPhone);
    }

    private get hasValidRescuePhone(): boolean {
        return this.hasRescuePhone && isValidPhoneNumber(this.activeIntegration?.registeredPhone);
    }

    private get hasValidRescueEmail(): boolean {
        return !isEmpty(this.activeIntegration?.registeredEmail);
    }

    //
    // Helpers
    //

    private get currentGuardianStatus(): AnimalStatus | null {
        return AnimalStatusEnum.INSTANCE.fromModel(this.animal.currentGuardian?.status);
    }

    private get activeIntegration(): Integration {
        return this.tenant.integrations.find(it => it.type === this.integrationType.model);
    }

    private get requiresRegistrationToGuardian(): boolean {
        if (this.animal.currentGuardian != null) {
            return this.currentGuardianStatus.requiresRegistrationToGuardian();
        } else {
            return false;
        }
    }

    private get requiresRegistrationToRescue(): boolean {
        if (this.animal.currentGuardian != null) {
            return this.currentGuardianStatus.requiresRegistrationToRescue();
        } else {
            return false;
        }
    }

    private equalAndNotNull(aThis: string, aThat: string): boolean {
        return aThis !== null && aThat !== null && aThis === aThat;
    }
}

