import {EnumElement, Enum} from "../../Enum";
import {TaskDetails} from "./ITask";

export class TaskStatus extends EnumElement {

    setTaskStatus(taskDetails: TaskDetails) {
        switch (this) {
            case TaskStatusEnum.COMPLETED:
            case TaskStatusEnum.RECORDED:
                taskDetails.completed = new Date();

                if (!taskDetails.started) {
                    taskDetails.started = taskDetails.completed;
                }
                break;

            case TaskStatusEnum.NOT_STARTED:
                taskDetails.started = null;
                taskDetails.completed = null;
                break;

            case TaskStatusEnum.IN_PROGRESS:
                if (!taskDetails.started) {
                    taskDetails.started = new Date();
                }
                taskDetails.completed = null;
                break;
        }
    }

    // static getStatus(task: ITask) : TaskStatus {
    //     if (task.completed) {
    //         switch (task.taskType) {
    //             case TaskTypeEnum.MEDICAL.model:
    //             case TaskTypeEnum.GROOMING.model:
    //             case TaskTypeEnum.TRAINING.model:
    //                 return TaskStatusEnum.RECORDED;
    //             case TaskTypeEnum.BASIC.model:
    //             default:
    //                 return TaskStatusEnum.COMPLETED;
    //         }
    //     } else if (task.started) {
    //         return TaskStatusEnum.IN_PROGRESS;
    //     } else {
    //         return TaskStatusEnum.NOT_STARTED;
    //     }
    // }
}

export class TaskStatusEnum extends Enum<TaskStatus> {

    static readonly NOT_STARTED = new TaskStatus('NOT_STARTED', 'Not Started');
    static readonly IN_PROGRESS = new TaskStatus('IN_PROGRESS', 'In Progress');
    static readonly COMPLETED = new TaskStatus('COMPLETED', 'Completed');
    static readonly RECORDED = new TaskStatus('RECORDED', 'Recorded');

    static readonly ELEMENTS = [
        TaskStatusEnum.NOT_STARTED,
        TaskStatusEnum.IN_PROGRESS,
        TaskStatusEnum.COMPLETED,
        TaskStatusEnum.RECORDED
    ];
    static readonly INSTANCE = new TaskStatusEnum();

    private constructor() {
        super(TaskStatusEnum.ELEMENTS);
    }
}
