import {Enum, EnumElement} from "../../Enum";

export class TimeOfDay extends EnumElement {}

export class TimeOfDayEnum extends Enum<TimeOfDay> {

    static readonly MORNING = new TimeOfDay('MORNING', 'Morning 7am-12pm');
    static readonly AFTERNOON = new TimeOfDay('AFTERNOON', 'Afternoon 12pm-5pm');
    static readonly EVENING = new TimeOfDay('EVENING', 'Evening 5pm-10pm');

    static readonly ELEMENTS = [
        TimeOfDayEnum.MORNING,
        TimeOfDayEnum.AFTERNOON,
        TimeOfDayEnum.EVENING
    ];
    static readonly INSTANCE = new TimeOfDayEnum();

    private constructor() {
        super(TimeOfDayEnum.ELEMENTS);
    }
}

