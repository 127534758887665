import $ from 'jquery';

import {autoinject, transient, bindable, customElement} from 'aurelia-framework';

import {Observer} from '../Observer';
import { isFunction, remove } from 'lodash';

@bindable("value")
@customElement("arp-editable-list")
@transient()
@autoinject()
export class ArpEditableListCustomElement {

    // Our template binds to items, which is a copy of this.value with an extra entry for adding new items
    items: Array<any> = [];
    valueProperty;
    newItem;
    private element: Element;
    private observer: Observer;
    private parent: any;
    private value: Array<any>;

    constructor(element: Element, observer: Observer) {
        this.element = element;
        this.observer = observer;
    }

    bind(bindingContext, overrideContext) {

        this.parent = bindingContext;

        // Some of our properties are defined in html, and are not bound.  So we just pull those values
        this.valueProperty = this.valueProperty || $(this.element).attr('valueProperty');
        this.newItem = this.newItem || $(this.element).attr('newItem');

        for (let item of this.value) {
            this.observeItem(item);
            this.items.push(item);
        }

        this.addNewRow();
    }

    observeItem(item) {
        this.observer.observe(item, this.valueProperty, (newValue, oldValue) => {
            this.onItemChange(item, newValue, oldValue);
        });
    }

    addNewRow() {
        let newItemFn = this.parent[this.newItem];
        let item = isFunction(newItemFn) ?  newItemFn() : {};

        this.observeItem(item);

        this.items.push(item);
    }

    onItemChange(item, newValue, oldValue) {

        if (!oldValue && newValue) {
            this.addItem(item);
        } else if (oldValue && !newValue) {
            this.removeItem(item);
        }
    }

    addItem(item) {
        // Copy this row to the values and add another new row
        this.value.push(item);
        this.addNewRow();
    }

    removeItem(item) {
        // Remove it
        if (this.items.length > 1) {
            this.observer.unObserve(item);

            remove(this.items, (it) => it === item );
            remove(this.value, (it) => it === item );

            if (this.items.length == 0) {
                this.addNewRow();
            }
        }
    }

    unbind() {
        this.observer.unObserveAll();
    }
}