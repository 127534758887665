
export class AnimalMetadata {

    // TODO: Move me to my own class!
    microchipCompanies: Array<string> = [
        '24PetWatch',
        'AKC CAR/EID',
        'Allflex',
        'Avid',
        'Bayer resQ',
        'Datamars',
        'Found Animals',
        'HomeAgain',
        'Microfindr',
        'PetLink',
    ];

    constructor() {
    }
}





