import {transient, bindable, inject, customElement} from 'aurelia-framework';
import {Observer} from '../Observer';
import {Enum, EnumElement} from "@sparkie/shared-model/src";
import isArray from "lodash/isArray";

@bindable("value")
@customElement("arp-multiline-stringlist")
@transient()
@inject(Observer)
export class ArpMultiLineStringList {

    @bindable enumeration: Enum<EnumElement> | Array<EnumElement>;
    @bindable emptyText;      // NOTE: use 'empty-text' to set in template!

    value: string[];
    isEmpty;
    orderedValues = [];
    private observer: Observer;

    constructor(observer) {
        this.observer = observer;
    }

    bind(bindingContext, overrideContext) {

        this.observer.observeArray(this.value, (changes) => {
            this.updateIsEmpty();
            this.updateOrderedValues();
        });

        this.updateIsEmpty();
        this.updateOrderedValues();
    }

    unbind() {
        this.observer.unObserveAll();
    }

    updateIsEmpty() {
        this.isEmpty = this.value === null || this.value.length === 0;
    }

    updateOrderedValues() {
        this.observer.safeClearArray(this.orderedValues);

        for (let enumElement of this.elements) {
            if (this.value.includes(enumElement.model)) {
                this.orderedValues.push(enumElement.model);
            }
        }
    }

    get elements(): Array<EnumElement> {
        if (isArray(this.enumeration)) {
            return this.enumeration;
        } else {
            return this.enumeration.elements;
        }
    }
}
