import {Aurelia, autoinject, Container} from "aurelia-framework";
import {PLATFORM} from 'aurelia-pal';

import {ClientAuthentication} from "./ClientAuthentication";
import {evaluateAttributes, SparkieAccessControl} from "@sparkie/shared-model/src";
import {SessionState, useSessionStore} from "./SessionState";
import {ServerModeEnum} from "@sparkie/shared-model/src";
import {UserRoleEnum} from "@sparkie/shared-model/src";
import {ArpLogger, ArpLogManager, ViewStateManager, WebApi} from "../arp-framework";
import {ModelResource} from "@sparkie/shared-model/src";

/**
 * Manage the session state in the Application.
 *
 * If you need to access the session state then inject the SessionState object (not this service).
 *
 */
@autoinject()
export class SessionManager {

    readonly sessionState: SessionState;

    private readonly aurelia: Aurelia;
    private readonly authenticationService: ClientAuthentication;
    private readonly authorizationService: SparkieAccessControl;
    private readonly vsm: ViewStateManager;
    private readonly logger: ArpLogger;

    constructor(aurelia: Aurelia, authenticationService: ClientAuthentication, authorizationService: SparkieAccessControl, sessionState: SessionState, vsm: ViewStateManager) {
        this.aurelia = aurelia;
        this.authenticationService = authenticationService;
        this.authorizationService = authorizationService;
        this.sessionState = sessionState;
        this.vsm = vsm;

        this.logger = ArpLogManager.getLogger(`SessionManager`);
    }

    async load(): Promise<Aurelia> {
        await this.loadSession();

        if (this.isAuthenticated()) {
            return this.showApplication();
        } else {
            return this.showLogin();
        }
    }

    /**
     * Loads the application session state from the server.
     */
    async loadSession(): Promise<void> {
        let statusResource = "/status";
        let webApi: WebApi = Container.instance.get(WebApi);

        try {
            // This will return 401 and then throw if not logged in
            let status: any = await webApi.getJSON(statusResource);

            this.sessionState.activeTenant = status.sessionStatus.tenant;
            this.sessionState.activeUser = status.sessionStatus.user;
            this.sessionState.activeRole = UserRoleEnum.INSTANCE.fromModel(status.sessionStatus.user.role);
            this.sessionState.applicationVersion = status.serverStatus.version;
            this.sessionState.serverMode = ServerModeEnum.INSTANCE.fromModel(status.serverStatus.serverMode);

            useSessionStore.setState({
                activeTenant: status.sessionStatus.tenant,
                activeUser: status.sessionStatus.user,
                activeRole: UserRoleEnum.INSTANCE.fromModel(status.sessionStatus.user.role),
                applicationVersion: status.serverStatus.version,
                serverMode: ServerModeEnum.INSTANCE.fromModel(status.serverStatus.serverMode)
            });

            this.logger.debug(`session`, this.sessionState);
        } catch (err) {
            this.reset();
        }

        return Promise.resolve()
    }

    refreshTenant(): Promise<void> {
        return this.loadSession();
    }

    refreshUser(userId: string): Promise<void> {
        if (userId === this.sessionState.activeUser._id) {
            return this.loadSession();
        } else {
            return Promise.resolve();
        }
    }

    reset(): void {
        if (
            this.sessionState.activeTenant != null ||
            this.sessionState.activeUser != null ||
            this.sessionState.activeRole != null ||
            this.sessionState.applicationVersion != null ||
            this.sessionState.serverMode != null
        ) {
            this.logger.info(`Resetting session`);
            this.sessionState.activeTenant = null;
            this.sessionState.activeUser = null;
            this.sessionState.activeRole = null;
            this.sessionState.applicationVersion = null;
            this.sessionState.serverMode = null;
    
            useSessionStore.setState({
                activeTenant: null,
                activeUser: null,
                activeRole: null,
                applicationVersion: null,
                serverMode: null
            });
        }
    }

    isAuthenticated() {
        return this.sessionState.isAuthenticated();
    }

    logout() {
        this.reset();

        return this.authenticationService.logout()
            .then(() => {
                return this.showLogin();
            });
    }

    showLogin() : Promise<Aurelia> {
        if (this.vsm.router) {
            this.vsm.router.navigate('app/welcome', { replace: true, trigger: false });
            this.vsm.router.reset();
            this.vsm.router.deactivate();
        }

        return this.setRoot(PLATFORM.moduleName('login/Login', 'login'));
    }

    showApplication() : Promise<Aurelia>  {
        return this.setRoot(PLATFORM.moduleName('SparkieClient', 'application'));
    }

    setRoot(root: string): Promise<Aurelia> {
        return this.aurelia.setRoot(root);
    }

    //
    // RBAC helpers.  NOTE: The activeRole can't change without reloading the UI.
    //

    canReadAny(resource: ModelResource) {
        return this.authorizationService.can(this.sessionState.activeRole).readAny(resource.model).granted;
    }

    canReadOwn(resource: ModelResource) {
        return this.authorizationService.can(this.sessionState.activeRole).readOwn(resource.model).granted;
    }

    canUpdateAny(resource: ModelResource, attributes: Array<string> = null) {
        const permission = this.authorizationService.can(this.sessionState.activeRole).updateAny(resource.model);

        if (permission.granted) {
            return evaluateAttributes(permission, attributes);
        } else {
            return false;
        }
    }

    canUpdateOwn(resource: ModelResource, attributes: Array<string> = null) {
        const permission = this.authorizationService.can(this.sessionState.activeRole).updateOwn(resource.model);

        if (permission.granted) {
            return evaluateAttributes(permission, attributes);
        } else {
            return false;
        }
    }

    canCreateAny(resource: ModelResource) {
        return this.authorizationService.can(this.sessionState.activeRole).createAny(resource.model).granted;
    }

    canCreateOwn(resource: ModelResource) {
        return this.authorizationService.can(this.sessionState.activeRole).createOwn(resource.model).granted;
    }

    canDeleteAny(resource: ModelResource) {
        return this.authorizationService.can(this.sessionState.activeRole).deleteAny(resource.model).granted;
    }

    canDeleteOwn(resource: ModelResource) {
        return this.authorizationService.can(this.sessionState.activeRole).deleteAny(resource.model).granted;
    }

    canDeleteFile(): boolean {
        switch (this.sessionState.activeRole) {
            case UserRoleEnum.ADMIN:
            case UserRoleEnum.OWNER:
                return true;

            default:
                return false;
        }
    }
}