import { EnumElement, Enum } from "../../Enum";

export class ContactPosition extends EnumElement { }

export class ContactPositionEnum extends Enum<ContactPosition> {

    static readonly ADMINISTRATOR = new ContactPosition('ADMINISTRATOR', 'Administrator');
    static readonly ANIMAL_CONTROL_OFFICER = new ContactPosition('ANIMAL_CONTROL_OFFICER', 'Animal Control Officer');
    static readonly COORDINATOR_FOSTER = new ContactPosition('COORDINATOR_FOSTER', 'Coordinator - Foster');
    static readonly COORDINATOR_INTAKE = new ContactPosition('COORDINATOR_INTAKE', 'Coordinator - Intake');
    static readonly COORDINATOR_ADOPTION = new ContactPosition('COORDINATOR_ADOPTION', 'Coordinator - Adoption');
    static readonly COORDINATOR_VOLUNTEER = new ContactPosition('COORDINATOR_VOLUNTEER', 'Coordinator - Volunteer');
    static readonly DIRECTOR_MANAGER = new ContactPosition('DIRECTOR_MANAGER', 'Director/Manager');
    static readonly FOUNDER = new ContactPosition('FOUNDER', 'Founder');
    static readonly TRAINER = new ContactPosition('TRAINER', 'Trainer');
    static readonly VETERINARIAN = new ContactPosition('VETERINARIAN', 'Veterinarian (DVM)');
    static readonly VETERINARIAN_ASSISTANT = new ContactPosition('VETERINARIAN_ASSISTANT', 'Veterinarian Assistant');
    static readonly VOLUNTEER = new ContactPosition('VOLUNTEER', 'Volunteer');
    static readonly OTHER = new ContactPosition('OTHER', 'Other');

    static readonly ELEMENTS = [
        ContactPositionEnum.ADMINISTRATOR,
        ContactPositionEnum.ANIMAL_CONTROL_OFFICER,
        ContactPositionEnum.COORDINATOR_FOSTER,
        ContactPositionEnum.COORDINATOR_INTAKE,
        ContactPositionEnum.COORDINATOR_ADOPTION,
        ContactPositionEnum.COORDINATOR_VOLUNTEER,
        ContactPositionEnum.DIRECTOR_MANAGER,
        ContactPositionEnum.FOUNDER,
        ContactPositionEnum.TRAINER,
        ContactPositionEnum.VETERINARIAN,
        ContactPositionEnum.VETERINARIAN_ASSISTANT,
        ContactPositionEnum.VOLUNTEER,
        ContactPositionEnum.OTHER
    ];
    static readonly INSTANCE = new ContactPositionEnum();

    private constructor() {
        super(ContactPositionEnum.ELEMENTS);
    }
}
