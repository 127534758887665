//import "reflect-metadata";

export class EnumElement {
    constructor(public readonly model: string, public readonly view: string) {}
}

export class Enum<T extends EnumElement> {

    constructor(public readonly elements: Array<T>) {}

    schema(): Array<string> {
        return this.elements.map( it => it.model );
    }

    /**
     * Find the element that matches the given model value.
     * 
     * @param model 
     * @returns The element that matches the given model value, or undefined if no match is found.
     */
    fromModel(model: string) : T {
        return this.elements.find((element) => element.model === model);
    }

    /**
     * Find the element that matches the given view value.
     * 
     * @param view 
     * @returns The element that matches the given view value, or undefined if no match is found.
     */
    fromView(view: string) : T {
        return this.elements.find((element) => element.view === view);
    }

    /**
     * Returns true if the given model value is valid for this enum.
     * 
     * @param model 
     * @returns 
     */
    hasValueFor(model: string) : boolean {
        return this.fromModel(model) !== undefined;
    }

    ordinalComparator(first: T, second: T): number {
        const firstPosition = this.elements.indexOf(first);
        const secondPosition = this.elements.indexOf(second);

        if (firstPosition < secondPosition) {
            return -1;
        } else if (firstPosition > secondPosition) {
            return 1;
        } else {
            return 0;
        }
    }
}

// export function enumElement(view: string): PropertyDecorator {

//     // This will be called by the decorator at runtime
//     return function(target: Object, propertyKey: string | symbol): void {

//         // target: The constructor function of the class for a static member
//         const type = (<any>target)['TYPE'] as typeof EnumElement;

//         if (type) {
//             //let type = Reflect.getMetadata("design:type", target, propertyKey);
//             const value = Reflect.construct(type, [propertyKey, view]);

//             const elementsArray = (<any>target)['ELEMENTS'] as Array<EnumElement>;
//             elementsArray.push(value);

//             Object.defineProperty(target, propertyKey, {
//                 get: function () {
//                     return value;
//                 },
//                 enumerable: true,
//                 configurable: false
//             });
//         }
//     }
// }

// export function enumOf(type: typeof EnumElement) {
//     return function(constructor: Function) {
//
//         Object.defineProperty(constructor, 'TYPE', {
//             get: function () {
//                 return type;
//             },
//             enumerable: true,
//             configurable: false
//         });
//     }
// }
//
