import $ from 'jquery';

import {autoinject, transient, bindable, customElement} from 'aurelia-framework';

import {Observer} from '../Observer';

@bindable("value")
@customElement("arp-stringlist-editor")
@transient()
@autoinject()
export class ArpStringListEditor {

    items = [];
    placeholder = "";
    private element: Element;
    private observer: Observer;
    private value: any;

    constructor(element: Element, observer: Observer) {
        this.element = element;
        this.observer = observer;
    }

    bind(bindingContext, overrideContext) {
        // Some of our properties are defined in html, and are not bound.  So we just pull those values
        this.placeholder = this.placeholder || $(this.element.children[0]).attr('placeholder');

        for (let i = 0; i < this.value.length; i++) {
            this.items.push(this.value[i]);
        }

        this.items.push("");

        this.observer.observeArray(this.items, (changes) => {
            this.handleChanges(changes);
        });
    }

    handleChanges(changes) {
        // [{"index":0,"removed":["One"],"addedCount":1}]
        // [{"index":0,"removed":[""],"addedCount":0}]
        for (let change of changes) {

            if (change.removed.length === 1 && change.addedCount === 1) {
                // Update
                this.onItemChange(change.index, change.removed[0]);

            } else if (change.removed.length === 0 && change.addedCount === 1) {
                // add
            } else if (change.removed.length === 1 && change.addedCount === 0) {
                // remove
            }
        }
    }

    onItemChange(index, oldValue) {

        let newValue = this.items[index];

        if (!oldValue && newValue) {
            // Add item
            this.value.push(newValue);

            // Add a new row
            this.items.push("");

        } else if (oldValue && !newValue) {
            // Remove item
            this.items.splice(index, 1);
            this.value.splice(index, 1);
        } else {
            // Update
            this.value[index] = this.items[index];
        }
    }

    unbind() {
        this.observer.unObserveAll();
    }
}