import {EnumElement, Enum} from "../../../Enum";

export class RabbitCharacteristics extends EnumElement {}

export class RabbitCharacteristicsEnum extends Enum<RabbitCharacteristics> {

    static readonly HOUSE_TRAINED = new RabbitCharacteristics('HOUSE_TRAINED', 'House Trained');
    static readonly SOCIAL = new RabbitCharacteristics('SOCIAL', 'Social');
    static readonly SHY = new RabbitCharacteristics('SHY', 'Shy');
    static readonly COMES_FOR_TREATS = new RabbitCharacteristics('COMES_FOR_TREATS', 'Comes for treats');
    static readonly LIKES_BEING_STROKED = new RabbitCharacteristics('LIKES_BEING_STROKED', 'Likes being stroked');
    static readonly SPECIAL_NEEDS = new RabbitCharacteristics('SPECIAL_NEEDS', 'Special Needs');

    static readonly ELEMENTS = [
        RabbitCharacteristicsEnum.HOUSE_TRAINED,
        RabbitCharacteristicsEnum.SOCIAL,
        RabbitCharacteristicsEnum.SHY,
        RabbitCharacteristicsEnum.COMES_FOR_TREATS,
        RabbitCharacteristicsEnum.LIKES_BEING_STROKED,
        RabbitCharacteristicsEnum.SPECIAL_NEEDS
    ];
    static readonly INSTANCE = new RabbitCharacteristicsEnum();

    private constructor() {
        super(RabbitCharacteristicsEnum.ELEMENTS);
    }
}
