import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IDogPreferences {
    sizeAsAdult: Array<string>;         // DogSizeEnum
    age: Array<string>;                 // AnimalAgeEnum
    energyLevel: Array<string>;         // AnimalEnergyLevelEnum
    goodWith: Array<string>;            // AnimalGoodWithEnum
    gender: Array<string>;              // AnimalSimpleGenderEnum
}

@Serializable()
export class DogPreferences implements IDogPreferences {

    @JsonProperty() sizeAsAdult: Array<string> = [];         // DogSizeEnum
    @JsonProperty() age: Array<string> = [];                 // AnimalAgeEnum
    @JsonProperty() energyLevel: Array<string> = [];         // AnimalEnergyLevelEnum
    @JsonProperty() goodWith: Array<string> = [];            // AnimalGoodWithEnum
    @JsonProperty() gender: Array<string> = [];              // AnimalSimpleGenderEnum

    static getQueryFields(): string[] {
        return [
            'sizeAsAdult',
            'age',
            'energyLevel',
            'goodWith',
            'gender'
        ]
    }
}