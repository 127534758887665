import { Enum, EnumElement } from "../../Enum";

export class AgencyCategory extends EnumElement { }

export class AgencyCategoryEnum extends Enum<AgencyCategory> {

    static readonly BOARDING_FACILITY = new AgencyCategory('BOARDING_FACILITY', 'Boarding facility');
    static readonly CORPORATION = new AgencyCategory('CORPORATION', 'Corporation');
    static readonly FOUNDATION = new AgencyCategory('FOUNDATION', 'Foundation');
    static readonly PRIVATE_SHELTER = new AgencyCategory('PRIVATE_SHELTER', 'Private shelter');
    static readonly PUBLIC_SHELTER = new AgencyCategory('PUBLIC_SHELTER', 'Public shelter');
    static readonly RESCUE_GROUP = new AgencyCategory('RESCUE_GROUP', 'Rescue group');
    static readonly TRAINING_FACILITY = new AgencyCategory('TRAINING_FACILITY', 'Training facility');
    static readonly GROOMER = new AgencyCategory('GROOMER', 'Groomer');
    static readonly TRANSPORTER = new AgencyCategory('TRANSPORTER', 'Transporter');
    static readonly VETERINARY_GROUP = new AgencyCategory('VETERINARY_GROUP', 'Veterinary group');
    static readonly OTHER = new AgencyCategory('OTHER', 'Other');

    static readonly ELEMENTS = [
        AgencyCategoryEnum.BOARDING_FACILITY,
        AgencyCategoryEnum.CORPORATION,
        AgencyCategoryEnum.FOUNDATION,
        AgencyCategoryEnum.PRIVATE_SHELTER,
        AgencyCategoryEnum.PUBLIC_SHELTER,
        AgencyCategoryEnum.RESCUE_GROUP,
        AgencyCategoryEnum.TRAINING_FACILITY,
        AgencyCategoryEnum.GROOMER,
        AgencyCategoryEnum.TRANSPORTER,
        AgencyCategoryEnum.VETERINARY_GROUP,
        AgencyCategoryEnum.OTHER
    ];

    static readonly INSTANCE = new AgencyCategoryEnum();

    private constructor() {
        super(AgencyCategoryEnum.ELEMENTS);
    }
}
