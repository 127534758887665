import {Enum, EnumElement} from "../../Enum";

export class PersonCurrentStatus extends EnumElement {}

/**
 * Used in the Person dashboard to show the Current status of the person
 */
export class PersonCurrentStatusEnum extends Enum<PersonCurrentStatus> {

    static readonly FOSTER = new PersonCurrentStatus('FOSTER', 'Fostering');
    static readonly HOSPICE = new PersonCurrentStatus('HOSPICE', 'In Hospice');
    static readonly TRIAL_ADOPTION = new PersonCurrentStatus('TRIAL_ADOPTION', 'In trial adoption');

    static readonly ELEMENTS = [
        PersonCurrentStatusEnum.FOSTER,
        PersonCurrentStatusEnum.HOSPICE,
        PersonCurrentStatusEnum.TRIAL_ADOPTION
    ];
    static readonly INSTANCE = new PersonCurrentStatusEnum();

    private constructor() {
        super(PersonCurrentStatusEnum.ELEMENTS);
    }
}
