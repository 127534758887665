import {EnumElement, Enum} from "../../Enum";

export class AnimalGoodWith extends EnumElement {}

export class AnimalGoodWithEnum extends Enum<AnimalGoodWith> {

    static readonly CATS = new AnimalGoodWith('CATS', 'Cats');
    static readonly DOGS = new AnimalGoodWith('DOGS', 'Dogs');
    static readonly YOUNG_CHILDREN = new AnimalGoodWith('YOUNG_CHILDREN', 'Children < 3');
    static readonly CHILDREN = new AnimalGoodWith('CHILDREN', 'Children 3-12');
    static readonly OLDER_CHILDREN = new AnimalGoodWith('OLDER_CHILDREN', 'Children 12+');
    static readonly MEN = new AnimalGoodWith('MEN', 'Men');
    static readonly WOMEN = new AnimalGoodWith('WOMEN', 'Women');

    static readonly ELEMENTS = [
        AnimalGoodWithEnum.CATS,
        AnimalGoodWithEnum.DOGS,
        AnimalGoodWithEnum.YOUNG_CHILDREN,
        AnimalGoodWithEnum.CHILDREN,
        AnimalGoodWithEnum.OLDER_CHILDREN,
        AnimalGoodWithEnum.MEN,
        AnimalGoodWithEnum.WOMEN
    ];
    static readonly INSTANCE = new AnimalGoodWithEnum();

    private constructor() {
        super(AnimalGoodWithEnum.ELEMENTS);
    }
}
