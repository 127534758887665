import { JsonProperty, Serializable } from 'typescript-json-serializer';

export type EntityId = string;

/**
 * Base interface for domain objects used by the FRONT END.
 */
export interface IEntity<T> {
    _id: T;           // NOTE: This is an ObjectId on the backend, so we don't share this interface with the backend

    // TODO: These should be optional?  They are not always fetched!
    createdAt: Date;
    updatedAt: Date;
}

@Serializable()
export class Entity implements IEntity<EntityId> {

    @JsonProperty() _id: EntityId = '';
    @JsonProperty() createdAt: Date = null;
    @JsonProperty() updatedAt: Date = null;

    static getQueryFields(): string[] {
        return [
            '_id',
            'createdAt',
            'updatedAt'
        ]
    }

    static getReadOnlyFields(): string[] {
        // None of these are editable by the UI
        return [
            'createdAt',
            'updatedAt'
        ]
    }
}
