import {EnumElement, Enum} from "../../Enum";

export class TransferRequestStatus extends EnumElement {}

export class TransferRequestStatusEnum extends Enum<TransferRequestStatus> {

    static readonly PENDING = new TransferRequestStatus('PENDING', 'Pending');
    static readonly ACCEPTED = new TransferRequestStatus('ACCEPTED', 'Accepted');
    static readonly REJECTED = new TransferRequestStatus('REJECTED', 'Rejected');

    static readonly ELEMENTS = [
        TransferRequestStatusEnum.PENDING,
        TransferRequestStatusEnum.ACCEPTED,
        TransferRequestStatusEnum.REJECTED
    ];
    static readonly INSTANCE = new TransferRequestStatusEnum();

    private constructor() {
        super(TransferRequestStatusEnum.ELEMENTS);
    }
}
