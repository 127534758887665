import {Enum, EnumElement} from "../../Enum";

export class CurrencyUnits extends EnumElement {
    symbol(): string {
        switch (this) {
            case CurrencyUnitsEnum.EURO:
                return '€';

                case CurrencyUnitsEnum.GBP:
                return '£';

            case CurrencyUnitsEnum.DOLLAR:
            default:
                return '$';
        }
    }
}

export class CurrencyUnitsEnum extends Enum<CurrencyUnits> {

    static readonly DOLLAR = new CurrencyUnits("DOLLAR", "Dollar");
    static readonly EURO = new CurrencyUnits("EURO", "Euro");
    static readonly GBP = new CurrencyUnits("GBP", "GBP");

    static readonly ELEMENTS = [
        CurrencyUnitsEnum.DOLLAR, 
        CurrencyUnitsEnum.EURO, 
        CurrencyUnitsEnum.GBP
    ];
    static readonly INSTANCE = new CurrencyUnitsEnum();

    private constructor() {
        super(CurrencyUnitsEnum.ELEMENTS);
    }
}
