import {EnumElement, Enum} from "../../../Enum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class HeartwormTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        switch (subType) {
            case HeartwormTestOutcomeEnum.HEARTWORM_POSITIVE.view:
                outcomes.push(MedicalConditionEnum.HEARTWORM.createCondition(ConditionStatusEnum.POSITIVE));
                break;

            case HeartwormTestOutcomeEnum.HEARTWORM_NEGATIVE.view:
                outcomes.push(MedicalConditionEnum.HEARTWORM.createCondition(ConditionStatusEnum.NEGATIVE));
                break;

            default:
                outcomes.push(MedicalConditionEnum.HEARTWORM.createCondition(ConditionStatusEnum.UNKNOWN));
                break;
        }
    }
}

export class HeartwormTestOutcomeEnum extends Enum<HeartwormTestOutcome> {

    static readonly HEARTWORM_POSITIVE = new HeartwormTestOutcome('HEARTWORM_POSITIVE', 'Heartworm positive');
    static readonly HEARTWORM_NEGATIVE = new HeartwormTestOutcome('HEARTWORM_NEGATIVE', 'Heartworm negative');

    static readonly ELEMENTS = [
        HeartwormTestOutcomeEnum.HEARTWORM_POSITIVE,
        HeartwormTestOutcomeEnum.HEARTWORM_NEGATIVE
    ];
    static readonly INSTANCE = new HeartwormTestOutcomeEnum();

    private constructor() {
        super(HeartwormTestOutcomeEnum.ELEMENTS);
    }
}
