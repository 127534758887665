import {autoinject, transient, bindable, Container, customElement} from 'aurelia-framework';
import {AppRouter} from "aurelia-router";
import isEmpty from 'lodash/isEmpty';
import {ViewStateManager} from "../ViewStateManager";

@transient()
@autoinject()
@customElement("arp-route")
export class ArpRouteCustomElement {

    @bindable label = "todo";
    @bindable label2 = "";
    @bindable image = null;
    @bindable id = "";
    @bindable module = "";
    routeHref = "";
    private element: Element;

    constructor(element: Element) {
        this.element = element;
    }

    idChanged() {
        this.updateRoute();
    }

    showImage() {
        return this.image;
    }

    attached() {
        this.updateRoute();
    }

    updateRoute() {
        if (isEmpty(this.id) || isEmpty(this.module)) {
            this.routeHref = "";
        } else {
            this.routeHref = this.router.generate(this.module, { id: this.id });
        }
    }

    get router() : AppRouter {
        return Container.instance.get(ViewStateManager).router;
    }
}
