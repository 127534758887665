import $ from 'jquery';
import {bindable, inject, customElement, Container} from 'aurelia-framework';
import {Observer} from '../Observer';

export class SelectMatch {
    displayValue: string;
    modelValue: string;
    selected: boolean
}

@inject(Element, Observer)
@customElement("arp-select")
export class ArpSelect {

    @bindable placeholder;
    @bindable datasource;

    @bindable inputValue;         // The value of the input control
    @bindable enabled = true;

    matches: Array<SelectMatch> = [];
    showCreate = false;
    private element: Element;
    private observer: any;

    constructor(element, observer) {
        this.element = element;
        this.observer = observer;
    }

    bind(bindingContext, overrideContext) {
        this.observer.observe(this.datasource, 'changeMarker', () => {
            this.updateMatches();
        });
    }

    unbind() {
        this.observer.unObserveAll();
    }

    attached() {
        this.updateMatches();
    }

    inputValueChanged(newValue, oldValue) {
        this.updateMatches();
    }

    selectChoice(choice) {
        if (choice !== this.inputValue) {
            this.inputValue = choice;
        }
    }

    updateMatches() {

        this.matches.splice(0, this.matches.length);      // Clear array

        if (this.datasource) {

            for (let match of this.datasource.match(this.inputValue)) {
                this.matches.push({
                    displayValue: match.displayValue,
                    modelValue: match.modelValue,
                    selected: false    // Unused!
                })
            }

            this.showCreate = this.inputValue && !this.datasource.isExactMatch(this.inputValue);
        } else {
            this.showCreate = false;
        }
    }

    checkIndexForMatch(index, match) {
        for (let term of match.matches) {
            for (let fragment of term.indices) {
                if (index >= fragment[0] && index <= fragment[1]) {
                    return true;
                }
            }
        }

        return false;
    }

    inputMousedown(event) {
        // this.showMenu = !this.showMenu;
        //
        return true;
    }

    menuClick(modelValue) {
        this.selectChoice(modelValue);
//        this.showMenu = false;
    }

    messageMousedown(event) {
        if (event.button === 0) {

            // Stop the mousedown on the menu from causing a blur event
            event.preventDefault();
        }
    }

    inputKeydown(event) {
        let continueProcessing = true;

        if (event.code === "Tab") {
            continueProcessing = this.onTabKey();
        } else if (event.code === "ArrowDown") {
            continueProcessing = this.onArrowDown();
        } else if (event.code === "ArrowUp") {
            continueProcessing = this.onArrowUp();
        } else {
            this.showMenu(true);
            //this.logger.debug(`inputKeyDown: value is ${event.code}`);
        }

        return continueProcessing;
    }

    onTabKey() {
        this.showMenu(false);

        return true;
    }

    onArrowDown() {
        // Find the "selected" match and set the "selected style" on it.
        return false;
    }

    onArrowUp() {
        // Find the "selected" match and set the "selected style" on it.
        return false;
    }

    inputFocus(event) {
//        this.showMenu(true);
    }

    inputBlur(event) {
//        this.showMenu(false);
    }

    showMenu(visible) {
        let theDiv = $(this.element).find("div");
        let currentlyVisible = theDiv.hasClass("open");

        if (visible != currentlyVisible) {
            this.toggleMenu();
        }
    }

    toggleMenu() {
        let theTrigger = $(this.element).find("input");
        let foo = $(theTrigger) as any;
        foo.dropdown("toggle");
    }

    create() {
        this.datasource.addChoice(this.inputValue);
        this.selectChoice(this.inputValue);

        // Need to do this explicitly since the value doesn't change on create
        this.updateMatches();
    }
}

