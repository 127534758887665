import {UploadResource, WebApi} from "../arp-framework";
import {Container} from "aurelia-dependency-injection";


export type SignedUploadResponse = {
    signedUploadUrl: string;
    downloadUrl: string;
}

export async function signPublicUpload(entityId: string, filename: string, filetype: string): Promise<SignedUploadResponse> {
    return signUpload("public", entityId, filename, filetype);
}

export async function signPrivateUpload(entityId: string, filename: string, filetype: string) {
    return signUpload("private", entityId, filename, filetype);
}

async function signUpload(bucket: "public" | "private", entityId: string, filename: string, filetype: string): Promise<SignedUploadResponse> {
    let resource = new UploadResource()
        .withAction('sign')
        .withQueryParameter('id', entityId)
        .withQueryParameter('file_name', encodeURIComponent(filename))
        .withQueryParameter('file_type', filetype)
        .withQueryParameter('bucket', bucket);

    const parsedResponse = await Container.instance.get(WebApi).postJSON(resource);

    return {
        signedUploadUrl: parsedResponse.signedUrl,
        downloadUrl: parsedResponse.uploadFilePath
    };
}