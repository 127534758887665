import {IQuery} from "../ArpResource";
import {FilterBase} from "./FilterBase";

export class BooleanFilter extends FilterBase {

    yesSelected: boolean;
    noSelected: boolean;

    constructor(propertyName: string) {
        super(propertyName);
    }

    isActive() : boolean {
        return this.yesSelected || this.noSelected;
    }

    clearFilter() : void {
        this.yesSelected = false;
        this.noSelected = false;
    }

    observe(parent: any) {
        parent.observer.observe(this, 'yesSelected', () => {
            parent.updateActive();
        });
        parent.observer.observe(this, 'noSelected', () => {
            parent.updateActive();
        });
    }

    configureResource(resource: IQuery) {

        if (this.yesSelected && !this.noSelected) {
            resource.withPropertyEqual(this.propertyName, true);
        } else if (!this.yesSelected && this.noSelected) {
            resource.withPropertyEqual(this.propertyName, false);
        }
    }

    getStateKey() : string {
        return `${this.propertyName}`;
    }

    getState() : any {
        return  {
            yesSelected: this.yesSelected,
            noSelected: this.noSelected
        };
    }

    setState(state: any) {
        this.yesSelected = state.yesSelected;
        this.noSelected = state.noSelected;
    }

    save() {
        this.saved = this.getState();
    }

    restore() {
        this.setState(this.saved);
    }
}