import { EntityId } from "@sparkie/shared-model/src";
import { ImageBuffer } from "./ImageBuffer";
import { signPublicUpload } from "../uploadSigning";
import { ArpLogManager } from "arp-framework/log/ArpLogManager";

const logger = ArpLogManager.getLogger("photo-upload");

/**
 * Uploads a public image to a specified entity.
 *
 * @param {ImageBuffer} image - The image buffer to be uploaded.
 * @param {string} fileName - The name of the file to be uploaded.
 * @param {EntityId} entityId - The ID of the entity to which the image is associated.
 * @returns {Promise<string>} - A promise that resolves to the download URL of the uploaded image.
 */
export async function uploadPublicImage(image: ImageBuffer, fileName: string, entityId: EntityId): Promise<string> {
    logger.info(`signing ${fileName}`);
    const response = await signPublicUpload(entityId, fileName, image.mimeType);

    logger.info(`uploading ${fileName}`);
    await uploadImageBuffer(response.signedUploadUrl, image);

    logger.info(`uploaded ${fileName}`);
    return response.downloadUrl;
}

/**
 * Uploads an image to the specified URL.
 *
 * TODO: Convert to use fetch instead of XMLHttpRequest.
 *
 * @param url
 * @param imageBuffer
 * @returns
 */
export async function uploadImageBuffer(url: string, imageBuffer: ImageBuffer): Promise<void> {
    const xhr = new XMLHttpRequest();

    xhr.open("put", url, true);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xhr.setRequestHeader("Content-type", imageBuffer.mimeType);

    const promise = new Promise<void>((resolve, reject) => {
        xhr.onreadystatechange = () => {
            if (xhr.readyState == XMLHttpRequest.DONE && xhr.status == 200) {
                resolve();
            }
        };

        xhr.onerror = () => {
            reject();
        };
    });

    xhr.send(new DataView(imageBuffer.data));

    return promise;
}

