import {PLATFORM} from "aurelia-pal";
import {useView, Container} from 'aurelia-framework';
import {BindingSignaler} from 'aurelia-templating-resources';
import {activationStrategy} from 'aurelia-router';

import {ArpViewState} from '../ArpViewState';
import {Observer} from '../Observer';
import {ArpScroller} from "./arp-scroller";
import {ArpRepository} from "../ArpRepository";
import {IParentView} from "./IParentView";
import {ArpLogger} from "../log/ArpLogger";
import {ArpLogManager} from "../log/ArpLogManager";
import {NavigationLocation} from "../NavigationLocation";
import {ViewStateManager} from "arp-framework";
import {EntityId, IEntity} from "@sparkie/shared-model/src";
import {IViewModel} from "./IViewModel";

@useView(PLATFORM.moduleName("./arp-details-page.html"))
export class ArpDetailsPage<T extends IEntity<EntityId>> implements IParentView {

    title: string;
    repository: ArpRepository<any, any, any>;
    navigationLocation: NavigationLocation;
    viewState: ArpViewState;

    protected readonly vsm: ViewStateManager;
    protected readonly observer: Observer;
    protected readonly bindingSignaler: BindingSignaler;
    protected scrollerModel: ArpScroller;
    protected filterMap = new Map();

    protected logger: ArpLogger;
    protected viewModels: Array<IViewModel>;
    protected entity: T;

    constructor(repository: ArpRepository<any, any, any>) {

        this.repository = repository;
        this.vsm = Container.instance.get(ViewStateManager);
        this.observer = Container.instance.get(Observer);
        this.viewState = new ArpViewState();
        this.scrollerModel = new ArpScroller(this.viewState);
        this.bindingSignaler = Container.instance.get(BindingSignaler) as BindingSignaler;
        this.navigationLocation = new NavigationLocation(repository.resource.model, 'details');

        this.logger = ArpLogManager.getLogger(this.navigationLocation.getLoggerId());

        this.viewModels = [];
    }

    /**
     * Implement this hook if you want to perform custom logic just before your view-model is displayed. You can
     * optionally return a promise to tell the router to wait to bind and attach the view until after you finish your work.
     *
     * @param params
     * @param routeConfig
     * @param navigationInstruction
     */
    activate(params, routeConfig, navigationInstruction) : Promise<any> | void {
        this.logger.nav(`activate ${params.id}`);
        this.navigationLocation.trackAction('show');

        if (params.id) {
            return this.loadInstance(params.id);
        }
    }

    determineActivationStrategy() {
        return activationStrategy.replace; //replace the viewmodel with a new instance
        // or activationStrategy.invokeLifecycle to invoke router lifecycle methods on the existing VM
        // or activationStrategy.noChange to explicitly use the default behavior
    }

    /**
     * Invoked when the databinding engine binds the view.
     * 
     * @param bindingContext
     * @param overrideContext
     */
    bind(bindingContext, overrideContext) {
        this.logger.debug("bind()");
    }

    attached() {
        this.logger.debug("attached()");
        this.vsm.setActiveViewState(this.viewState);
        this.scrollerModel.restoreState();
    }

    detached() {
        this.logger.debug("detached()");
        this.vsm.setActiveViewState(null);
    }

    unbind() {
        this.logger.debug("unbind()");
        this.observer.unObserveAll();
    }

    async loadInstance(entityId) : Promise<T> {

        this.entity = await this.repository.loadEntityGQL(entityId, this.filterMap);
        this.bindingSignaler.signal("entity-changed");

        return this.entity;
    }

    async refreshInstance() : Promise<T> {

        let entity: T = await this.repository.refreshEntityGQL(this.entity, this.filterMap);
        this.bindingSignaler.signal("entity-changed");

        return entity;
    }

    /**
     * Called after an instance editor has created a new entity.
     */
    async postCreate(id: string) : Promise<T> {
        return this.refreshInstance();
    }

    /**
     * Called after an instance editor has made changes to an existing entity.
     */
    async postEdit(id: string) : Promise<T> {
        return this.refreshInstance();
    }

    addViewModel(viewModel: IViewModel) {
        this.viewModels.push(viewModel);
    }

    scrollToAnchor(anchorName) {
        let element_to_scroll_to = document.getElementById(anchorName);
        // Basically `element_to_scroll_to` just have to be a reference
        // to any DOM element present on the page
        // Then:
        if (element_to_scroll_to) {
            element_to_scroll_to.scrollIntoView();
        } else {
            this.logger.warn(`Can't scroll, anchor ${anchorName} is missing`)
        }
    }
}