import {EnumElement, Enum} from "../../Enum";

export class MiscRecordType extends EnumElement {}

export class MiscRecordTypeEnum extends Enum<MiscRecordType> {

    static readonly TRAINING: MiscRecordType = new MiscRecordType('TRAINING', 'Training');
    static readonly GROOMING: MiscRecordType = new MiscRecordType('GROOMING', 'Grooming');

    static readonly ELEMENTS = [
        MiscRecordTypeEnum.TRAINING,
        MiscRecordTypeEnum.GROOMING
    ];
    static readonly INSTANCE = new MiscRecordTypeEnum();

    private constructor() {
        super(MiscRecordTypeEnum.ELEMENTS);
    }
}
