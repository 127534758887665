import type { EntityId } from "../common/IEntity";
import {JsonProperty} from "typescript-json-serializer";

/**
 * Data for Tenants that are approved to transfer Animals to this Tenant
 */
export interface ITransferSource<T> {
    invitationId: string;
    tenantId: T;                    // Source tenant id (updated when invite accepted)
    tenantName: string;             // Source tenant name (updated when invite accepted)
    adminUsername: string;          // Username of Admin in Source tenant (The invitee)
    status: string;                 // Pending | Accepted
    invitedAt: Date;
    invitedBy: string;              // Admin that sent the invite
}

// Sending invite will add this entry locally.  Accepting the invite will update this record
// and create a ITransferDestination.  Deleting will also remove the ITransferDestination
export class TransferSource implements ITransferSource<EntityId> {

    @JsonProperty() invitationId: string = '';
    @JsonProperty() tenantId: EntityId = null;
    @JsonProperty() tenantName: string = '';
    @JsonProperty() adminUsername: string = '';
    @JsonProperty() status: string = '';
    @JsonProperty() invitedAt: Date = null;
    @JsonProperty() invitedBy: string = '';

    static getQueryFields(): string[] {
        return [
            'invitationId',
            'tenantId',
            'tenantName',
            'adminUsername',
            'status',
            'invitedAt',
            'invitedBy'
        ]
    }
}

export function createDefaultTransferSource<T extends ITransferSource<any>>() {
    return new TransferSource() as T;
}
