import assignWith from "lodash/assignWith";
import cloneDeepWith from "lodash/cloneDeepWith";
import {IHasNotes, Note} from "../common/INote";
import {Address, IAddress} from "../common/IAddress";
import {Email, IEmail} from "../common/IEmail";
import {IPhone, Phone} from "../common/IPhone";
import { Entity } from "../common/IEntity";
import type { EntityId, IEntity } from "../common/IEntity";
import {Contact, IContact} from "./IContact";
import {PersonEntity} from "../person/IPerson";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import {AnimalHistoryFull} from "../person/IAnimalHistory";
import {AnimalIntakeFull} from "./IAnimalIntakeFull";

//
// Agency
//

export interface IAgency<T> extends IEntity<T>, IHasNotes {

    name: string;

    address: IAddress;
    email: Array<IEmail>;
    phone: Array<IPhone>;

    website: string;
    category: string;     // AgencyCategoryEnum

    contacts: Array<IContact<T>>;  // These can be either Persons, or just names

    tags: Array<string>;
}

@Serializable()
export class AgencyEntity extends Entity implements IAgency<EntityId> {
    @JsonProperty() name: string = '';

    @JsonProperty() address: Address = new Address();
    @JsonProperty({ type: Email }) email: Array<Email> = [];
    @JsonProperty({ type: Phone }) phone: Array<Phone> = [];

    @JsonProperty() website: string = '';
    @JsonProperty() category: string = '';

    @JsonProperty({ type: Contact }) contacts: Array<Contact> = [];

    @JsonProperty() tags: Array<string> = [];

    @JsonProperty({ type: Note }) notes: Array<Note> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'name',
            ...Address.getQueryFields().map(it => `address.${it}`),
            ...Email.getQueryFields().map(it => `email.${it}`),
            ...Phone.getQueryFields().map(it => `phone.${it}`),
            'website',
            'category',
            ...Contact.getQueryFields().map(it => `contacts.${it}`),
            'tags',
            ...Note.getQueryFields().map(it => `notes.${it}`),
        ]
    }
}

//
// AgencyDetails
//

@Serializable()
export class AgencyDetails extends AgencyEntity {

    // This is a lookup property
    @JsonProperty({ type: PersonEntity })
    contactPersons: Array<PersonEntity> = []

    // This is a lookup property
    @JsonProperty({ type: AnimalIntakeFull })
    intakes: Array<AnimalIntakeFull> = [];

    // This is a lookup property
    @JsonProperty({ type: AnimalHistoryFull })
    animalHistoryFull: Array<AnimalHistoryFull> = [];      // This is not the animalHistory

    static createInstance(data: any): AgencyDetails {
        return assignWith(new AgencyDetails(), cloneDeepWith(data));
    }

    static getQueryFields(): string[] {
        return [
            ...AgencyEntity.getQueryFields(),
            'contactPersons._id',
            ...AnimalIntakeFull.getQueryFields().map(it => `intakes.${it}`),
            ...AnimalHistoryFull.getQueryFields().map(it => `animalHistoryFull.${it}`),
            ...Email.getQueryFields().map(it => `contactPersons.email.${it}`),
            ...Phone.getQueryFields().map(it => `contactPersons.phone.${it}`),
        ]
    }
}

//
// AgencySummary
//

export type IAgencySummaryPartial = Omit<AgencyEntity, 'createdAt' | 'updatedAt' | 'contacts' | 'tags' | 'notes'>

@Serializable()
export class AgencySummary extends Entity implements IAgencySummaryPartial {
    @JsonProperty() name: string = '';

    @JsonProperty() address: Address = new Address();
    @JsonProperty({ type: Email }) email: Array<Email> = [];
    @JsonProperty({ type: Phone }) phone: Array<Phone> = [];

    @JsonProperty() website: string = '';
    @JsonProperty() readonly category: string = '';

    static getQueryFields() {
        return [
            ...Entity.getQueryFields(),
            'name',
            ...Address.getQueryFields().map(it => `address.${it}`),
            ...Email.getQueryFields().map(it => `email.${it}`),
            ...Phone.getQueryFields().map(it => `phone.${it}`),
            'website',
            'category'
        ]
    }
}
