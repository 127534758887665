import $ from 'jquery';

import {bindable, autoinject, customElement} from 'aurelia-framework';

@autoinject()
@bindable("value")
@customElement("arp-email-editor")
export class ArpEmailEditor {

    hasValue;
    emailTypes = ['Home', 'Work', 'Other', ''];
    private element: Element;
    private value: any;

    private showType: string;

    constructor(element: Element) {
        this.element = element;
        this.value = this.newInstance();
    }

    newInstance() {
        let newInstance = {
            address: "",
            type: ""
        };

        return newInstance;
    }

    bind(bindingContext, overrideContext) {
        this.showType = this.showType || $(this.element).attr('showType');

        // NOTE: this.value is a email { number: xxx, type: xxx }
        this.hasValue = this.value.address;
    }

    attached() {
        $(this.element).focusin((event) => {

            this.hasValue = true;
        });

        $(this.element).focusout((event) => {

            this.hasValue = this.value.address;
        });

        //this.numberInput = $(this.element).find("input").get(0);
        //this.typeInput = $(this.element).find('.typeahead').get(0);
    }

    get showTypeSelect(): boolean {
        return this.hasValue && this.showType != "false";
    }
}
