import {EnumElement, Enum} from "../../../Enum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class RingwormTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        switch (subType) {
            case RingwormTestOutcomeEnum.RINGWORM_POSITIVE.view:
                outcomes.push(MedicalConditionEnum.RINGWORM.createCondition(ConditionStatusEnum.POSITIVE));
                break;
            case RingwormTestOutcomeEnum.RINGWORM_NEGATIVE.view:
                outcomes.push(MedicalConditionEnum.RINGWORM.createCondition(ConditionStatusEnum.NEGATIVE));
                break;
            default:
                outcomes.push(MedicalConditionEnum.RINGWORM.createCondition(ConditionStatusEnum.UNKNOWN));
                break;
        }

        return outcomes;
    }
}

export class RingwormTestOutcomeEnum extends Enum<RingwormTestOutcome> {

    static readonly RINGWORM_POSITIVE = new RingwormTestOutcome('RINGWORM_POSITIVE', 'Ringworm positive');
    static readonly RINGWORM_NEGATIVE = new RingwormTestOutcome('RINGWORM_NEGATIVE', 'Ringworm negative');

    static readonly ELEMENTS = [
        RingwormTestOutcomeEnum.RINGWORM_POSITIVE,
        RingwormTestOutcomeEnum.RINGWORM_NEGATIVE
    ];
    static readonly INSTANCE = new RingwormTestOutcomeEnum();

    private constructor() {
        super(RingwormTestOutcomeEnum.ELEMENTS);
    }
}
