import {EnumElement, Enum} from "../../Enum";

export class ApplicationState extends EnumElement {}

export class ApplicationStateEnum extends Enum<ApplicationState> {

    static readonly NEEDS_REVIEW = new ApplicationState('NEEDS_REVIEW', 'Needs Review');
    static readonly IN_REVIEW = new ApplicationState('IN_REVIEW', 'In Review');
    static readonly ON_HOLD = new ApplicationState('ON_HOLD', 'On Hold');
    static readonly APPROVED = new ApplicationState('APPROVED', 'Approved');
    static readonly CANCELLED = new ApplicationState('CANCELLED', 'Cancelled');
    static readonly DENIED = new ApplicationState('DENIED', 'Denied');

    static readonly ELEMENTS = [
        ApplicationStateEnum.NEEDS_REVIEW,
        ApplicationStateEnum.IN_REVIEW,
        ApplicationStateEnum.ON_HOLD,
        ApplicationStateEnum.APPROVED,
        ApplicationStateEnum.CANCELLED,
        ApplicationStateEnum.DENIED
    ];
    static readonly INSTANCE = new ApplicationStateEnum();

    private constructor() {
        super(ApplicationStateEnum.ELEMENTS);
    }
}
