import {EnumElement, Enum} from "../../Enum";


export class AnimalCharacteristics extends EnumElement {}

export class AnimalCharacteristicsEnum extends Enum<AnimalCharacteristics> {

    static readonly HOUSE_TRAINED: AnimalCharacteristics = new AnimalCharacteristics('HOUSE_TRAINED', 'House Trained');
    static readonly DECLAWED: AnimalCharacteristics = new AnimalCharacteristics('DECLAWED', 'Declawed');
    static readonly FERAL: AnimalCharacteristics = new AnimalCharacteristics('FERAL', 'Feral');
    static readonly SHY: AnimalCharacteristics = new AnimalCharacteristics('SHY', 'Shy');
    static readonly COMES_FOR_TREATS: AnimalCharacteristics = new AnimalCharacteristics('COMES_FOR_TREATS', 'Comes for treats');
    static readonly LIKES_BEING_STROKED: AnimalCharacteristics = new AnimalCharacteristics('LIKES_BEING_STROKED', 'Likes being stroked');
    static readonly SOCIAL: AnimalCharacteristics = new AnimalCharacteristics('SOCIAL', 'Social');
    static readonly SPECIAL_NEEDS: AnimalCharacteristics = new AnimalCharacteristics('SPECIAL_NEEDS', 'Special Needs');
    static readonly SEPARATION_ANXIETY: AnimalCharacteristics = new AnimalCharacteristics('SEPARATION_ANXIETY', 'Separation anxiety');

    // Jumping Up (D/C)
    static readonly JUMPING_UP: AnimalCharacteristics = new AnimalCharacteristics('JUMPING_UP', 'Jumping Up');

    // Resource Guarding (D/C)
    static readonly RESOURCE_GUARDING_PEOPLE: AnimalCharacteristics = new AnimalCharacteristics('RESOURCE_GUARDING_PEOPLE', 'Resource Guarding - People');

    // Resource Guarding (D/C)
    static readonly RESOURCE_GUARDING_DOGS: AnimalCharacteristics = new AnimalCharacteristics("RESOURCE_GUARDING_DOGS", "Resource Guarding - Dogs");

    // Dominating (D/C)
    static readonly DOMINATING: AnimalCharacteristics = new AnimalCharacteristics('DOMINATING', 'Dominating');

    // Leash Pulling (D)
    static readonly LEASH_PULLING: AnimalCharacteristics = new AnimalCharacteristics('LEASH_PULLING', 'Leash Pulling');

    // Leach Reactive (D)
    static readonly LEASH_REACTIVE: AnimalCharacteristics = new AnimalCharacteristics('LEASH_REACTIVE', 'Leash Reactive');

    // Marking/Spraying (D/C)
    static readonly MARKING_SPRAYING: AnimalCharacteristics = new AnimalCharacteristics('MARKING_SPRAYING', 'Marking/Spraying');

    // Digging (D)
    static readonly DIGGING: AnimalCharacteristics = new AnimalCharacteristics('DIGGING', 'Digging');

    // Climbing (C)
    static readonly CLIMBING: AnimalCharacteristics = new AnimalCharacteristics('CLIMBING', 'Climbing');

    // Chewing - Destructive (ALL)
    static readonly CHEWING: AnimalCharacteristics = new AnimalCharacteristics('CHEWING', 'Chewing - Destructive');

    // Crate Trained (D)
    static readonly CRATE_TRAINED: AnimalCharacteristics = new AnimalCharacteristics('CRATE_TRAINED', 'Crate Trained');

    // Barking (D)
    static readonly BARKING: AnimalCharacteristics = new AnimalCharacteristics('BARKING', 'Barking');

    // Begging (D/C)
    static readonly BEGGING: AnimalCharacteristics = new AnimalCharacteristics('BEGGING', 'Begging');

    // Aggressive (ALL)
    static readonly AGGRESSIVE_PEOPLE: AnimalCharacteristics = new AnimalCharacteristics('AGGRESSIVE_PEOPLE', 'Aggressive - People');

    // Aggressive (ALL)
    static readonly AGGRESSIVE_DOGS: AnimalCharacteristics = new AnimalCharacteristics('AGGRESSIVE_DOGS', 'Aggressive - Dogs');

    // Aggressive (ALL)
    static readonly AGGRESSIVE_CATS: AnimalCharacteristics = new AnimalCharacteristics('AGGRESSIVE_CATS', 'Aggressive - Cats');

    // Noise Anxiety (ALL)
    static readonly NOISE_ANXIETY: AnimalCharacteristics = new AnimalCharacteristics('NOISE_ANXIETY', 'Noise anxiety');

    // ully Vaccinated (ALL)
    static readonly VACCINATED: AnimalCharacteristics = new AnimalCharacteristics('VACCINATED', 'Fully Vaccinated');

    static readonly ELEMENTS = [
        AnimalCharacteristicsEnum.HOUSE_TRAINED,
        AnimalCharacteristicsEnum.DECLAWED,
        AnimalCharacteristicsEnum.FERAL,
        AnimalCharacteristicsEnum.SHY,
        AnimalCharacteristicsEnum.COMES_FOR_TREATS,
        AnimalCharacteristicsEnum.LIKES_BEING_STROKED,
        AnimalCharacteristicsEnum.SOCIAL,
        AnimalCharacteristicsEnum.SPECIAL_NEEDS,
        AnimalCharacteristicsEnum.SEPARATION_ANXIETY,
        AnimalCharacteristicsEnum.JUMPING_UP,
        AnimalCharacteristicsEnum.RESOURCE_GUARDING_PEOPLE,
        AnimalCharacteristicsEnum.RESOURCE_GUARDING_DOGS,
        AnimalCharacteristicsEnum.DOMINATING,
        AnimalCharacteristicsEnum.LEASH_PULLING,
        AnimalCharacteristicsEnum.LEASH_REACTIVE,
        AnimalCharacteristicsEnum.MARKING_SPRAYING,
        AnimalCharacteristicsEnum.DIGGING,
        AnimalCharacteristicsEnum.CLIMBING,
        AnimalCharacteristicsEnum.CHEWING,
        AnimalCharacteristicsEnum.CRATE_TRAINED,
        AnimalCharacteristicsEnum.BARKING,
        AnimalCharacteristicsEnum.BEGGING,
        AnimalCharacteristicsEnum.AGGRESSIVE_PEOPLE,
        AnimalCharacteristicsEnum.AGGRESSIVE_DOGS,
        AnimalCharacteristicsEnum.AGGRESSIVE_CATS,
        AnimalCharacteristicsEnum.NOISE_ANXIETY,
        AnimalCharacteristicsEnum.VACCINATED
    ];
    static readonly INSTANCE = new AnimalCharacteristicsEnum();

    private constructor() {
        super(AnimalCharacteristicsEnum.ELEMENTS);
    }
}
