import { SpeciesMetadata} from "../SpeciesMetadata";
import {AnimalCharacteristics, AnimalCharacteristicsEnum} from "../AnimalCharacteristicsEnum";
import {MedicalProcedure, MedicalProcedureEnum} from "../medical/MedicalProcedureEnum";

export function guineaPigMetadata(): SpeciesMetadata {
    return new SpeciesMetadata(breeds, colors, characteristics, medications, procedures, vaccines);
}

const breeds: Array<string> = [
    "Abyssinian",
    "Abyssinian Satin",
    "American",
    "American Satin",
    "Coronet",
    "Lethal White",
    "Peruvian",
    "Peruvian Satin",
    "Silkie",
    "Silkie Satin",
    "Teddy",
    "Teddy Satin",
    "Texel",
    "White Crested"
];

const colors: Array<string> = [
    "Albino",
    "Agouti",
    "Black",
    "Blue / Gray",
    "Brown / Chocolate",
    "Cream",
    "Dalmatian",
    "Himalayan",
    "Lilac",
    "Orange / Red",
    "Roan",
    "Sable",
    "Silver Marten",
    "Tan",
    "Tortoiseshell",
    "White",
];

const characteristics: Array<AnimalCharacteristics> = [
    AnimalCharacteristicsEnum.AGGRESSIVE_PEOPLE,
    AnimalCharacteristicsEnum.CHEWING,
    AnimalCharacteristicsEnum.COMES_FOR_TREATS,
    AnimalCharacteristicsEnum.HOUSE_TRAINED,
    AnimalCharacteristicsEnum.LIKES_BEING_STROKED,
    AnimalCharacteristicsEnum.NOISE_ANXIETY,
    AnimalCharacteristicsEnum.SEPARATION_ANXIETY,
    AnimalCharacteristicsEnum.SHY,
    AnimalCharacteristicsEnum.SOCIAL,
    AnimalCharacteristicsEnum.SPECIAL_NEEDS,
    AnimalCharacteristicsEnum.VACCINATED,
];

const procedures: Array<MedicalProcedure> = [
    MedicalProcedureEnum.BLOODWORK,
    MedicalProcedureEnum.DENTAL,
    MedicalProcedureEnum.EAR_EXAM,
    MedicalProcedureEnum.EUTHANASIA,
    MedicalProcedureEnum.MICROCHIP_IMPLANT,
    MedicalProcedureEnum.NEUTER,
    MedicalProcedureEnum.OFFICE_EXAM,
    MedicalProcedureEnum.SPAY,
];

const medications: Map<string, Array<string>> = new Map([
    [
        "Antibiotic", [
            "Chlorsig",
            "Conoptal",
            "Propercillin",
            "Flagella",
            "Enrotril"
        ]
    ],
    [
        "Anti-inflammatory", [
            "Meloxicam"
        ]
    ],
    [
        "Flea/Tick", [
            "Revolution (Kitten)"
        ]
    ]
]);

const vaccines: Array<string> = [
];

