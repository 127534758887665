import {debounce, DebouncedFunc, isEmpty} from 'lodash';

import {Action} from '../actions/Action';
import {ArpViewState} from "../ArpViewState";
import {ArpLogger, ArpLogManager} from "..";
import {IQuery} from "../ArpResource";

export class ArpSearch {

    public searchText: string = "";
    public placeholderText: string = "Search for...";
    public searchAction: Action;

    private readonly autoSearchDelay: number = 600;

    private static ENTER_KEY = 13;
    private logger: ArpLogger;
    private viewState: ArpViewState;
    private modelTermMap: Map<any, any>;
    private debouncedSearch: DebouncedFunc<() => void>;

    constructor(viewState?: ArpViewState) {
        this.logger = ArpLogManager.getLogger("arp-search");
        this.viewState = viewState;
        this.searchAction = new Action().withLabel("Search");
        this.modelTermMap = new Map<any, any>();
        this.debouncedSearch = debounce(() => this.onSearch(), this.autoSearchDelay)
    }

    onKeyUp(event) {

        if (event.which == ArpSearch.ENTER_KEY) {
            this.debouncedSearch.cancel();
            this.onSearch();
        } else {
            this.debouncedSearch();
        }

        return false;
    }

    onKeyDown(event) {
        return event.which != ArpSearch.ENTER_KEY;
    }

    iconClick() {
        this.searchText = "";
        this.onSearch();
    }

    onSearch() {
        this.saveState();
        this.searchAction.perform();
    }

    get searchActive(): boolean {
        return !isEmpty(this.searchText.trim())
    }

    /**
     * Enumerations are stored in the model as "FOO_BAR", so we need to translate "Foo" or "Bar" into "FOO_BAR" for
     * the search to succeed.
     *
     * @param enumeration
     */
    addEnumerationMapping(enumeration) {

        for (let enumElement of enumeration.ELEMENTS) {
            let userTerms = enumElement.view.split(" ");

            for (let userTerm of userTerms) {

                let modelTermSet = this.modelTermMap.get(userTerm);

                if (!modelTermSet) {
                    modelTermSet = new Set();
                    this.modelTermMap.set(userTerm.toLowerCase(), modelTermSet);
                }

                modelTermSet.add(enumElement.model);
            }
        }
    }

    configureResource(resource: IQuery) {
        if (!isEmpty(this.searchText.trim())) {
            let expandedSearchTerms = this.expandSearchTerms();
            resource.withTextQuery(expandedSearchTerms);
        }
    }

    private expandSearchTerms(): string {
        let searchTextTerms = new Set(this.searchText.trim().split(" "));

        for (let textTerm of searchTextTerms) {
            let modelTermSet = this.modelTermMap.get(textTerm.toLowerCase());

            if (modelTermSet) {
                for (let modelTerm of modelTermSet) {
                    searchTextTerms.add(modelTerm);
                }
            }
        }

        return Array.from(searchTextTerms).join(" ");
    }

    saveState() {
        if (this.viewState) {
            this.viewState.save('searchData', {
                searchText: this.searchText
            });
        }
    }

    restoreState() {
        if (this.viewState) {
            let searchData = this.viewState.restore('searchData');

            if (searchData) {
                this.searchText = searchData.searchText;
            }
        }
    }
}