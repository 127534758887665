import {IReportConverter} from "@sparkie/shared-model/src";
import {DecimalUtils} from "@sparkie/shared-model/src";

export class MoneyConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {
        return modelValue > 0 ? DecimalUtils.formatString(modelValue) : "";
    }
}

