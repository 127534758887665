import {Decimal128} from "bson";

import {ValidationRules} from "aurelia-validation";

import {DecimalUtils} from"@sparkie/shared-model/src";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

export class ArpValidationRules {

    /**
     * Initialize the custom rules used in Sparkie.
     *
     * NOTE: define each rule to stand alone
     *
     */
    static init() {

        //
        // Type: Integer
        //

        // ValidationRules.customRule(
        //     'integerValid',
        //     (value, obj) => {
        //         // Conditions that must be valid for the rule to succeeded
        //         return value === null || value === undefined || Number.isInteger(value)
        //     },
        //     `\${$displayName} must be a valid integer.`
        // );

        ValidationRules.customRule(
            'integerPositive',
            (value, obj) => {
                // Conditions that must be valid for the rule to succeeded
                return value === null || value === undefined || (Number.isInteger(value) && value >= 0)
            },
            `\${$displayName} must be a positive integer.`
        );

        //
        // Type: string
        //

        ValidationRules.customRule(
            'isValidUrl',
            (value, obj) => {
                return value === null || value === undefined || value.startsWith("http")
            },
            `\${$displayName} must be a valid url.`
        );

        //
        // Type: Decimal128
        //

        ValidationRules.customRule(
            'numberValid',
            (value, obj) => {
                return value === null || value === undefined || DecimalUtils.isValid(value)
            },
            `\${$displayName} must be a valid number.`
        );

        ValidationRules.customRule(
            'numberPositive',
            (value, obj) => {
                return value === null || value === undefined || !DecimalUtils.isValid(value) || DecimalUtils.isPositive(value)
            },
            `\${$displayName} must be a positive number.`
        );

        ValidationRules.customRule(
            'moneyDecimalPlaces',
            (value: Decimal128, obj) => {
                if (value === null) {
                    return true;
                } else if (value === undefined) {
                    return true;
                } else if (!DecimalUtils.isValid(value)) {
                    return true;
                } else {
                    let decimalPlaces = DecimalUtils.decimalPlaces(value);

                    return decimalPlaces >= 0 && decimalPlaces <= 2;
                }
            },
            `\${$displayName} not a valid monetary value.`
        );

        //
        // Type: Datasource
        //

        ValidationRules.customRule(
            'existingEntity',
            (value, obj, idProperty, datasource) => { return isEmpty(value) || datasource.validateEntity(get(obj, idProperty), value) },
            `\${$displayName} not found.`
        );

        ValidationRules.customRule(
            'validBondedEntity',
            (value, obj, idProperty, existingId) => {
                return isEmpty(value) || ( existingId != obj[idProperty])
            },
            `\${$displayName} can't bond to self.`
        );

        ValidationRules.customRule(
            'existingMatch',
            (value, obj, datasource) => { return isEmpty(value) || datasource.isExactMatch(value) },
            `\${$displayName} not found.`
        );

        //
        // Type: PersonDataSource
        //

        ValidationRules.customRule(
            'personNotDNA',
            (value, obj, idProperty, datasource) => { return isEmpty(value) || datasource.isNotDNA(obj.person) },
            `\${$displayName} has DO NOT ADOPT/FOSTER status.`
        );
    }
}