import {SpeciesMetadata} from "../SpeciesMetadata";
import {AnimalCharacteristics, AnimalCharacteristicsEnum} from "../AnimalCharacteristicsEnum";
import {MedicalProcedure, MedicalProcedureEnum} from "../medical/MedicalProcedureEnum";

export function catMetadata(): SpeciesMetadata {
    return new SpeciesMetadata(breeds, colors, characteristics, medications, procedures, vaccines);
}

const breeds: Array<string> = [
    'Domestic Shorthair',
    'Domestic Longhair',

    'Abyssinian',
    'Bengal',
    'Calico',
    'Maine Coon',
    'Munchkin',
    'Persian',
    'Rag Doll',
    'Russian Blue',
    'Scottish Fold',
    'Siamese',
    'Tabby',
    'Turkish Van',
    'Tuxedo'
];

const colors: Array<string> = [
    "Black",
    "Black & White / Tuxedo",
    "Cream Point",
    "Blue Cream",
    "Blue Point",
    "Brown / Chocolate",
    "Dilute Calico",
    "Buff / Tan / Fawn",
    "Calico",
    "Dilute Tortoiseshell",
    "Chocolate Point",
    "Cream / Ivory",
    "Flame Point",
    "Gray / Blue / Silver",
    "Lilac Point",
    "Orange / Red",
    "Seal Point",
    "Smoke",
    "Tabby (Brown / Chocolate)",
    "Tabby (Buff / Tan / Fawn)",
    "Tabby (Gray / Blue / Silver)",
    "Tabby (Leopard / Spotted)",
    "Tabby (Orange / Red)",
    "Tabby (Tiger Striped)",
    "Torbie",
    "Tortoiseshell",
    "White",
    "Gray & White",
    "Orange & White",
    "Buff & White"
];

const characteristics: Array<AnimalCharacteristics> = [
    AnimalCharacteristicsEnum.AGGRESSIVE_PEOPLE,
    AnimalCharacteristicsEnum.AGGRESSIVE_DOGS,
    AnimalCharacteristicsEnum.AGGRESSIVE_CATS,
    AnimalCharacteristicsEnum.BEGGING,
    AnimalCharacteristicsEnum.CHEWING,
    AnimalCharacteristicsEnum.CLIMBING,
    AnimalCharacteristicsEnum.DECLAWED,
    AnimalCharacteristicsEnum.DOMINATING,
    AnimalCharacteristicsEnum.FERAL,
    AnimalCharacteristicsEnum.HOUSE_TRAINED,
    AnimalCharacteristicsEnum.JUMPING_UP,
    AnimalCharacteristicsEnum.MARKING_SPRAYING,
    AnimalCharacteristicsEnum.NOISE_ANXIETY,
    AnimalCharacteristicsEnum.RESOURCE_GUARDING_PEOPLE,
    AnimalCharacteristicsEnum.RESOURCE_GUARDING_DOGS,
    AnimalCharacteristicsEnum.SEPARATION_ANXIETY,
    AnimalCharacteristicsEnum.SHY,
    AnimalCharacteristicsEnum.SOCIAL,
    AnimalCharacteristicsEnum.SPECIAL_NEEDS,
    AnimalCharacteristicsEnum.VACCINATED,
];

const procedures: Array<MedicalProcedure> = [
    MedicalProcedureEnum.BLOODWORK,
    MedicalProcedureEnum.DENTAL,
    MedicalProcedureEnum.EAR_EXAM,
    MedicalProcedureEnum.EUTHANASIA,
    MedicalProcedureEnum.FECAL_PARASITE_TEST,
    MedicalProcedureEnum.FELV_FIV_COMBO_TEST,       // Cat only
    MedicalProcedureEnum.FELV_TEST,      // Cat only
    MedicalProcedureEnum.FIV_TEST,      // Cat only
    MedicalProcedureEnum.HEARTWORM_TEST,
    MedicalProcedureEnum.RINGWORM_TEST,
    MedicalProcedureEnum.MICROCHIP_IMPLANT,
    MedicalProcedureEnum.NEUTER,
    MedicalProcedureEnum.OFFICE_EXAM,
    MedicalProcedureEnum.SKIN_SCRAPING,
    MedicalProcedureEnum.SPAY,
    MedicalProcedureEnum.FELINE_TRIPLE_TEST
];

const medications: Map<string, Array<string>> = new Map([
    [
        "Dewormer", [
            "Drontal",
            "Excel",
            "Nemex",
            "Sergeant’s",
            "Sure Sho"
        ]
    ],
    [
        "Heartworm Preventative", [
            "Advantage",
            "Heartgard",
            "Interceptor",
            "Revolution"
        ]
    ],
    [
        "Flea/Tick", [
            "Activyl",
            "Advantage",
            "Capstar",
            "Comfortis",
            "Frontline"
        ]
    ],
    [
        "Antibiotic", [
            "Albon Liquid",
            "Clavamox",
            "Convenia"
        ]
    ],
    [
        "Other", [
        ]
    ]
]);

const vaccines: Array<string> = [
    "Calicivirus",
    "Feline Leukemia",
    "FVRCP",
    "Panleukopenia",
    "Rabies",
    "Rabies Booster",
    "Rhinotracheitis"
];

