import {Container} from "aurelia-framework";

import {Action} from "../actions/Action";
import {ArpModal} from "./arp-modal";
import {ArpMessage} from "./arp-message";
import {EntityId, IEntity} from "@sparkie/shared-model/src";
import {SessionManager} from "../../auth/SessionManger";
import {IParentView} from "./IParentView";
import {Observer} from "../Observer";
import {IViewModel} from "./IViewModel";
import {ActionList} from "../index";
import get from "lodash/get";
import { makeId } from "arp-framework-react/TestId";


/**
 * Base for ViewModels that display a list of items that exist within an Entity.
 */
export abstract class ArpListViewer<T extends IEntity<EntityId>> implements IViewModel {

    readonly title: string;
    readonly id: string;
    headerStyle: string = 'h2';
    description: string;

    entity: T;
    items: any[];

    protected readonly parentView: IParentView;
    protected readonly modalService: ArpModal;
    protected readonly sessionManager: SessionManager;
    protected readonly observer: Observer;
    protected readonly listProperty: string;

    protected headerActions: Array<Action> = [];
    protected instanceActions: Array<Action> = [];

    protected constructor(entity: T, parentView: IParentView, listProperty: string, title: string) {
        this.entity = entity;
        this.parentView = parentView;
        this.listProperty = listProperty;
        this.items = get(entity, listProperty) as any[];
        this.title = title;
        this.id = makeId(title);
        this.modalService = Container.instance.get(ArpModal);
        this.sessionManager = Container.instance.get(SessionManager);
        this.observer = Container.instance.get(Observer);

        this.createHeaderActions(this.headerActions);
        this.createInstanceActions(this.instanceActions);
    }

    get isEnabled() : boolean {
        return true;
    }

    get canUpdate() : boolean {
        return this.sessionManager.canUpdateAny(this.parentView.repository.resource);
    }

    get canCreate() : boolean {
        return this.sessionManager.canCreateAny(this.parentView.repository.resource);
    }

    get canDelete() : boolean {
        return this.sessionManager.canDeleteAny(this.parentView.repository.resource);
    }

    /**
     * Implement this hook if you want to perform custom logic just before your view-model is displayed. You can
     * optionally return a promise to tell the router to wait to bind and attach the view until after you finish your work.
     *
     * @param params
     * @param routeConfig
     * @param navigationInstruction
     */
    activate(params, routeConfig, navigationInstruction) : Promise<any> | void {
    }

    abstract createInstanceEditor(action: Action, params?: any) : any;

    /**
     * Actions that are rendered in the Section title
     *
     * @param headerActions
     */
    createHeaderActions(headerActions: Array<Action | ActionList>) {

        // Default is to allow new
        headerActions.push(
            this.createNewAction()
        );
    }

    /**
     * Actions that are rendered for each row in the Table
     *
     * @param instanceActions
     */
    createInstanceActions(instanceActions: Array<Action>) {

        // Default is to allow edit and delete
        instanceActions.push(
            this.createEditAction(),
            this.createDeleteAction()
        );
    }

    createNewAction() : Action {
        return new Action()
            .withGlyph('glyphicon-plus')
            .withPerformCallback((action: Action) => this.showInstanceEditor(action))
            .withVisible(this.canCreate);
    }

    createEditAction() : Action {
        return new Action()
            .withLabel('Edit')
            .withPerformCallback((action: Action) => this.showInstanceEditor(action))
            .withVisible(this.canUpdate);
    }

    createDeleteAction() : Action {
        return new Action()
            .withLabel('Delete')
            .withPerformCallback((action: Action) => this.performDelete(action))
            .withVisible(this.canDelete);
    }

    performDelete(action: Action) : void {
        return this.showDeleteConfirmation(action);
    }

    showDeleteConfirmation(action: Action) {
        let description = "Are you sure you want to delete?";
        let confirmationView = new ArpMessage(description);

        let deleteAction = new Action()
            .withLabel("Delete")
            .withPerformCallback((deleteAction: Action) => {
                this.performDeleteInstance(action)
            });

        this.modalService.show("Confirm", confirmationView, [deleteAction, this.modalService.cancelAction]);
    }

    async performDeleteInstance(action: Action) : Promise<void> {
        this.items.splice(action.target, 1);

        await this.parentView.repository.saveEntity(this.entity);
        this.modalService.hide();
        await this.parentView.postEdit(this.entity._id);
    }

    showInstanceEditor(action: Action, params?: any) {
        let editor = this.createInstanceEditor(action, params);
        editor.show();
        return true;
    }
}
