// TODO: Don't do this on every field.  We now know the types of the fields!!
import "json.date-extensions";

import { Container } from "aurelia-dependency-injection";
import { HttpClient, HttpResponseMessage } from "aurelia-http-client";

import { SessionState } from "../auth/SessionState";
import { IResource } from "./IResource";
import { AuthenticationError } from "../auth/AuthenticationError";
import { AuthorizationError } from "../auth/AuthorizationError";
import { Decimal128 } from "bson";
import { ArpLogger } from "./log/ArpLogger";
import { ArpLogManager } from "./log/ArpLogManager";
import { GQLQuery } from "./QueryBuilder";
import isString from "lodash/isString";

export type GQLResponse = {
    data: any;
    errors: any[];
};

/**
 * Resource based API for REST requests that performs logging and JSON parsing of responses.
 *
 * TODO: Use interceptors to transform JSON Dates to JS Dates
 */
export class WebApi {
    private readonly http: HttpClient = new HttpClient();
    private readonly logger: ArpLogger = ArpLogManager.getLogger("web-api");
    private _sessionState: SessionState = null;

    constructor() {
        this.http.rejectPromiseWithErrorObject = true;
        this.http.configure((x) => {
            x.withHeader("Content-Type", "application/json");

            // This header is used to identify the client type to the server so we can log better
            x.withHeader("sparkie-client", "web-app");

            x.withInterceptor({
                response: (message: HttpResponseMessage) => {
                    const serverVersion = message.headers.get("sparkie-release-version");

                    if (serverVersion !== SPARKIE_VERSION) {
                        this.logger.info(`Set Version Mismatch: server: ${serverVersion} != client: ${SPARKIE_VERSION}`);
                        window.localStorage.setItem("version-update-needed", "true");
                    }

                    return message;
                },
            });
        });
    }

    get sessionState(): SessionState {
        if (this._sessionState == null) {
            this._sessionState = Container.instance.get(SessionState);
        }

        return this._sessionState;
    }

    get(resource: IResource | string) {
        let resourcePath = isString(resource) ? resource : resource.resourcePath;

        this.logger.info("TX.GET: " + decodeURIComponent(resourcePath));

        return this.createRequest(resourcePath)
            .asGet()
            .send()
            .then((httpResponseMessage) => {
                this.logger.info("RX.GET: " + decodeURIComponent(resourcePath));

                return Promise.resolve(httpResponseMessage);
            })
            .catch((error: any) => {
                this.processError(resourcePath, "GET", error);
            });
    }

    getJSON(resource: IResource | string): Promise<any> {
        let resourcePath = isString(resource) ? resource : resource.resourcePath;

        this.logger.info("TX.GET: " + decodeURIComponent(resourcePath));

        return this.createRequest(resourcePath)
            .asGet()
            .send()
            .then((httpResponseMessage) => {
                this.logger.info("RX.GET: " + decodeURIComponent(resourcePath));
                return Promise.resolve(this.parseComplexTypes(httpResponseMessage) as any);
            })
            .catch((error: any) => {
                this.processError(resourcePath, "GET", error);
            });
    }

    post(resource: IResource, body: any) {
        this.logger.info("TX.POST: " + decodeURIComponent(resource.resourcePath) + " " + this.stringifyBody(body));

        return this.createRequest(resource.resourcePath).asPost().withContent(body).send();
    }

    postJSON(resource: IResource | string, body?: any, errorHandler?: any): Promise<any> {
        let resourcePath = isString(resource) ? resource : resource.resourcePath;
        this.logger.info("TX.POST: " + decodeURIComponent(resourcePath) + " " + this.stringifyBody(body));

        return this.createRequest(resourcePath)
            .asPost()
            .withContent(body)
            .send()
            .then((httpResponseMessage) => {
                this.logger.info("RX.POST: " + decodeURIComponent(resourcePath));
                return this.parseComplexTypes(httpResponseMessage);
            })
            .catch((error) => {
                if (errorHandler) {
                    return errorHandler(error);
                } else {
                    return this.processError(resourcePath, "POST", error);
                }
            });
    }

    async executeGQLQuery(query: GQLQuery): Promise<any> {
        const response = await this.executeGQL(query.query, query.variables);
        return response.data[query.operation];
    }

    async executeGQL(query: any, vars?: any): Promise<GQLResponse> {
        let resourcePath: string = "graphql";
        const body = {
            query,
            variables: vars ? vars : undefined,
        };
        this.logger.info("TX.GQL", body);

        let request = this.createRequest(resourcePath).asPost().withContent(JSON.stringify(body));

        let parsedResponse = null;

        try {
            let response: HttpResponseMessage = await request.send();
            parsedResponse = this.parseComplexTypes(response);
            this.logger.info("RX.GQL", parsedResponse);

            if (parsedResponse.errors && parsedResponse.errors.length > 0) {
                // noinspection ExceptionCaughtLocallyJS
                throw new GraphQLError(parsedResponse.errors);
            }
        } catch (error) {
            // This throws!
            this.processError(resourcePath, "GQL", error);
        }

        return parsedResponse;
    }

    put(resource: IResource, body: any) {
        this.logger.info("TX.PUT: " + decodeURIComponent(resource.resourcePath) + " " + this.stringifyBody(body));

        return this.createRequest(resource.resourcePath).asPut().withContent(body).send();
    }

    putJSON(resource: IResource, body: any): Promise<any> {
        this.logger.info("TX.PUT: " + decodeURIComponent(resource.resourcePath) + " " + this.stringifyBody(body));

        return this.createRequest(resource.resourcePath)
            .asPut()
            .withContent(body)
            .send()
            .then((httpResponseMessage) => {
                this.logger.info("RX.PUT: " + decodeURIComponent(resource.resourcePath));
                return httpResponseMessage.statusCode === 200
                    ? this.parseComplexTypes(httpResponseMessage)
                    : {};
            })
            .catch((error) => {
                return this.processError(resource.resourcePath, "PUT", error);
            });
    }

    delete(resource: IResource) {
        this.logger.info("TX.DELETE: " + decodeURIComponent(resource.resourcePath));

        return this.createRequest(resource.resourcePath).asDelete().send();
    }

    createRequest(resourcePath: string) {
        let request = this.http.createRequest(resourcePath);

        request.withReplacer(this.jsonReplacer);
        request.withHeader("CSRF-TOKEN", localStorage.getItem("csrf"));

        return request;
    }

    /**
     * Process errors that occur during REST API calls.
     *
     * These can occur during navigation, or loading or saving a modal.  In general we want:
     *
     *  Server errors to show the error page with details
     *  Authorization errors to show the error page with details
     *  Authentication errors to force a reload into the Login page
     *  400s () to show the error page with details
     *
     * @param resourcePath
     * @param method
     * @param error
     */
    processError(resourcePath: string, method: string, error: any) {
        if (error instanceof HttpResponseMessage) {
            const webApiError = new WebApiError(resourcePath, method, error);

            error = this.processHttpError(webApiError);
        }

        if (error) {
            throw error;
        }
    }

    processHttpError(webApiError: WebApiError) {
        switch (webApiError.error.statusCode) {
            case 401: // Unauthorized
                return new AuthenticationError();

            case 403: // Forbidden
                if (webApiError.error.response === "CSRF Mismatch") {
                    // TODO: Don't do this here!
                    // Force a reload of the application
                    window.location.reload();
                }
                return new AuthorizationError(
                    this.sessionState.activeUser,
                    webApiError.resource,
                    webApiError.error.requestMessage.method
                );

            default:
                return webApiError;
        }
    }

    stringifyBody(body: any) {
        if (body) {
            return JSON.stringify(body);
        } else {
            return "";
        }
    }

    /**
     * Convert incoming data types from json
     *
     * @param {HttpResponseMessage} response
     * @returns {any} object with complex types converted
     */
    parseComplexTypes(response: HttpResponseMessage): any {
        try {
            const body = response.response;

            // NOTE: parseWithDate is added by the json.date-extensions module
            return (<any>JSON).parseWithDate(body, (key, value) => {
                // Convert incoming JSON to Decimal128
                if (value && value.$numberDecimal) {
                    // This hits with REST GET responses.
                    return Decimal128.fromString(value.$numberDecimal);
                } else if (value && value === "EMPTY_STRING") {
                    // This handles the case where graphql can't represent '' as a value for an Enum type
                    return "";
                } else {
                    return value;
                }
            });
        } catch (e) {
            throw new ReponseParseError(response, e);
        }
    }

    /**
     * Convert outgoing data types (Decimal128) to their json representation.
     *
     * @param {string} key
     * @param value
     * @returns {any}
     */
    jsonReplacer(key: string, value: any) {
        // Convert the Decimal128 to it's JSON representation
        if (value && value.$numberDecimal) {
            return value.$numberDecimal;
        } else {
            return value;
        }
    }
}

export class GraphQLError extends Error {
    errors: any;

    constructor(errors: any) {
        let message = JSON.stringify(errors);

        super(message);

        this.name = this.constructor.name;
        this.errors = errors;
    }

    asLogMessage() {
        return `GraphQLError: ${this.message}`;
    }
}

export class WebApiError extends Error {
    resource: string;
    method: string;
    error: HttpResponseMessage;

    constructor(resource: string, type: string, error: HttpResponseMessage) {
        let message = error.response;

        super(message);

        this.name = this.constructor.name;
        this.resource = resource;
        this.method = type;
        this.error = error;
    }

    asLogMessage() {
        return `WebApiError: ${this.resource} ${this.method} ${this.message}`;
    }
}

export class ReponseParseError extends Error {
    body: any;
    url: string;

    constructor(response: HttpResponseMessage, err: Error) {
        super(err.message);

        this.name = this.constructor.name;
        this.body = response.response;
        this.url = response.requestMessage.url;
    }
}