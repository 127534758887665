import type { EntityId } from "../common/IEntity";
import {Decimal128} from "bson";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IMedicalRecord<T> {
    category: string;    //  Medication | Vaccination | Procedure | Other Medical
    type: string;
    subType: string;					    // Brand or Outcome or Microchip Company
    details: string;				        // Medication:Dosage or Procedure:Rabies.Rabies Tag or Other.Details

    // All
    cost: Decimal128;

    dateGiven: Date;
    note: string;

    person?: T;
    personFullName?: string;
    personLastName?: string;

    agency?: T;
    agencyName?: string;
}

@Serializable()
export class MedicalRecord implements IMedicalRecord<EntityId> {
    @JsonProperty() category: string = '';    //  Medication | Vaccination | Procedure | Other Medical
    @JsonProperty() type: string = '';
    @JsonProperty() subType: string = '';					    // Brand or Outcome or Microchip Company
    @JsonProperty() details: string = '';				        // Medication:Dosage or Procedure:Rabies.Rabies Tag or Other.Details

    // All
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    cost: Decimal128 = null;

    @JsonProperty() dateGiven: Date = null;
    @JsonProperty() note: string = '';

    // TODO: optional?
    @JsonProperty() person?: EntityId = null;
    @JsonProperty() personFullName?: string = '';
    @JsonProperty() personLastName?: string = '';

    // TODO: optional?
    @JsonProperty() agency?: EntityId = null;
    @JsonProperty() agencyName?: string = '';

    static getQueryFields(): string[] {
        return [
            'category',
            'type',
            'subType',
            'details',
            'cost',
            'dateGiven',
            'note',
            'person',
            'personFullName',         // Read-only
            'personLastName',      // Read-only
            'agency',
            'agencyName'      // Read-only
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            'personFullName',         // Read-only
            'personLastName',      // Read-only
            'agencyName'      // Read-only
        ]
    }
}

export function createDefaultMedicalRecord<T extends IMedicalRecord<any>>(): T {
    return new MedicalRecord() as any as T;
}