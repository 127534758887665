
export interface ITenantStats {
    totalAdoptions: number;
    availableAnimals: number;
    adoptionsThisMonth: number;
    intakesThisMonth: number;
    overdueCare: number;

    availableAdopters: number;
    availableFosters: number;

    tasksDueThisWeek: number;

    needsReviewApplications: number;
    inReviewApplications: number;
}

export class TenantStats implements ITenantStats {

    // TODO: These should be -1, but when the GQL mongodb aggregation hits a value that should be zero,
    //  it is skipped and the -1s will remain
    totalAdoptions: number = 0;
    availableAnimals: number = 0;
    adoptionsThisMonth: number = 0;
    intakesThisMonth: number = 0;
    overdueCare: number = 0;

    availableAdopters: number = 0;
    availableFosters: number = 0;

    tasksDueThisWeek: number = 0;

    needsReviewApplications: number = 0;
    inReviewApplications: number = 0;

    static getQueryFields(): string[] {
        return [
            'totalAdoptions',
            'availableAnimals',
            'adoptionsThisMonth',
            'intakesThisMonth',
            'overdueCare',

            'availableAdopters',
            'availableFosters',

            'tasksDueThisWeek',

            'needsReviewApplications',
            'inReviewApplications',
        ]
    }
}