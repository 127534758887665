import {JsonProperty, Serializable} from "typescript-json-serializer";
import {Address, IAddress} from "../common/IAddress";
import {Email, IEmail, IHasEmails} from "../common/IEmail";
import {IHasPhones, IPhone, Phone} from "../common/IPhone";
import {IHasNotes, Note} from "../common/INote";
import {FileUpload, IHasFiles} from "../common/IFileUpload";
import {PhotoUpload, IHasPhotos} from "../common/IPhotoUpload";
import {IEntity, Entity, EntityId} from "../common/IEntity";
import {IPersonName, PersonName} from "./IPersonName";
import {CatPreferences, ICatPreferences} from "./ICatPreferences";
import {PersonApplicationSummary, IPersonApplicationSummary} from "./IPersonApplicationSummary";
import {ITrainerDetails, TrainerDetails} from "./ITrainerDetails";
import {GroomerDetails, IGroomerDetails} from "./IGroomerDetails";
import {ITransporterDetails, TransporterDetails} from "./ITransporterDetails";
import {IVolunteerDetails, VolunteerDetails} from "./IVolunteerDetails";
import {FosterDetails, IFosterDetails} from "./IFosterDetails";
import {IRabbitPreferences, RabbitPreferences} from "./IRabbitPreferences";
import {DogPreferences, IDogPreferences} from "./IDogPreferences";
import {AnimalHistory, IAnimalHistory, AnimalHistoryFull} from "./IAnimalHistory";
import {PersonDonationSummaryEntity} from "../donation/IDonation";
import {TaskDetails} from "../task/ITask";
import {PersonAnimalIntake} from "./IPersonAnimalIntake";
import {GuineaPigPreferences, IGuineaPigPreferences} from "./IGuineaPigPreferences";

//
// Person
//

export interface IPerson<T> extends IEntity<T>, IHasEmails, IHasPhones, IHasNotes, IHasPhotos, IHasFiles {
    name: IPersonName;
    address: IAddress;
    email: Array<IEmail>;
    phone: Array<IPhone>;
    tags: Array<string>;

    notificationsEnabled: boolean;

    // These are updated whenever an application is created or it's state changes!
    applications: Array<IPersonApplicationSummary<T>>;

    // These are updated whenever an animal status is updated.  Supports 'currently' in list views, as well as filtering
    // on HasAdopted or HasFostered.
    animalHistory: Array<IAnimalHistory<T>>;

    employer: string;
    dateOfBirth: Date;

    canAdoptOrFoster: boolean;
    availability: Array<string>;            // PersonAvailabilityEnum

    animalPreference: Array<string>;        // SpeciesPreferenceEnum

    dogPreferences: IDogPreferences;
    catPreferences: ICatPreferences;
    rabbitPreferences: IRabbitPreferences;
    guineaPigPreferences: IGuineaPigPreferences;
    fosterDetails: IFosterDetails;
    volunteerDetails: IVolunteerDetails;
    transporterDetails: ITransporterDetails;
    trainerDetails: ITrainerDetails;
    groomerDetails: IGroomerDetails;
}

@Serializable()
export class PersonEntity extends Entity implements IPerson<EntityId> {
    @JsonProperty() name: PersonName = new PersonName();
    @JsonProperty() address: Address = new Address();
    @JsonProperty({ type: Email }) email: Array<Email> = [];
    @JsonProperty({ type: Phone }) phone: Array<Phone> = [];
    @JsonProperty({ type: Note }) notes: Array<Note> = [];
    @JsonProperty() tags: Array<string> = [];

    @JsonProperty() notificationsEnabled: boolean = false;

    // These are updated whenever an application is created or it's state changes!
    @JsonProperty({ type: PersonApplicationSummary }) applications: Array<PersonApplicationSummary>;

    // These are updated whenever an animal status is updated
    @JsonProperty({ type: AnimalHistory }) animalHistory: Array<AnimalHistory> = [];

    @JsonProperty() employer: string = '';
    @JsonProperty() dateOfBirth: Date = null;

    @JsonProperty() canAdoptOrFoster: boolean = null;       // TODO: Null or false?
    @JsonProperty() availability: Array<string> = [];            // PersonAvailabilityEnum

    @JsonProperty() animalPreference: Array<string> = [];        // SpeciesPreferenceEnum

    @JsonProperty() dogPreferences: DogPreferences = new DogPreferences();
    @JsonProperty() catPreferences: CatPreferences = new CatPreferences();
    @JsonProperty() rabbitPreferences: RabbitPreferences = new RabbitPreferences();
    @JsonProperty() guineaPigPreferences: GuineaPigPreferences = new GuineaPigPreferences();
    @JsonProperty() fosterDetails: FosterDetails = new FosterDetails();
    @JsonProperty() volunteerDetails: VolunteerDetails = new VolunteerDetails();
    @JsonProperty() transporterDetails: TransporterDetails = new TransporterDetails();
    @JsonProperty() trainerDetails: TrainerDetails = new TrainerDetails();
    @JsonProperty() groomerDetails: GroomerDetails = new GroomerDetails();

    // For now we only expect there to be a single photo
    @JsonProperty({ type: PhotoUpload }) photos: Array<PhotoUpload> = [];

    @JsonProperty({ type: FileUpload }) files: Array<FileUpload> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            ...PersonName.getQueryFields().map(it => `name.${it}`),
            ...Address.getQueryFields().map(it => `address.${it}`),
            ...Email.getQueryFields().map(it => `email.${it}`),
            ...Phone.getQueryFields().map(it => `phone.${it}`),
            ...Note.getQueryFields().map(it => `notes.${it}`),
            'tags',
            'notificationsEnabled',
            ...PersonApplicationSummary.getQueryFields().map(it => `applications.${it}`),
            ...AnimalHistory.getQueryFields().map(it => `animalHistory.${it}`),
            'employer',
            'dateOfBirth',
            'canAdoptOrFoster',
            'availability',
            'animalPreference',
            ...DogPreferences.getQueryFields().map(it => `dogPreferences.${it}`),
            ...CatPreferences.getQueryFields().map(it => `catPreferences.${it}`),
            ...RabbitPreferences.getQueryFields().map(it => `rabbitPreferences.${it}`),
            ...GuineaPigPreferences.getQueryFields().map(it => `guineaPigPreferences.${it}`),
            ...FosterDetails.getQueryFields().map(it => `fosterDetails.${it}`),
            ...VolunteerDetails.getQueryFields().map(it => `volunteerDetails.${it}`),
            ...TransporterDetails.getQueryFields().map(it => `transporterDetails.${it}`),
            ...TrainerDetails.getQueryFields().map(it => `trainerDetails.${it}`),
            ...GroomerDetails.getQueryFields().map(it => `groomerDetails.${it}`),

            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`),
            ...FileUpload.getQueryFields().map(it => `files.${it}`)
        ]
    }
}

//
// PersonDetails
//

@Serializable()
export class PersonDetails extends PersonEntity {

    // This is a lookup property
    @JsonProperty({ type: PersonDonationSummaryEntity })
    donations: Array<PersonDonationSummaryEntity> = [];

    // This is a lookup property
    @JsonProperty({ type: AnimalHistoryFull })
    animalHistoryFull: Array<AnimalHistoryFull> = [];      // This is not the animalHistory

    // This is fetched by the front end for now
    @JsonProperty({ type: TaskDetails })
    tasks: Array<TaskDetails> = [];

    // This is fetched by the front end for now
    @JsonProperty({ type: TaskDetails })
    referencedTasks: Array<TaskDetails> = [];

    @JsonProperty({ type: PersonAnimalIntake })
    intakes: Array<PersonAnimalIntake> = [];

    static getQueryFields(): string[] {
        return [
            ...PersonEntity.getQueryFields(),
            ...PersonDonationSummaryEntity.getQueryFields().map(it => `donations.${it}`),
            ...AnimalHistoryFull.getQueryFields().map(it => `animalHistoryFull.${it}`),
            ...PersonAnimalIntake.getQueryFields().map(it => `intakes.${it}`)
        ]
    }

    static getDownloadQueryFields(): string[] {
        // For now this is the same as summary
        return PersonSummary.getQueryFields();
    }
}

//
// PersonSummary
//

export type IPersonSummaryPartial = Omit<PersonEntity,
    'createdAt' |
    'updatedAt' |
    'notificationsEnable' |
    'applications' |
    'animalHistory' |
    'employer' |
    'dateOfBirth' |
    'animalPreference' |
    `dogPreferences` |
    `catPreferences` |
    `rabbitPreferences` |
    `guineaPigPreferences` |
    `fosterDetails` |
    `volunteerDetails` |
    `transporterDetails` |
    `trainerDetails` |
    `groomerDetails` |
    `files`
    >

// "photos,name,address,email,phone,availability,currentAnimals,tags"

@Serializable()
export class PersonSummary extends Entity implements IPersonSummaryPartial {

    @JsonProperty() readonly name: PersonName = new PersonName();
    @JsonProperty() readonly address: Address = new Address();
    @JsonProperty({ type: Email }) readonly email: Array<Email> = [];
    @JsonProperty({ type: Phone }) readonly phone: Array<Phone> = [];
    @JsonProperty({ type: Note }) readonly notes: Array<Note> = [];
    @JsonProperty() canAdoptOrFoster: boolean = null;       // TODO: Null or false?
    @JsonProperty() readonly tags: Array<string> = [];
    @JsonProperty() readonly availability: Array<string> = [];
    @JsonProperty() readonly notificationsEnabled: boolean = false;
    @JsonProperty({ type: PhotoUpload }) readonly photos: Array<PhotoUpload> = [];

    // NOTE: currentAnimals is not in the database, but is a projection of the animalHistory that is current.
    @JsonProperty({ type: AnimalHistory }) readonly currentAnimals: Array<AnimalHistory> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            ...PersonName.getQueryFields().map(it => `name.${it}`),
            ...Address.getQueryFields().map(it => `address.${it}`),
            ...Email.getQueryFields().map(it => `email.${it}`),
            ...Phone.getQueryFields().map(it => `phone.${it}`),
            ...Note.getQueryFields().map(it => `notes.${it}`),
            'tags',
            "canAdoptOrFoster",
            ...AnimalHistory.getQueryFields().map(it => `currentAnimals.${it}`),
            'availability',
            'notificationsEnabled',
            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`)
        ]
    }
}

//
// PersonSummary
//

export type IPersonDownloadPartial = Omit<PersonEntity,
    'createdAt' |
    'updatedAt' |
    'notificationsEnable' |
    'applications' |
    'animalHistory' |
    'employer' |
    'dateOfBirth' |
    'canAdoptOrFoster' |
    'animalPreference' |
    `dogPreferences` |
    `catPreferences` |
    `rabbitPreferences` |
    `guineaPigPreferences` |
    `fosterDetails` |
    `volunteerDetails` |
    `transporterDetails` |
    `trainerDetails` |
    `groomerDetails` |
    'photos' |
    'files' |
    'tags' |
    'notificationsEnabled' |
    'notes'
    >

/*
        "name.last", "name.first",
        "address.line1", "address.line2""address.city", "address.stateProvinceCounty"), "address.zipOrPostCode"
        "email[0].address"
        "phone[0].number"
        "availability"
        "currentAnimals"
 */

@Serializable()
export class PersonDownload extends Entity implements IPersonDownloadPartial {

    @JsonProperty() readonly name: PersonName = new PersonName();
    @JsonProperty() readonly address: Address = new Address();
    @JsonProperty({ type: Email }) readonly email: Array<Email> = [];
    @JsonProperty({ type: Phone }) readonly phone: Array<Phone> = [];
    @JsonProperty({ type: Note }) readonly notes: Array<Note> = [];
    @JsonProperty() readonly availability: Array<string> = [];

    // NOTE: currentAnimals is not in the database, but is a projection of the animalHistory that is current.
    @JsonProperty({ type: AnimalHistory }) readonly currentAnimals: Array<AnimalHistory> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            ...PersonName.getQueryFields().map(it => `name.${it}`),
            ...Address.getQueryFields().map(it => `address.${it}`),
            ...Email.getQueryFields().map(it => `email.${it}`),
            ...Phone.getQueryFields().map(it => `phone.${it}`),
            ...AnimalHistory.getQueryFields().map(it => `currentAnimals.${it}`),
            'availability'
        ]
    }
}
