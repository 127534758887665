import {Enum, EnumElement} from "../../Enum";

export class FosterInterests extends EnumElement {}

export class FosterInterestsEnum extends Enum<FosterInterests> {

    static readonly BEHARIORAL_NEEDS = new FosterInterests('BEHARIORAL_NEEDS', 'Behavioral Needs');
    static readonly MEDICAL_NEEDS = new FosterInterests('MEDICAL_NEEDS', 'Medical Needs');
    static readonly SEPARATION_ANXIETY = new FosterInterests('SEPARATION_ANXIETY', 'Separation Anxiety');
    static readonly SHY = new FosterInterests('SHY', 'Shy');
    static readonly OTHER_SPECIAL_NEEDS = new FosterInterests('OTHER_SPECIAL_NEEDS', 'Other Special Needs');

    static readonly ELEMENTS = [
        FosterInterestsEnum.BEHARIORAL_NEEDS,
        FosterInterestsEnum.MEDICAL_NEEDS,
        FosterInterestsEnum.SEPARATION_ANXIETY,
        FosterInterestsEnum.SHY,
        FosterInterestsEnum.OTHER_SPECIAL_NEEDS
    ];
    static readonly INSTANCE = new FosterInterestsEnum();

    private constructor() {
        super(FosterInterestsEnum.ELEMENTS);
    }
}
