import type { EntityId } from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ITaskAnimal<T> {
    animal: T;

    // Include the animal data locally to support full text query
    animalName: string;
    species: string;    // Species is used when querying medical records for custom procedures. vaccines, etc.
    animalId: string;                 // 'RNNNN'
}

@Serializable()
export class TaskAnimal implements ITaskAnimal<EntityId> {
    @JsonProperty() animal: EntityId = null;

    // Include the animal data locally to support full text query
    @JsonProperty() animalName: string = '';
    @JsonProperty() species: string = '';    // Species is used when querying medical records for custom procedures. vaccines, etc.
    @JsonProperty() animalId: string = '';                 // 'RNNNN'

    static getQueryFields(): string[] {
        return [
            'animal',
            'animalName',      // Read-only
            'species',      // Read-only
            'animalId'      // Read-only
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            'animalName',      // Read-only
            'species',      // Read-only
            'animalId'      // Read-only
        ]
    }
}