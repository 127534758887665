import {ActionBase} from "./ActionBase";
import {ArpLogger} from "../log/ArpLogger";
import {ArpLogManager} from "../log/ArpLogManager";
import {NavigationLocation} from "../NavigationLocation";

/**
 * Exposes all the properties you need to display menus, toolbars, links, etc.
 */
export class Action extends ActionBase {

    label: string;
    id: string;
    glyph: string = null;       // glyph from https://getbootstrap.com/docs/3.3/components/
    icon: string = null;        // Icon from font-awesome TODO: Not used!
    target: any = null;
    performing: boolean = false;
    href: string;

    private performCallback: (action: Action) => void = null;
    private updateCallback: (action: Action) => void = null;
    private logger: ArpLogger;
    private navigationLocation: NavigationLocation;

    constructor() {
        super("action");
        this.logger = ArpLogManager.getLogger(`arp-action`);
        this.id = '';
    }

    withLabel(label: string) : Action {
        this.label = label;

        if (this.id === '') {
            this.id = this.label.split(' ').join('-').toLowerCase();
        }

        return this;
    }

    withId(id: string) : Action {
        this.id = id;
        return this;
    }

    withGlyph(glyph: string) : Action {
        this.glyph = glyph;
        return this;
    }

    withIcon(icon: string) : Action {
        this.icon = icon;
        return this;
    }

    withEnabled(enabled: boolean) : Action {
        this.enabled = enabled;
        return this;
    }

    withVisible(visible: boolean) : Action {
        this.visible = visible;
        return this;
    }

    withLocation(navigationLocation: NavigationLocation) : Action {
        this.navigationLocation = navigationLocation;
        return this;
    }

    /**
     * The target of an action is typically set (via template binding) to the currently selected item in the UI.  This
     * will trigger a updateCallback to be invoked, which provides the hook for updating enable/visible state.
     *
     * @param target
     */
    withTarget(target: any) : Action {
        this.target = target;

        this.update();
        return this;
    }

    /**
     * Called when the perform() has been invoked, normally as a result of clicking or selecting a UI element.
     *
     * @param performCallback
     * @returns {Action}
     */
    withPerformCallback(performCallback: (action: Action) => void) : Action {
        this.performCallback = performCallback;
        return this;
    }

    withUpdateCallback(updateCallback: (action: Action) => void) : Action {
        this.updateCallback = updateCallback;
        return this;
    }

    /**
     * Call this to invoke the update callback.  This will allow the view model to react to user interactions and
     * update the Action state.
     */
    update() : void {
        if (this.updateCallback) {
            this.updateCallback(this);
        }
    }

    perform() : void {
        if (this.enabled && !this.performing) {
            this.performing = true;

            try {

                if (this.performCallback) {
                    if (this.target) {
                        this.logger.nav(`perform ${this.id} for ${this.target}`);
                    } else {
                        this.logger.nav(`perform ${this.id}`);
                    }
                    this.performCallback(this);

                    this.update();

                    if (this.navigationLocation) {
                        this.navigationLocation.trackAction(this.id);
                    }
                } else {
                    this.logger.debug("No performCallback for " + this.label);
                }
            } finally {
                this.performing = false;
            }
        }
    }
}

