import Fuse, { FuseResult, IFuseOptions } from 'fuse.js';

export class FuzzySearcher {
    private searchOptions: IFuseOptions<string>;

    constructor() {
        this.searchOptions = {
//            include: ["score","matches"],
            includeScore: true,
            includeMatches: true,
            shouldSort: true,
            findAllMatches: true,
            threshold: 0.4,
            location: 0,
            distance: 30,
//            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: []
        };
    }

    search(query, choices): Array<FuseResult<string>> {

        let fuse = new Fuse<string>(choices, this.searchOptions);

        return fuse.search(query);
    }

    getStyledValue(modelValue: string, match: FuseResult<string>) {
        let styledDisplayValue = "";
        let inMatch = false;

        for (let index = 0; index < modelValue.length; index++) {
            let strChar = modelValue.charAt(index);
            let thisCharMatches = this.checkIndexForMatch(index, match);

            if (thisCharMatches && !inMatch) {
                if (index != 0) {
                    styledDisplayValue += '</span>';
                }
                styledDisplayValue += '<span class="arp-typeahead-highlight">';
                styledDisplayValue += strChar;
            } else if (thisCharMatches && inMatch) {
                styledDisplayValue += strChar;
            } else if (!thisCharMatches && inMatch) {
                styledDisplayValue += '</span>';
                styledDisplayValue += '<span>';
                styledDisplayValue += strChar;
            } else if (!thisCharMatches && !inMatch) {
                styledDisplayValue += strChar;
            }

            inMatch = thisCharMatches;
        }

        styledDisplayValue += '</span>';

        return styledDisplayValue;
    }

    private checkIndexForMatch(index: number, match: FuseResult<string>) {
        for (let term of match.matches) {
            for (let fragment of term.indices) {
                if (index >= fragment[0] && index <= fragment[1]) {
                    return true;
                }
            }
        }

        return false;
    }
}
