import {JsonProperty, Serializable} from "typescript-json-serializer";

// This is a projection of an Animal & AnimalIntake that is used in AgencyDetails.
export interface IAnimalIntakeFull {
    _id: string;
    name: string;
    mode: string;             // AnimalIntakeModeEnum
    date: Date;
}

@Serializable()
export class AnimalIntakeFull implements IAnimalIntakeFull {

    @JsonProperty() _id: string = null;
    @JsonProperty() name: string = '';
    @JsonProperty() mode: string = '';                    // AnimalIntakeModeEnum
    @JsonProperty() date: Date = null;

    static getQueryFields(): string[] {
        return [
            '_id',
            'name',
            'mode',
            'date'
        ]
    }
}
