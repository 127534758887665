import {IReportConverter} from "./IReportConverter";
import {Enum, EnumElement} from "../Enum";


export class EnumConverter implements IReportConverter {

    private theEnum: Enum<any>;

    constructor(theEnum: Enum<any>) {
        this.theEnum = theEnum;
    }

    toView(modelValue: any, modelInstance: any) : string {
        let enumValue: EnumElement = this.theEnum.fromModel(modelValue);

        if (enumValue) {
            return enumValue.view
        } else {
            return modelValue;
        }
    }
}

