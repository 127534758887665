import {EnumElement, Enum} from "../../../Enum";

export class DogCharacteristics extends EnumElement {}

export class DogCharacteristicsEnum extends Enum<DogCharacteristics> {

    static readonly HOUSE_TRAINED = new DogCharacteristics('HOUSE_TRAINED', 'House Trained');
    static readonly SPECIAL_NEEDS = new DogCharacteristics('SPECIAL_NEEDS', 'Special Needs');
    static readonly SEPARATION_ANXIETY = new DogCharacteristics('SEPARATION_ANXIETY', 'Separation anxiety');

    static readonly ELEMENTS = [
        DogCharacteristicsEnum.HOUSE_TRAINED,
        DogCharacteristicsEnum.SPECIAL_NEEDS,
        DogCharacteristicsEnum.SEPARATION_ANXIETY
    ];
    static readonly INSTANCE = new DogCharacteristicsEnum();

    private constructor() {
        super(DogCharacteristicsEnum.ELEMENTS);
    }
}

