//
// PersonAnimalIntake
//

import {JsonProperty, Serializable} from "typescript-json-serializer";
import type {EntityId} from "../common/IEntity";
import {AnimalIntake, IAnimalIntake} from "../animal/IAnimalIntake";

export interface IPersonAnimalIntake<T> {
    _id: T;
    name: string;

    intake: IAnimalIntake<T>;
}

@Serializable()
export class PersonAnimalIntake implements IPersonAnimalIntake<EntityId> {
    @JsonProperty() _id: EntityId = '';
    @JsonProperty() name: string = '';
    @JsonProperty() intake: AnimalIntake = new AnimalIntake();

    static getQueryFields(): string[] {
        return [
            '_id',
            'name',
            ...AnimalIntake.getQueryFields().map(it => `intake.${it}`),
        ]
    }
}

