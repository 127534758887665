import {bindable, customElement, computedFrom, inject, DOM} from 'aurelia-framework';
import {Action} from "../actions/Action";
import $ from "jquery";

@customElement("arp-action-menu")
@inject(DOM.Element)
export class ArpActionMenu {

    @bindable label: string;
    @bindable actions: Array<Action>;
    @bindable target: any;
    @bindable location: string = 'right';     // left | right
    @bindable direction: string = 'down';     // down | up
    @bindable glyph: string = 'glyphicon-cog';
    @bindable size: string = 'sm';      // sm or xs (for header bars)

    private readonly element: Element;
    private overrideDirection = false;

    constructor(element: Element) {
        this.element = element;
    }

    attached() {
        const responsiveTableDiv = $(this.element).closest('.table-responsive');
        const tableDiv = $(this.element).closest('.table');

        // Only do this if we are in a table responsive
        if (responsiveTableDiv.length > 0 && tableDiv.length > 0) {

            const isSmallCheck = window.matchMedia("(max-width: 767px)")

            // If the screen size is small we always drop down
            this.overrideDirection = isSmallCheck.matches;

            // All dropdown events are fired at the .dropdown-menu's parent element (this.element).
            // All dropdown events have a relatedTarget property, whose value is the toggling anchor element.
            $(this.element).on('shown.bs.dropdown', (e) => {

                if (isSmallCheck.matches) {
                    const dropdownMenu = $(this.element).find('.dropdown-menu');

                    const tableOffsetHeight = responsiveTableDiv.offset().top + responsiveTableDiv.height();
                    const menuOffsetHeight = dropdownMenu.offset().top + dropdownMenu.outerHeight(true);
                    const xtraSpace = 20; // Space for shadow + scrollbar.

                    if (menuOffsetHeight + xtraSpace > tableOffsetHeight) {
                        responsiveTableDiv.css('padding-bottom', ((menuOffsetHeight + xtraSpace) - tableOffsetHeight));
                    }
                } else {
                    responsiveTableDiv.css('overflow', 'visible');
                }
            });

            $(this.element).on('hidden.bs.dropdown', function() {
                responsiveTableDiv.css({
                    'padding-bottom': '',
                    'overflow': ''
                });
            });
        }
    }

    // TODO: actions.length won't trigger change event!
    @computedFrom('actions.length')
    get show() : boolean {

        for (let action of this.actions) {
            if (action.visible) {
                return true;
            }
        }

        return false;
    }

    @computedFrom('location', 'direction', 'overrideDirection')
    get menustyle() : string {
        let classes = [];

        if (this.direction === 'up' && !this.overrideDirection) {
            classes.push('dropup');
        } else {
            classes.push('dropdown');
        }

        if (this.location === 'right') {
            classes.push('pull-right');
        }

        return classes.join(' ');
    }

    click() {
        for (let i = 0; i < this.actions.length; i++) {
            this.actions[i].withTarget(this.target);
        }
    }
}
