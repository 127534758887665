import {autoinject, customAttribute} from 'aurelia-framework';
import $ from "jquery";

@customAttribute('arp-collapse')
@autoinject()
export class ArpCollapseAttribute {

    private readonly element: HTMLButtonElement;
    private isCollapsed: boolean = false;
    private targets: Array<string> = [];

    constructor(element: Element) {
        this.element = element as HTMLButtonElement;
    }

    bind(bindingContext, overrideContext) {
        $(this.element).click(() => {
            this.toggle();
        });

        const dt = $(this.element).attr('data-target').split(',');
        this.targets.push(...dt);
    }

    unbind() {
    }

    attached() {
        this.targets.forEach((selector) => {
            const jeElement = getCollapseElement(selector);

            jeElement.collapse({
                toggle: false
            })
        })

        this.setGlyph();
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
        this.setGlyph();

        this.targets.forEach((selector) => {
            const jeElement = getCollapseElement(selector);

            jeElement.collapse(this.isCollapsed ? 'hide' : 'show');
        })
    }

    setGlyph() {
        if (this.isCollapsed) {
            this.element.innerHTML = '<span class="glyphicon glyphicon-menu-down"/>';
        } else {
            this.element.innerHTML = '<span class="glyphicon glyphicon-menu-up"/>';
        }
    }
}

interface CollapseElement<E extends HTMLElement> extends JQuery<E> {
    collapse(any);
}

function getCollapseElement(selector: any): CollapseElement<HTMLElement> {
    return $(selector) as CollapseElement<HTMLElement>;
}
