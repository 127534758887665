import {EnumElement, Enum} from "../../Enum";

export class DonationFor extends EnumElement {}

export class DonationForEnum extends Enum<DonationFor> {

    static readonly EVENTS = new DonationFor('EVENTS', 'Events');
    static readonly ANIMAL = new DonationFor('ANIMAL', 'Animal');
    static readonly PROGRAMS = new DonationFor('PROGRAMS', 'Special Programs');
    static readonly OTHER = new DonationFor('OTHER', 'Other');

    static readonly ELEMENTS = [
        DonationForEnum.EVENTS,
        DonationForEnum.ANIMAL,
        DonationForEnum.PROGRAMS,
        DonationForEnum.OTHER
    ];
    static readonly INSTANCE = new DonationForEnum();

    private constructor() {
        super(DonationForEnum.ELEMENTS);
    }

    static hasAnimal(model: string) : boolean {
        switch (model) {
            case DonationForEnum.ANIMAL.model:
                return true;
            default:
                return false;
        }
    }

    static hasValue(model: string) : boolean {
        switch (model) {
            case DonationForEnum.EVENTS.model:
            case DonationForEnum.PROGRAMS.model:
            case DonationForEnum.OTHER.model:
                return true;
            default:
                return false;
        }
    }

    static valueLabel(model: string) : string {
        switch (model) {

            case DonationForEnum.EVENTS.model:
                return "Event";
            case DonationForEnum.ANIMAL.model:
                return "Animal";
            case DonationForEnum.PROGRAMS.model:
                return "Program";
            case DonationForEnum.OTHER.model:
                return "Other";
            default:
                return "";
        }
    }
}
