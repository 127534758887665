import {bindable, computedFrom, customElement} from 'aurelia-framework';

@customElement("arp-phone")
export class ArpPhoneCustomElement {

    @bindable phone;

    constructor() {
    }

    @computedFrom('phone')
    get type(){
        if (this.phone.type) {
            return `(${this.phone.type})`;
        } else {
            return "";
        }
    }
}
