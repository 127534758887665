import {EnumElement, Enum} from "../../Enum";

export class AnswerType extends EnumElement {}

export class AnswerTypeEnum extends Enum<AnswerType> {

    static readonly BOOLEAN = new AnswerType('BOOLEAN', 'Yes/No');
    static readonly TEXT = new AnswerType('TEXT', 'Text');
    static readonly SINGLE_CHOICE = new AnswerType('SINGLE_CHOICE', 'Single Choice');
    static readonly MULTIPLE_CHOICE = new AnswerType('MULTIPLE_CHOICE', 'Multiple Choice');
    static readonly DATE = new AnswerType('DATE', 'Date');

    static readonly ELEMENTS = [
        AnswerTypeEnum.BOOLEAN,
        AnswerTypeEnum.TEXT,
        AnswerTypeEnum.SINGLE_CHOICE,
        AnswerTypeEnum.MULTIPLE_CHOICE,
        AnswerTypeEnum.DATE
    ];
    static readonly INSTANCE = new AnswerTypeEnum();

    private constructor() {
        super(AnswerTypeEnum.ELEMENTS);
    }
}
