import {Enum, EnumElement} from "../../Enum";

export class IntegrationType extends EnumElement {

    isMicrochipIntegration(): boolean {
        switch (this) {
            case IntegrationTypeEnum.FOUND_ANIMALS:
                return true;
            default:
                return false;
        }
    }

    // TODO: Have this here or in IntegrationTypeEnum?
    static fromModel(model: string): IntegrationType {
        return IntegrationTypeEnum.INSTANCE.fromModel(model);
    }
}

export class IntegrationTypeEnum extends Enum<IntegrationType> {

    static readonly PETFINDER = new IntegrationType('PETFINDER', 'Petfinder');
    static readonly ADOPT_A_PET = new IntegrationType('ADOPT_A_PET', 'Adopt-a-Pet');
    static readonly FOUND_ANIMALS = new IntegrationType('FOUND_ANIMALS', 'Michelson Found Animals');

    static readonly ELEMENTS = [
        IntegrationTypeEnum.PETFINDER,
        IntegrationTypeEnum.ADOPT_A_PET,
        IntegrationTypeEnum.FOUND_ANIMALS
    ];
    static readonly INSTANCE = new IntegrationTypeEnum();

    private constructor() {
        super(IntegrationTypeEnum.ELEMENTS);
    }
}
