import {JsonProperty, Serializable} from "typescript-json-serializer";

/**
 * Structure used to represent a Persons name.  Used by multiple entity schemas.
 */
export interface IPersonName {
    first: string;
    last: string;
    readonly full: string;       // Virtual in mongoose Model, NOT IN database!
}

@Serializable()
export class PersonName implements IPersonName {
    @JsonProperty() first: string = '';
    @JsonProperty() last: string = '';
    @JsonProperty() readonly full: string = '';

    static getQueryFields(): string[] {
        return [
            'first',
            'last',
            'full'
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            'full'
        ];
    }

}

