import {Enum, EnumElement} from "../../Enum";

export class AnimalSimpleGender extends EnumElement {
}

export class AnimalSimpleGenderEnum extends Enum<AnimalSimpleGender> {

    static readonly FEMALE = new AnimalSimpleGender('FEMALE', 'Female');
    static readonly MALE = new AnimalSimpleGender('MALE', 'Male');

    static readonly ELEMENTS = [
        AnimalSimpleGenderEnum.FEMALE,
        AnimalSimpleGenderEnum.MALE
    ];
    static readonly INSTANCE = new AnimalSimpleGenderEnum();

    private constructor() {
        super(AnimalSimpleGenderEnum.ELEMENTS);
    }
}
