import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IFosterDetails {
    timePeriod: Array<string>;          // FosterTimePeriodEnum
    has: Array<string>;                 // FosterHasEnum
    interests: Array<string>;           // FosterInterestsEnum
    animalCount: Array<string>;         // FosterAnimalCountEnum
}

@Serializable()
export class FosterDetails implements IFosterDetails {

    @JsonProperty() timePeriod: Array<string> = [];          // FosterTimePeriodEnum
    @JsonProperty() has: Array<string> = [];                 // FosterHasEnum
    @JsonProperty() interests: Array<string> = [];           // FosterInterestsEnum
    @JsonProperty() animalCount: Array<string> = [];         // FosterAnimalCountEnum

    static getQueryFields(): string[] {
        return [
            'timePeriod',
            'has',
            'interests',
            'animalCount'
        ]
    }
}