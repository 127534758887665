import {inject, bindable, bindingMode, customElement, DOM, computedFrom} from 'aurelia-framework';
import {Key} from 'ts-key-enum';

@inject(DOM.Element)
@customElement('arp-integer-input')
export class ArpIntegerInputCustomElement {

    @bindable({ defaultBindingMode: bindingMode.toView }) prefix: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView }) suffix: string = "";

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: number;

    private element: Element;
    private input: HTMLInputElement;

    private static allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
//      '.',
        ',',
        Key.Backspace,
        Key.Delete,
        Key.ArrowRight,
        Key.ArrowLeft,
        Key.Tab
    ];

    private static allowedControlKeys = [
        'c', 'C',
        'v', 'V',
        'x', 'X'
    ];

    constructor(element) {
        this.element = element;
    }

    created() {
        this.input = this.element.firstElementChild.firstElementChild.nextElementSibling as HTMLInputElement;

        this.input.addEventListener("keydown", (e) => this.filterInput(e));
    }

    filterInput(e: KeyboardEvent) {
        const key = e.key;

        let allowed: boolean =
            ArpIntegerInputCustomElement.allowedKeys.includes(key) ||
            e.ctrlKey && ArpIntegerInputCustomElement.allowedControlKeys.includes(key) ||
            e.metaKey && ArpIntegerInputCustomElement.allowedControlKeys.includes(key);

        if (!allowed) {
            e.preventDefault();
        }
    }

    valueChanged() {
        if (this.value === null) {
            // Leave the error, the user typed it!
        } else if (this.value === -1) {
            // leave the error, the user typed it!
        } else if (this.input.value !== this.value.toString()) {
            // Only update the input text if the value is valid, if not it's already what the user typed
            this.input.value = this.value.toString();
        }
    }

    blur() {

        // blank input maps to null value
        if (this.input.value === '') {
            this.value = null;
        } else {
            const newValue = this.convertToInteger(this.input.value);
            const valid = newValue !== Infinity && String(newValue) === this.input.value && newValue >= 0;

            if (!valid) {
                // Can't bind NaN to a number (See https://github.com/aurelia/binding/issues/613)
                this.value = -1;
            } else if (this.value !== newValue) {
                this.value = newValue;
            }
        }
    }

    convertToInteger(value: string) : number {
        const numValue = Number(this.input.value);
        const intValue = Math.floor(numValue);

        return intValue;
    }

    @computedFrom('prefix')
    get showPrefix() {
        return this.prefix.length > 0;
    }

    @computedFrom('suffix')
    get showSuffix() {
        return this.suffix.length > 0;
    }
}

