import type { EntityId } from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ITaskPerson<T> {
    person: T;

    // Include the person data locally to support full text query
    personFullName: string;
    personLastName: string;
    personPrimaryEmail: string;
}

@Serializable()
export class TaskPerson implements ITaskPerson<EntityId> {
    @JsonProperty() person: EntityId = null;

    // Include the person data locally to support full text query
    @JsonProperty() personFullName: string = '';
    @JsonProperty() personLastName: string = '';
    @JsonProperty() personPrimaryEmail: string = '';

    static getQueryFields(): string[] {
        return [
            'person',
            'personFullName',       // Read-only
            'personLastName',      // Read-only
            'personPrimaryEmail',      // Read-only
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            'personFullName',       // Read-only
            'personLastName',      // Read-only
            'personPrimaryEmail',      // Read-only
        ]
    }
}