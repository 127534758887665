import {EnumElement, Enum} from "../../Enum";

export class AnimalStatus extends EnumElement {

    hasPerson(): boolean {
        switch (this) {
            case AnimalStatusEnum.ADOPTED:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.RETURNED:
            case AnimalStatusEnum.TRIAL_ADOPTION:
            case AnimalStatusEnum.OTHER:
                return true;
            default:
                return false;
        }
    }

    requiresPerson(): boolean {
        switch (this) {
            case AnimalStatusEnum.ADOPTED:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.RETURNED:
            case AnimalStatusEnum.TRIAL_ADOPTION:
                return true;
            default:
                return false;
        }
    }

    hasAgency(): boolean {
        switch (this) {

            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.ON_SITE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.TRANSFERRED_LOCAL:
            case AnimalStatusEnum.TRANSFERRED_REMOTE:
                return true;
            default:
                return false;
        }
    }

    hasTenant() {
        switch (this) {
            case AnimalStatusEnum.TRANSFERRED_SPARKIE:
                return true;
            default:
                return false;
        }
    }

    hasAmount(): boolean {
        switch (this) {
            case AnimalStatusEnum.ADOPTED:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.ON_SITE:
                return true;
            default:
                return false;
        }
    }

    amountLabel(): string {
        switch (this) {
            case AnimalStatusEnum.FOSTER:
                return 'Foster expense';
            case AnimalStatusEnum.ADOPTED:
                return 'Adoption fee';
            case AnimalStatusEnum.HOSPITALIZED:
                return 'Hospital expense';
            case AnimalStatusEnum.IN_TRANSPORT:
                return 'Transport expense';
            case AnimalStatusEnum.BOARDING:
                return 'Boarding expense';
            case AnimalStatusEnum.ON_SITE:
                return 'On site expense';
            default:
                return '';
        }
    }

    pastTenseLabel(): string {
        switch (this) {
            case AnimalStatusEnum.FOSTER:
                return 'Fostered';
            case AnimalStatusEnum.ADOPTED:
                return 'Adopted';
            case AnimalStatusEnum.HOSPITALIZED:
                return 'Hospitalized';
            case AnimalStatusEnum.IN_TRANSPORT:
                return 'Transported';
            case AnimalStatusEnum.BOARDING:
                return 'Boarded';
            default:
                return this.view;
        }
    }

    causesEndOfCare(): boolean {
        switch (this) {

            case AnimalStatusEnum.ADOPTED:

            case AnimalStatusEnum.DIED_IN_CARE:
            case AnimalStatusEnum.IN_HOUSE_EUTHANASIA:
            case AnimalStatusEnum.RETURNED_TO_FIELD:
            case AnimalStatusEnum.RETURNED_TO_OWNER:
            case AnimalStatusEnum.TRANSFERRED_LOCAL:
            case AnimalStatusEnum.TRANSFERRED_REMOTE:
            case AnimalStatusEnum.TRANSFERRED_SPARKIE:
                return true;

            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.ON_SITE:
            case AnimalStatusEnum.RETURNED:
            case AnimalStatusEnum.TRIAL_ADOPTION:       // TODO:?
            case AnimalStatusEnum.OTHER:
            default:
                return false;
        }
    }

    causesIntake(): boolean {
        switch (this) {

            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.ON_SITE:
            case AnimalStatusEnum.RETURNED:
            case AnimalStatusEnum.TRIAL_ADOPTION:       // TODO:?
            case AnimalStatusEnum.OTHER:
                return true;

            case AnimalStatusEnum.ADOPTED:

            case AnimalStatusEnum.DIED_IN_CARE:
            case AnimalStatusEnum.IN_HOUSE_EUTHANASIA:
            case AnimalStatusEnum.RETURNED_TO_FIELD:
            case AnimalStatusEnum.RETURNED_TO_OWNER:
            case AnimalStatusEnum.TRANSFERRED_LOCAL:
            case AnimalStatusEnum.TRANSFERRED_REMOTE:
            case AnimalStatusEnum.TRANSFERRED_SPARKIE:      // Not an intake to the source rescue!!!
            default:
                return false;
        }
    }

    isNeverAvailable(): boolean {
        switch (this) {

            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.ADOPTED:
            case AnimalStatusEnum.RETURNED:

            case AnimalStatusEnum.DIED_IN_CARE:
            case AnimalStatusEnum.IN_HOUSE_EUTHANASIA:
            case AnimalStatusEnum.RETURNED_TO_FIELD:
            case AnimalStatusEnum.RETURNED_TO_OWNER:
            case AnimalStatusEnum.TRANSFERRED_LOCAL:
            case AnimalStatusEnum.TRANSFERRED_REMOTE:
            case AnimalStatusEnum.TRANSFERRED_SPARKIE:
                return true;
            default:
                return false;
        }
    }

    isAvailableToAdopt(): boolean {
        switch (this) {

            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.ON_SITE:
                return true;

            default:
                return false;
        }
    }

    isValidAtIntake(): boolean {
        switch (this) {
            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.ON_SITE:
            case AnimalStatusEnum.OTHER:
                return true;

            default:
                return false;
        }
    }

    isInCare(): boolean {
        switch (this) {
            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.IN_TRANSPORT:
            case AnimalStatusEnum.ON_SITE:
            case AnimalStatusEnum.TRIAL_ADOPTION:
                return true;

            default:
                return false;
        }
    }

    hasCurrentPerson() {
        // NOTE: These all need to be mirrored in PersonCurrentStatusEnum!!!
        switch (this) {
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.TRIAL_ADOPTION:
                return true;

            default:
                return false;
        }
    }

    /**
     * Animal is in the care of this rescue
     */
    requiresRegistrationToRescue(): boolean {
        switch (this) {
            case AnimalStatusEnum.BOARDING:
            case AnimalStatusEnum.FOSTER:
            case AnimalStatusEnum.HOSPICE:
            case AnimalStatusEnum.HOSPITALIZED:
            case AnimalStatusEnum.ON_SITE:
            case AnimalStatusEnum.TRIAL_ADOPTION:
                return true;

            default:
                return false;
        }
    }

    /**
     * Animal should be registered to the current guardian.
     *
     * NOTE: if the current guardian is an agency we don't register it as it is expected that the new agency will
     * do this registration and also change the Permanent Rescue Contact.
     */
    requiresRegistrationToGuardian(): boolean {
        switch (this) {
            case AnimalStatusEnum.ADOPTED:
            case AnimalStatusEnum.RETURNED:
                return true;

            case AnimalStatusEnum.RETURNED_TO_FIELD:
            case AnimalStatusEnum.RETURNED_TO_OWNER:
            case AnimalStatusEnum.TRANSFERRED_LOCAL:
            case AnimalStatusEnum.TRANSFERRED_REMOTE:
            case AnimalStatusEnum.TRANSFERRED_SPARKIE:
            default:
                return false;
        }
    }
}

/**
 * Enum that describes the current status of an Animal with the rescue
 */
export class AnimalStatusEnum extends Enum<AnimalStatus> {

    /**
     * The animal has been adopted and is no longer in the care of the Rescue.
     */
    static readonly ADOPTED = new AnimalStatus('ADOPTED', 'Adopted');

    /**
     * The animal is in an offsite boarding facility.
     */
    static readonly BOARDING = new AnimalStatus('BOARDING', 'Boarding');

    /**
     * The animal is in the care of a Foster.
     */
    static readonly FOSTER = new AnimalStatus('FOSTER', 'In foster');

    /**
     * The animal is in permanent hospice care, and is expected to remain in the care of the Rescue till death.
     */
    static readonly HOSPICE = new AnimalStatus('HOSPICE', 'In Hospice');

    /**
     * The animal is under medical care at a hospital of other off site medical facility.
     */
    static readonly HOSPITALIZED = new AnimalStatus('HOSPITALIZED', 'Hospitalized');

    /**
     * The animal is in transit to the Rescue, under the direction of the Rescue.
     */
    static readonly IN_TRANSPORT = new AnimalStatus('IN_TRANSPORT', 'In Transport');

    /**
     * The animal is housed in one of the Rescue's group sites/kennels.
     */
    static readonly ON_SITE = new AnimalStatus('ON_SITE', 'On Site');

    /**
     * The animal was returned from the Adopter to the Rescue.
     */
    static readonly RETURNED = new AnimalStatus('RETURNED', 'Returned');

    /**
     * THe animal is undergoing a trial adoption, but is still the responsibility of the Rescue.
     */
    static readonly TRIAL_ADOPTION = new AnimalStatus('TRIAL_ADOPTION', 'Trial adoption');

    /**
     * The animal died while under the care of the Rescue.
     */
    static readonly DIED_IN_CARE = new AnimalStatus('DIED_IN_CARE', 'Died in care');

    /**
     * The animal was euthanized while under the care of the Rescue.
     */
    static readonly IN_HOUSE_EUTHANASIA = new AnimalStatus('IN_HOUSE_EUTHANASIA', 'Euthanasia');

    /**
     * The animal was returned to the agency from which it was obtained, and is no longer under the care of the rescue.
     */
    static readonly RETURNED_TO_FIELD = new AnimalStatus('RETURNED_TO_FIELD', 'Returned to field');

    /**
     * The animal had previously surrendered by it's owner to the Rescue, and was returned to the original owner.
     */
    static readonly RETURNED_TO_OWNER = new AnimalStatus('RETURNED_TO_OWNER', 'Returned to owner');

    /**
     * The animal was transferred to an agency that was not where it originated from.
     */
    static readonly TRANSFERRED_LOCAL = new AnimalStatus('TRANSFERRED_LOCAL', 'Transferred (local)');

    /**
     * The animal was transferred to an agency that was not where it originated from.
     */
    static readonly TRANSFERRED_REMOTE = new AnimalStatus('TRANSFERRED_REMOTE', 'Transferred (non-local)');

    /**
     * The animal was digitally transferred to a rescue that also uses Sparkie.
     */
    static readonly TRANSFERRED_SPARKIE = new AnimalStatus('TRANSFERRED_SPARKIE', 'Transferred (Sparkie)');

    /**
     * Used historically when we didn't have a status that matched what the rescue wanted (Hospice, Hospital, etc)
     * Note that this can have any Availability value
     */
    static readonly OTHER = new AnimalStatus('OTHER', 'Other');

    static readonly ELEMENTS = [
        AnimalStatusEnum.ADOPTED,
        AnimalStatusEnum.BOARDING,
        AnimalStatusEnum.FOSTER,
        AnimalStatusEnum.HOSPICE,
        AnimalStatusEnum.HOSPITALIZED,
        AnimalStatusEnum.IN_TRANSPORT,
        AnimalStatusEnum.ON_SITE,
        AnimalStatusEnum.RETURNED,
        AnimalStatusEnum.TRIAL_ADOPTION,
        AnimalStatusEnum.DIED_IN_CARE,
        AnimalStatusEnum.IN_HOUSE_EUTHANASIA,
        AnimalStatusEnum.RETURNED_TO_FIELD,
        AnimalStatusEnum.RETURNED_TO_OWNER,
        AnimalStatusEnum.TRANSFERRED_LOCAL,
        AnimalStatusEnum.TRANSFERRED_REMOTE,
        AnimalStatusEnum.TRANSFERRED_SPARKIE,
        AnimalStatusEnum.OTHER
    ];
    static readonly INSTANCE = new AnimalStatusEnum();

    private constructor() {
        super(AnimalStatusEnum.ELEMENTS);
    }
}
