import {inject, bindable, bindingMode, customElement, DOM, computedFrom} from 'aurelia-framework';
import {Key} from 'ts-key-enum';
import {Decimal128} from "bson";
import {DecimalUtils} from "@sparkie/shared-model/src";

@inject(DOM.Element)
@customElement('arp-number-input')
export class ArpNumberInputCustomElement {

    @bindable({ defaultBindingMode: bindingMode.toView }) prefix: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView }) suffix: string = "";
    @bindable({ defaultBindingMode: bindingMode.toView }) decimals: string = "0";

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: Decimal128;

    private element: Element;
    private input: HTMLInputElement;

    private static allowedKeys = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
        ',',
        Key.Backspace,
        Key.Delete,
        Key.ArrowRight,
        Key.ArrowLeft,
        Key.Tab
    ];

    private static allowedControlKeys = [
        'c', 'C',
        'v', 'V',
        'x', 'X'
    ];

    constructor(element) {
        this.element = element;
    }

    created() {
        this.input = this.element.firstElementChild.firstElementChild.nextElementSibling as HTMLInputElement;

        this.input.addEventListener("keydown", (e) => this.filterInput(e));
    }

    filterInput(e: KeyboardEvent) {
        const key = e.key;

        let allowed: boolean =
            ArpNumberInputCustomElement.allowedKeys.includes(key) ||
            e.ctrlKey && ArpNumberInputCustomElement.allowedControlKeys.includes(key) ||
            e.metaKey && ArpNumberInputCustomElement.allowedControlKeys.includes(key);

        if (!allowed) {
            e.preventDefault();
        }
    }

    valueChanged() {
        if (this.value && DecimalUtils.isValid(this.value)) {

            // Only update the input text if the value is valid, if not it's already what the user typed
            this.input.value = DecimalUtils.formatString(this.value, false, parseInt(this.decimals));
        }
    }

    decimalsChanged() {

    }

    blur() {

        // blank input maps to null value
        if (this.input.value === '') {
            this.value = null;
        } else {
            this.value = DecimalUtils.fromString(this.input.value);
        }
    }

    @computedFrom('prefix')
    get showPrefix() {
        return this.prefix.length > 0;
    }


    @computedFrom('suffix')
    get showSuffix() {
        return this.suffix.length > 0;
    }
}

