
import {FilterCategory} from './FilterCategory';
import {InstantFilter} from "./InstantFilter";

export class InstantFilterCategory extends FilterCategory {

    constructor(label: string, filter: InstantFilter) {
        super(label);

        this.instantFilter = true;  // TODO: Use typeof?

        if (filter) {
            this.addFilter(filter);
        }
    }

    activate() {
        for (let filter of this.filters) {
            (filter as InstantFilter).active = true;
        }
    }
}
