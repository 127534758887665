import { SessionManager } from "../../../auth/SessionManger";
import { HttpClient } from "aurelia-http-client";
import { Appender } from "../Appender";
import { Logger } from "../ArpLogger";
import { showReloadRequired } from "arp-framework/views/ReloadMessage";

/**
 * Logger that writes to the server.
 *
 * NOTE: This logger supports a custom level: NAV, which is used to log the users navigation
 * through the application.  These NAV logs, as well as WARN and ERROR are also sent
 * to the server for logging.
 */
export class ServerLogAppender implements Appender {
    private readonly uri: string = "/log";
    private readonly httpClient: HttpClient = new HttpClient();

    constructor(readonly sessionManager: SessionManager) {
        this.sessionManager = sessionManager;
    }

    /**
     * Appends a debug log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    debug(logger: Logger, ...rest: any[]): void {}

    /**
     * Appends an info log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    info(logger: Logger, ...rest: any[]): void {}

    /**
     * Appends an info log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    api(logger: Logger, ...rest: any[]): void {}

    /**
     * Appends an nav log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    nav(logger: Logger, ...rest: any[]): void {
        this.logRemote("nav", logger, rest);
    }

    /**
     * Appends a warning log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    warn(logger: Logger, ...rest: any[]): void {
        this.logRemote("warn", logger, rest);
    }

    /**
     * Appends an error log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    apiError(logger: Logger, ...rest: any[]): void {}

    /**
     * Appends an error log.
     *
     * @param logger The source logger.
     * @param rest The data to log.
     */
    error(logger: Logger, ...rest: any[]): void {
        this.logRemote("error", logger, rest);
    }

    /**
     * Increases indentation of subsequent lines by two spaces.
     * If one or more `label`s are provided, those are printed first without the additional indentation.
     */
    group(...label: any[]): void {}

    /**
     * The `console.groupCollapsed()` function is an alias for {@link console.group()}.
     */
    groupCollapsed(...label: any[]): void {}

    /**
     * Decreases indentation of subsequent lines by two spaces.
     */
    groupEnd(): void {}

    private async logRemote(level: string, logger: Logger, ...rest: any[]) {
        if (this.sessionManager.isAuthenticated()) {
            const request = this.httpClient
                .createRequest(this.uri)
                .asPost()
                .withHeader("CSRF-TOKEN", localStorage.getItem("csrf"))
                .withHeader("sparkie-client", "web-app")
                .withContent({
                    level: level,
                    logger: logger.id,
                    payload: rest,
                    version: SPARKIE_VERSION,
                });
            try {
                const response = await request.send();
                const serverVersion = response.headers.get("sparkie-release-version");

                if (serverVersion !== SPARKIE_VERSION) {
                    console.log(`Show Version Mismatch: server: ${serverVersion} != client: ${SPARKIE_VERSION}`);

                    // Server has updated, so we need to refresh.  Since this is either a nav event or real error we can force
                    // the user to reload now.  No need to wait
                    // to refresh the page.
                    showReloadRequired();
                }
            } catch (err) {
                // Do nothing, server logging is best effort
            }
        }
    }
}

export async function forceReload() {}
