import {EnumElement, Enum} from "../../../Enum";

export class GuineaPigSize extends EnumElement {}

export class GuineaPigSizeEnum extends Enum<GuineaPigSize> {

    static readonly SMALL = new GuineaPigSize('SMALL', 'Small (< 2 pounds)');
    static readonly MEDIUM = new GuineaPigSize('MEDIUM', 'Medium (2-3 pounds)');
    static readonly LARGE = new GuineaPigSize('LARGE', 'Large (3+ pounds)');

    static readonly ELEMENTS = [
        GuineaPigSizeEnum.SMALL,
        GuineaPigSizeEnum.MEDIUM,
        GuineaPigSizeEnum.LARGE
    ];
    static readonly INSTANCE = new GuineaPigSizeEnum();

    private constructor() {
        super(GuineaPigSizeEnum.ELEMENTS);
    }
}

export class GuineaPigSizeMetric extends EnumElement {}

export class GuineaPigSizeEnumMetric extends Enum<GuineaPigSizeMetric> {

    static readonly SMALL = new GuineaPigSizeMetric('SMALL', 'Small (< 1 kg)');
    static readonly MEDIUM = new GuineaPigSizeMetric('MEDIUM', 'Medium (1-1.5 kg)');
    static readonly LARGE = new GuineaPigSizeMetric('LARGE', 'Large (1.5+ kg)');

    static readonly ELEMENTS = [
        GuineaPigSizeEnumMetric.SMALL,
        GuineaPigSizeEnumMetric.MEDIUM,
        GuineaPigSizeEnumMetric.LARGE
    ];
    static readonly INSTANCE = new GuineaPigSizeEnumMetric();

    private constructor() {
        super(GuineaPigSizeEnumMetric.ELEMENTS);
    }
}
