import padEnd from "lodash/padEnd";
import {Decimal128} from "bson";


export class DecimalUtils {

    // Nan value bits as 32 bit values (due to lack of longs)
    private static NAN_BUFFER = [0x7c, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00].reverse();

    // Infinity value bits 32 bit values (due to lack of longs)
    private static INF_NEGATIVE_BUFFER = [0xf8, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00].reverse();
    private static INF_POSITIVE_BUFFER = [0x78, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00].reverse();

    static fromString(amount: string) : Decimal128 {

        try {
            return Decimal128.fromString(amount);
        } catch (e) {
            return Decimal128.fromString("NaN");
        }
    }

    static formatString(amount: Decimal128, showCommas: boolean = false, decimalPlaces: number = 2) : string {
        let parts = amount.toString().split(".");
        let whole = showCommas ? parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : parts[0];
        let fraction = parts.length === 1 ? "" : parts[1];

        return [whole, padEnd(fraction, decimalPlaces, '0')].join(".");
    }

    static isValid(decimal: Decimal128) : boolean {
        return !DecimalUtils.isNan(decimal) && !DecimalUtils.isPositiveInfinity(decimal) && !DecimalUtils.isNegativeInfinity(decimal);
    }

    static isNan(decimal: Decimal128) : boolean {

        const bytes : Array<number> = DecimalUtils.getBytes(decimal);
        return DecimalUtils.equalsBytes(bytes, DecimalUtils.NAN_BUFFER);
    }

    static isPositiveInfinity(decimal: Decimal128) : boolean {

        const bytes : Array<number> = DecimalUtils.getBytes(decimal);
        return DecimalUtils.equalsBytes(bytes, DecimalUtils.INF_POSITIVE_BUFFER);
    }

    static isNegativeInfinity(decimal: Decimal128) : boolean {

        const bytes : Array<number> = DecimalUtils.getBytes(decimal);
        return DecimalUtils.equalsBytes(bytes, DecimalUtils.INF_NEGATIVE_BUFFER);
    }

    static decimalPlaces(decimal: Decimal128) : number {
        let decimalString = decimal.toString();
        let tokens = decimalString.split(".");

        return tokens.length === 1 ? 0 : tokens[1].length;
    }

    static isPositive(decimal: Decimal128) : boolean {
        let decimalString = decimal.toString();

        let tokens = decimalString.split(".");
        let dollars = parseInt(tokens[0], 10);

        return dollars >= 0;

    }

    private static getBytes(decimal: Decimal128) : Array<number> {
        return (decimal as any).bytes;
    }

    private static equalsBytes(a: Array<number>, b: Array<number>) : boolean {

        for (let index = 0; index < 16; index++) {
            if (a[index] !== b[index]) {
                return false;
            }
        }

        return true;
    }
}