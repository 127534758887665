import {EnumElement, Enum} from "../../../Enum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class FourDxTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {
        let heartworm;
        let lyme;
        let ehrlichiosis;
        let anaplasmosis;

        switch (subType) {
            case FourDxTestOutcomeEnum.NEGATIVE_ALL.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_HEARTWORM.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_LYME.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_EHRLICHIOSIS.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_ANAPLASMOSIS.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_LYME.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_EHRLICHIOSIS.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_ANAPLASMOSIS.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_LYME_EHRLICHIOSIS.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_LYME_ANAPLASMOSIS.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_EHRLICHIOSIS_ANAPLASMOSIS.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.NEGATIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_LYME_EHRLICHIOSIS.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.NEGATIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_LYME_ANAPLASMOSIS.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.NEGATIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_LYME_EHRLICHIOSIS_ANAPLASMOSIS.view:
                heartworm = ConditionStatusEnum.NEGATIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            case FourDxTestOutcomeEnum.POSITIVE_ALL.view:
                heartworm = ConditionStatusEnum.POSITIVE;
                lyme = ConditionStatusEnum.POSITIVE;
                ehrlichiosis = ConditionStatusEnum.POSITIVE;
                anaplasmosis = ConditionStatusEnum.POSITIVE;
                break;

            default:
                heartworm = ConditionStatusEnum.UNKNOWN;
                lyme = ConditionStatusEnum.UNKNOWN;
                ehrlichiosis = ConditionStatusEnum.UNKNOWN;
                anaplasmosis = ConditionStatusEnum.UNKNOWN;
                break;
        }

        outcomes.push(MedicalConditionEnum.HEARTWORM.createCondition(heartworm));
        outcomes.push(MedicalConditionEnum.LYME.createCondition(lyme));
        outcomes.push(MedicalConditionEnum.EHRLICHIOSIS.createCondition(ehrlichiosis));
        outcomes.push(MedicalConditionEnum.ANAPLASMOSIS.createCondition(anaplasmosis));
    }
}

export class FourDxTestOutcomeEnum extends Enum<FourDxTestOutcome> {

    static readonly NEGATIVE_ALL = new FourDxTestOutcome('NEGATIVE_ALL', 'Negative: All');
    static readonly POSITIVE_HEARTWORM = new FourDxTestOutcome('POSITIVE_HEARTWORM', 'Positive: Heartworm');
    static readonly POSITIVE_LYME = new FourDxTestOutcome('POSITIVE_LYME', 'Positive: Lyme');
    static readonly POSITIVE_EHRLICHIOSIS = new FourDxTestOutcome('POSITIVE_EHRLICHIOSIS', 'Positive: Ehrlichiosis');
    static readonly POSITIVE_ANAPLASMOSIS = new FourDxTestOutcome('POSITIVE_ANAPLASMOSIS', 'Positive: Anaplasmosis');
    static readonly POSITIVE_HEARTWORM_LYME = new FourDxTestOutcome('POSITIVE_HEARTWORM_LYME', 'Positive: Heartworm & Lyme');
    static readonly POSITIVE_HEARTWORM_EHRLICHIOSIS = new FourDxTestOutcome('POSITIVE_HEARTWORM_EHRLICHIOSIS', 'Positive: Heartworm & Ehrlichiosis');
    static readonly POSITIVE_HEARTWORM_ANAPLASMOSIS = new FourDxTestOutcome('POSITIVE_HEARTWORM_ANAPLASMOSIS', 'Positive: Heartworm & Anaplasmosis');
    static readonly POSITIVE_LYME_EHRLICHIOSIS = new FourDxTestOutcome('POSITIVE_LYME_EHRLICHIOSIS', 'Positive: Lyme & Ehrlichiosis');
    static readonly POSITIVE_LYME_ANAPLASMOSIS = new FourDxTestOutcome('POSITIVE_LYME_ANAPLASMOSIS', 'Positive: Lyme & Anaplasmosis');
    static readonly POSITIVE_EHRLICHIOSIS_ANAPLASMOSIS = new FourDxTestOutcome('POSITIVE_EHRLICHIOSIS_ANAPLASMOSIS', 'Positive: Ehrlichiosis & Anaplasmosis');
    static readonly POSITIVE_HEARTWORM_LYME_EHRLICHIOSIS = new FourDxTestOutcome('POSITIVE_HEARTWORM_LYME_EHRLICHIOSIS', 'Positive: Heartworm & Lyme & Ehrlichiosis');
    static readonly POSITIVE_HEARTWORM_LYME_ANAPLASMOSIS = new FourDxTestOutcome('POSITIVE_HEARTWORM_LYME_ANAPLASMOSIS', 'Positive: Heartworm & Lyme & Anaplasmosis');
    static readonly POSITIVE_LYME_EHRLICHIOSIS_ANAPLASMOSIS = new FourDxTestOutcome('POSITIVE_LYME_EHRLICHIOSIS_ANAPLASMOSIS', 'Positive: Lyme & Ehrlichiosis & Anaplasmosis');
    static readonly POSITIVE_ALL = new FourDxTestOutcome('POSITIVE_ALL', 'Positive: All');

    static readonly ELEMENTS = [
        FourDxTestOutcomeEnum.NEGATIVE_ALL,
        FourDxTestOutcomeEnum.POSITIVE_HEARTWORM,
        FourDxTestOutcomeEnum.POSITIVE_LYME,
        FourDxTestOutcomeEnum.POSITIVE_EHRLICHIOSIS,
        FourDxTestOutcomeEnum.POSITIVE_ANAPLASMOSIS,
        FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_LYME,
        FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_EHRLICHIOSIS,
        FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_ANAPLASMOSIS,
        FourDxTestOutcomeEnum.POSITIVE_LYME_EHRLICHIOSIS,
        FourDxTestOutcomeEnum.POSITIVE_LYME_ANAPLASMOSIS,
        FourDxTestOutcomeEnum.POSITIVE_EHRLICHIOSIS_ANAPLASMOSIS,
        FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_LYME_EHRLICHIOSIS,
        FourDxTestOutcomeEnum.POSITIVE_HEARTWORM_LYME_ANAPLASMOSIS,
        FourDxTestOutcomeEnum.POSITIVE_LYME_EHRLICHIOSIS_ANAPLASMOSIS,
        FourDxTestOutcomeEnum.POSITIVE_ALL
    ];
    static readonly INSTANCE = new FourDxTestOutcomeEnum();

    private constructor() {
        super(FourDxTestOutcomeEnum.ELEMENTS);
    }
}
