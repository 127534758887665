
/**
 *  Simple class that denotes the User's navigation in a manner that allows tracking of Sparkie usage.
 */
export class NavigationLocation {

    public readonly resource: string;
    public readonly view: string;

    constructor(resource: string, view: string) {
        this.resource = this.cleanString(resource);
        this.view = this.cleanString(view);
    }

    getLoggerId() : string {
        return `${this.resource}.${this.view}`;
    }

    trackAction(action: string, properties = {}) {
        // TODO: Track this action.
    }

    cleanString(value: string) : string {
        return value.split(' ').join('-').toLowerCase();
    }
}