
import {IReportConverter} from "@sparkie/shared-model/src";
import {AnimalSpeciesEnum} from "@sparkie/shared-model/src";
import {CatSizeEnum} from "@sparkie/shared-model/src";
import {DogSizeEnum} from "@sparkie/shared-model/src";

export class AnimalSizeConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {

        if (modelValue) {
            let animalSpecies = AnimalSpeciesEnum.INSTANCE.fromModel(modelInstance.species);
            switch (animalSpecies) {
                case AnimalSpeciesEnum.CAT:
                    return CatSizeEnum.INSTANCE.fromModel(modelValue).view;
                case AnimalSpeciesEnum.DOG:
                    return DogSizeEnum.INSTANCE.fromModel(modelValue).view;
                default:
                    return "";
            }

        } else {
            return "";
        }
    }
}

