import {IDependency} from "../ArpRepository";
import {Container} from "aurelia-framework";
import {ViewStateManager} from "../ViewStateManager";
import {ArpModal} from "./arp-modal";
import {ArpViewState} from "../ArpViewState";
import {set} from "lodash";
import {ModelResource, ModelResourceEnum} from "@sparkie/shared-model/src";

/**
 * ViewModel for presenting delete error messages to the user.
 */
export class ArpDeleteMessage {

    description: string;
    dependencies: Array<IDependency> = [];
    links: Array<string> = [];
    private modalService: ArpModal;
    private vsm: ViewStateManager;

    constructor(description: string, dependencies: Array<IDependency>) {
        this.description = description;
        this.dependencies = dependencies;
        this.modalService = Container.instance.get(ArpModal);
        this.vsm = Container.instance.get(ViewStateManager);

        this.buildLinks();
    }

    onClick(index: number) {
        const dependency = this.dependencies[index];
        const resource: ModelResource = ModelResourceEnum.INSTANCE.fromModel(dependency.type.toUpperCase());

        this.modalService.hide();

        if (resource.hasDetailsPage()) {
            return this.vsm.router.navigateToRoute(resource.detailsRoute(), { id: dependency.id });

        } else {
            // navigateWithState
            this.setViewState(dependency);
            return this.vsm.router.navigateToRoute(resource.route());
        }
    }

    private setViewState(dependency: IDependency) {

        let viewState = new ArpViewState();

        let data = {};
        let key = viewState.getKey('searchData');
        set(data, key, {
            searchText: `_id=${dependency.id}`
        });

        key = viewState.getKey('filterData');
        set(data, key, {
            filters: []
        });

        this.vsm.queueViewState(data);
    }

    private buildLinks() {
        for (let dependency of this.dependencies) {
            this.links.push({
                label: `${dependency.type}: ${dependency.label} (${dependency.property})`
            } as any);
        }
    }
}


