import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IEmail {
    type: string;     // home | work | ...
    address: string;
}

@Serializable()
export class Email implements IEmail {
    @JsonProperty() type: string = '';
    @JsonProperty() address: string = '';

    static getQueryFields(): string[] {
        return [
            'type',
            'address'
        ]
    }
}

export interface IHasEmails {
    email: Array<IEmail>;
}
