import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface INotificationSettings {
    summaryEnabled: boolean;
    summaryLastUpdate: Date;
    summaryLastStatus: string;

    alertEnabled: boolean;
    alertLastUpdate: Date;
    alertLastStatus: string;

    totalSent: number;
}

@Serializable()
export class NotificationSettings implements INotificationSettings {
    @JsonProperty() summaryEnabled: boolean = false;
    @JsonProperty() summaryLastUpdate: Date = null;
    @JsonProperty() summaryLastStatus: string = '';

    @JsonProperty() alertEnabled: boolean = false;
    @JsonProperty() alertLastUpdate: Date = null;
    @JsonProperty() alertLastStatus: string = '';

    @JsonProperty() totalSent: number = 0;

    static getQueryFields(): string[] {
        return [
            'summaryEnabled',
            'summaryLastUpdate',
            'summaryLastStatus',
            'alertEnabled',
            'alertLastUpdate',
            'alertLastStatus',
            'totalSent'
        ]
    }
}

