import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IVideoLink {
    id: string;
    source: string;         // VideoSourceEnum
    description: string;
}

@Serializable()
export class VideoLink implements IVideoLink {
    @JsonProperty() id: string = '';
    @JsonProperty() source: string = '';
    @JsonProperty() description: string = '';

    static getQueryFields(): string[] {
        return [
            'id',
            'source',
            'description'
        ]
    }
}
