import {EnumElement, Enum} from "../../../Enum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class FelvTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {
        let outcome;

        switch (subType) {
            case FelvTestOutcomeEnum.FELV_POSITIVE.view:
                outcome = ConditionStatusEnum.POSITIVE;
                break;

            case FelvTestOutcomeEnum.FELV_NEGATIVE.view:
                outcome = ConditionStatusEnum.NEGATIVE;
                break;

            default:
                outcome = ConditionStatusEnum.UNKNOWN;
                break;
        }

        outcomes.push(MedicalConditionEnum.FELV.createCondition(outcome));
    }
}

export class FelvTestOutcomeEnum extends Enum<FelvTestOutcome> {

    static readonly FELV_POSITIVE = new FelvTestOutcome('FELV_POSITIVE', 'FeLV positive');
    static readonly FELV_NEGATIVE = new FelvTestOutcome('FELV_NEGATIVE', 'FeLV negative');

    static readonly ELEMENTS = [
        FelvTestOutcomeEnum.FELV_POSITIVE,
        FelvTestOutcomeEnum.FELV_NEGATIVE
    ];
    static readonly INSTANCE = new FelvTestOutcomeEnum();

    private constructor() {
        super(FelvTestOutcomeEnum.ELEMENTS);
    }
}
