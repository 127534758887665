import {Enum, EnumElement} from "../../Enum";

export class FosterTimePeriod extends EnumElement {
}

export class FosterTimePeriodEnum extends Enum<FosterTimePeriod> {

    static readonly SHORT_TERM = new FosterTimePeriod('SHORT_TERM', 'Short-term (<2 weeks)');
    static readonly MEDIUM_TERM = new FosterTimePeriod('MEDIUM_TERM', 'Medium-term (2-8 weeks)');
    static readonly LONG_TERM = new FosterTimePeriod('LONG_TERM', 'Long-term (2+ months)');

    static readonly ELEMENTS = [
        FosterTimePeriodEnum.SHORT_TERM,
        FosterTimePeriodEnum.MEDIUM_TERM,
        FosterTimePeriodEnum.LONG_TERM
    ];
    static readonly INSTANCE = new FosterTimePeriodEnum();

    private constructor() {
        super(FosterTimePeriodEnum.ELEMENTS);
    }
}
