import {EnumElement, Enum} from "../../../Enum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";

export class FelineTripleTestOutcome extends EnumElement {
    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        let felvOutcome = ConditionStatusEnum.UNKNOWN;
        let fivOutcome = ConditionStatusEnum.UNKNOWN;
        let heartwormOutcome = ConditionStatusEnum.UNKNOWN;

        switch (subType) {
            case FelineTripleTestOutcomeEnum.POSITIVE_ALL.view:
                felvOutcome = ConditionStatusEnum.POSITIVE;
                fivOutcome = ConditionStatusEnum.POSITIVE;
                heartwormOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case FelineTripleTestOutcomeEnum.POSITIVE_FELV_FIV.view:
                felvOutcome = ConditionStatusEnum.POSITIVE;
                fivOutcome = ConditionStatusEnum.POSITIVE;
                heartwormOutcome = ConditionStatusEnum.NEGATIVE;
                break;

            case FelineTripleTestOutcomeEnum.POSITIVE_FELV_HEARTWORM.view:
                felvOutcome = ConditionStatusEnum.POSITIVE;
                fivOutcome = ConditionStatusEnum.NEGATIVE;
                heartwormOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case FelineTripleTestOutcomeEnum.POSITIVE_FIV_HEARTWORM.view:
                felvOutcome = ConditionStatusEnum.NEGATIVE;
                fivOutcome = ConditionStatusEnum.POSITIVE;
                heartwormOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case FelineTripleTestOutcomeEnum.POSITIVE_FELV.view:
                felvOutcome = ConditionStatusEnum.POSITIVE;
                fivOutcome = ConditionStatusEnum.NEGATIVE;
                heartwormOutcome = ConditionStatusEnum.NEGATIVE;
                break;

            case FelineTripleTestOutcomeEnum.POSITIVE_FIV.view:
                felvOutcome = ConditionStatusEnum.NEGATIVE;
                fivOutcome = ConditionStatusEnum.POSITIVE;
                heartwormOutcome = ConditionStatusEnum.NEGATIVE;
                break;

            case FelineTripleTestOutcomeEnum.POSITIVE_HEARTWORM.view:
                felvOutcome = ConditionStatusEnum.NEGATIVE;
                fivOutcome = ConditionStatusEnum.NEGATIVE;
                heartwormOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case FelineTripleTestOutcomeEnum.NEGATIVE.view:
                felvOutcome = ConditionStatusEnum.NEGATIVE;
                fivOutcome = ConditionStatusEnum.NEGATIVE;
                heartwormOutcome = ConditionStatusEnum.NEGATIVE;
                break;

            default:
                break;
        }

        outcomes.push(MedicalConditionEnum.FELV.createCondition(felvOutcome));
        outcomes.push(MedicalConditionEnum.FIV.createCondition(fivOutcome));
        outcomes.push(MedicalConditionEnum.HEARTWORM.createCondition(heartwormOutcome));
    }
}

export class FelineTripleTestOutcomeEnum extends Enum<FelineTripleTestOutcome> {

    static readonly POSITIVE_ALL = new FelineTripleTestOutcome('POSITIVE_ALL', 'Positive: All');
    static readonly POSITIVE_FELV = new FelineTripleTestOutcome('POSITIVE_FELV', 'Positive: FeLV');
    static readonly POSITIVE_FIV = new FelineTripleTestOutcome('POSITIVE_FIV', 'Positive: FIV');
    static readonly POSITIVE_HEARTWORM = new FelineTripleTestOutcome('POSITIVE_HEARTWORM', 'Positive: Heartworm');
    static readonly POSITIVE_FELV_FIV = new FelineTripleTestOutcome('POSITIVE_FELV_FIV', 'Positive: FeLV & FIV');
    static readonly POSITIVE_FELV_HEARTWORM = new FelineTripleTestOutcome('POSITIVE_FELV_HEARTWORM', 'Positive: FeLV & Heartworm');
    static readonly POSITIVE_FIV_HEARTWORM = new FelineTripleTestOutcome('POSITIVE_FIV_HEARTWORM', 'Positive: FIV & Heartworm');
    static readonly NEGATIVE = new FelineTripleTestOutcome('NEGATIVE', 'Negative: All');

    static readonly ELEMENTS = [
        FelineTripleTestOutcomeEnum.POSITIVE_ALL,
        FelineTripleTestOutcomeEnum.POSITIVE_FELV,
        FelineTripleTestOutcomeEnum.POSITIVE_FIV,
        FelineTripleTestOutcomeEnum.POSITIVE_HEARTWORM,
        FelineTripleTestOutcomeEnum.POSITIVE_FELV_FIV,
        FelineTripleTestOutcomeEnum.POSITIVE_FELV_HEARTWORM,
        FelineTripleTestOutcomeEnum.POSITIVE_FIV_HEARTWORM,
        FelineTripleTestOutcomeEnum.NEGATIVE
    ];
    static readonly INSTANCE = new FelineTripleTestOutcomeEnum();

    private constructor() {
        super(FelineTripleTestOutcomeEnum.ELEMENTS);
    }
}
