import {Enum, EnumElement} from "../../Enum";
import {AnimalSpecies, AnimalSpeciesEnum} from "../animal/AnimalSpeciesEnum";
import {TenantEntity} from "../tenant/ITenant";

export class SpeciesPreference extends EnumElement {

    getSpecies(): AnimalSpecies {
        switch (this) {
            case SpeciesPreferenceEnum.DOGS:
                return AnimalSpeciesEnum.DOG;
            case SpeciesPreferenceEnum.CATS:
                return AnimalSpeciesEnum.CAT;
            case SpeciesPreferenceEnum.RABBITS:
                return AnimalSpeciesEnum.RABBIT;
            case SpeciesPreferenceEnum.GUINEA_PIGS:
                return AnimalSpeciesEnum.GUINEA_PIG;
            default:
                return null;
        }
    }
}

export class SpeciesPreferenceEnum extends Enum<SpeciesPreference> {

    static readonly DOGS = new SpeciesPreference('DOGS', 'Dogs');
    static readonly CATS = new SpeciesPreference('CATS', 'Cats');
    static readonly RABBITS = new SpeciesPreference('RABBITS', 'Rabbits');
    static readonly GUINEA_PIGS = new SpeciesPreference('GUINEA_PIGS', 'Guinea Pigs');

    static readonly ELEMENTS = [
        SpeciesPreferenceEnum.DOGS,
        SpeciesPreferenceEnum.CATS,
        SpeciesPreferenceEnum.RABBITS,
        SpeciesPreferenceEnum.GUINEA_PIGS
    ];
    static readonly INSTANCE = new SpeciesPreferenceEnum();

    constructor() {
        super(SpeciesPreferenceEnum.ELEMENTS);
    }
}

export function preferredSpecies(tenant: TenantEntity): Array<AnimalSpecies> {
    return tenant.speciesPreference.map((it) => SpeciesPreferenceEnum.INSTANCE.fromModel(it).getSpecies());
}

export function isPreferredSpecies(tenant: TenantEntity, species: AnimalSpecies): boolean {
    return preferredSpecies(tenant).some((it) => it === species);
}