import {Enum, EnumElement} from "../../Enum";

export class TrainerSpecialities extends EnumElement {}

export class TrainerSpecialitiesEnum extends Enum<TrainerSpecialities> {

    static readonly ANIMAL_AGGRESSION = new TrainerSpecialities('ANIMAL_AGGRESSION', 'Animal Aggression');
    static readonly BASIC_SKILLS = new TrainerSpecialities('BASIC_SKILLS', 'Basic Skills');
    static readonly HUMAN_AGGRESSION = new TrainerSpecialities('HUMAN_AGGRESSION', 'Human Aggression');
    static readonly OTHER_BEHAVIORAL = new TrainerSpecialities('OTHER_BEHAVIORAL', 'Other Behavioral Needs');
    static readonly TRAUMATIZED_ANIMALS = new TrainerSpecialities('TRAUMATIZED_ANIMALS', 'Traumatized Animals');

    static readonly ELEMENTS = [
        TrainerSpecialitiesEnum.ANIMAL_AGGRESSION,
        TrainerSpecialitiesEnum.BASIC_SKILLS,
        TrainerSpecialitiesEnum.HUMAN_AGGRESSION,
        TrainerSpecialitiesEnum.OTHER_BEHAVIORAL,
        TrainerSpecialitiesEnum.TRAUMATIZED_ANIMALS
    ];
    static readonly INSTANCE = new TrainerSpecialitiesEnum();

    constructor() {
        super(TrainerSpecialitiesEnum.ELEMENTS);
    }
}
