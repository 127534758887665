import {buildKey, buildMap, IntegrationMetadata} from "../IntegrationMetadata";

export function rabbitIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}


function translateBreed(sparkieBreed: string): string {
    let breedKey = buildKey(sparkieBreed);

    let petFinderBreed = rabbitBreedMap.get(breedKey);

    if (petFinderBreed) {
        return petFinderBreed;
    } else {
        switch (sparkieBreed) {
            default:
                return "";
        }
    }
}

function translateColor(sparkieColor: string): string {
    if (rabbitColors.has(sparkieColor)) {
        return sparkieColor;
    }
    switch (sparkieColor) {
        default:
            return "";
    }
}

const rabbitBreeds = new Set<string>([
    "American",
    "American Fuzzy Lop",
    "American Sable",
    "Angora Rabbit",
    "Belgian Hare",
    "Beveren",
    "Britannia Petite",
    "Bunny Rabbit",
    "Californian",
    "Champagne D'Argent",
    "Checkered Giant",
    "Chinchilla",
    "Cinnamon",
    "Creme D'Argent",
    "Dutch",
    "Dwarf",
    "Dwarf Eared",
    "English Lop",
    "English Spot",
    "Flemish Giant",
    "Florida White",
    "French Lop",
    "Harlequin",
    "Havana",
    "Himalayan",
    "Holland Lop",
    "Hotot",
    "Jersey Wooly",
    "Lilac",
    "Lionhead",
    "Lop Eared",
    "Mini Lop",
    "Mini Rex",
    "Netherland Dwarf",
    "New Zealand",
    "Palomino",
    "Polish",
    "Rex",
    "Rhinelander",
    "Satin",
    "Silver",
    "Silver Fox",
    "Silver Marten",
    "Tan",
]);

const rabbitBreedMap = buildMap(rabbitBreeds);

const rabbitColors = new Set<string>([
    "Agouti",
    "Black",
    "Silver Marten",
    "Blue / Gray",
    "Brown / Chocolate",
    "Cream",
    "Tan",
    "White",
    "Lilac",
    "Tortoiseshell",
    "Orange / Red",
    "Sable"
]);
