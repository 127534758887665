import moment from "moment";
import {query} from "gql-query-builder";
import IQueryBuilderOptions from "gql-query-builder/build/IQueryBuilderOptions";

import VariableOptions from "gql-query-builder/build/VariableOptions";
import {IQuery} from "./ArpResource";
import {SortDirection, SortDirectionEnum} from "./views/SortDirectionEnum";
import {Enum, EnumElement, ModelResource, ModelResourceEnum} from "@sparkie/shared-model/src";
import {toGQL} from "@sparkie/shared-model/src";
import set from "lodash/set";
import unset from "lodash/unset";
import get from "lodash/get";
import {capitalize, isObject, lowerCase, merge } from "lodash";

export type GQLQuery = {
    operation: string;      // Name of the query/mutation
    variables: any;         // vars
    query: string;          // projections
}

export class QueryBuilder implements IQuery {
    private readonly queryData: IQueryBuilderOptions;
    private readonly resource: ModelResource;

    constructor(resource: ModelResource) {
        this.resource = resource
        this.queryData = {
            operation: '',
            variables: {},
            fields: null
        };
    }

    buildByIdQuery(id: string): GQLQuery {

        if (this.resource !== ModelResourceEnum.TENANT) {
            this.addOrUpdateVariable('_id', id, 'MongoID!');
        }
        this.addOrUpdateVariable('offset', moment().utcOffset(), 'Int');
        this.addOrUpdateVariable('zone', Intl.DateTimeFormat().resolvedOptions().timeZone, "String")

        this.queryData.operation = `${this.queryResource}ById`;
        return this._buildQuery();
    }

    buildManyQuery(): GQLQuery {
        this.addOrUpdateVariable('offset', moment().utcOffset(), 'Int');
        this.addOrUpdateVariable('zone', Intl.DateTimeFormat().resolvedOptions().timeZone, "String")

        this.queryData.operation = `${this.queryResource}Many`;
        return this._buildQuery();
    }

    buildCountQuery(): GQLQuery {
        this.addOrUpdateVariable('offset', moment().utcOffset(), 'Int');
        this.addOrUpdateVariable('zone', Intl.DateTimeFormat().resolvedOptions().timeZone, "String")

        this.queryData.operation = `${this.queryResource}Count`;
        return this._buildQuery();
    }

    buildQuery(query: string, inputType?: string): GQLQuery {

        this.queryData.operation = query;
        return this._buildQuery();
    }


    withGQLProperties(gqlProperties: string[]): this {
        // Nested properties must be another array!
        let exploded = {};
        for (const path of gqlProperties) {
            set(exploded, path, 1);
        }

        this.queryData.fields = this.transformFields(exploded);
        return this;
    }

    withSelectProperties(selectProperties: string): this {

        // Nested properties must be another array!
        let exploded = {};
        for (const path of selectProperties.split(',')) {
            set(exploded, path, 1);
        }

        this.queryData.fields = this.transformFields(exploded);
        return this;
    }

    withLimit(limit: number): this {
        this.addOrUpdateVariable('limit', limit, 'Int');
        return this;
    }

    withSkip(skip: number): this {
        this.addOrUpdateVariable('skip', skip, 'Int');
        return this;
    }

    withGroup(groupProperty: any): this {

        // Used by data sources
        throw new Error("Method not implemented.");
    }

    //
    // Obsolete
    //

    withAggregate(aggregate: any): this {

        // Used by loading medications, procedures, vaccines, etc.
        throw new Error("Method not implemented.");
    }

    //
    // Sorting
    //

    withSort(sortProperty: any): this {
        // sortProperty = {'intake.date': -1}, or even {'intake.date': -1, 'name': 1}
        for (const [propertyPath, direction] of Object.entries(sortProperty)) {
            let sort = set({}, propertyPath, direction === 1 ? 'ASC' : 'DESC');
            this.addOrUpdateSort({ TERMS: [ sort ] });
        }
        return this;
    }

    withWeightedSort(weightedSort: boolean): this {
        this.addOrUpdateSort({ weighted: weightedSort });
        return this;
    }

    withEnumSort(sortProperty: string, sortDirection: SortDirection, enumeration: Enum<EnumElement>): this {
        // Used by Applications to sort on a projected property
        let sort = set({}, sortProperty, sortDirection === SortDirectionEnum.ASCENDING ? 'ASC_ENUM' : 'DESC_ENUM');
        this.addOrUpdateSort({ TERMS: [ sort ] });
        return this;
    }

    //
    // Search
    //

    withTextQuery(value: string): this {
        /*
        TODO: Sort by score (but work with ArpSort also?)
         db.articles.find(
             { $text: { $search: "cake" } },
             { score: { $meta: "textScore" } }
             ).sort( { score: { $meta: "textScore" } } )
         */

        let escapedValue = this.escapeSearchTerm(value).trim();

        if (escapedValue.length > 0) {
            this.addOrUpdateVariable('search', escapedValue, 'String');
        } else {
            this.removeVariable('search');
        }

        return this;
    }

    //
    // Property Match
    //

    withId(id: string): this {
        return this.withPropertyEqual("_id", id);
    }

    withPropertyEqual(propertyPath: string, propertyValue: any): this {
        let filter = { EQ: propertyValue };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyNotEqual(propertyPath: string, propertyValue: any): this {
        let filter = { NE: propertyValue };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyNull(propertyPath: string): this {
        let filter = { EQ: null };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyNotNull(propertyPath: string): this {
        let filter = { NE: null };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyIn(propertyPath: string, arrayOfStrings: string[]): this {
        let filter = { IN: arrayOfStrings };
        return this.withPropertyFilter(propertyPath, filter);
    }

    // withPropertyAllOf(propertyPath: string, arrayOfStrings: string[]): this {
    //     let filter = { ALL: arrayOfStrings };
    //     return this.withPropertyFilter(propertyPath, filter);
    // }

    withDatePropertyBefore(propertyPath: string, last: Date): this {
        let filter = {
            GTE: moment().subtract(50, "years").toDate(),
            LTE: last
        };

        return this.withPropertyFilter(propertyPath, filter);
    }

    withDatePropertyBetween(propertyPath: string, first: Date, last: Date): this {
        let filter = { GTE: first, LTE: last };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withDatePropertyAfter(propertyPath: string, first: Date): this {
        let filter = { GTE: first };
        return this.withPropertyFilter(propertyPath, filter);
    }

    // withArrayPropertyNotEmpty(propertyPath: string): this {
    //     let filter = { exists: true, NE: [] };
    //     return this.withPropertyFilter(propertyPath, filter);
    // }

    withAndTerms(termArray: any[]): this {
        const transformed = termArray.map((it) => toGQL(it) )
        return this.withPropertyFilter('AND', transformed);
    }

    withOrTerms(termArray: any[]) : this {
        // Convert from $xx operations to OP operations
        const transformed = termArray.map((it) => toGQL(it) )
        return this.withPropertyFilter('OR', transformed);
    }

    withPropertyFilter(propertyPath: string, filterTerm: any) : this {
        let newMatch = set({}, propertyPath, filterTerm);
        this.addOrUpdateMatch({AND: [ newMatch ]});

        return this;
    }

    //
    // Implementation
    //

    private addOrUpdateMatch(match: any) {
        this.addOrUpdateVariable('match', match, `Match${this.variableResource}Input`)
    }

    private addOrUpdateSort(sort: any) {
        this.addOrUpdateVariable('sort', sort, `Sort${this.variableResource}Input`)
    }

    private addOrUpdateVariable(name: string, value: any, type: string) {
        let variable: VariableOptions = get(this.queryData.variables, name, null);

        if (variable == null) {
            variable = { name: name, value: value, type: type }
            set(this.queryData.variables, name, variable);
        } else {
            merge(variable.value, value);
        }
    }

    private removeVariable(name: string) {
        let variable: VariableOptions = get(this.queryData.variables, name, null);

        if (variable != null) {
            unset(this.queryData.variables, name);
        }
    }

    private transformFields(object: any): any[] {
        let result = [];

        for (const [key, value] of Object.entries(object)) {
            if (isObject(value)) {
                const nested = this.transformFields(value);
                result.push( {
                    [key]: nested
                });
            } else {
                result.push(key);
            }
        }

        return result;
    }

    private escapeSearchTerm(term: string) : string {
        switch (term) {
            case 'true':
            case 'false':
                return `"${term}"`;

            default:
                return term;
        }
    }

    private _buildQuery(): GQLQuery {
        return {
            operation: this.queryData.operation,
            ...query(this.queryData)
        };

    }

    private get queryResource(): string {
        // Initial is lower
        return this.resource.model.split('_').map((it, number) => {
            return (number === 0) ? lowerCase(it) : capitalize(it);
        }).join('');
    }

    private get variableResource(): string {
        // Initial is upper
        return this.resource.model.split('_').map((it) => capitalize(it)).join('');
    }
}

export function buildSafeRegexTerm(query: string): any {
    // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex/6969486#6969486
    const safeQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

    return {
        $regex: `^${safeQuery}`,
        $options: 'i'
    }
}
