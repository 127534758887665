import { Decimal128 } from "bson";
import { Money } from "@sparkie/shared-model/src";

export class ArpCurrencyFormatValueConverter {
    toView(value: Decimal128 | Money, displaySymbol = "$") {
        if (isDecimal128(value)) {
            return Money.fromDecimal128(value as any).format(displaySymbol);
        } else if (value instanceof Money) {
            return value.format(displaySymbol);
        } else {
            return "";
        }
    }
}

function isDecimal128(value: any): value is Decimal128 {
    // TODO: This used to be `value instanceof Decimal128` but that doesn't work anymore,
    return value !== null && value !== undefined && Object.getPrototypeOf(value)["_bsontype"] === "Decimal128";
}
