import {AnimalSpecies, AnimalSpeciesEnum} from "../animal/AnimalSpeciesEnum";
import {IntegrationMetadata} from "./IntegrationMetadata";
import {dogIntegrationMetadata} from "./petfinder/DogMetadata";
import {catIntegrationMetadata} from "./petfinder/CatMetadata";
import {rabbitIntegrationMetadata} from "./petfinder/RabbitMetadata";
import {guineaPigIntegrationMetadata} from "./petfinder/GuineaPigMetadata";

export function getPetFinderMetadata(species: AnimalSpecies): IntegrationMetadata {
    switch (species) {
        case AnimalSpeciesEnum.DOG:
            return dogIntegrationMetadata();
        case AnimalSpeciesEnum.CAT:
            return catIntegrationMetadata();
        case AnimalSpeciesEnum.RABBIT:
            return rabbitIntegrationMetadata();
        case AnimalSpeciesEnum.GUINEA_PIG:
            return guineaPigIntegrationMetadata();
        default:
            return null;
    }
}
