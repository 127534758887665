import {bindable, inject, customElement} from 'aurelia-framework';
import {Observer} from '../Observer';
import {Enum, EnumElement} from "@sparkie/shared-model/src";
import isString from 'lodash/isString';
import isArray from "lodash/isArray";

@customElement("arp-multi-check-enum")
@inject(Observer)
export class ArpMultiCheckEnum {

    @bindable enumeration: Enum<EnumElement> | Array<EnumElement>;
    @bindable selection: Array<EnumElement> = [];   // Array of enumElements that is reset each time a bind occurs
    @bindable value: Array<string>;                 // Array of enumElement.model, in which we only add & remove selected items
    @bindable columnCount: number = 1;
    @bindable cclass: string;
    private observer: any;
    
    constructor(observer: any) {
        this.observer = observer;
        this.cclass = "col-md-12";
    }

    bind(bindingContext: any, overrideContext: any) {

        if (isString(this.columnCount)) {
            this.columnCount = Number(this.columnCount);
        }

        this.copyValuesToSelection();

        this.observer.observeArray(this.selection, (changes) => {
            this.copySelectionToValues();
        });

        this.observer.observeArray(this.value, (changes) => {
            this.copyValuesToSelection();
        });

        if (this.columnCount === 1) {
            this.cclass = "col-md-12";
        } else if (this.columnCount === 2) {
            this.cclass = "col-md-6";
        } else if (this.columnCount === 3) {
            this.cclass = "col-md-4";
        } else if (this.columnCount === 4) {
            this.cclass = "col-md-3";
        }
    }


    get elements(): Array<EnumElement> {
        if (isArray(this.enumeration)) {
            return this.enumeration;
        } else {
            return this.enumeration.elements;
        }
    }

    getElementsForColumn(columnIndex: number) : Array<EnumElement> {
        let elements = [];
        let elementsPerColumn = Math.floor(this.elements.length / this.columnCount) + (this.elements.length % this.columnCount);
        let firstIndex = columnIndex * elementsPerColumn;
        let lastIndex = Math.min(this.elements.length, firstIndex + elementsPerColumn);

        for (let index = firstIndex; index < lastIndex; index++) {
            let enumElement = this.elements[index];
            elements.push(enumElement)
        }
        
        return elements;
    }

    /**
     *  The entities we bind to don't understand Enums and thus use enumElement.model.  So we need to translate
        between enumElements and enumElement.models
     */
    copyValuesToSelection() {
        this.alignArrays(this.value, this.selection, (item) => {
            return this.fromModel(item);
        });
    }

    fromModel(model: string) : EnumElement {
        return this.elements.find((element) => element.model === model);
    }

    copySelectionToValues() {

        // Copy the selection into the value array
        this.alignArrays(this.selection, this.value, (item) => {
            return item.model;
        });
    }

    alignArrays(source: any[], target: any[], mapper: any) {

        // Make arrays the same length
        if (source.length !== target.length) {
            if (source.length === 0) {
                this.observer.safeClearArray(target);
            } else if (source.length < target.length) {
                let startIndex = source.length - 1;
                target.splice(startIndex);
            }
        }

        for (let index=0; index < source.length; index++) {

            let theValue = source[index];

            // Values are .models since the bind to Entities and are directly sent to the Server
            let theTranslatedValue = mapper ? mapper(theValue) : theValue;

            if (index < target.length) {
                // Existing index so update
                if (target[index] !== theTranslatedValue) {
                    target[index] = theTranslatedValue;
                }
            } else {
                // New index so push
                target.push(theTranslatedValue);
            }
        }
    }
}

