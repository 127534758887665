import EXIF from "exif-js";

export const JPEG_MIME_TYPE = "image/jpeg";
export const PNG_MIME_TYPE = "image/png";

export class ImageBuffer {
    public readonly exifData: ExifData;

    constructor(public readonly data: ArrayBuffer, public readonly mimeType: string) {
        this.data = data;
        this.mimeType = mimeType;
        this.exifData = new ExifData(data);
    }

    toBlob(): Blob {
        return new Blob([new DataView(this.data)], { type: this.mimeType });
    }

    toDataUrl(): string {
        let foo = new Uint8Array(this.data);
        let bar = foo.reduce((data, byte) => data + String.fromCharCode(byte), "");
        let base64 = `data:${this.mimeType};base64,` + btoa(bar);
    
        return base64;
    }    
}

export class ExifData {
    private readonly exifData: any;

    constructor(arrayBuffer: ArrayBuffer) {
        this.exifData = EXIF.readFromBinaryFile(arrayBuffer);
    }

    /*
        0x0112	Orientation	int16u	IFD0
        1 = Horizontal (normal)
        2 = Mirror horizontal
        3 = Rotate 180
        4 = Mirror vertical
        5 = Mirror horizontal and rotate 270 CW
        6 = Rotate 90 CW
        7 = Mirror horizontal and rotate 90 CW
        8 = Rotate 270 CW
    */
    get exifOrientation(): number {
        return this.exifData["Orientation"];
    }

    get exifWidth(): number {
        // 0xa002	ExifImageWidth	int16u:	ExifIFD	(called PixelXDimension by the EXIF spec.)
        return this.exifData["PixelXDimension"];
    }

    get exifHeight(): number {
        // 0xa003	ExifImageHeight	int16u:	ExifIFD	(called PixelYDimension by the EXIF spec.)
        return this.exifData["PixelYDimension"];
    }
}

export async function loadImageFromUrl(url: string): Promise<ImageBuffer> {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const mimeType = response.headers.get("Content-Type") || "";

    const imageBuffer = new ImageBuffer(arrayBuffer, mimeType);

    return imageBuffer;
}

export async function loadImageFromFile(file: File): Promise<ImageBuffer> {
    const arrayBuffer = await loadFile(file);
    const mimeType = getMimeType(file.name);
    const imageBuffer = new ImageBuffer(arrayBuffer, mimeType);

    return imageBuffer;
}

async function loadFile(file: File): Promise<ArrayBuffer> {
    const fileReader = new FileReader();

    const promise = new Promise<ArrayBuffer>((resolve, reject) => {
        fileReader.onload = (e: Event) => {
            resolve(fileReader.result as ArrayBuffer);
        };

        fileReader.onerror = () => {
            reject();
        };
    });

    fileReader.readAsArrayBuffer(file);

    return promise;
}

function getMimeType(fileName: string): string {
    if (fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
        return JPEG_MIME_TYPE;
    } else if (fileName.startsWith("data:image/jpeg") || fileName.startsWith("data:image/jpg")) {
        return JPEG_MIME_TYPE;
    } else {
        return PNG_MIME_TYPE;
    }
}

