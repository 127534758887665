import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import { Aurelia } from "aurelia-framework";
import { SessionManager } from "./auth/SessionManger";
import { PLATFORM } from "aurelia-pal";
import { ArpLogManager } from "./arp-framework";
import { ServerLogAppender } from "./arp-framework/log/appenders/ServerLogAppender";
import { ConsoleLogAppender } from "./arp-framework/log/appenders/ConsoleLogAppender";

declare global {
    const SPARKIE_VERSION: string;
}

// Only Once in your app you can set whether to enable hooks tracking or not.
setUseWhatChange(process.env.NODE_ENV === "development");

export async function configure(aurelia: Aurelia) {
    aurelia.use
        .standardConfiguration()
        .plugin(PLATFORM.moduleName("aurelia-validation"))
        .feature(PLATFORM.moduleName("arp-framework/index"));

    await aurelia.start();

    const sessionManager = aurelia.container.get(SessionManager);

    ArpLogManager.initialize([new ServerLogAppender(sessionManager), new ConsoleLogAppender(sessionManager)]);

    await sessionManager.load();
}
