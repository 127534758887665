import $ from 'jquery';

import {PLATFORM} from "aurelia-pal";
import {useView} from 'aurelia-framework';
import {FilterCategory} from "./FilterCategory";

@useView(PLATFORM.moduleName('./arp-custom-filter.html'))
export class CustomFilterView {

    categories = [];
    private tabs: any;

    constructor() {
    }

    addCategory(category: FilterCategory) {
        this.categories.push(category);
    }

    /**
     * Invoked when the view that contains the extension is attached to the DOM.
     */
    attached() {
        let theTab: any = $(this.tabs).find('a:first');
        theTab.tab('show'); // Select first tab
    }
}
