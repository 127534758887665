import {IReportConverter} from "./IReportConverter";
import {Age} from "../model/common/Age";


export class AgeConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {
        return Age.formatAsAge(modelValue);
    }
}

