import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IAnimalProfile {
    description: string;
    showInIntegrations: boolean;
}

@Serializable()
export class AnimalProfile implements IAnimalProfile {
    @JsonProperty() description: string = '';
    @JsonProperty() showInIntegrations: boolean = false;

    static getQueryFields(): string[] {
        return [
            'description',
            'showInIntegrations',
        ];
    }
}