import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ITransporterDetails {
    daysAvailable: Array<string>;       // DaysOfWeekEnum
    timeAvailable: Array<string>;       // TimeOfDayEnum
    roundtripDistance: Array<string>;   // TransportDistanceEnum
    carSize: Array<string>;             // TransportCarSizeEnum
}

@Serializable()
export class TransporterDetails implements ITransporterDetails {

    @JsonProperty() daysAvailable: Array<string> = [];       // DaysOfWeekEnum
    @JsonProperty() timeAvailable: Array<string> = [];       // TimeOfDayEnum
    @JsonProperty() roundtripDistance: Array<string> = [];   // TransportDistanceEnum
    @JsonProperty() carSize: Array<string> = [];             // TransportCarSizeEnum

    static getQueryFields(): string[] {
        return [
            'daysAvailable',
            'timeAvailable',
            'roundtripDistance',
            'carSize'
        ]
    }
}