import {buildKey, buildMap, IntegrationMetadata} from "../IntegrationMetadata";

export function dogIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}

function translateBreed(sparkieBreed: string): string {
    let breedKey = buildKey(sparkieBreed);

    let petFinderBreed = dogBreedMap.get(breedKey);

    if (petFinderBreed) {
        return petFinderBreed;
    }

    switch (sparkieBreed) {
        // Translated breed in adopt-a-pet
        case "Pit Bull Mix":
            return "Pit Bull Terrier";
        case "Australian Heeler":
            return "Blue Heeler";
        case "Shetland Sheepdog":
            return "Sheltie, Shetland Sheepdog";
        case "West Highland Terrier":
            return "Westie, West Highland White Terrier";
        case "English Foxhound":
            return "Foxhound";
        case "Poodle":
            return "Poodle (Standard)";
        case "Saint Bernard":
            return "St. Bernard";
        case "Yorkshire Terrier":
            return "Yorkie, Yorkshire Terrier";
        case "Miniature Greyhound":
            return "Italian Greyhound";

        // Should map to breed + mix = true
        case "Hound Mix":
            return "Hound (Unknown Type)";
        case "Shepherd Mix":
            return "Shepherd (Unknown Type)";
        case "Terrier Mix":
            return "Terrier (Unknown Type, Small)";
        case "Retriever Mix":
            return "Retriever (Unknown Type)";


        case "Aruba Cunucu":
            return "Hound (Unknown Type)";      // Is there a better match?
        default:
            return "";
    }
}

function translateColor(sparkieColor: string): string {
    if (dogColors.has(sparkieColor)) {
        return sparkieColor;
    }

    switch (sparkieColor) {
        case "Red / Chestnut / Orange":
        case "Golden":
            return "Red/Golden/Orange/Chestnut";

        case "Brown / Chocolate":
            return "Brown/Chocolate";
        case "Tricolor (Brown, Black, & White)":
            return "Tricolor (Tan/Brown & Black & White)";
        case "Gray / Blue / Silver":
            return "Gray/Blue/Silver/Salt & Pepper";

        case "White / Cream":
            return "White";
        case "Merle (Red)":
        case "Merle (Blue)":
            return "Merle";
        case "Yellow / Tan / Blond / Fawn":
            return "Tan/Yellow/Fawn";

        case "Apricot / Beige":
            return "Tan/Yellow/Fawn";
        case "Harlequin":
            return "White - with Black";
        case "Bicolor":
            return "Black - with White";

        case "Sable":
            return "Gray/Silver/Salt & Pepper - with Black";

        default:
            return "";
    }
}

const dogColors = new Set<string>([
    "Black",
    "Black - with Tan, Yellow or Fawn",
    "Black - with White",
    "Brindle",
    "Brindle - with White",
    "Brown/Chocolate",
    "Brown/Chocolate - with Black",
    "Brown/Chocolate - with White",
    "Red/Golden/Orange/Chestnut",
    "Red/Golden/Orange/Chestnut - with Black",
    "Red/Golden/Orange/Chestnut - with White",
    "Silver & Tan (Yorkie colors)",
    "Tan/Yellow/Fawn",
    "Tan/Yellow/Fawn - with White",
    "Tricolor (Tan/Brown & Black & White)",
    "White",
    "White - with Black",
    "White - with Brown or Chocolate",
    "Black - with Brown, Red, Golden, Orange or Chestnut",
    "Black - with Gray or Silver",
    "Brown/Chocolate - with Tan",
    "Gray/Blue/Silver/Salt & Pepper",
    "Gray/Silver/Salt & Pepper - with White",
    "Gray/Silver/Salt & Pepper - with Black",
    "Merle",
    "Tan/Yellow/Fawn - with Black",
    "White - with Tan, Yellow or Fawn",
    "White - with Red, Golden, Orange or Chestnut",
    "White - with Gray or Silver",
]);

const dogBreeds = new Set<string>([
    "Affenpinscher",
    "Afghan Hound",
    "Airedale Terrier",
    "Akbash",
    "Akita",
    "Alaskan Malamute",
    "American Bulldog",
    "American Eskimo Dog",
    "American Hairless Terrier",
    "American Pit Bull Terrier",
    "American Staffordshire Terrier",
    "American Water Spaniel",
    "Anatolian Shepherd",
    "Australian Cattle Dog",
    "Australian Kelpie",
    "Australian Shepherd",
    "Australian Terrier",

    "Basenji",
    "Basset Griffon Vendeen",
    "Basset Hound",
    "Beagle",
    "Bearded Collie",
    "Beauceron",
    "Bedlington Terrier",
    "Belgian Laekenois",
    "Belgian Malinois",
    "Belgian Shepherd",
    "Belgian Tervuren",
    "Bernese Mountain Dog",
    "Bichon Frise",
    "Black Mouth Cur",
    "Black and Tan Coonhound",
    "Bloodhound",
    "Blue Heeler",
    "Blue Lacy/Texas Lacy",
    "Bluetick Coonhound",
    "Bobtail",
    "Bolognese",
    "Border Collie",
    "Border Terrier",
    "Borzoi",
    "Boston Terrier",
    "Bouvier des Flandres",
    "Boxer",
    "Boykin Spaniel",
    "Briard",
    "Brittany",
    "Brussels Griffon",
    "Bull Terrier",
    "Bulldog",
    "Bullmastiff",

    "Cairn Terrier",
    "Canaan Dog",
    "Canary Dog",
    "Cane Corso",
    "Cardigan Welsh Corgi",
    "Carolina Dog",
    "Catahoula Leopard Dog",
    "Cattle Dog",
    "Cavalier King Charles Spaniel",
    "Chesapeake Bay Retriever",
    "Chihuahua",
    "Chinese Crested",
    "Chow Chow",
    "Clumber Spaniel",
    "Cockapoo",
    "Cocker Spaniel",
    "Collie",
    "Coonhound",
    "Coonhound (Unknown Type)",
    "Corgi",
    "Coton de Tulear",
    "Curly-Coated Retriever",

    "Dachshund",
    "Dalmatian",
    "Dandie Dinmont Terrier",
    "Deerhound",
    "Doberman Pinscher",
    "Dogo Argentino",
    "Dogue de Bordeaux",
    "Dutch Shepherd",

    "English (Redtick) Coonhound",
    "English Bulldog",
    "English Mastiff",
    "English Pointer",
    "English Setter",
    "English Sheepdog",
    "English Shepherd",
    "English Springer Spaniel",
    "English Toy Spaniel",
    "Entlebucher",
    "Eskimo Dog",
    "Eskimo Spitz",

    "Feist",
    "Field Spaniel",
    "Fila Brasileiro",
    "Finnish Lapphund",
    "Finnish Spitz",
    "Flat-Coated Retriever",
    "Fox Terrier (Smooth)",
    "Fox Terrier (Toy)",
    "Fox Terrier (Wirehaired)",
    "Foxhound",
    "French Bulldog",
    "French Mastiff",

    "German Pinscher",
    "German Shepherd Dog",
    "German Shorthaired Pointer",
    "German Wirehaired Pointer",
    "Giant Schnauzer",
    "Glen of Imaal Terrier",
    "Golden Retriever",
    "Goldendoodle",
    "Gordon Setter",
    "Great Dane",
    "Great Pyrenees",
    "Greater Swiss Mountain Dog",
    "Greyhound",

    "Halden Hound (Haldenstrover)",
    "Harrier",
    "Havanese",
    "Hound (Unknown Type)",
    "Hovawart",
    "Hungarian Puli",
    "Hungarian Water Dog",
    "Husky",

    "Ibizan Hound",
    "Irish Setter",
    "Irish Terrier",
    "Irish Water Spaniel",
    "Irish Wolfhound",
    "Italian Greyhound",
    "Italian Spinone",

    "Jack Russell Terrier",
    "Japanese Chin",
    "Jindo",

    "Kai Dog",
    "Karelian Bear Dog",
    "Keeshond",
    "Kerry Blue Terrier",
    "King Charles Spaniel",
    "Kishu",
    "Komondor",
    "Kuvasz",
    "Kyi Leo",

    "Labradoodle",
    "Labrador Retriever",
    "Lakeland Terrier",
    "Lancashire Heeler",
    "Leonberger",
    "Lhasa Apso",
    "Löwchen",

    "Maltese",
    "Manchester Terrier",
    "Maremma Sheepdog",
    "Mastiff",
    "Mexican Hairless",
    "Miniature Pinscher",
    "Miniature Poodle",
    "Miniature Schnauzer",
    "Mountain Cur",
    "Munsterlander",

    "Neapolitan Mastiff",
    "Newfoundland",
    "Norfolk Terrier",
    "Norwegian Buhund",
    "Norwegian Elkhound",
    "Norwich Terrier",
    "Nova Scotia Duck-Tolling Retriever",

    "Old English Sheepdog",
    "Otterhound",

    "Papillon",
    "Parson Russell Terrier",
    "Patterdale Terrier (Fell Terrier)",
    "Pekingese",
    "Pembroke Welsh Corgi",
    "Petit Basset Griffon Vendeen",
    "Pharaoh Hound",
    "Pit Bull Terrier",
    "Plott Hound",
    "Podengo Portugueso",
    "Pointer",
    "Polish Lowland Sheepdog",
    "Pomeranian",
    "Poodle (Miniature)",
    "Poodle (Standard)",
    "Poodle (Toy or Tea Cup)",
    "Portuguese Water Dog",
    "Pug",
    "Puli",
    "Pumi",
    "Presa Canario",

    "Rat Terrier",
    "Redbone Coonhound",
    "Redtick Coonhound",
    "Retriever (Unknown Type)",
    "Rhodesian Ridgeback",
    "Rottweiler",
    "Russian Wolfhound",

    "Saluki",
    "Samoyed",
    "Schiller Hound",
    "Schipperke",
    "Schnauzer (Giant)",
    "Schnauzer (Miniature)",
    "Schnauzer (Standard)",
    "Scottie, Scottish Terrier",
    "Scottish Deerhound",
    "Sealyham Terrier",
    "Setter (Unknown Type)",
    "Shar Pei",
    "Sheltie, Shetland Sheepdog",
    "Shepherd (Unknown Type)",
    "Shiba Inu",
    "Shih Tzu",
    "Siberian Husky",
    "Silky Terrier",
    "Skye Terrier",
    "Sloughi",
    "Smooth Fox Terrier",
    "Spaniel (Unknown Type)",
    "Spitz (Unknown Type, Large)",
    "Spitz (Unknown Type, Medium)",
    "Spitz (Unknown Type, Small)",
    "Springer Spaniel",
    "St. Bernard",
    "Staffordshire Bull Terrier",
    "Standard Poodle",
    "Standard Schnauzer",
    "Sussex Spaniel",
    "Swedish Vallhund",

    "Tea Cup Poodle",
    "Terrier (Unknown Type, Medium)",
    "Terrier (Unknown Type, Small)",
    "Thai Ridgeback",
    "Tibetan Mastiff",
    "Tibetan Spaniel",
    "Tibetan Terrier",
    "Tosa Inu",
    "Toy Fox Terrier",
    "Toy Poodle",
    "Treeing Walker Coonhound",

    "Vizsla",

    "Weimaraner",
    "Welsh Corgi",
    "Welsh Springer Spaniel",
    "Welsh Terrier",
    "Westie, West Highland White Terrier",
    "Wheaten Terrier",
    "Whippet",
    "Wirehaired Fox Terrier",
    "Wirehaired Pointing Griffon",

    "Xoloitzcuintle/Mexican Hairless",

    "Yorkie, Yorkshire Terrier",
]);

const dogBreedMap = buildMap(dogBreeds);

