import {Enum, EnumElement} from "../../Enum";

export class PersonAlreadyHasStatus extends EnumElement {}

/**
 * Used in Person history to show Animal actions from the perspective of the Person.
 */
export class PersonAlreadyHasStatusEnum extends Enum<PersonAlreadyHasStatus> {

    static readonly ADOPTED = new PersonAlreadyHasStatus('ADOPTED', 'Adopted');
    static readonly FOSTER = new PersonAlreadyHasStatus('FOSTER', 'Fostered');

    static readonly ELEMENTS = [
        PersonAlreadyHasStatusEnum.ADOPTED,
        PersonAlreadyHasStatusEnum.FOSTER
    ];
    static readonly INSTANCE = new PersonAlreadyHasStatusEnum();

    private constructor() {
        super(PersonAlreadyHasStatusEnum.ELEMENTS);
    }
}
