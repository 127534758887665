import $ from 'jquery';

import {ArpViewState} from "../ArpViewState";

export class ArpScroller {
    private viewState: ArpViewState;

    constructor(viewState: ArpViewState) {
        this.viewState = viewState;
    }

    scrollToTop() {
        $(".page-host").scrollTop(0);
    }

    restoreState() {

        let top = this.viewState.restore('scrollData.top') as number || 0;

        $(".page-host").scrollTop(top);
    }
}
