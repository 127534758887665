import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IQuestionMetadata {

    /**
     * Text of the question (will be in the Label)
     */
    question: string;

    /**
     * If enabled then an answer is required to submit the application
     */
    answerRequired: boolean;

    /**
     * Model values from AnswerTypeEnum
     */
    answerType: string;

    /**
     * Choices that only apply when answerType is SINGLE_CHOICE or MULTIPLE_CHOICE
     */
    choiceList: Array<string>;
}

@Serializable()
export class QuestionMetadata implements IQuestionMetadata {
    @JsonProperty() question: string = '';
    @JsonProperty() answerRequired: boolean = false;
    @JsonProperty() answerType: string = '';                // AnswerTypeEnum
    @JsonProperty() choiceList: Array<string> = [];

    static getQueryFields(): string[] {
        return [
            'question',
            'answerRequired',
            'answerType',
            'choiceList'
        ]
    }
}