import {EnumElement, Enum} from "../../Enum";

export class TransferSourceStatus extends EnumElement {}

export class TransferSourceStatusEnum extends Enum<TransferSourceStatus> {

    static readonly PENDING = new TransferSourceStatus('PENDING', 'Pending');
    static readonly ACCEPTED = new TransferSourceStatus('ACCEPTED', 'Accepted');

    static readonly ELEMENTS = [
        TransferSourceStatusEnum.PENDING,
        TransferSourceStatusEnum.ACCEPTED
    ];
    static readonly INSTANCE = new TransferSourceStatusEnum();

    private constructor() {
        super(TransferSourceStatusEnum.ELEMENTS);
    }
}
