import { Appender } from "./Appender";

/**
 * A logger logs messages to a set of appenders, depending on the log level that is set.
 */
export class Logger {
    constructor(readonly id: string, readonly appenders: Appender[]) {}

    /**
     * Logs a debug message.
     *
     * @param message The message to log.
     * @param rest The data to log.
     */
    debug(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.debug(this, message, ...rest);
        }
    }

    /**
     * Logs info.
     *
     * @param message The message to log.
     * @param rest The data to log.
     */
    info(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.info(this, message, ...rest);
        }
    }

    /**
     * Logs navigation information.
     *
     * @param message
     * @param rest
     */
    nav(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.nav(this, message, ...rest);
        }
    }

    /**
     * Logs a warning.
     *
     * @param message The message to log.
     * @param rest The data to log.
     */
    warn(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.warn(this, message, ...rest);
        }
    }

    /**
     * Logs an error.
     *
     * @param message The message to log.
     * @param rest The data to log.
     */
    apiError(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.apiError(this, message, ...rest);
        }
    }

    /**
     * Logs an error.
     *
     * @param message The message to log.
     * @param rest The data to log.
     */
    error(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.error(this, message, ...rest);
        }
    }

    /**
     * Increases indentation of subsequent lines by two spaces.
     * If one or more `label`s are provided, those are printed first without the additional indentation.
     */
    group(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.group(this, message, ...rest);
        }
    }

    /**
     * The `groupCollapsed()` function is an alias for {@link console.group()}.
     */
    groupCollapsed(message: string, ...rest: any[]): void {
        for (const appender of this.appenders) {
            appender.groupCollapsed(this, message, ...rest);
        }
    }

    /**
     * Decreases indentation of subsequent lines by two spaces.
     */
    groupEnd(): void {
        for (const appender of this.appenders) {
            appender.groupEnd();
        }
    }
}

// TODO: Stop using ArpLogger and use the Logger instead
export type ArpLogger = Logger;
