import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IVolunteerDetails {
    daysAvailable: Array<string>;       // DaysOfWeekEnum
    timeAvailable: Array<string>;       // TimeOfDayEnum
    hoursPerWeek: string;               // VolunteerHoursPerWeekEnum
    specialSkills: Array<string>;       // VolunteerSkillsEnum
    interests: Array<string>;           // VolunteerInterestsEnum
    attendedTraining: boolean;
}

@Serializable()
export class VolunteerDetails implements IVolunteerDetails {

    @JsonProperty() daysAvailable: Array<string> = [];       // DaysOfWeekEnum
    @JsonProperty() timeAvailable: Array<string> = [];       // TimeOfDayEnum
    @JsonProperty() hoursPerWeek: string = '';               // VolunteerHoursPerWeekEnum
    @JsonProperty() specialSkills: Array<string> = [];       // VolunteerSkillsEnum
    @JsonProperty() interests: Array<string> = [];           // VolunteerInterestsEnum
    @JsonProperty() attendedTraining: boolean = false;

    static getQueryFields(): string[] {
        return [
            'daysAvailable',
            'timeAvailable',
            'hoursPerWeek',
            'specialSkills',
            'interests',
            'attendedTraining'
        ]
    }
}