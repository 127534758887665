import { ModelResource } from "./ModelResourceEnum";
import { evaluateAttributes, SparkieAccessControl } from "./SparkieAccessControl";
import { UserEntity, UserSummary } from "../model/user/IUser";
import { UserRole, UserRoleEnum } from "../model/user/UserRoleEnum";

const authorizationService: SparkieAccessControl = new SparkieAccessControl();

export class RBAC {

    constructor(readonly activeRole: UserRole, readonly activeUser: UserEntity, readonly resource: ModelResource) {
    }

    //
    // RBAC helpers.  NOTE: The activeRole/activeUser can't change without reloading the UI.
    //

    canReadAny() {
        return authorizationService.can(this.activeRole).readAny(this.resource.model).granted;
    }

    canReadOwn() {
        return authorizationService.can(this.activeRole).readOwn(this.resource.model).granted;
    }

    canUpdateAny(attributes: Array<string> = null) {
        const permission = authorizationService.can(this.activeRole).updateAny(this.resource.model);

        if (permission.granted) {
            return evaluateAttributes(permission, attributes);
        } else {
            return false;
        }
    }

    canUpdateOwn(attributes: Array<string> = null) {
        const permission = authorizationService.can(this.activeRole).updateOwn(this.resource.model);

        if (permission.granted) {
            return evaluateAttributes(permission, attributes);
        } else {
            return false;
        }
    }

    canUpdateUser(userToUpdate: UserSummary, attributes: Array<string>): boolean {
        if (userToUpdate === null) {
            return this.canUpdateAny(attributes);
        } else if (userToUpdate.username === this.activeUser.username) {
            return this.canUpdateOwn(attributes);
        } else if (userToUpdate.role === UserRoleEnum.OWNER.model) {
            return userToUpdate.username === this.activeUser.username;
        } else {
            return this.canUpdateAny(attributes);
        }
    }

    canCreateAny() {
        return authorizationService.can(this.activeRole).createAny(this.resource.model).granted;
    }

    canCreateOwn() {
        return authorizationService.can(this.activeRole).createOwn(this.resource.model).granted;
    }

    canDeleteAny() {
        return authorizationService.can(this.activeRole).deleteAny(this.resource.model).granted;
    }

    canDeleteOwn() {
        return authorizationService.can(this.activeRole).deleteOwn(this.resource.model).granted;
    }

    canDeleteUser(userToDelete: UserSummary): boolean {
        if (userToDelete.role === UserRoleEnum.OWNER.model) {
            // Can't delete the owner
            return false;
        } else if (userToDelete.username === this.activeUser.username) {
            return this.canDeleteOwn();
        } else {
            return this.canDeleteAny();
        }
    }

    canDeleteFile(): boolean {
        switch (this.activeRole) {
            case UserRoleEnum.ADMIN:
            case UserRoleEnum.OWNER:
                return true;

            default:
                return false;
        }
    }
}
