import { Action, ArpMessage, ArpModal, Container } from "arp-framework";

export function showReloadRequired() {
    const description = "Your session has expired.  Click refresh to reload.";
    const confirmationView = new ArpMessage(description);
    const modalService = Container.instance.get(ArpModal);

    let reloadAction = new Action()
        .withLabel("Refresh")
        .withPerformCallback( async () => {
            window.localStorage.removeItem("version-update-needed"); 
            window.location.reload(); 
        });

    // "Disable" the cancel button
    let customCancelAction = new Action()
        .withPerformCallback( async () => {});

    modalService.show("Session Expired", confirmationView, [reloadAction], [], false, customCancelAction);
}
