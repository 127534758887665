import {EnumElement, Enum} from "../../Enum";

export class AnimalIntakeMode extends EnumElement {}

export class AnimalIntakeModeEnum extends Enum<AnimalIntakeMode> {

    /** Animal was a stray and had no guardian */
    static readonly STRAY = new AnimalIntakeMode('STRAY', 'Stray/At Large');

    /** Animal was relinquised by the owner */
    static readonly RELINQUISHED = new AnimalIntakeMode('RELINQUISHED', 'Relinquished by Owner');

    /** Animal was to be euthanised by the owner and so was surrendered instead */
    static readonly EUTHANASIA = new AnimalIntakeMode('EUTHANASIA', 'Owner Intended Euthanasia');

    /** Animal was transferred from an agency */
    static readonly TRANSFERRED = new AnimalIntakeMode('TRANSFERRED', 'Transferred from Agency');

    /** Animal was born to an animal in the care of the rescue */
    static readonly BORN_IN_CARE = new AnimalIntakeMode('BORN_IN_CARE', 'Born in Care');

    /** Animal was transferred from another Sparke rescue */
    static readonly SPARKIE_TRANSFER = new AnimalIntakeMode('SPARKIE_TRANSFER', 'Sparkie Transfer');

    /** Animal intake did not match any exiting mode */
    static readonly OTHER = new AnimalIntakeMode('OTHER', 'Other Intake');

    static readonly ELEMENTS = [
        AnimalIntakeModeEnum.STRAY,
        AnimalIntakeModeEnum.RELINQUISHED,
        AnimalIntakeModeEnum.EUTHANASIA,
        AnimalIntakeModeEnum.TRANSFERRED,
        AnimalIntakeModeEnum.BORN_IN_CARE,
        AnimalIntakeModeEnum.SPARKIE_TRANSFER,
        AnimalIntakeModeEnum.OTHER
    ];
    static readonly INSTANCE = new AnimalIntakeModeEnum();

    private constructor() {
        super(AnimalIntakeModeEnum.ELEMENTS);
    }
}
