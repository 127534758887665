import {ApplicationTemplateEntity} from "./IApplicationTemplate";
import {AnswerTypeEnum} from "./AnswerTypeEnum";
import {ICategoryMetadata} from "./ICategoryMetadata";
import {ApplicationTypeEnum} from "./ApplicationTypeEnum";
import {IQuestionMetadata} from "../common/IQuestionMetadata";
import {TenantEntity} from "../tenant/ITenant";
import {preferredSpecies} from "../person/SpeciesPreferenceEnum";

// TODO: Make this a simple function that returns a template object.
export class ApplicationTemplateFactory {

    /**
     * Load the default sections into the template based on the template type.
     * 
     * @param template the template to load the default sections into.
     * @param tenant the active tenant.  Used for personalizing the template.
     * 
     * @returns the same template object that was passed in with the default sections loaded.
     */
    public static loadDefaults(template: ApplicationTemplateEntity, tenant: TenantEntity): ApplicationTemplateEntity {
        const tenantName = tenant.name;
        const species: Array<string> = preferredSpecies(tenant).map(s => s.view);

        switch (template.type) {
            case ApplicationTypeEnum.DOG_ADOPT.model:
                ApplicationTemplateFactory.loadAdoptWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadDefaultPetCareDog(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;
            case ApplicationTypeEnum.CAT_ADOPT.model:
                ApplicationTemplateFactory.loadAdoptWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadDefaultPetCareOthers(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;
            case ApplicationTypeEnum.RABBIT_ADOPT.model:
                ApplicationTemplateFactory.loadFosterWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadDefaultPetCareOthers(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;
            case ApplicationTypeEnum.GUINEA_PIG_ADOPT.model:
                ApplicationTemplateFactory.loadFosterWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadDefaultPetCareOthers(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;

            case ApplicationTypeEnum.DOG_FOSTER.model:
                ApplicationTemplateFactory.loadFosterWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadFosterExperience(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;
            case ApplicationTypeEnum.CAT_FOSTER.model:
                ApplicationTemplateFactory.loadFosterWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadFosterExperience(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;
            case ApplicationTypeEnum.RABBIT_FOSTER.model:
                ApplicationTemplateFactory.loadFosterWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadFosterExperience(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;
            case ApplicationTypeEnum.GUINEA_PIG_FOSTER.model:
                ApplicationTemplateFactory.loadFosterWelcome(template, tenantName);
                ApplicationTemplateFactory.loadDefaultHomeInfo(template);
                ApplicationTemplateFactory.loadFosterExperience(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;

            case ApplicationTypeEnum.VOLUNTEER.model:
                ApplicationTemplateFactory.loadVolunteerWelcome(template, tenantName);
                ApplicationTemplateFactory.loadVolunteerBackground(template);
                ApplicationTemplateFactory.loadDefaultReferences(template);
                ApplicationTemplateFactory.loadStandardDisclaimer(template, tenantName);
                break;

            case ApplicationTypeEnum.SURRENDER.model:
                ApplicationTemplateFactory.loadSurrenderWelcome(template, tenantName);
                ApplicationTemplateFactory.loadSurrenderPetInfo(template, species);
                ApplicationTemplateFactory.loadSurrenderPetHistory(template);
                ApplicationTemplateFactory.loadSurrenderPetBehavior(template);
                ApplicationTemplateFactory.loadSurrenderDisclaimer(template, tenantName);
                break;

            default:
                throw new Error("Unsupported ApplicationTemplate type")
        }

        return template;
    }

    private static loadAdoptWelcome(template: ApplicationTemplateEntity, tenantName: string) {
        template.welcome = `<p>Thank you for applying to adopt a pet from ${tenantName}!</p><h1></h1><p>Please note it typically takes at least 7-14 days to process your application (including reference checks and home visit). If you haven't heard from a rescue representative after 7 days, please do email us to catch up.</p><br><p><strong>IMPORTANT</strong>: If you're planning on attending an adoption event please fill out our application 1-2 days beforehand to expedite the adoption process. Thank you!</p>`;
    }

    private static loadFosterWelcome(template: ApplicationTemplateEntity, tenantName: string) {
        template.welcome = `<p>Thank you for applying to foster a pet from ${tenantName}!</p><h1></h1><p>Please note it typically takes at least 7-14 days to process your application (including reference checks and home visit). If you haven't heard from a rescue representative after 7 days, please do email us to catch up.</p><br>`;
    }

    private static loadVolunteerWelcome(template: ApplicationTemplateEntity, tenantName: string) {
        template.welcome = `<p>Thank you for applying to volunteer for ${tenantName}!</p><h1></h1><p>Please note it typically takes at least 7-14 days to process your application (including reference checks). If you haven't heard from a rescue representative after 7 days, please do email us to catch up.</p><br>`;
    }

    private static loadSurrenderWelcome(template: ApplicationTemplateEntity, tenantName: string) {
        template.welcome = `<p>Thank you for applying to volunteer for ${tenantName}!</p><h1></h1><p>Please note it typically takes at least 7-14 days to process your application (including reference checks). If you haven't heard from a rescue representative after 7 days, please do email us to catch up.</p><br>`;
    }

    private static loadStandardDisclaimer(template: ApplicationTemplateEntity, tenantName: string) {
        template.disclaimer = `By submitting my application, I acknowledge that my information will be stored with ${tenantName} and I have accurately represented all answers above.`;
    }

    private static loadSurrenderDisclaimer(template: ApplicationTemplateEntity, tenantName: string) {
        template.disclaimer = `By submitting this application, I certify that the above information is true and accurate to the best of my knowledge and authorize ${tenantName} to contact my veterinarian and others I have listed, and I understand that my completion of this form in no way obligates the acceptance of my pet into the rescue.  `
    }

    private static loadDefaultHomeInfo(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Home Environment",
            questions: [
                this.createTextQuestion("Please describe your type of residence (e.g. own a home, rent an apartment, etc.)"),
                this.createTextQuestion("How long have you lived at your current address?"),
                this.createTextQuestion("If you rent, please list your Landlord’s full name and contact information (phone and/or email)", false),
                this.createTextQuestion("Please list the full names and ages of all adults and children currently living in your household"),
                this.createTextQuestion("Please list all your current and past pets (up to 5 years ago), including ages, breed, and current medical status")
            ]
        } as ICategoryMetadata);
    }

    private static loadDefaultPetCareDog(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Care for your Pet",
            questions: [
                this.createTextQuestion("Why are you interested in adopting this animal?"),
                this.createTextQuestion("How much do you plan to budget for your pet’s care each month?"),
                this.createTextQuestion("What is your plan to exercise your pet?"),
                this.createTextQuestion("Where will your pet be kept when alone?"),
                this.createTextQuestion("How many hours a day will your pet be left alone?"),
                this.createTextQuestion("What is your plan for when the pet will be left alone longer than 6 hrs?"),
                this.createTextQuestion("Where do you plan for your pet to sleep?"),
                this.createTextQuestion("In what circumstances would you not keep your new pet?"),
                this.createTextQuestion("Who will be primarily responsible for your pet?"),
                this.createTextQuestion("Why should we adopt to you?")
            ]
        } as ICategoryMetadata);
    }

    private static loadDefaultPetCareOthers(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Care for your Pet",
            questions: [
                this.createTextQuestion("How much do you plan to budget for your pet’s care each month?"),
                this.createTextQuestion("Why are you interested in adopting this animal?"),
                this.createTextQuestion("In what circumstances would you not keep your new pet?"),
                this.createTextQuestion("Who will be primarily responsible for your pet?"),
                this.createTextQuestion("Why should we adopt to you?")
            ]
        } as ICategoryMetadata);
    }

    private static loadDefaultReferences(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "References",
            questions: [
                this.createTextQuestion("Reference 1. Include First and Last Name; Relationship to you (no family members), and Email and Phone"),
                this.createTextQuestion("Reference 2. Include First and Last Name; Relationship to you (no family members), and Email and Phone"),
                this.createTextQuestion("Reference 3. Include First and Last Name; Relationship to you (no family members), and Email and Phone"),
            ]
        } as ICategoryMetadata);
    }

    private static loadFosterExperience(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Foster Experience",
            questions: [
                this.createTextQuestion("Why are you interested in fostering with us?"),
                this.createTextQuestion("If you have fostered an animal before, tell us a little bit about your experience."),
                this.createTextQuestion("If approved, how long are you able to commit to fostering an animal for?"),
            ]
        } as ICategoryMetadata);
    }

    private static loadVolunteerBackground(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Volunteer Background",
            questions: [
                this.createTextQuestion("Why are you interested in volunteering with our rescue?"),
                this.createTextQuestion("Have you volunteered with any animal rescue organizations in the past? If so, tell us a bit about that experience!"),
                this.createMultipleChoiceQuestion(
                    "What areas of volunteering are you interested in?", [
                        "Meet and Greet",
                        "Adoptions",
                        "Intake",
                        "Administrative Support",
                        "Social Media management",
                        "PR",
                        "Transportation",
                        "Community Events",
                        "Finance"
                    ],
                    false
                ),
                this.createMultipleChoiceQuestion(
                    "Do you have any other specific areas of expertise or skills that may be helpful to our rescue?", [
                        "Accounting/Finance",
                        "Administrative Skills",
                        "Event Planning",
                        "Fundraising",
                        "Graphic Artist",
                        "Information Technology (IT)",
                        "Marketing",
                        "Photographer",
                        "Public Relations",
                        "Social Media (Facebook/Instagram/Twitter)",
                        "Vet Tech",
                        "Veterinarian",
                        "Writer/Editor"
                    ],
                    false
                ),
                this.createTextQuestion("How many hours a week are you available to volunteer with our rescue?"),
                this.createTextQuestion("What days (and times) are you available to volunteer with us? (for example, Saturday mornings, Monday nights, etc)"),
            ]
        } as ICategoryMetadata);
    }

    private static loadSurrenderPetInfo(template: ApplicationTemplateEntity, species: Array<string>) {
        template.categories.push({
            displayName: "Pet Information",
            questions: [
                this.createTextQuestion("Pet's Name"),
                this.createTextQuestion("Nickname"),
                this.createSingleChoiceQuestion("Species", species),
                this.createTextQuestion("Breed"),
                this.createYesNoQuestion("Spayed/Neutered"),
                this.createTextQuestion("Age"),
                this.createDateQuestion("Date of Birth", false),
            ]
        } as ICategoryMetadata);
    }

    private static loadSurrenderPetHistory(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Pet History",
            questions: [
                this.createTextQuestion("How long has this pet lived with you?"),
                this.createTextQuestion("Where did you obtain the pet?"),
                this.createYesNoQuestion("If from a breeder or rescue group, did you contact them before surrendering your pet here?"),
                this.createMultipleChoiceQuestion("Why are you surrendering your pet?", [
                    "No time",
                    "Owner Medical Issues",
                    "Pet Medical Issues",
                    "Behavioral Problems",
                    "Moving",
                    "Other"
                ]),
                this.createTextQuestion("Please explain why you are surrendering your pet."),
                this.createTextQuestion("Who is your current veterinarian?"),
                this.createYesNoQuestion("Is your pet current on their rabies vaccination?"),
                this.createDateQuestion("Date of last rabies vaccination"),
                this.createYesNoQuestion("Is your pet current on their distemper vaccination?"),
                this.createDateQuestion("Date of last distemper vaccination"),
                this.createYesNoQuestion("Does your pet have any current health issues?"),
                this.createTextQuestion("Please explain the current health issues.", false),
                this.createYesNoQuestion("Does your pet have any past health issues?"),
            ]
        } as ICategoryMetadata);
    }

    private static loadSurrenderPetBehavior(template: ApplicationTemplateEntity) {
        template.categories.push({
            displayName: "Pet Behavior",
            questions: [
                this.createMultipleChoiceQuestion("When left alone, does your pet:", [
                    "Destroy household items",
                    "Urinate",
                    "Defecate",
                    "Bark",
                    "Cry",
                    "Nothing"
                ]),
                this.createYesNoQuestion("Is your pet crate-trained?"),
                this.createYesNoQuestion("Is your pet house trained?"),
                this.createTextQuestion("How does your pet greet strangers?"),
                this.createTextQuestion("How does your pet greet other animals outside of the home or in general?"),
                this.createYesNoQuestion("Has your pet had any experience with children?"),
                this.createYesNoQuestion("Would you recommend your pet to be placed in a home with children?"),
                this.createYesNoQuestion("Has your pet ever bitten or nipped you or anyone else?"),
                this.createYesNoQuestion("Did the bite(s) break skin?"),
                this.createYesNoQuestion("Was there stitches?"),
                this.createTextQuestion("Please explain the details of the bite", false),
                this.createYesNoQuestion("Has your pet lived with other animals?"),
                this.createTextQuestion("Species & Age", false),
                this.createYesNoQuestion("Has your pet ever fought with another animal?"),
                this.createTextQuestion("Please explain", false),
                this.createMultipleChoiceQuestion("Are there areas on your dog's body that he/she does NOT like to be touched?", [
                    "Ears",
                    "Mouth",
                    "Head",
                    "Neck",
                    "Paw/Nails",
                    "Other",
                    "Can touch anywhere"
                ]),
                this.createMultipleChoiceQuestion("If touched in the above place(s), how does your pet respond?", [
                    "Moves away",
                    "Shows teeth",
                    "Growls",
                    "Snaps",
                    "Bites",
                    "Other",
                    "No reaction"
                ]),
                this.createTextQuestion("Please tell us about your pet's \"bad behavior\" or fears."),
                this.createTextQuestion("Is there anything specific about your pet that you would like to share to make the transition easier?"),
                this.createTextQuestion("What is your pet's potty schedule?"),
                this.createTextQuestion("What is your pet's meal schedule?"),
                this.createTextQuestion("Where does your pet sleep? Describe sleeping habits."),
                this.createTextQuestion("Describe the ideal home for your pet."),
                this.createYesNoQuestion("Have you consulted with a professional trainer about your pet?"),
            ]
        } as ICategoryMetadata);
    }

    private static createTextQuestion(question: string, answerRequired: boolean = true): IQuestionMetadata {
        return {
            question: question,
            questionRequired: true,
            enabled: true,
            answerRequired: answerRequired,
            answerType: AnswerTypeEnum.TEXT.model,
            choiceList: []
        } as IQuestionMetadata;
    }

    private static createDateQuestion(question: string, answerRequired: boolean = true): IQuestionMetadata {
        return {
            question: question,
            questionRequired: true,
            enabled: true,
            answerRequired: answerRequired,
            answerType: AnswerTypeEnum.DATE.model,
            choiceList: []
        } as IQuestionMetadata;
    }

    private static createSingleChoiceQuestion(question: string, choices: Array<string>, answerRequired: boolean = true): IQuestionMetadata {
        return {
            question: question,
            questionRequired: true,
            enabled: true,
            answerRequired: answerRequired,
            answerType: AnswerTypeEnum.SINGLE_CHOICE.model,
            choiceList: choices
        } as IQuestionMetadata;
    }

    private static createMultipleChoiceQuestion(question: string, choices: Array<string>, answerRequired: boolean = true): IQuestionMetadata {
        return {
            question: question,
            questionRequired: true,
            enabled: true,
            answerRequired: answerRequired,
            answerType: AnswerTypeEnum.MULTIPLE_CHOICE.model,
            choiceList: choices
        } as IQuestionMetadata;
    }

    private static createYesNoQuestion(question: string, answerRequired: boolean = true): IQuestionMetadata {
        return {
            question: question,
            questionRequired: true,
            enabled: true,
            answerRequired: answerRequired,
            answerType: AnswerTypeEnum.SINGLE_CHOICE.model,
            choiceList: ["Yes", "No"]
        } as IQuestionMetadata;
    }
}
