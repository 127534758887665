import {FilterBase} from './FilterBase';
import {EnumElement} from "@sparkie/shared-model/src";
import {IQuery} from "../ArpResource";

/**
 * Filter where the propertyName equals the model value of the selected EnumElement.
 *
 * Typically used for on-off values:
 *
 * <arp-switch label="House trained" value.two-way="houseTrainedValue.selected"></arp-switch>
 */
export class EnumValueFilter<T extends EnumElement> extends FilterBase {

    selected: boolean;
    enumElement: T;

    constructor(propertyName: string, enumElement: T) {
        super(propertyName);

        this.enumElement = enumElement;
    }

    get label(): string {
        return this.enumElement.view;
    }

    observe(parent: any) : void {
        parent.observer.observe(this, 'selected', (changes) => {
            parent.updateActive();
        });
    }
    
    isActive() : boolean {
        return this.selected;
    }

    clearFilter() : void {
        this.selected = false;
    }
    
    configureResource(resource: IQuery) : void {

        resource.withPropertyEqual(this.propertyName, this.enumElement.model);
    }

    save() : void {
        this.saved = this.selected;
    }

    restore() : void {
        this.selected = this.saved;
    }

    getStateKey(): string {
        return `${this.propertyName}.${this.enumElement.model}`;
    }

    getState() : any {
        return  {
            selected: this.selected
        };
    }

    setState(state: any) : void {
        this.selected = state.selected;
    }
}
