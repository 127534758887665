import $ from "jquery";
import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";
import Sortable from "sortablejs";
import "./arp-photo-upload.css";

import { inject, bindable, customElement, Container } from "aurelia-framework";

import { WebApi } from "../WebApi";
import { UploadResource } from "../UploadResource";
import { ArpLogger, ArpLogManager } from "..";
import { IPhotoUpload } from "@sparkie/shared-model/src/model/common/IPhotoUpload";
import { find, remove } from "lodash";
import { convertImageToJpeg } from "utils/image/image_conversion";
import { ImageBuffer, loadImageFromFile } from "utils/image/ImageBuffer";
import { getThumbnailName, makeJpegFileName } from "utils/image/image_utils";
import { uploadPublicImage } from "utils/image/image_upload";
import { createJpegThumbnail } from "utils/image/image_resize";

const SMALL_THUMBNAIL_WIDTH = 60;
const SMALL_THUMBNAIL_HEIGHT = 60;

const MEDIUM_THUMBNAIL_WIDTH = 200;
const MEDIUM_THUMBNAIL_HEIGHT = 200;

type DropzoneFileEx = Dropzone.DropzoneFile & {
    arpMediumThumbnailFilePath: string;
    arpSmallThumbnailFilePath: string;
    uploadFilePath: string;
    signedUrl: string;
    type: string;
    custom_status: string;
};

@customElement("arp-photo-upload")
@inject(Element)
export class ArpPhotoUploadCustomElement {
    @bindable id;
    @bindable photos: Array<IPhotoUpload>;
    @bindable s3bucket;
    private element: HTMLElement;
    private webApi: WebApi;
    private uploaded: Array<IPhotoUpload> = [];
    private removed: Array<IPhotoUpload> = [];
    private logger: ArpLogger;
    private dropzoneOptions: any;
    private dropzone: any;
    private readonly thumbnailWidth = 120;
    private readonly thumbnailHeight = 120;

    constructor(element) {
        this.element = element;
        this.webApi = Container.instance.get(WebApi);

        this.logger = ArpLogManager.getLogger("arp-photo-upload");
    }

    attached() {
        this.dropzoneOptions = {
            clickable: true,
            dictDefaultMessage: "Drop files or click here to upload",
//            method: "put",
            autoQueue: true, // Make sure the files aren't queued until manually added
            autoProcessQueue: false,
            uploadMultiple: false,
            maxFilesize: 20, // MB
            createImageThumbnails: true,
            addRemoveLinks: true,
            url: (files) => this.getUrl(files),
            accept: (file, done) => this.accept(file, done),
            // sending: (file, xhr, formData) => this.sending(file, xhr, formData),
            // complete: (file) => this.complete(file),
        };

        this.dropzone = new Dropzone(this.element.children[0] as HTMLElement, this.dropzoneOptions);

        this.dropzone.processPhoto = (file, callback) => this.createThumbnail(file, callback);
        for (let file of this.photos) {
            this.addExisting(file);
        }

        this.dropzone.on("removedfile", (file) => this.removedfile(file));

        const sortableOptions: Sortable.Options = {
            // WTF? The "indexes" are 1 based?
            onEnd: (event) => this.onMoveFile(event.oldIndex - 1, event.newIndex - 1),
        };

        Sortable.create(this.element.children[0] as HTMLElement, sortableOptions);

        this.logger.debug(`attached`);
    }

    addExisting(existingFile: IPhotoUpload) {
        // Create the mock file:
        let mockFile = {
            url: existingFile.url,
            name: existingFile.filename,
            size: 12345,
        };

        // Call the default addedfile event handler
        this.dropzone.emit("addedfile", mockFile);

        const thumbnailUrl = `${this.s3bucket}/${existingFile.mediumThumbnailUrl}`;
        this.loadThumbnailFromUrl(mockFile, thumbnailUrl);

        // Make sure that there is no progress bar, etc...
        this.dropzone.emit("complete", mockFile);

        // If you use the maxFiles option, make sure you adjust it to the
        // correct amount:
        //var existingFileCount = 1; // The number of files already uploaded
        //this.dropzone.options.maxFiles = myDropzone.options.maxFiles - existingFileCount;
    }

    async accept(file: DropzoneFileEx, done) {
        this.logger.debug(`accept ${JSON.stringify(file)}`);

        try {
            $(file.previewTemplate).addClass("uploading");

            // Upload the image from file
            const originalImage = await loadImageFromFile(file);

            // Upload small thumbnail
            const smallThumbnailName = getThumbnailName(file.name, SMALL_THUMBNAIL_WIDTH);
            const smallThumbnail = await createJpegThumbnail(
                originalImage,
                SMALL_THUMBNAIL_WIDTH,
                SMALL_THUMBNAIL_HEIGHT
            );
            file.arpSmallThumbnailFilePath = await uploadPublicImage(smallThumbnail, smallThumbnailName, this.id);

            // Upload medium thumbnail
            const mediumThumbnailName = getThumbnailName(file.name, MEDIUM_THUMBNAIL_WIDTH);
            const mediumThumbnail = await createJpegThumbnail(
                originalImage,
                MEDIUM_THUMBNAIL_WIDTH,
                MEDIUM_THUMBNAIL_HEIGHT
            );
            file.arpMediumThumbnailFilePath = await uploadPublicImage(mediumThumbnail, mediumThumbnailName, this.id);

            // Upload the full size photo
            const jpgImageBuffer = await convertImageToJpeg(originalImage);
            const jpegFileName = makeJpegFileName(file.name);
            file.uploadFilePath = await uploadPublicImage(jpgImageBuffer, jpegFileName, this.id);

            this.logger.info(`uploaded all thumbnails`);

            file.custom_status = "ready";

            this.complete(file);
            done();
        } catch (err) {
            // If you pass an error message, the file is rejected, and the error message will be displayed.
            done(err);
        } finally {
        }
    }

    getUrl(files: Array<DropzoneFileEx>) {
        if (files.length > 1) {
            this.logger.error(`can't handle multiple file upload! ${JSON.stringify(files)}`);
        }
        // Is this always a single file?
        return files[0].signedUrl;
    }

    sending(file: DropzoneFileEx, xhr: XMLHttpRequest, formData: FormData) {
        // Dropzone calls xhr.send, so we override the call to prevent it from doing anything
        xhr.send = async function () {
            // Don't do anything here, we already uploaded the files
            return Promise.resolve();
        };
    }

    complete(file) {
        if (file._removeLink) {
            file._removeLink.textContent = this.dropzone.options.dictRemoveFile;
        }

        if (file.previewElement) {
            file.previewElement.classList.add("dz-complete");
        }

        if (!find(this.photos, (photo: any) => file.url === photo.url)) {
            let fileEntity = this.createFileEntity(file);
            this.uploaded.push(fileEntity);
            this.photos.push(fileEntity);
            this.logger.debug(`Upload complete for ${JSON.stringify(fileEntity)}`);
        }
    }

    onMoveFile(oldIndex: number, newIndex: number) {
        array_move(this.photos, oldIndex, newIndex);
        this.logger.debug(`Moved file from ${oldIndex} to ${newIndex}\n${JSON.stringify(this.photos)}`);
    }

    removedfile(file) {
        this.logger.debug(`remove ${JSON.stringify(file)}`);

        let removed = remove(this.photos, (photo) => {
            this.logger.debug(`remove: ${JSON.stringify(photo)}`);
            return photo.url === file.uploadFilePath || photo.url === file.url;
        });

        for (let fileEntity of removed) {
            this.removed.push(fileEntity);
        }
    }

    async createThumbnail(file, callback) {
        let fullImage = await loadImageFromFile(file);
        let thumbnailImage = await createJpegThumbnail(fullImage, this.thumbnailWidth, this.thumbnailHeight);

        // Load the thumbnail into the imageElement for the file
        this.loadThumbnail(file, thumbnailImage);

        if (callback) {
            callback();
        }
    }

    loadThumbnail(file: any, image: ImageBuffer) {
        const imageElement: HTMLImageElement = file.previewElement.querySelectorAll(
            "[data-dz-thumbnail]"
        )[0] as HTMLImageElement;
        file.previewElement.classList.remove("dz-file-preview");
    
        const url = URL.createObjectURL(image.toBlob());
    
        imageElement.onload = () => {
            URL.revokeObjectURL(url);
            file.previewElement.classList.add("dz-image-preview");
        };
    
        imageElement.src = url;
        imageElement.alt = file.name;
    }

    loadThumbnailFromUrl(file: any, url: string) {
        const imageElement: HTMLImageElement = file.previewElement.querySelectorAll(
            "[data-dz-thumbnail]"
        )[0] as HTMLImageElement;
        file.previewElement.classList.remove("dz-file-preview");
    
        imageElement.onload = () => {
            file.previewElement.classList.add("dz-image-preview");
        };
    
        imageElement.alt = file.name;
        imageElement.src = url;
    }

    createFileEntity(file): IPhotoUpload {
        return {
            url: file.uploadFilePath,
            smallThumbnailUrl: file.arpSmallThumbnailFilePath,
            mediumThumbnailUrl: file.arpMediumThumbnailFilePath,
            filename: file.name,
            title: "",
            description: "",
            uploaded: new Date(),
        };
    }

    /**
     * Use Cases:
     *      Add FileA, Save->No Op, uploaded=FileA
     *      Add FileA, Remove FileA, Save->Delete FileA, uploaded=FileA, removed=FileA
     *      Add FileA, Remove FileB, Save->Delete FileB, uploaded=FileA, removed=FileB
     *
     * @returns {Promise<any>}
     */
    save(): Promise<any> {
        // Files that we removed can be removed from the server
        let ps = [];
        for (const fileEntity of this.removed) {
            // If there is a file entity we can
            ps.push(this.safeRemoveFromServer(fileEntity.url, "save full", fileEntity));
            ps.push(this.safeRemoveFromServer(fileEntity.smallThumbnailUrl, "save sThumb", fileEntity));
            ps.push(this.safeRemoveFromServer(fileEntity.mediumThumbnailUrl, "save mThumb", fileEntity));
        }

        return Promise.all(ps);
    }

    /**
     * Use Cases:
     *      Add FileA, Cancel->Delete FileA, uploaded=FileA
     *      Add FileA, Remove FileA, Cancel->Delete FileA, uploaded=FileA, removed=FileA
     *      Add FileA, Remove FileB, Cancel->Delete FileA, uploaded=FileA, removed=FileB
     *
     * @returns {Promise<any>}
     */
    cancel(): Promise<any> {
        // Files that we uploaded can be removed the server
        let ps = [];
        for (const fileEntity of this.uploaded) {
            ps.push(this.safeRemoveFromServer(fileEntity.url, "cancel full", fileEntity));
            ps.push(this.safeRemoveFromServer(fileEntity.smallThumbnailUrl, "cancel sThumb", fileEntity));
            ps.push(this.safeRemoveFromServer(fileEntity.mediumThumbnailUrl, "cancel mThumb", fileEntity));
        }

        return Promise.all(ps);
    }

    safeRemoveFromServer(url: string, mode: string, data: any) {
        if (url === undefined || url === null) {
            this.logger.error(`url is undefined or null for ${mode}`, data);
            return Promise.resolve();
        }

        return this.removeFromServer(url);
    }

    removeFromServer(url: string): Promise<any> {
        // URLS are like T2/entity/filepath
        let [tenant, entityId, filename] = url.split("/");

        let resource = new UploadResource()
            .withEntityId(entityId)
            .withFile(filename)
            .withQueryParameter("bucket", "public");

        return this.webApi
            .delete(resource)
            .then((httpResponseMessage) => {
                this.logger.info("delete response: " + httpResponseMessage.response);
                return Promise.resolve(filename);
            })
            .catch((err) => {
                this.logger.warn(err);
            });
    }
}

const array_move = (array, from, to) => {
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length) {
        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
};
