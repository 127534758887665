import {Enum, EnumElement} from "../../Enum";

export class VideoSource extends EnumElement {
    embeddedUrl(id: string): string {
        switch (this) {
            case VideoSourceEnum.YOUTUBE:
                return `//www.youtube.com/embed/${id}`;
            default:
                return '';
        }
    }

    viewUrl(id: string): string {
        switch (this) {
            case VideoSourceEnum.YOUTUBE:
                return `https://www.youtube.com/watch?v=${id}`;
            default:
                return '';
        }
    }

    thumbnailUrl(id: string): string {
        switch (this) {
            case VideoSourceEnum.YOUTUBE:
                // https://i.ytimg.com/vi/Sw22taZbuEo/hqdefault.jpg
                return `https://i.ytimg.com/vi/${id}/hqdefault.jpg`;
            default:
                return '';
        }
    }
}

export class VideoSourceEnum extends Enum<VideoSource> {

    static readonly YOUTUBE = new VideoSource('YOUTUBE', 'YouTube');

    static readonly ELEMENTS = [
        VideoSourceEnum.YOUTUBE
    ];
    static readonly INSTANCE = new VideoSourceEnum();

    private constructor() {
        super(VideoSourceEnum.ELEMENTS);
    }
}
