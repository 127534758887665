
// This is the fixed\default part,  Tenants can add via the DB whatever they want
import {AnimalCharacteristics} from "./AnimalCharacteristicsEnum";
import {MedicalProcedure} from "./medical/MedicalProcedureEnum";

export interface ISpeciesMetadata {

    getBreeds(): Array<string>;
    getColors(): Array<string>;
    getCharacteristics(): Array<AnimalCharacteristics>
    getProcedures(): Array<MedicalProcedure>

    // What the medicine fixes (heartworm etc)
    getMedicineTypes(): Array<string>;

    // The medicine name (branded or generic)
    getMedicineBrands(type: string): Array<string>;
    getVaccines(): Array<string>;
}


export class SpeciesMetadata implements ISpeciesMetadata {
    constructor(
        private readonly breeds: Array<string>,
        private readonly colors: Array<string>,
        private readonly characteristics: Array<AnimalCharacteristics>,
        private readonly medications: Map<string, Array<string>>,
        private readonly procedures: Array<MedicalProcedure>,
        private readonly vaccines: Array<string>,
    ) {
    }

    getBreeds(): Array<string> {
        return this.breeds;
    }

    getColors(): Array<string> {
        return this.colors;
    }

    getCharacteristics(): Array<AnimalCharacteristics> {
        return this.characteristics;
    }

    getProcedures(): Array<MedicalProcedure> {
        return this.procedures;
    }

    // What the medicine fixes (heartworm etc)
    getMedicineTypes(): Array<string> {
        return Array.from(this.medications.keys());
    }

    // The medicine name (branded or generic)
    getMedicineBrands(type: string): Array<string> {
        return this.medications.get(type);
    }

    getVaccines(): Array<string> {
        return this.vaccines;
    }
}