import {ArpSort} from "../views/arp-sort";
import {ArpFilterBar} from "../filters/arp-filter-bar";
import {ArpRepository} from "../ArpRepository";
import {ArpSearch} from "../views/arp-search";
import {ClientReportSpec} from "./ClientReportSpec";
import {EntityId, IEntity} from "@sparkie/shared-model/src";

export class ArpDownload {

    title: string;
    sortModel: ArpSort;
    searchModel: ArpSearch;
    filterBarModel: ArpFilterBar;
    repository: ArpRepository<IEntity<EntityId>, IEntity<EntityId>, IEntity<EntityId>>;

    private operation: string;
    private readonly reportSpecification: ClientReportSpec;

    constructor(reportSpecification: ClientReportSpec) {
        this.reportSpecification = reportSpecification;
        this.operation = " Download";
    }

    async load(): Promise<any> {
        const queryBuilder = this.repository.createQueryBuilder();

        this.sortModel.configureResource(queryBuilder, this.searchModel.searchActive);
        this.searchModel.configureResource(queryBuilder);
        this.filterBarModel.configureResource(queryBuilder);

        let entities = [];

        await this.repository.loadDownloadEntitiesGQL(queryBuilder, entities);
        this.reportSpecification.generateCsvReport(entities);
    }
}

