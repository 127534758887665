import $ from 'jquery';

import 'trumbowyg/dist/trumbowyg.min.js';
import 'trumbowyg/dist/ui/trumbowyg.min.css';

const icons = require('trumbowyg/dist/ui/icons.svg') as string;

import {DOM, inject, bindable, customElement, bindingMode} from 'aurelia-framework';

@customElement('arp-editor')
@inject(DOM.Element)
export class ArpEditorCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value : string;

    private editor: any;
    private element: Element;

    constructor(element) {
        this.element = element;

        (<any>$).trumbowyg.svgPath = icons;
    }

    bind() {
    }

    attached() {
        let jeditor = $(this.editor) as any;
        const editor = jeditor.trumbowyg({
            removeformatPasted: true,
            btns: [
                ['undo', 'redo'], // Only supported in Blink browsers
                ['formatting'],
                ['strong', 'em'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['removeformat']
            ]
        });

        this.registerEvents(editor);

        editor.on('tbwchange', () => {
            this.value = jeditor.trumbowyg('html');
        });

        editor.on('tbwpaste', () => {
            this.value = jeditor.trumbowyg('html');
        });

        jeditor.trumbowyg('html', this.value);
    }

    registerEvents(editor) {
        const events = [
            'tbwfocus',
            'tbwblur',
            'tbwinit',
            'tbwresize',
            'tbwpaste',
            'tbwopenfullscreen',
            'tbwclosefullscreen',
            'tbwclose'
        ];

        events.forEach((name) => {
            editor.on(name, (event) => {
                const _event = new CustomEvent(name, {
                    detail: event.target.value,
                    bubbles: true
                });
                this.element.dispatchEvent(_event);
            });
        });
    }

    detached() {
        let jeditor = $(this.editor) as any;
        jeditor.trumbowyg('destroy');
    }
}
