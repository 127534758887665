import {Enum, EnumElement} from "../../Enum";

export class VolunteerSkills extends EnumElement {}

export class VolunteerSkillsEnum extends Enum<VolunteerSkills> {

    // TODO: _DESIGN_PHOTOGRAPHY?
    static readonly _DESIGN_PHOTOGRAPHY: VolunteerSkills = new VolunteerSkills('_DESIGN_PHOTOGRAPHY', 'Design/Photography');
    static readonly EVENT_PLANNING: VolunteerSkills = new VolunteerSkills('EVENT_PLANNING', 'Event Planning');
    static readonly FINANCE_ACCOUNTING: VolunteerSkills = new VolunteerSkills('FINANCE_ACCOUNTING', 'Finance/Accounting');
    static readonly IT_TECHNOLOGY: VolunteerSkills = new VolunteerSkills('IT_TECHNOLOGY', 'IT/Technology');
    static readonly MARKETING_SOCIAL_MEDIA: VolunteerSkills = new VolunteerSkills('MARKETING_SOCIAL_MEDIA', 'Marketing/Social Media');

    static readonly ELEMENTS = [
        VolunteerSkillsEnum._DESIGN_PHOTOGRAPHY,
        VolunteerSkillsEnum.EVENT_PLANNING,
        VolunteerSkillsEnum.FINANCE_ACCOUNTING,
        VolunteerSkillsEnum.IT_TECHNOLOGY,
        VolunteerSkillsEnum.MARKETING_SOCIAL_MEDIA
    ];

    static readonly INSTANCE = new VolunteerSkillsEnum();

    private constructor() {
        super(VolunteerSkillsEnum.ELEMENTS);
    }
}
