import {JsonProperty, Serializable} from "typescript-json-serializer";
import {AnimalCompatibilityEnum} from "./AnimalCompatibilityEnum";

export interface IAnimalCompatibilities {
    cats: string;                   // AnimalCompatibilityEnum
    dogs: string;                   // AnimalCompatibilityEnum
    toddlers: string;               // AnimalCompatibilityEnum
    children: string;               // AnimalCompatibilityEnum
    teens: string;                  // AnimalCompatibilityEnum
    men: string;                    // AnimalCompatibilityEnum
    women: string;                  // AnimalCompatibilityEnum
}

@Serializable()
export class AnimalCompatibilities implements IAnimalCompatibilities {
    @JsonProperty() cats: string = AnimalCompatibilityEnum.UNKNOWN.model;
    @JsonProperty() dogs: string = AnimalCompatibilityEnum.UNKNOWN.model;
    @JsonProperty() toddlers: string = AnimalCompatibilityEnum.UNKNOWN.model;
    @JsonProperty() children: string = AnimalCompatibilityEnum.UNKNOWN.model;
    @JsonProperty() teens: string = AnimalCompatibilityEnum.UNKNOWN.model
    @JsonProperty() men: string = AnimalCompatibilityEnum.UNKNOWN.model
    @JsonProperty() women: string = AnimalCompatibilityEnum.UNKNOWN.model

    static getQueryFields(): string[] {
        return [
            'cats',
            'dogs',
            'toddlers',
            'children',
            'teens',
            'men',
            'women'
        ];
    }
}

export function toDisplayStrings(compatibilities: IAnimalCompatibilities): Array<string> {
    return compatibilities == null ? [] : [
        toDisplayString(compatibilities.cats, "Cats"),
        toDisplayString(compatibilities.dogs, "Dogs"),
        toDisplayString(compatibilities.toddlers, "Children < 3"),
        toDisplayString(compatibilities.children, "Children 3-12"),
        toDisplayString(compatibilities.teens, "Children 12+"),
        toDisplayString(compatibilities.men, "Men"),
        toDisplayString(compatibilities.women, "Women")
    ].filter(it => it != null);
}

export function toDisplayString(value: string, view: string): string | null {
    if (value == null) {
        return null;
    } else if (value === AnimalCompatibilityEnum.UNKNOWN.model) {
        return null;
    } else {
        const compatibility = AnimalCompatibilityEnum.INSTANCE.fromModel(value);
        return `${compatibility.view} ${view}`;
    }
}