import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IPhone {
    // TODO: phoneTypes = ['Home', 'Work', 'Mobile', 'Other', 'Main', 'Home Fax', 'Work Fax', ''];
    type: string;     // home | work | ...
    number: string;
}

@Serializable()
export class Phone implements IPhone {
    @JsonProperty() type: string = '';
    @JsonProperty() number: string = '';

    static getQueryFields(): string[] {
        return [
            'type',
            'number'
        ]
    }
}

export interface IHasPhones {
    phone: Array<IPhone>;
}
