/**
 * Rotates and/or flips the canvas based on the provided EXIF orientation.
 *
 * @param canvas - The HTMLCanvasElement to be rotated.
 * @param ctx - The CanvasRenderingContext2D used to perform the rotation.
 * @param exifOrientation - The EXIF orientation value that determines the rotation/flip to be applied.
 * 
 * The EXIF orientation values and their corresponding transformations are:
 * - 2: Horizontal flip
 * - 3: 180° rotate left
 * - 4: Vertical flip
 * - 5: Vertical flip + 90° rotate right
 * - 6: 90° rotate right
 * - 7: Horizontal flip + 90° rotate right
 * - 8: 90° rotate left
 */
export function rotate(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, exifOrientation: number): void {
    const width = canvas.width;
    const height = canvas.height;
    const styleWidth = canvas.style.width;
    const styleHeight = canvas.style.height;

    if (exifOrientation > 4) {
        canvas.width = height;
        canvas.height = width;
        canvas.style.width = styleHeight;
        canvas.style.height = styleWidth;
    }

    switch (exifOrientation) {
        case 2:
            // horizontal flip
            ctx.translate(width, 0);
            ctx.scale(-1, 1);
            break;
        case 3:
            // 180° rotate left
            ctx.translate(width, height);
            ctx.rotate(Math.PI);
            break;
        case 4:
            // vertical flip
            ctx.translate(0, height);
            ctx.scale(1, -1);
            break;
        case 5:
            // vertical flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI);
            ctx.scale(1, -1);
            break;
        case 6:
            // 90° rotate right
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(0, -height);
            break;
        case 7:
            // horizontal flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(width, -height);
            ctx.scale(-1, 1);
            break;
        case 8:
            // 90° rotate left
            ctx.rotate(-0.5 * Math.PI);
            ctx.translate(-width, 0);
            break;
    }
}
