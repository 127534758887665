import {EnumElement, Enum} from "../../Enum";
import {IAnimal} from "./IAnimal";
import {Interval} from "luxon";
import {AnimalStatusEnum} from "./AnimalStatusEnum";

export class AnimalAvailability extends EnumElement {
    isValidForLitter(): boolean {
        switch (this) {
            case AnimalAvailabilityEnum.AVAILABLE:
            case AnimalAvailabilityEnum.FOSTER_ONLY:
            case AnimalAvailabilityEnum.ON_HOLD:
            case AnimalAvailabilityEnum.NOT_AVAILABLE:
                return true;

            case AnimalAvailabilityEnum.ADOPTION_IN_PROGRESS:
            default:
                return false;
        }
    }

    countAsAvailable(): boolean {
        switch (this) {
            case AnimalAvailabilityEnum.AVAILABLE:
            case AnimalAvailabilityEnum.ADOPTION_IN_PROGRESS:
                return true;

            case AnimalAvailabilityEnum.FOSTER_ONLY:
            case AnimalAvailabilityEnum.ON_HOLD:
            case AnimalAvailabilityEnum.NOT_AVAILABLE:
            default:
                return false;
        }
    }
}

export class AnimalAvailabilityEnum extends Enum<AnimalAvailability> {

    /**
     * The animal is available for adoption
     */
    static readonly AVAILABLE: AnimalAvailability = new AnimalAvailability('AVAILABLE', 'Available');

    /**
     * The animal is only available for fostering.
     */
    static readonly FOSTER_ONLY: AnimalAvailability = new AnimalAvailability('FOSTER_ONLY', 'Foster Only');

    /**
     * The animal is temporarily not available
     */
    static readonly ON_HOLD: AnimalAvailability = new AnimalAvailability('ON_HOLD', 'On hold');

    /**
     * The animal is not available
     */
    static readonly NOT_AVAILABLE: AnimalAvailability = new AnimalAvailability('NOT_AVAILABLE', 'Not available');

    /**
     * The animal is currently undergoing the adoption process
     */
    static readonly ADOPTION_IN_PROGRESS: AnimalAvailability = new AnimalAvailability('ADOPTION_IN_PROGRESS', 'Adoption in progress');

    static readonly ELEMENTS = [
        AnimalAvailabilityEnum.AVAILABLE,
        AnimalAvailabilityEnum.FOSTER_ONLY,
        AnimalAvailabilityEnum.ON_HOLD,
        AnimalAvailabilityEnum.NOT_AVAILABLE,
        AnimalAvailabilityEnum.ADOPTION_IN_PROGRESS
    ];
    static readonly INSTANCE = new AnimalAvailabilityEnum();

    private constructor() {
        super(AnimalAvailabilityEnum.ELEMENTS);
    }
}

export function getCumulativeDaysAvailable<T>(animal: IAnimal<T>): number {
    let daysAvailable = 0;

    for (let status of animal.guardians) {

        const availability = AnimalAvailabilityEnum.INSTANCE.fromModel(status.availability);

        if (availability.countAsAvailable()) {
            const start = status.start;
            const end = status.end || new Date();

            // Make sure the status wasn't set for a future date
            if (start < end) {
                daysAvailable += Interval.fromDateTimes(start, end)
                    .toDuration(['days', 'hours'])
                    .toObject()
                    .days;
            }
        }
    }

    return daysAvailable;
}

export function getCumulativeDaysUnavailable<T>(animal: IAnimal<T>): number {
    let daysUnavailable = 0;

    // If there is time between intake and first status it should count as unavailable
    const earliestStatusDate = animal.guardians.length > 0 ? animal.guardians[animal.guardians.length - 1].start : undefined;
    const intakeDate = animal.intake.date;

    if (intakeDate < earliestStatusDate) {
        daysUnavailable += Interval.fromDateTimes(intakeDate, earliestStatusDate)
            .toDuration(['days', 'hours'])
            .toObject()
            .days;
    }

    for (let statusRecord of animal.guardians) {
        const status = AnimalStatusEnum.INSTANCE.fromModel(statusRecord.status);
        const availability = AnimalAvailabilityEnum.INSTANCE.fromModel(statusRecord.availability);

        // Only count days the animal is in rescue!
        if (status.isInCare() && !availability.countAsAvailable()) {
            const start = statusRecord.start;
            const end = statusRecord.end || new Date();

            // Ignore post dated statuses
            if (end > start) {
                daysUnavailable += Interval.fromDateTimes(start, end)
                    .toDuration(['days', 'hours'])
                    .toObject()
                    .days;
            }
        }
    }

    return daysUnavailable;
}

export function getEffectiveIntakeDate<T>(animal: IAnimal<T>): Date | undefined {
    // Find the earlier of any animal status or the actual intake date
    const earliestAnimalStatus = animal.guardians.length > 0 ? animal.guardians[animal.guardians.length - 1] : undefined;

    if (animal.intake?.date && earliestAnimalStatus) {
        return (animal.intake?.date > earliestAnimalStatus.start) ? animal.intake?.date : earliestAnimalStatus.start;
    } else if (earliestAnimalStatus) {
        return earliestAnimalStatus.start;
    } else {
        return animal.intake?.date;
    }
}
