import {EnumElement, Enum} from "../../Enum";
import {ISpeciesMetadata} from "./SpeciesMetadata";
import {dogMetadata} from "./species/DogMetadata";
import {catMetadata} from "./species/CatMetadata";
import {rabbitMetadata} from "./species/RabbitMetadata";
import {guineaPigMetadata} from "./species/GuineaPigMetadata";

export class AnimalSpecies extends EnumElement {
    get metadata(): ISpeciesMetadata {
        switch (this) {
            case AnimalSpeciesEnum.DOG:
                return dogMetadata();
            case AnimalSpeciesEnum.CAT:
                return catMetadata();
            case AnimalSpeciesEnum.RABBIT:
                return rabbitMetadata();
            case AnimalSpeciesEnum.GUINEA_PIG:
                return guineaPigMetadata();
            default:
                throw new Error("No species")
        }
    }
}

export class AnimalSpeciesEnum extends Enum<AnimalSpecies> {

    static readonly DOG: AnimalSpecies = new AnimalSpecies('DOG', 'Dog');
    static readonly CAT: AnimalSpecies = new AnimalSpecies('CAT', 'Cat');
    static readonly RABBIT: AnimalSpecies = new AnimalSpecies('RABBIT', 'Rabbit');
    static readonly GUINEA_PIG: AnimalSpecies = new AnimalSpecies('GUINEA_PIG', 'Guinea Pig');

    static readonly ELEMENTS = [
        AnimalSpeciesEnum.DOG,
        AnimalSpeciesEnum.CAT,
        AnimalSpeciesEnum.RABBIT,
        AnimalSpeciesEnum.GUINEA_PIG
    ];
    static readonly INSTANCE = new AnimalSpeciesEnum();

    constructor() {
        super(AnimalSpeciesEnum.ELEMENTS);
    }
}

