import {EnumElement, Enum} from "../../Enum";

export class DonationType extends EnumElement {}

export class DonationTypeEnum extends Enum<DonationType> {

    static readonly DONATION = new DonationType('DONATION', 'Donation');
    static readonly GRANT = new DonationType('GRANT', 'Grant');
    static readonly IN_KIND_GOODS = new DonationType('IN_KIND_GOODS', 'In-kind (Goods)');
    static readonly IN_KIND_HOURS = new DonationType('IN_KIND_HOURS', 'In-kind (Hours)');

    static readonly ELEMENTS = [
        DonationTypeEnum.DONATION,
        DonationTypeEnum.GRANT,
        DonationTypeEnum.IN_KIND_GOODS,
        DonationTypeEnum.IN_KIND_HOURS
    ];
    static readonly INSTANCE = new DonationTypeEnum();

    private constructor() {
        super(DonationTypeEnum.ELEMENTS);
    }

    static hasPaymentMethod(model: string) : boolean {
        switch (model) {
            case DonationTypeEnum.IN_KIND_GOODS.model:
            case DonationTypeEnum.IN_KIND_HOURS.model:
                return false;
            default:
                return true;
        }
    }

    static getUnits(model: string) : string {
        switch (model) {
            case DonationTypeEnum.IN_KIND_HOURS.model:
                return 'hours';
            default:
                return 'dollars';
        }
    }

    static hasDollarAmount(model: string) : boolean {
        switch (model) {
            case DonationTypeEnum.IN_KIND_HOURS.model:
                return false;
            default:
                return true;
        }
    }
}
