import {EnumElement, Enum} from "../../../Enum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";

export class SkinScrapingTestOutcome extends EnumElement {
    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        let skinParasitesOutcome = ConditionStatusEnum.UNKNOWN;
        let mangeDemodexOutcome = ConditionStatusEnum.UNKNOWN;
        let mangeSarcoptesOutcome = ConditionStatusEnum.UNKNOWN;

        switch (subType) {
            case SkinScrapingTestOutcomeEnum.POSITIVE.view:
                skinParasitesOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case SkinScrapingTestOutcomeEnum.POSITIVE_MANGE_DEMODEX.view:
                mangeDemodexOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case SkinScrapingTestOutcomeEnum.POSITIVE_MANGE_SARCOPTES.view:
                mangeSarcoptesOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case SkinScrapingTestOutcomeEnum.NEGATIVE.view:
                skinParasitesOutcome = ConditionStatusEnum.NEGATIVE;
                mangeDemodexOutcome = ConditionStatusEnum.NEGATIVE;
                mangeSarcoptesOutcome = ConditionStatusEnum.NEGATIVE;
                break;

            default:
                break;
        }

        outcomes.push(MedicalConditionEnum.SKIN_PARASITES.createCondition(skinParasitesOutcome));
        outcomes.push(MedicalConditionEnum.MANGE_DEMODEX.createCondition(mangeDemodexOutcome));
        outcomes.push(MedicalConditionEnum.MANGE_SARCOPTES.createCondition(mangeSarcoptesOutcome));
    }
}

export class SkinScrapingTestOutcomeEnum extends Enum<SkinScrapingTestOutcome> {

    static readonly POSITIVE = new SkinScrapingTestOutcome('POSITIVE', 'Positive');
    static readonly POSITIVE_MANGE_DEMODEX = new SkinScrapingTestOutcome('POSITIVE_MANGE_DEMODEX', 'Positive (Mange/Demodex)');
    static readonly POSITIVE_MANGE_SARCOPTES = new SkinScrapingTestOutcome('POSITIVE_MANGE_SARCOPTES', 'Positive (Mange/Sarcoptes)');
    static readonly NEGATIVE = new SkinScrapingTestOutcome('NEGATIVE', 'Negative');

    static readonly ELEMENTS = [
        SkinScrapingTestOutcomeEnum.POSITIVE,
        SkinScrapingTestOutcomeEnum.POSITIVE_MANGE_DEMODEX,
        SkinScrapingTestOutcomeEnum.POSITIVE_MANGE_SARCOPTES,
        SkinScrapingTestOutcomeEnum.NEGATIVE
    ];
    static readonly INSTANCE = new SkinScrapingTestOutcomeEnum();

    private constructor() {
        super(SkinScrapingTestOutcomeEnum.ELEMENTS);
    }
}
