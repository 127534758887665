import {PersonEntity} from "@sparkie/shared-model/src";
import {isNull, isString, isUndefined } from "lodash";

export class ArpPersonNameFormatValueConverter {

    toView(person: PersonEntity) {

        if (isUndefined(person) || isNull(person)) {
            return "";
        } else if (isString(person)) {
            return person;
        } else {
            return this.getDisplayValue(person)
        }
    }

    getDisplayValue(person: PersonEntity) {
        return `${this.getName(person)}${this.getDNA(person)}${this.getEmail(person)}`
    }

    getName(person: PersonEntity) {
        if (person == null) {
            return '';
        } else {
            return `${person.name.first} ${person.name.last}`
        }
    }

    getEmail(person: PersonEntity) {
        if (person.email && person.email.length > 0) {
            return ` (${person.email[0].address})`
        } else {
            return '';
        }
    }

    getDNA(person: PersonEntity) {
        if (person.canAdoptOrFoster === false) {
            return '-!!!DNA!!! ';
        } else {
            return '';
        }
    }
}
