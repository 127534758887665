import {Decimal128} from "bson";
import type { EntityId } from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IFinanceRecord<T> {
    date: Date;
    type: string;   // TODO: "income | expense" enum
    details: string;
    amount: Decimal128;
    provider: T;
    providerModule: string;
    providerName: string;
}


@Serializable()
export class FinanceRecord implements IFinanceRecord<EntityId> {
    @JsonProperty() date: Date = null;
    @JsonProperty() type: string = '';   // TODO: "income | expense" enum
    @JsonProperty() details: string = '';
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    amount: Decimal128 = null;
    @JsonProperty() provider: EntityId = null;
    @JsonProperty() providerModule: string = '';
    @JsonProperty() providerName: string = '';
}