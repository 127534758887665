import {ValidationRenderer} from "aurelia-validation";

export class BootstrapFormRenderer implements ValidationRenderer {
    private readonly errorClass: string = 'has-error';
    private readonly initialErrorClass: string = 'help-block';
    private readonly appendMessageToLabel: boolean = true;

    public initialized: boolean = false;

    constructor() {
    }

    render(instruction) {
        for (let { result, elements } of instruction.unrender) {
            for (let element of elements) {
                this.remove(element, result);
            }
        }

        for (let { result, elements } of instruction.render) {
            for (let element of elements) {
                this.add(element, result);
            }
        }
    }

    private add(element, result) {
        if (result.valid) {
            return;
        }

        const formGroup = element.closest('.form-group');
        if (!formGroup) {
            return;
        }

        // add the errorClass class to the enclosing form-group div
        if (this.initialized) {
            //formGroup.classList.remove(this.initialErrorClass);
            formGroup.classList.add(this.errorClass);
        } else {
            //formGroup.classList.add(this.initialErrorClass);
        }

        // add help-block
        const message = document.createElement('span');

        message.className = `help-block validation-message`;
        message.id = `validation-message-${result.id}`;

        if (this.appendMessageToLabel) {
            let labels = this.findLabels(formGroup, element.id);
            for (let ii = 0; ii < labels.length; ii++) {
                let label = labels[ii];
                message.textContent = result.message.slice(label.textContent.length + 1);

                label.parentNode.insertBefore(message, label.nextSibling);
            }
        } else {
            message.textContent = result.message;
            formGroup.appendChild(message);
        }
    }

    private remove(element, result) {
        if (result.valid) {
            return;
        }

        const formGroup = element.closest('.form-group');
        if (!formGroup) {
            return;
        }

        // remove help-block
        const message = formGroup.querySelector(`#validation-message-${result.id}`);
        if (message) {
            message.parentNode.removeChild(message);

            // remove the errorClass class from the enclosing form-group div
            if (formGroup.querySelectorAll('.help-block.validation-message').length === 0) {
                formGroup.classList.remove(this.errorClass);
                formGroup.classList.remove(this.initialErrorClass);
            }
        }
    }

    private findLabels(formGroup, inputId) {
        let labels = [];
        this.findLabelsRecursively(formGroup, inputId, labels, 0);
        return labels;
    }

    private findLabelsRecursively(currentElement, inputId, currentLabels, currentDepth) {
        if (currentDepth === 5) {
            return;
        }
        if (currentElement.nodeName === 'LABEL' && (currentElement.attributes.for && currentElement.attributes.for.value === inputId || !currentElement.attributes.for)) {
            currentLabels.push(currentElement);
        }
        for (let i = 0; i < currentElement.children.length; i++) {
            this.findLabelsRecursively(currentElement.children[i], inputId, currentLabels, 1 + currentDepth);
        }
    }
}
