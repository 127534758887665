// Single Animal | Bonded Pair | 3+ at a time
import {Enum, EnumElement} from "../../Enum";

export class FosterAnimalCount extends EnumElement {}

// TODO: This class is fucked.  The view values make sense, but the model values are wrong.  It will still function,.
// but we should fix it via a migration.
export class FosterAnimalCountEnum extends Enum<FosterAnimalCount> {

    static readonly DOGS = new FosterAnimalCount('DOGS', 'Single Animal');
    static readonly CATS = new FosterAnimalCount('CATS', 'Bonded Pair');
    static readonly YOUNG_CHILDREN = new FosterAnimalCount('YOUNG_CHILDREN', '3+ Animals');

    static readonly ELEMENTS = [
        FosterAnimalCountEnum.DOGS,
        FosterAnimalCountEnum.CATS,
        FosterAnimalCountEnum.YOUNG_CHILDREN
    ];
    static readonly INSTANCE = new FosterAnimalCountEnum();

    private constructor() {
        super(FosterAnimalCountEnum.ELEMENTS);
    }
}
