import $ from 'jquery';

import {autoinject, bindable, customAttribute} from 'aurelia-framework';
import {Observer} from '../Observer';

/**
 * Add to a <table> to provide a text message when the table is empty
 *
 * usage: arp-table-placeholder="text: No Items; enabled.items: items"
 */
@customAttribute('arp-table-placeholder')
@autoinject()
export class ArpTablePlaceholderAttribute {

    @bindable items = [];
    @bindable itemValue : string = "";
    @bindable text: string;
    @bindable style: string;
    static nextId: number = 0;

    private element: Element;
    private observer: Observer;
    private id: string;

    constructor(element: Element, observer: Observer) {
        this.element = element;
        this.observer = observer;
        ArpTablePlaceholderAttribute.nextId = ArpTablePlaceholderAttribute.nextId + 1;
        this.id = `arp-tph-${ArpTablePlaceholderAttribute.nextId}`
    }

    bind(bindingContext, overrideContext) {
        this.observer.observeArray(this.items, () => this.updateState());
        this.observer.observe(this, 'itemValue', () => this.updateState());
        this.observer.observe(this, 'text', () => this.updateText());
    }

    attached() {

        $('<p>').text(this.text).attr('id', this.id).attr('class', this.style).appendTo(this.element);

        this.updateState();
    }

    updateText() {
        $(this.element).find("p").text(this.text);
    }

    updateState() {
        var showPlaceholder = this.hasItems() || this.hasItemValue();

        if (showPlaceholder) {
            $(this.element).find("thead").show();
            $(this.element).find("tbody").show();
            $(this.element).find(`#${this.id}`).hide();
        } else {
            $(this.element).find("thead").hide();
            $(this.element).find("tbody").hide();
            $(this.element).find(`#${this.id}`).show();
        }
    }

    hasItems() {
        return this.items && this.items.length !== 0;
    }

    hasItemValue() {
        return this.itemValue && this.itemValue.length > 0;
    }
}

