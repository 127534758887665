import {bindable, customElement, inject} from "aurelia-framework";
import {debounce, isEmpty, DebouncedFunc} from "lodash";
import {ArpTypeaheadDatasource} from "./arp-typeahead-datasource";
import type {EntityId, IEntity} from "@sparkie/shared-model/src";

const DS_PAGE_SIZE: number = 30;
const SEARCH_DELAY: number = 250;

export interface IDatasourceEntity extends IEntity<EntityId> {
    displayValue: string;       // What is in the input
    menuValue: string;          // What is in the menu
    styledMenuValue: string;    // Menu text that has the matching characters in bold
}

// TODO: Where to put this?  Lodash escapeRegExp doesn't handle /
function escapeRegExp(s: string): string {
    if (isEmpty(s)) {
        return s;
    }

    // https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex/6969486#6969486
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

@inject(Element)
@customElement("arp-typeahead")
export class ArpTypeahead<T extends IDatasourceEntity> {

    @bindable placeholder: string;
    @bindable datasource: ArpTypeaheadDatasource<T>;
    @bindable enableCreate = false;

    @bindable displayValue: string;         // i.e. "item.personFullName"
    @bindable modelValue: EntityId;         // i.e. "item.person"
    @bindable enabled: boolean = true;

    private element: Element;
    private showMenu: boolean = false;
    private showCreate: boolean = false;
    private totalMatches: number = 0;
    private remainingMatches: number = 0;
    private lastSearch: string = "";
    private readonly matchingEntities: Array<T> = [];
    private readonly debouncedSearch: DebouncedFunc<() => void>;

    constructor(element: Element) {
        this.element = element;
        this.debouncedSearch = debounce(async () => {
                await this.search();
            }, SEARCH_DELAY);
    }

    /**
     * Invoked when the databinding engine binds the view.
     *
     * @param bindingContext
     * @param overrideContext
     */
    bind(bindingContext, overrideContext) {
        this.lastSearch = this.displayValue?.toLowerCase() || "";
    }

    //
    // Events
    //

    /**
     * Called when the user clicks in the <input> element.
     *
     * @param event
     */
    mousedownInput(event) {

        //
        this.search();

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
        return true;
    }

    /**
     * Called when the input element gets focus
     *
     * NOTE: The blur, focus, load and unload events do not bubble so you'll need to use the trigger binding
     * command to subscribe to these events
     *
     * @param event
     */
    focusInput(event) {
        this.updateMenu();

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
        return true;
    }

    /**
     * Called when the input element loses focus
     *
     * NOTE: The blur, focus, load and unload events do not bubble so you'll need to use the trigger binding
     * command to subscribe to these events
     *
     * @param event
     */
    blurInput(event) {
        this.showMenu = false;

        // To support validation we need to send a blur event from the Element bound to this custom element (a span).
        this.element.dispatchEvent(new CustomEvent('blur', {
            detail: { value: event.target.value }, bubbles: true
        }));

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
        return true;
    }

    /**
     * Called as the user types, but after the displayValue is updated.
     *
     * @param event
     */
    async keyUpInput(event) {
        let continueProcessing = true;

        if (event.code === "Enter") {
            continueProcessing = this.onEnterKey();
        } else {
            this.debouncedSearch();
        }

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
        return continueProcessing;
    }

    /**
     * Called when the user clicks on one of ths choices in the dropdown.
     *
     * @param event
     * @param entity
     */
    mousedownEntity(event, entity) {
        if (event.button === 0) {
            // Stop the mousedown on the menu from causing a blur event
            event.preventDefault();

            // Clicking is just shorthand for typing!
            this.selectEntity(entity);
        }

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
    }

    /**
     * Called when the user clicks the 'create new' menu item.
     *
     * @param event
     */
    mousedownCreate(event) {
        if (event.button === 0) {

            // Stop the mousedown on the menu from causing a blur event
            event.preventDefault();

            this.create();
        }

        // Aurelia will automatically call preventDefault() on events handled with delegate or trigger binding. Most
        // of the time this is the behavior you want. To turn this off, return true from your event handler function.
    }

    /**
     * Called when the user clicks on the no matches menu item.
     *
     * @param event
     */
    mousedownMessage(event) {
        if (event.button === 0) {

            // Stop the mousedown on the menu from causing a blur event
            event.preventDefault();
        }
    }

    /**
     * Called when the user clicks on the no matches menu item.
     *
     * @param event
     */
    mousedownMore(event) {
        if (event.button === 0) {

            // Stop the mousedown on the menu from causing a blur event
            event.preventDefault();

            this.loadMore();
        }
    }

    //
    //
    //

    /**
     * Ask the datasource to match the contents of displayValue and return any matching entities.
     */
    async search() {
        let lowerSearch = this.displayValue?.toLowerCase() || "";

        if (lowerSearch !== this.lastSearch) {
            await this.datasource.load(lowerSearch, DS_PAGE_SIZE);
            this.lastSearch = lowerSearch;

            this.updateMatchingEntities();
            this.updateModelValue();
        }

        this.updateMenu();
    }

    async loadMore() {
        let lowerSearch = this.displayValue?.toLowerCase() || "";

        await this.datasource.loadMore(lowerSearch, DS_PAGE_SIZE * 2);

        this.updateMatchingEntities();
        this.updateModelValue();

        this.updateMenu();
    }

    selectEntity(entity: T) {
        this.displayValue = entity.displayValue;
        this.modelValue = entity._id;

        // The user selected a specific entity, so remove the other entries from this.matchingEntities
        this.matchingEntities.splice(0, this.matchingEntities.length);      // Clear array
        this.matchingEntities.push(entity);
        this.datasource.clear();

        this.updateMenu()
    }

    updateMatchingEntities() {

        let matches = this.datasource.getMatchingEntities();

        this.totalMatches = this.datasource.getTotalMatches();
        this.remainingMatches = this.totalMatches - matches.length;

        this.matchingEntities.splice(0, this.matchingEntities.length);      // Clear array
        Array.prototype.push.apply(this.matchingEntities, matches);

        this.updateMatchingDisplayEntities();
    }

    updateMatchingDisplayEntities() {
        for (let entity of this.matchingEntities) {
            entity.styledMenuValue = entity.menuValue;
        }

        // You should escape (, {, } and . with \
        // let escaped = escapeRegExp(this.displayValue);
        // let regex = new RegExp(`(${escaped})`, "gi");
        //
        // for (let entity of this.matchingEntities) {
        //     let menuValueString = entity.menuValue;
        //     let menuFragments = menuValueString.split(regex);
        //
        //     if (this.displayValue) {
        //         for (let i = 0; i < menuFragments.length; i++) {
        //             let thisFragment = menuFragments[i];
        //             if (thisFragment) {
        //                 let match = thisFragment.match(regex);
        //
        //                 if (match) {
        //                     entity.styledMenuValue += `<span class="arp-typeahead-highlight">${thisFragment}</span>`;
        //                 } else {
        //                     entity.styledMenuValue += `<span>${thisFragment}</span>`;
        //                 }
        //             }
        //         }
        //     } else {
        //         entity.styledMenuValue = menuValueString;
        //     }
        // }
    }

    updateModelValue() {
        if (this.displayValueHasExactMatch()) {
            let entity = this.matchingEntities[0];
            this.modelValue = entity._id;
        } else {
            this.modelValue = null;
        }
    }

    updateMenu() {

        let hasExactMatch = this.modelValue != null; // this.displayValueHasExactMatch();
        let numMatches = hasExactMatch ? 1 : this.matchingEntities.length;
        let emptyInput = this.isEmpty(this.displayValue);
        let canCreate = this.isTrue(this.enableCreate) && this.datasource.canCreate(this.displayValue);

        console.log(`hasExactMatch=${hasExactMatch},numMatches=${numMatches},emptyInput=${emptyInput},canCreate=${canCreate}`)
        console.log(`displayValue=${this.displayValue},modelValue=${this.modelValue}`)

        // A: Empty input: Hide menu
        // B: Contents match none, not valid: Show menu with "no results"
        // C: Contents match none, valid: Show menu with "no results" & create
        // D: Contents match single exact: Hide menu
        // D: Contents match single not exact: Show menu with create
        // E: Contents match multiple with 1 exact: Show menu
        // F: Contents match multiple with 0 exact: Show menu with create
        if (emptyInput) {
            this.showMenu = false;
        } else if (numMatches === 0) {
            this.showMenu = true;
            this.showCreate = canCreate;
        } else if (numMatches === 1) {
            if (hasExactMatch) {
                this.showMenu = false;
                this.showCreate = false;
            } else {
                this.showMenu = true;
                this.showCreate = canCreate;
            }
        } else {
            this.showMenu = true;
            this.showCreate = canCreate && !hasExactMatch;
        }
    }

    isTrue(value) {
        return value === "true";
    }

    hasValue(aString) {
        return aString != null && aString.length > 0;
    }

    isEmpty(aString) {
        return aString == null || aString.length === 0;
    }

    displayValueHasExactMatch() {
        if (this.matchingEntities.length === 1) {
            let entity = this.matchingEntities[0];

            let matching = entity.displayValue.toLowerCase();
            let typed = this.displayValue.toLowerCase();

            return (typed === matching);
        }

        return false;
    }

    async create() {
        if (this.datasource.canCreate(this.displayValue)) {

            let entity = await this.datasource.create(this.displayValue);

            this.selectEntity(entity);
        }
    }

    onEnterKey() {
        // Enter key is shorthand for choosing the top\\current match
        if (this.matchingEntities.length > 0) {
            let entity = this.matchingEntities[0];
            this.selectEntity(entity);
            return false;
        }

        return true;
    }
}

