
export interface IMedicalConditionStatus {
    condition: string;                  // MedicalConditionEnum
    status: string;                     // ConditionStatusEnum
}

export class MedicalConditionStatus implements IMedicalConditionStatus {
    condition: string = '';                  // MedicalConditionEnum
    status: string = '';                     // ConditionStatusEnum

    static getQueryFields(): string[] {
        return [
            'condition',
            'status'
        ]
    }
}