import {Enum, EnumElement} from "../Enum";

/**
 * Represent the resources from the database
 *
 * The model value is what is stored in the database
 * The view value is the singular display name
 */
export class ModelResource extends EnumElement {

    /**
     * The route in the client for the resource.  The list view follow /app/{resource} and the
     * detail views follow /app/{resource}/:id
     *
     * @returns {any}
     */
    route() : string {
        switch (this) {
            case ModelResourceEnum.AGENCY:
                return "agencies";
            case ModelResourceEnum.ANIMAL:
                return "animals";
            case ModelResourceEnum.APPLICATION:
                return "applications";
            case ModelResourceEnum.APPLICATION_TEMPLATE:
                return "applicationTemplates";
            case ModelResourceEnum.DONATION:
                return "donations";
            case ModelResourceEnum.PERSON:
                return "people";
            case ModelResourceEnum.TASK:
                return "tasks";
            case ModelResourceEnum.TEMPLATE:
                return "templates";
            case ModelResourceEnum.TENANT:
                return "tenants";
            case ModelResourceEnum.TRANSFER_REQUEST:
                return "transfers";
            case ModelResourceEnum.USER:
                return "users";
            default:
                return 'error';
        }
    }

    /**
     * The route in the client for the resource.  The list view follow /app/{resource} and the
     * detail views follow /app/{resource}/:id
     *
     * @returns {any}
     */
    detailsRoute() : string {
        switch (this) {
            case ModelResourceEnum.AGENCY:
                return "agencyDetails";
            case ModelResourceEnum.ANIMAL:
                return "animalDetails";
            case ModelResourceEnum.APPLICATION:
                return "applicationDetails";
            case ModelResourceEnum.APPLICATION_TEMPLATE:
                return "applicationTemplateDetails";
            case ModelResourceEnum.PERSON:
                return "personDetails";
            case ModelResourceEnum.TEMPLATE:
                return "templateDetails";
            case ModelResourceEnum.USER:
                return "userDetails";
            case ModelResourceEnum.TASK:
            case ModelResourceEnum.TENANT:
            case ModelResourceEnum.DONATION:
            default:
                return 'error';
        }
    }

    resource() : string {
        return this.model.toLowerCase();
    }

    navBar() : boolean {
        switch (this) {
            case ModelResourceEnum.APPLICATION_TEMPLATE:
            case ModelResourceEnum.TEMPLATE:
            case ModelResourceEnum.TENANT:
            case ModelResourceEnum.TRANSFER_REQUEST:
            case ModelResourceEnum.USER:
                return false;

            case ModelResourceEnum.AGENCY:
            case ModelResourceEnum.ANIMAL:
            case ModelResourceEnum.DONATION:
            case ModelResourceEnum.PERSON:
            case ModelResourceEnum.TASK:
            case ModelResourceEnum.APPLICATION:
            default:
                return true;
        }
    }

    pluralView() : string {
        switch (this) {
            case ModelResourceEnum.APPLICATION_TEMPLATE:
                return "Application Templates";
            case ModelResourceEnum.AGENCY:
                return "Organizations";
            case ModelResourceEnum.PERSON:
                return "People";
            default:
                return `${this.view}s`;
        }
    }

    hasDetailsPage() : boolean {
        return this.detailsRoute() != "error"
    }
}

/**
 * Enumeration that represent the Resource types managed by the Sparkie application.  Each Resource maps
 * directly (via the model property) to a resource in the server REST API.
 */
export class ModelResourceEnum extends Enum<ModelResource> {

    static readonly AGENCY = new ModelResource('AGENCY', 'Organization');
    static readonly ANIMAL = new ModelResource('ANIMAL', 'Animal');
    static readonly APPLICATION = new ModelResource('APPLICATION', 'Application');
    static readonly APPLICATION_TEMPLATE = new ModelResource('APPLICATION_TEMPLATE', 'Application Template');
    static readonly DONATION = new ModelResource('DONATION', 'Donation');
    static readonly PERSON = new ModelResource('PERSON', 'Person');
    static readonly TASK = new ModelResource('TASK', 'Task');
    static readonly TEMPLATE = new ModelResource('TEMPLATE', 'Template');
    static readonly TENANT = new ModelResource('TENANT', 'Tenant');
    static readonly TRANSFER_REQUEST = new ModelResource('TRANSFER_REQUEST', 'Transfer Request');
    static readonly USER = new ModelResource('USER', 'User');

    static readonly ELEMENTS = [
        ModelResourceEnum.AGENCY,
        ModelResourceEnum.ANIMAL,
        ModelResourceEnum.APPLICATION,
        ModelResourceEnum.APPLICATION_TEMPLATE,
        ModelResourceEnum.DONATION,
        ModelResourceEnum.PERSON,
        ModelResourceEnum.TASK,
        ModelResourceEnum.TEMPLATE,
        ModelResourceEnum.TENANT,
        ModelResourceEnum.TRANSFER_REQUEST,
        ModelResourceEnum.USER
    ];
    static readonly INSTANCE = new ModelResourceEnum();

    private constructor() {
        super(ModelResourceEnum.ELEMENTS);
    }
}
