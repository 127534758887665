import {EnumElement, Enum} from "../../Enum";

export class AnimalAge extends EnumElement {}

export class AnimalAgeEnum extends Enum<AnimalAge> {

    static readonly UNDER_SIX_MONTHS: AnimalAge = new AnimalAge('UNDER_SIX_MONTHS', '< 6 months');
    static readonly SIX_TO_TWELVE_MONTHS: AnimalAge = new AnimalAge('SIX_TO_TWELVE_MONTHS', '6-12 months');
    static readonly ONE_TO_SEVEN_YEARS: AnimalAge = new AnimalAge('ONE_TO_SEVEN_YEARS', '1-7 years');
    static readonly SEVEN_PLUS_YEARS: AnimalAge = new AnimalAge('SEVEN_PLUS_YEARS', '7+ years');

    static readonly ELEMENTS = [
        AnimalAgeEnum.UNDER_SIX_MONTHS,
        AnimalAgeEnum.SIX_TO_TWELVE_MONTHS,
        AnimalAgeEnum.ONE_TO_SEVEN_YEARS,
        AnimalAgeEnum.SEVEN_PLUS_YEARS
    ];
    static readonly INSTANCE = new AnimalAgeEnum();

    private constructor() {
        super(AnimalAgeEnum.ELEMENTS);
    }
}
