import {inject, transient, bindable, customElement} from 'aurelia-framework';
import isEmpty from 'lodash/isEmpty';

import {Observer} from '../Observer';

@transient()
@inject(Observer)
@customElement("arp-address")
export class ArpAddressCustomElement {

    @bindable address = null;

    cityStateZip: string = "";
    private observer: Observer;

    constructor(observer: Observer) {
        this.observer = observer;
    }

    bind(bindingContext, overrideContext) {
        if (this.address) {
            this.observer.observe(this.address, "city", () => {
                this.updateCityStateZip();
            });
            this.observer.observe(this.address, "stateProvinceCounty", () => {
                this.updateCityStateZip();
            });
            this.observer.observe(this.address, "zipOrPostCode", () => {
                this.updateCityStateZip();
            });

            this.updateCityStateZip();
        }
    }

    unbind() {
        this.observer.unObserveAll();
    }

    updateCityStateZip(){
        let value = "";

        if (this.address) {

            if (this.address.city) {
                value += this.address.city;

                if (this.address.stateProvinceCounty || this.address.zipOrPostCode) {
                    value += ",";
                }
            }

            if (this.address.stateProvinceCounty) {
                if (!isEmpty(value)) {
                    value += " ";
                }
                value += this.address.stateProvinceCounty;
            }

            if (this.address.zipOrPostCode) {
                if (!isEmpty(value)) {
                    value += " ";
                }
                value += this.address.zipOrPostCode;
            }
        }

        this.cityStateZip = value;
    }
}
