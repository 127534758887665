import { isNull, isString, isUndefined } from "lodash";

export class ArpNameFormatValueConverter {

    toView(value) {

        if (isUndefined(value) || isNull(value)) {
            return "";
        } else if (isString(value)) {
            return value;
        } else  {
            let first = value.first;
            let last = value.last;

            if (first || last) {
                return `${first} ${last}`;
            } else {
                return '';
            }
        }
    }
}
