import {JsonProperty, Serializable} from "typescript-json-serializer";

const PROD_PRIVATE_BUCKET = 'sparkie-app-private';
const PROD_PUBLIC_BUCKET = 'sparkie-app-public';
const DEV_PRIVATE_BUCKET = 'sparkie-dev-private';
const DEV_PUBLIC_BUCKET = 'sparkie-dev-public';
const STORAGE_ROOT = 's3.amazonaws.com';

export interface IFileUpload {
    url: string;
    filename: string;
    title: string;
    description: string;
    uploaded: Date;
}

@Serializable()
export class FileUpload implements IFileUpload {
    @JsonProperty() url: string = '';           // Required
    @JsonProperty() filename: string = '';      // Required
    @JsonProperty() title: string = '';
    @JsonProperty() description: string = '';
    @JsonProperty() uploaded: Date = new Date();

    static getQueryFields(): string[] {
        return [
            'url',
            'filename',
            'title',
            'description',
            'uploaded'
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
        ];
    }
}

export interface IHasFiles {
    files: Array<IFileUpload>;
}

export function getPublicStorageBucket(isProductionMode: boolean): string {
    let bucket = isProductionMode ? PROD_PUBLIC_BUCKET : DEV_PUBLIC_BUCKET;

    return `https://${bucket}.${STORAGE_ROOT}`;
}

export function getPrivateStorageBucket(isProductionMode: boolean): string {
    let bucket = isProductionMode ? PROD_PRIVATE_BUCKET : DEV_PRIVATE_BUCKET;

    return `https://${bucket}.${STORAGE_ROOT}`;
}

export function isHostedBySparkie(fileUpload: IFileUpload): boolean {
    // Links to files hosted by Sparkie are stored in the following format:
    // "T{tenantKey}/{entityId}/{uploadInstant}.{extension}"
    // Links to external files will start with http or https.
    return fileUpload.url.startsWith(`http`) === false;
}

export function isHostedByCurrentEnvironment(fileUpload: IFileUpload, isProductionMode: boolean): boolean {
    let bucket = isProductionMode ? PROD_PRIVATE_BUCKET : DEV_PRIVATE_BUCKET;
    return fileUpload.url.includes(bucket);
}

export function isUpload(item: IFileUpload) : boolean {
    return isHostedBySparkie(item);
}

export function isLink(item: IFileUpload) : boolean {
    return !isHostedBySparkie(item);
}

