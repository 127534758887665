
import {ITemplateItem, TemplateItem} from "./ITemplateItem";
import {IEntity, Entity, EntityId} from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ITemplate<T> extends IEntity<T> {

    name: string;
    description: string;
    species: string;    // AnimalSpeciesEnum - Medical input required Species

    // This defines where you can expand the template from
    type: string;       // TemplateTypeEnum (MEDICAL | MISC | TASK)

    items: Array<ITemplateItem<T>>;
}

@Serializable()
export class TemplateEntity extends Entity implements ITemplate<EntityId> {
    @JsonProperty() name: string = '';
    @JsonProperty() description: string = '';
    @JsonProperty() species: string = '';    // AnimalSpeciesEnum - Medical input required Species

    // This defines where you can expand the template from
    @JsonProperty() type: string = '';       // TemplateTypeEnum (MEDICAL | MISC | TASK)

    @JsonProperty({ type: TemplateItem }) items: Array<TemplateItem> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'name',
            'description',
            'species',
            'type',
            ...TemplateItem.getQueryFields().map(it => `items.${it}`),
        ]
    }
}

@Serializable()
export class TemplateDetails extends TemplateEntity {
    static getQueryFields(): string[] {
        return [
            ...TemplateEntity.getQueryFields()
        ]
    }
}

//
// TemplateSummary
//

//        resource.withSelectProperties("name,type,species,description");
export type ITemplateSummaryPartial = Omit<TemplateEntity, 'createdAt' | 'updatedAt' | 'items'>

@Serializable()
export class TemplateSummary extends Entity implements ITemplateSummaryPartial {
    @JsonProperty() name: string = '';
    @JsonProperty() description: string = '';
    @JsonProperty() species: string = '';    // AnimalSpeciesEnum - Medical input required Species

    // This defines where you can expand the template from
    @JsonProperty() type: string = '';       // TemplateTypeEnum (MEDICAL | MISC | TASK)

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'name',
            'description',
            'species',
            'type'
        ]
    }
}
