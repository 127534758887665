import { EnumElement, Enum } from "../../../Enum";
import { MedicalConditionEnum } from "./MedicalConditionEnum";
import { ConditionStatusEnum } from "./ConditionStatusEnum";
import { IMedicalConditionStatus } from "../IMedicalConditionStatus";

export class FecalParasiteTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        switch (subType) {
            case FecalParasiteTestOutcomeEnum.POSITIVE_GENERAL.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_TAPEWORM.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_ROUNDWORM.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_HOOKWORM.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_WHIPWORM.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_COCCIDIA.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_GIARDIA.view:
            case FecalParasiteTestOutcomeEnum.POSITIVE_LUNGWORM.view:
                outcomes.push(MedicalConditionEnum.FECAL_PARASITES.createCondition(ConditionStatusEnum.POSITIVE));
                break;
            case FecalParasiteTestOutcomeEnum.NEGATIVE.view:
                outcomes.push(MedicalConditionEnum.FECAL_PARASITES.createCondition(ConditionStatusEnum.NEGATIVE));
                break;
            default:
                outcomes.push(MedicalConditionEnum.FECAL_PARASITES.createCondition(ConditionStatusEnum.UNKNOWN));
                break;
        }
    }
}

export class FecalParasiteTestOutcomeEnum extends Enum<FecalParasiteTestOutcome> {

    static readonly NEGATIVE = new FecalParasiteTestOutcome('NEGATIVE', 'Negative');
    static readonly POSITIVE_GENERAL = new FecalParasiteTestOutcome('POSITIVE_GENERAL', 'Positive: General');
    static readonly POSITIVE_TAPEWORM = new FecalParasiteTestOutcome('POSITIVE_TAPEWORM', 'Positive: Tapeworm');
    static readonly POSITIVE_ROUNDWORM = new FecalParasiteTestOutcome('POSITIVE_ROUNDWORM', 'Positive: Roundworm');
    static readonly POSITIVE_HOOKWORM = new FecalParasiteTestOutcome('POSITIVE_HOOKWORM', 'Positive: Hookworm');
    static readonly POSITIVE_WHIPWORM = new FecalParasiteTestOutcome('POSITIVE_WHIPWORM', 'Positive: Whipworm');
    static readonly POSITIVE_COCCIDIA = new FecalParasiteTestOutcome('POSITIVE_COCCIDIA', 'Positive: Coccidia');
    static readonly POSITIVE_GIARDIA = new FecalParasiteTestOutcome('POSITIVE_GIARDIA', 'Positive: Giardia');
    static readonly POSITIVE_LUNGWORM = new FecalParasiteTestOutcome('POSITIVE_LUNGWORM', 'Positive: Lungworm');

    static readonly ELEMENTS = [
        FecalParasiteTestOutcomeEnum.NEGATIVE,
        FecalParasiteTestOutcomeEnum.POSITIVE_GENERAL,
        FecalParasiteTestOutcomeEnum.POSITIVE_TAPEWORM,
        FecalParasiteTestOutcomeEnum.POSITIVE_ROUNDWORM,
        FecalParasiteTestOutcomeEnum.POSITIVE_HOOKWORM,
        FecalParasiteTestOutcomeEnum.POSITIVE_WHIPWORM,
        FecalParasiteTestOutcomeEnum.POSITIVE_COCCIDIA,
        FecalParasiteTestOutcomeEnum.POSITIVE_GIARDIA,
        FecalParasiteTestOutcomeEnum.POSITIVE_LUNGWORM
    ];
    static readonly INSTANCE = new FecalParasiteTestOutcomeEnum();

    private constructor() {
        super(FecalParasiteTestOutcomeEnum.ELEMENTS);
    }
}
