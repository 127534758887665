import { SpeciesMetadata} from "../SpeciesMetadata";
import {AnimalCharacteristics, AnimalCharacteristicsEnum} from "../AnimalCharacteristicsEnum";
import {MedicalProcedure, MedicalProcedureEnum} from "../medical/MedicalProcedureEnum";

export function rabbitMetadata(): SpeciesMetadata {
    return new SpeciesMetadata(breeds, colors, characteristics, medications, procedures, vaccines);
}

const breeds: Array<string> = [
    "American",
    "American Fuzzy Lop",
    "American Sable",
    "Angora Rabbit",
    "Belgian Hare",
    "Beveren",
    "Britannia Petite",
    "Bunny Rabbit",
    "Californian",
    "Champagne D'Argent",
    "Checkered Giant",
    "Chinchilla",
    "Cinnamon",
    "Creme D'Argent",
    "Dutch",
    "Dwarf",
    "Dwarf Eared",
    "English Lop",
    "English Spot",
    "Flemish Giant",
    "Florida White",
    "French Lop",
    "Harlequin",
    "Havana",
    "Himalayan",
    "Holland Lop",
    "Hotot",
    "Jersey Wooly",
    "Lilac",
    "Lionhead",
    "Lop Eared",
    "Mini Lop",
    "Mini Rex",
    "Netherland Dwarf",
    "New Zealand",
    "Palomino",
    "Polish",
    "Rex",
    "Rhinelander",
    "Satin",
    "Silver",
    "Silver Fox",
    "Silver Marten",
    "Tan",
];

const colors: Array<string> = [
    "Agouti",
    "Black",
    "Silver Marten",
    "Blue / Gray",
    "Brown / Chocolate",
    "Cream",
    "Tan",
    "White",
    "Lilac",
    "Tortoiseshell",
    "Orange / Red",
    "Sable"
];

const characteristics: Array<AnimalCharacteristics> = [
    AnimalCharacteristicsEnum.AGGRESSIVE_PEOPLE,
    AnimalCharacteristicsEnum.CHEWING,
    AnimalCharacteristicsEnum.COMES_FOR_TREATS,
    AnimalCharacteristicsEnum.HOUSE_TRAINED,
    AnimalCharacteristicsEnum.LIKES_BEING_STROKED,
    AnimalCharacteristicsEnum.NOISE_ANXIETY,
    AnimalCharacteristicsEnum.SEPARATION_ANXIETY,
    AnimalCharacteristicsEnum.SHY,
    AnimalCharacteristicsEnum.SOCIAL,
    AnimalCharacteristicsEnum.SPECIAL_NEEDS,
    AnimalCharacteristicsEnum.VACCINATED,
];

const procedures: Array<MedicalProcedure> = [
    MedicalProcedureEnum.BLOODWORK,
    MedicalProcedureEnum.DENTAL,
    MedicalProcedureEnum.EAR_EXAM,
    MedicalProcedureEnum.EUTHANASIA,
    MedicalProcedureEnum.MICROCHIP_IMPLANT,
    MedicalProcedureEnum.NEUTER,
    MedicalProcedureEnum.OFFICE_EXAM,
    MedicalProcedureEnum.SKIN_SCRAPING,
    MedicalProcedureEnum.SPAY,
];

const medications: Map<string, Array<string>> = new Map([
    [
        "Antibiotic", [
            "Chlorsig",
            "Conoptal",
            "Propercillin",
            "Flagella",
            "Enrotril"
        ]
    ],
    [
        "Anti-inflammatory", [
            "Meloxicam",
            "Metacam"
        ]
    ],
    [
        "Dewormer", [
            "Panacur"
        ]
    ],
    [
        "Flea/Tick", [
            "Revolution",
            "Advantix"
        ]
    ],
    [
        "Other", [
            "Zantac Syrup",
            "Metomide Injection",
            "Vitamin E Oil",
            "Viscotears",
            "Cylap RCD"
        ]
    ]
]);

const vaccines: Array<string> = [
    "Calicivirus",
    "RHDV2"
];

