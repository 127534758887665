import map from "lodash/map";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";
import isDate from "lodash/isDate";
import isString from "lodash/isString";

import moment from "moment";
import {Decimal128} from "bson";

import {IReportConverter} from "./IReportConverter";

export class ReportColumn {
    readonly converter: IReportConverter;
    readonly columnName: string;
    readonly property: string | Array<string>;  // If an array use the first property that has a truthy false

    constructor(columnName: string, property: string | Array<string>, converter: IReportConverter = null) {
        this.columnName = columnName;
        this.property = property;
        this.converter = converter;
    }

    getModelValue(instance: any) : any {

        let modelValue = null;

        if (this.property === '$') {
            modelValue = instance;
        } else if (isArray(this.property)) {
            // If the property name is an array then search each property name and return the first one with a non-nil value
            for (let propertyName of this.property) {
                let value = get(instance, propertyName);

                if (value) {
                    modelValue = value;
                    break;
                }
            }
        } else if (this.property.indexOf("=") >= 0) {
            // property=value
            let tokens = this.property.split("=");
            let propertyValue = get(instance, tokens[0], []) as Array<string>;

            if (propertyValue.includes(tokens[1])) {
                modelValue = tokens[1];
            } else {
                modelValue = "";
            }
        } else {
            modelValue = get(instance, this.property);
        }

        return modelValue;
    }

    getDisplayValue(instance: any) : string {
        let modelValue = this.getModelValue(instance);
        let columnValue = this.translateDisplayValue(modelValue, instance);

        return columnValue;
    }

    translateDisplayValue(modelValue: any, instance: any) : string {
        let columnValue: string = null;

        if (isNil(modelValue)) {
            columnValue = "";
        } else if (isArray(modelValue)) {
            columnValue = this.processArray(modelValue as Array<any>, instance);
        } else if (this.converter) {
            columnValue = this.converter.toView(modelValue, instance);
        } else if (modelValue instanceof Decimal128) {
            columnValue = modelValue.toString()
        } else if (isNumber(modelValue)) {
            columnValue = modelValue.toString()
        } else if (isBoolean(modelValue)) {
            columnValue = modelValue.toString();
        } else if (isDate(modelValue)) {
            columnValue = moment(modelValue).format("YYYY-MM-DD HH:mm:ss");
        } else if (isString(modelValue)) {
            columnValue = modelValue;
        } else {
            columnValue = "";
        }

        return columnValue;
    }

    processArray(array: Array<any>, instance: any) : string {
        return map(array, (it: string) => {
            return this.translateDisplayValue(it, instance);
        }).join(',');
    }
}