import assignWith from "lodash/assignWith";
import cloneDeepWith from "lodash/cloneDeepWith";

import type { IEntity, EntityId } from "../common/IEntity";

import {ApplicationCategory, IApplicationCategory} from "./IApplicationCategory";
import {IHasNotes, Note} from "../common/INote";
import {IHasFiles, FileUpload} from "../common/IFileUpload";
import {Entity} from "../common/IEntity";
import {Address} from "../common/IAddress";
import type {IAddress} from "../common/IAddress";
import {PersonDetails} from "../person/IPerson";
import {JsonProperty, Serializable} from "typescript-json-serializer";


export interface IApplication<T> extends IEntity<T>, IHasNotes, IHasFiles {

    applicationId: string;                // 'ANNN'
    type: string;                         // ApplicationTypeEnum
    state: string;                        // ApplicationStateEnum

    // This stores data before creating a person.  It won't change if the person is later edited
    applicantFirstName: string;
    applicantLastName: string;
    applicantEmail: string;
    applicantPhone: string;
    applicantAddress: IAddress;
    secondaryApplicantFirstName: string;
    secondaryApplicantLastName: string;

    // Include the person name locally to support full text query
    person: T;
    personFullName: string;        // Read-only
    personLastName: string;        // Read-only
    personPrimaryEmail: string;    // Read-only

    // Include the animal name locally to support full text query
    animal: T;     // Can be null?
    animalName: string;

    // These should be in order
    categories: Array<IApplicationCategory>;

    tags: Array<string>;
}

@Serializable()
export class ApplicationEntity extends Entity implements IApplication<EntityId> {
    @JsonProperty() applicationId: string = '';                // 'ANNN'
    @JsonProperty() type: string = '';                         // ApplicationTypeEnum
    @JsonProperty() state: string = '';                        // ApplicationStateEnum

    // This stores data before creating a person.  It won't change if the person is later edited
    @JsonProperty() applicantFirstName: string = '';
    @JsonProperty() applicantLastName: string = '';
    @JsonProperty() applicantEmail: string = '';
    @JsonProperty() applicantPhone: string = '';
    @JsonProperty() applicantAddress: IAddress = new Address();
    @JsonProperty() secondaryApplicantFirstName: string = '';
    @JsonProperty() secondaryApplicantLastName: string = '';

    // Include the person name locally to support full text query
    @JsonProperty() person: EntityId = null;
    @JsonProperty() personFullName: string = '';        // Read-only
    @JsonProperty() personLastName: string = '';        // Read-only
    @JsonProperty() personPrimaryEmail: string = '';    // Read-only

    // Include the animal name locally to support full text query
    @JsonProperty() animal: EntityId = null;     // Can be null?
    @JsonProperty() animalName: string = '';

    // These should be in order
    @JsonProperty({ type: ApplicationCategory }) categories: Array<ApplicationCategory> = [];

    @JsonProperty() tags: Array<string> = [];
    @JsonProperty({ type: Note }) notes: Array<Note> = [];
    @JsonProperty({ type: FileUpload }) files: Array<FileUpload> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'applicationId',
            'type',
            'state',
            'applicantFirstName',
            'applicantLastName',
            'applicantEmail',
            'applicantPhone',
            ...Address.getQueryFields().map(it => `applicantAddress.${it}`),
            'secondaryApplicantFirstName',
            'secondaryApplicantLastName',
            'person',
            'personFullName',
            'personLastName',
            'personPrimaryEmail',
            'animal',
            'animalName',
            ...ApplicationCategory.getQueryFields().map(it => `categories.${it}`),
            'tags',
            ...Note.getQueryFields().map(it => `notes.${it}`),
            ...FileUpload.getQueryFields().map(it => `files.${it}`)
        ]
    }

    // Readonly fields from the API perspective
    static getReadOnlyFields(): string[] {
        return [
            "personFullName",
            "personLastName",
        ];
    }
}


@Serializable()
export class ApplicationDetails extends ApplicationEntity {
    @JsonProperty() personEntity: PersonDetails = null; // TODO: How much data is really needed

    static getQueryFields(): string[] {
        return [
            ...ApplicationEntity.getQueryFields()
        ]
    }

    // Readonly fields from the API perspective
    static getReadOnlyFields(): string[] {
        return [
            ...ApplicationEntity.getReadOnlyFields(),
            "personEntity",
        ];
    }

    static createInstance(data: any): ApplicationDetails {
        return assignWith(new ApplicationDetails(), cloneDeepWith(data));
    }
}

//
// ApplicationSummary
//

// ithSelectProperties("applicationId,createdAt,updatedAt,applicantFirstName,applicantLastName,person,personFullName,animal,animalName,type,state");
export type IApplicationSummaryPartial = Omit<ApplicationEntity,
    'applicantEmail' |
    'applicantPhone' |
    'applicantAddress' |
    'secondaryApplicantFirstName' |
    'secondaryApplicantLastName' |
    'personLastName' |
    'personPrimaryEmail' |
    'categories' |
    'notes' |
    'files'
    >

@Serializable()
export class ApplicationSummary extends Entity implements IApplicationSummaryPartial {

    @JsonProperty() applicationId: string = '';                // 'ANNN'
    @JsonProperty() type: string = '';                         // ApplicationTypeEnum
    @JsonProperty() state: string = '';                        // ApplicationStateEnum

    // This stores data before creating a person.  It won't change if the person is later edited
    @JsonProperty() applicantFirstName: string = '';
    @JsonProperty() applicantLastName: string = '';

    // Include the person name locally to support full text query
    @JsonProperty() person: EntityId = null;
    @JsonProperty() personFullName: string = '';        // Read-only

    // Include the animal name locally to support full text query
    @JsonProperty() animal: EntityId = null;     // Can be null?
    @JsonProperty() animalName: string = '';

    @JsonProperty() tags: Array<string> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'applicationId',
            'type',
            'state',
            'applicantFirstName',
            'applicantLastName',
            'person',
            'personFullName',
            'animal',
            'animalName',
            'tags'
        ]
    }
}

//
// ApplicationDownload
//

// ithSelectProperties("applicationId,createdAt,updatedAt,applicantFirstName,applicantLastName,person,personFullName,animal,animalName,type,state");
export type IApplicationDownloadPartial = Omit<ApplicationEntity,
    'secondaryApplicantFirstName' |
    'secondaryApplicantLastName' |
    'animal' |
    'person' |
    'personLastName' |
    'categories' |
    'notes' |
    'files'
    >

@Serializable()
export class ApplicationDownload extends Entity implements IApplicationDownloadPartial {

    @JsonProperty() applicationId: string = '';                // 'ANNN'
    @JsonProperty() type: string = '';                         // ApplicationTypeEnum
    @JsonProperty() state: string = '';                        // ApplicationStateEnum

    @JsonProperty() applicantFirstName: string = '';
    @JsonProperty() applicantLastName: string = '';
    @JsonProperty() applicantEmail: string = '';
    @JsonProperty() applicantPhone: string = '';
    @JsonProperty() applicantAddress: IAddress = new Address();

    @JsonProperty() personFullName: string = '';        // Read-only
    @JsonProperty() personPrimaryEmail: string = '';    // Read-only

    @JsonProperty() animalName: string = '';

    @JsonProperty() tags: Array<string> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'applicationId',
            'type',
            'state',
            'applicantFirstName',
            'applicantLastName',
            'applicantEmail',
            'applicantPhone',
            ...Address.getQueryFields().map(it => `applicantAddress.${it}`),
            'person',
            'personFullName',
            'personPrimaryEmail',
            'animal',
            'animalName',
            'tags'
        ]
    }
}


