import { JsonProperty, Serializable } from 'typescript-json-serializer';
import {IEntity, Entity} from "../common/IEntity";
import {Decimal128} from "bson";
import type { EntityId } from "../common/IEntity";

export interface IDonation<T> extends IEntity<T> {

    date: Date;
    type: string;			            // DonationTypeEnum
    channel: string;		            // DonationChannelEnum
    amount: Decimal128;
    paymentMethod: string;		        // PaymentMethodEnum

    forType: string;
    forValue: string;

    // Animal
    animal: T;
    animalName: string;

    // Donor (person)
    person: T;
    personFullName: string;
    personLastName: string;

    // Donor (agency)
    agency: T;
    agencyName: string;

    description: string;
}

@Serializable()
export class DonationEntity extends Entity implements IDonation<EntityId> {

    @JsonProperty() date: Date = new Date();
    @JsonProperty() type: string = '';			            // DonationTypeEnum
    @JsonProperty() channel: string = '';		            // DonationChannelEnum
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    amount: Decimal128 = null;
    @JsonProperty() paymentMethod: string = '';		        // PaymentMethodEnum

    @JsonProperty() forType: string = '';
    @JsonProperty() forValue: string = '';

    // Animal
    @JsonProperty() animal: EntityId = null;
    @JsonProperty() animalName: string = '';

    // Donor (person)
    @JsonProperty() person: EntityId = null;
    @JsonProperty() personFullName: string = '';
    @JsonProperty() personLastName: string = '';

    // Donor (agency)
    @JsonProperty() agency: EntityId = null;
    @JsonProperty() agencyName: string = '';

    @JsonProperty() description: string = '';

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'date',
            'type',
            'channel',
            'amount',
            'paymentMethod',
            'forType',
            'forValue',
            'animal',
            'animalName',
            'person',
            'personFullName',
            'personLastName',
            'agency',
            'agencyName',
            'description'
        ]
    }
}

@Serializable()
export class DonationDetails extends DonationEntity {
    static getQueryFields(): string[] {
        return [
            ...DonationEntity.getQueryFields()
        ]
    }
}

//
// DonationSummary
//

export type DonationSummaryPartial = Omit<DonationEntity, 'createdAt' | 'updatedAt' | 'channel' | 'paymentMethod' | 'personLastName'>

@Serializable()
export class DonationSummary extends Entity implements DonationSummaryPartial {

    @JsonProperty() date: Date = null;
    @JsonProperty() type: string = '';			            // DonationTypeEnum
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    amount: Decimal128 = null;

    @JsonProperty() forType: string = '';
    @JsonProperty() forValue: string = '';

    // Animal
    @JsonProperty() animal: EntityId = null;
    @JsonProperty() animalName: string = '';

    // Donor (person)
    @JsonProperty() person: EntityId = null;
    @JsonProperty() personFullName: string = '';

    // Donor (agency)
    @JsonProperty() agency: EntityId = null;
    @JsonProperty() agencyName: string = '';

    @JsonProperty() description: string = '';

    // Projected by GQL query
    @JsonProperty() donorSort: string = '';
    @JsonProperty() forSort: string = '';

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'date',
            'type',
            'amount',
            'forType',
            'forValue',
            'animal',
            'animalName',
            'person',
            'personFullName',
            'agency',
            'agencyName',
            'description',
            'donorSort',
            'forSort'
        ]
    }
}

//
// PersonDonationSummaryEntity
//

export type IPersonDonationSummaryEntityPartial = Omit<DonationEntity,
    'createdAt' |
    'updatedAt' |
    'channel' |
    'paymentMethod' |
    'person' |
    'personFullName' |
    'personLastName' |
    'agency' |
    'agencyName' |
    'description'
    >

@Serializable()
export class PersonDonationSummaryEntity extends Entity implements IPersonDonationSummaryEntityPartial {
    @JsonProperty() readonly date: Date = null;
    @JsonProperty() readonly type: string = '';			            // DonationTypeEnum

    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    readonly amount: Decimal128 = null;

    @JsonProperty() readonly forType: string = '';
    @JsonProperty() readonly forValue: string = '';

    // Animal
    @JsonProperty() readonly animal: EntityId = null;
    @JsonProperty() readonly animalName: string = '';

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'date',
            'type',
            'amount',
            'forType',
            'forValue',
            'animal',
            'animalName',
        ]
    }
}
