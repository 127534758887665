import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ISocialSettings {
    facebook: string;
    instagram: string;
    twitter: string;
}

@Serializable()
export class SocialSettings implements ISocialSettings {
    @JsonProperty() facebook: string = '';
    @JsonProperty() instagram: string = '';
    @JsonProperty() twitter: string = '';

    static getQueryFields(): string[] {
        return [
            'facebook',
            'instagram',
            'twitter',
        ]
    }
}

