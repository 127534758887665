import {PLATFORM} from "aurelia-pal";
import {useView} from "aurelia-framework";
import {AppRouter} from "aurelia-router";

import {ActionBase} from "../actions/ActionBase";
import {ViewStateManager} from "../ViewStateManager";

/**
 * Base class supporting the typical Bootstrap nav bar attached to the Aurelia Router.  Derive from this
 * and compose into your root application view.
 */
@useView(PLATFORM.moduleName('./arp-nav-bar.html'))
export class ArpNavBar {

    protected readonly rightActions: Array<ActionBase> = [];
    protected readonly vsm: ViewStateManager;

    constructor(vsm: ViewStateManager) {
        this.vsm = vsm;
    }

    // The template binds to this!
    get router() : AppRouter {
        return this.vsm.router;
    }
}