import {Container} from 'aurelia-framework';

import {ArpResource, IQuery} from './ArpResource';
import {refreshEntity} from './ArpEntity';
import {WebApi} from './WebApi';
import {EntityId, IEntity, ModelResource, ModelResourceEnum} from "@sparkie/shared-model/src";
import {ResourceFilter} from "./filters/arp-filter-bar";
import {QueryBuilder} from "./QueryBuilder";
import forEach from 'lodash/forEach';
import isString from 'lodash/isString';
import { countBy, get, isArray, isEmpty, isNull, isUndefined, set } from 'lodash';
import {ArpLogManager} from "./log/ArpLogManager";
import {Logger} from "./log/ArpLogger";

export interface IDependency {
    type: string;
    property: string;
    label: string;
    id: string;
}

/**
 * Class that centralizes accessing Resources from the server via the REST API.
 *
 * T = Details entity (DetailsView)
 * S = Summary entity (ListPage)
 * D = Download entity
 *
 */
export abstract class ArpRepository<T extends IEntity<EntityId>, S extends IEntity<EntityId>, D extends IEntity<EntityId>> {

    public readonly hasDetailsPage: boolean;
    public readonly resource: ModelResource;

    public readonly webApi: WebApi;
    protected readonly logger: Logger;

    protected constructor(resource: ModelResource, hasDetailsPage: boolean) {
        this.resource = resource;
        this.hasDetailsPage = hasDetailsPage;

        this.webApi = Container.instance.get(WebApi);
        this.logger = ArpLogManager.getLogger(`repository-${this.resource.model.toLowerCase()}`);
    }

    abstract configureFullEntity(query: IQuery): IQuery;

    /**
     * Override to fine tune query (populate & select) for list based views.  We only need to include visible
     * fields since sorting and Searching are implemented on the server.
     *
     * @param query
     */
    abstract configureSummaryEntity(query: IQuery): IQuery;

    /**
     * Fine tune query for csv download.  We only include flattened data, so no nested arrays should
     * be included.
     *
     * @param {ArpResource} resource
     */
    abstract configureDownloadEntity(resource: IQuery): IQuery;

    abstract createDefaultEntity(jsonEntity?: any): T
    abstract createSummaryEntity(jsonEntity?: any): S
    abstract createDownloadEntity(jsonEntity?: any): D

    createResource() : ArpResource {
        return new ArpResource(this.resource.model.toLowerCase());
    }

    createQueryBuilder(): QueryBuilder {
        return new QueryBuilder(this.resource);
    }

    async loadCountGQL(queryBuilder: QueryBuilder): Promise<number> {
        const query = queryBuilder.buildCountQuery();

        let response = await this.webApi.executeGQLQuery(query);
        let count = response as number;

        return count;
    }

    async loadSummaryEntitiesGQL(queryBuilder: QueryBuilder, entities: Array<S>) : Promise<Array<S>> {
        this.configureSummaryEntity(queryBuilder);
        const query = queryBuilder.buildManyQuery();

        let response = await this.webApi.executeGQLQuery(query);
        let gqlEntities: Array<S> = response as Array<S>;

        entities.splice(0, entities.length);      // Clear existing entities

        forEach(gqlEntities, (jsonEntity: any) => {

            let entity = this.createSummaryEntity(jsonEntity)
            //refreshEntity(entity, jsonEntity)

            this.processSummaryEntity(entity);

            entities.push(entity);
        });

        this.logger.debug("loadSummaryEntitiesGQL", entities);

        return entities;
    }

    /**
     * Download data suitable for generating CSV.  Returns a Details entity that is sparsely populated with only
     * the fields required for the generated CSV.
     *
     * @param queryBuilder
     * @param entities
     */
    async loadDownloadEntitiesGQL(queryBuilder: QueryBuilder, entities: Array<D>) : Promise<Array<D>> {

        this.configureDownloadEntity(queryBuilder);
        const query = queryBuilder.buildManyQuery();

        const json = await this.webApi.executeGQLQuery(query);

        entities.splice(0, entities.length);      // Clear existing entities

        forEach(json, (jsonEntity: any) => {

            let entity = this.createDownloadEntity(jsonEntity);

            this.processDownloadEntity(entity);

            this.logger.debug("loadDownloadEntitiesGQL", entity);

            entities.push(entity);
        });

        return entities;
    }

    async refreshSummaryEntityGQL(entity: S) : Promise<S> {

        let queryBuilder = this.createQueryBuilder()
        this.configureSummaryEntity(queryBuilder)
        let query = queryBuilder.buildByIdQuery(entity._id);

        let jsonEntity: any = await this.webApi.executeGQLQuery(query)

        let updatedEntity = this.createSummaryEntity(jsonEntity);
        //refreshEntity(updatedEntity, jsonEntity);

        this.processSummaryEntity(updatedEntity);

        refreshEntity(entity, updatedEntity);

        this.logger.debug("refreshSummaryEntityGQL", entity);

        return entity;
    }

    processSummaryEntity(entity: S) : void {
    }

    processDownloadEntity(entity: D): void {
    }

    async loadEntityGQL(entityId: string, filterMap?: Map<string, ResourceFilter>) : Promise<T> {
        let queryBuilder = this.createQueryBuilder();
        this.configureFullEntity(queryBuilder);
        const query = queryBuilder.buildByIdQuery(entityId);

        let jsonEntity = await this.webApi.executeGQLQuery(query);
        let entity = this.createDefaultEntity(jsonEntity);
        //refreshEntity(entity, jsonEntity);

        this.logger.debug("loadEntityGQL", entity);

        return entity;
    }

    async refreshEntityGQL(entity: T, filterMap?: Map<string, ResourceFilter>) : Promise<T> {

        let originalEntity = entity;

        let newEntity = await this.loadEntityGQL(entity._id, filterMap);
        refreshEntity(originalEntity, newEntity)

        this.logger.debug("refreshEntityGQL", originalEntity);

        return originalEntity;
    }

    /**
     * Saves the changes to an entity.
     *
     * @param entity
     *
     * @returns promise that resolves when the changes are saved
     */
    async saveEntity(entity: T) : Promise<string> {

        if (entity._id) {

            // Existing entity, we know the id so use put
            let resource = this.createResource().withId(entity._id);
            let processed = this.processChanges(entity);

            await this.webApi.putJSON(resource, processed);
            return entity._id;
        } else {
            return this.createEntity(entity);
        }
    }

    /**
     * Creates a new entity
     *
     * @param entity
     *
     * @returns promise that resolves when the resource is created
     */
    async createEntity(entity: T) : Promise<EntityId> {
        let resource = this.createResource();
        let processed = this.processChanges(entity);

        // New entity, no id so use post
        let response = await this.webApi.postJSON(resource, processed);
        return response._id;
    }

    /**
     * Provides a means to clean\filter changes before sending to the server
     *
     * @param entity
     * @returns {*}
     */
    processChanges(entity: T | S) : any {
        return entity;
    }

    /**
     * Deletes an entity.
     *
     * @param entity
     *
     * @returns promise that resolves when the entity is deleted
     */
    deleteEntity(entity: T | S) {

        // Make sure to never accidentally delete without an ID!
        if (!isString(entity._id) || entity._id.length === 0) {
            throw new Error("Entity Id missing on delete");
        }

        let resource = this.createResource().withId(entity._id);

        return this.webApi.delete(resource);
    }

    deleteEntityById(id: EntityId) {

        // Make sure to never accidentally delete without an ID!
        if (!isString(id) || id.length === 0) {
            throw new Error("Entity Id missing on delete");
        }

        let resource = this.createResource().withId(id);

        return this.webApi.delete(resource);
    }

    getDependencies(entity: T): Promise<Array<IDependency>> {
        return this.getDependenciesById(entity._id);
    }

    getDependenciesById(id: EntityId): Promise<Array<IDependency>> {
        let resource = this.createResource()
            .withAction('dependencies')
            .withQueryParameter('id', id);

        return this.webApi
            .getJSON(resource)
            .then((jsonEntity: any) => {
                this.logger.debug("getDependencies", jsonEntity);

                return Promise.resolve(jsonEntity);
            });
    }

    processDependencies(dependencies: Array<IDependency>): string {
        // TODO: This should be renamed to buildDependencyDescription() or such
        let dependencyTypes = countBy(dependencies, 'type');
        let dependencyDescriptions = [];

        if (dependencyTypes["Agency"]) {
            dependencyDescriptions.push(`${dependencyTypes["Agency"]} Organization(s)`);
        }
        if (dependencyTypes["Animal"]) {
            dependencyDescriptions.push(`${dependencyTypes["Animal"]} Animal(s)`);
        }
        if (dependencyTypes["Application"]) {
            dependencyDescriptions.push(`${dependencyTypes["Application"]} Application(s)`);
        }
        if (dependencyTypes["Donation"]) {
            dependencyDescriptions.push(`${dependencyTypes["Donation"]} Donation(s)`);
        }
        if (dependencyTypes["Person"]) {
            dependencyDescriptions.push(`${dependencyTypes["Person"]} Person(s)`);
        }
        if (dependencyTypes["Task"]) {
            dependencyDescriptions.push(`${dependencyTypes["Task"]} Task(s)`);
        }
        if (dependencyTypes["User"]) {
            dependencyDescriptions.push(`${dependencyTypes["User"]} User(s)`);
        }
        if (dependencyTypes["Template"]) {
            dependencyDescriptions.push(`${dependencyTypes["Template"]} Template(s)`);
        }

        // TODO: ApplicationTemplates?

        return `Can't delete this ${this.resource.view}. It is referenced by ${dependencyDescriptions.join(', ')}`;
    }

    refreshArray(entity: any, path: any, source: any) {
        let sourceAsArray = isArray(source) ? source : [source];

        let targetArray = get(entity, path);

        if (isUndefined(targetArray)) {
            set(entity, path, sourceAsArray);
        } else {
            targetArray.splice(0, targetArray.length);      // Clear array

            for (let i = 0; i < sourceAsArray.length; i++) {
                targetArray.push(sourceAsArray[i]);
            }
        }
    }

    /**
     *
     * @param target
     * @param path
     */
    convertEntityToId(target: any, path: any) {
        let value: any = get(target, path);

        if (isUndefined(value) || isNull(value)) {
            set(target, path, null);
        } else if (isString(value)) {
            // Nothing to do, already an id
        } else {
            let setValue = isEmpty(value._id) ? null : value._id;
            set(target, path, setValue);
        }
    }

    removeValue(target: any, path: any) {

        // Split the path string
        let pathNodes = path.split(".");
        let key = pathNodes.pop();

        if (pathNodes.length > 0) {
            let value: any = get(target, pathNodes.join("."));
            if (value) {
                delete value[key];
            }
        } else {
            delete target[key];
        }
    }
}

