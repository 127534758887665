import {Email, IEmail} from "../..";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IApplicationPortalSettings {
    enabled: boolean;
    alertEmails: Array<IEmail>;
}

@Serializable()
export class ApplicationPortalSettings implements IApplicationPortalSettings {
    @JsonProperty() enabled: boolean = false;
    @JsonProperty({ type: Email }) alertEmails: Array<Email> = [];

    static getQueryFields(): string[] {
        return [
            'enabled',
            ...Email.getQueryFields().map(it => `alertEmails.${it}`)
        ]
    }
}

