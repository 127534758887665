import {Enum, EnumElement} from "../../Enum";

export class PersonLocation extends EnumElement {}

export class PersonLocationEnum extends Enum<PersonLocation> {

    static readonly WILL_TRAVEL = new PersonLocation('WILL_TRAVEL', 'Will travel to animal');
    static readonly STUDIO = new PersonLocation('STUDIO', 'In own studio');

    static readonly ELEMENTS = [
        PersonLocationEnum.WILL_TRAVEL,
        PersonLocationEnum.STUDIO
    ];
    static readonly INSTANCE = new PersonLocationEnum();

    constructor() {
        super(PersonLocationEnum.ELEMENTS);
    }
}
