import {InstantFilterCategory} from './InstantFilterCategory';

export class NullFilterCategory extends InstantFilterCategory {

    constructor(label: string) {
        super(label, null);
    }

    activate() {
        // Do nothing on purpose
    }
}
