import {IntegrationMetadata} from "../IntegrationMetadata";

export function guineaPigIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}

function translateBreed(sparkieBreed: string): string {

    // Adopt A Pet doesn't do breeds.  The species is all they support.
    return "Guinea Pig";
}

function translateColor(sparkieColor: string): string {
    if (colors.has(sparkieColor)) {
        return sparkieColor;
    }
    switch (sparkieColor) {
        // These are Sparkie colors that AAP doesn't have
        case "Albino":
        case "Himalayan":
            return "White";
        case "Silver Marten":
        case "Blue / Gray":
        case "Sable":
        case "Lilac":
            return "Silver or Gray";
        case "Brown / Chocolate":
            return "Brown or Chocolate";
        case "Tan":
            return "Blonde";
        case "Orange / Red":
            return "Orange";
        case "Tortoiseshell":
            return "Calico";
        case "Agouti":
        case "Roan":
        case "Dalmatian":
            return "Multi";
        default:
            return "";
    }
}

// NOTE: These are really small animal colors!
const colors = new Set<string>([
    "Yellow",
    "White",
    "Tortoiseshell",
    "Tan or Beige",
    "Silver or Gray",
    "Sable",
    "Red",
    "Orange",
    "Multi",
    "Lilac",
    "Golden",
    "Cream",
    "Calico",
    "Buff",
    "Brown or Chocolate",
    "Blonde",
    "Black",
    "Albino or Red-Eyed White"
]);
