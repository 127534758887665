import {EnumElement, Enum} from "../../../Enum";

export class RabbitSize extends EnumElement {}

export class RabbitSizeEnum extends Enum<RabbitSize> {

    static readonly EXTRA_SMALL = new RabbitSize('EXTRA_SMALL', 'Extra Small (< 3.5 pounds)');
    static readonly SMALL = new RabbitSize('SMALL', 'Small (3-6 pounds)');
    static readonly MEDIUM = new RabbitSize('MEDIUM', 'Medium (6-8 pounds)');
    static readonly LARGE = new RabbitSize('LARGE', 'Large (8-13 pounds)');
    static readonly EXTRA_LARGE = new RabbitSize('EXTRA_LARGE', 'Very Large (13+ pounds)');

    static readonly ELEMENTS = [
        RabbitSizeEnum.EXTRA_SMALL,
        RabbitSizeEnum.SMALL,
        RabbitSizeEnum.MEDIUM,
        RabbitSizeEnum.LARGE,
        RabbitSizeEnum.EXTRA_LARGE
    ];
    static readonly INSTANCE= new RabbitSizeEnum();

    private constructor() {
        super(RabbitSizeEnum.ELEMENTS);
    }
}

export class RabbitSizeMetric extends EnumElement {}

export class RabbitSizeEnumMetric extends Enum<RabbitSizeMetric> {

    static readonly EXTRA_SMALL = new RabbitSizeMetric('EXTRA_SMALL', 'Extra Small (< 1.5 kg)');
    static readonly SMALL = new RabbitSizeMetric('SMALL', 'Small (1.5-2.5 kg)');
    static readonly MEDIUM = new RabbitSizeMetric('MEDIUM', 'Medium (2.5-3.5 kg)');
    static readonly LARGE = new RabbitSizeMetric('LARGE', 'Large (3.5-6 kg)');
    static readonly EXTRA_LARGE = new RabbitSizeMetric('EXTRA_LARGE', 'Very Large (6+ kg)');

    static readonly ELEMENTS = [
        RabbitSizeEnumMetric.EXTRA_SMALL,
        RabbitSizeEnumMetric.SMALL,
        RabbitSizeEnumMetric.MEDIUM,
        RabbitSizeEnumMetric.LARGE,
        RabbitSizeEnumMetric.EXTRA_LARGE
    ];
    static readonly INSTANCE = new RabbitSizeEnumMetric();

    private constructor() {
        super(RabbitSizeEnumMetric.ELEMENTS);
    }
}
