import {EnumElement, Enum} from "../../../Enum";

export class CatCharacteristics extends EnumElement {}

export class CatCharacteristicsEnum extends Enum<CatCharacteristics> {

    static readonly HOUSE_TRAINED = new CatCharacteristics('HOUSE_TRAINED', 'House Trained');
    static readonly DECLAWED = new CatCharacteristics('DECLAWED', 'Declawed');
    static readonly FERAL = new CatCharacteristics('FERAL', 'Feral');
    static readonly SOCIAL = new CatCharacteristics('SOCIAL', 'Social');
    static readonly SHY = new CatCharacteristics('SHY', 'Shy');
    static readonly SPECIAL_NEEDS = new CatCharacteristics('SPECIAL_NEEDS', 'Special Needs');
    static readonly SEPARATION_ANXIETY = new CatCharacteristics('SEPARATION_ANXIETY', 'Separation anxiety');

    static readonly ELEMENTS = [
        CatCharacteristicsEnum.HOUSE_TRAINED,
        CatCharacteristicsEnum.DECLAWED,
        CatCharacteristicsEnum.FERAL,
        CatCharacteristicsEnum.SOCIAL,
        CatCharacteristicsEnum.SHY,
        CatCharacteristicsEnum.SPECIAL_NEEDS,
        CatCharacteristicsEnum.SEPARATION_ANXIETY
    ];
    static readonly INSTANCE = new CatCharacteristicsEnum();

    private constructor() {
        super(CatCharacteristicsEnum.ELEMENTS);
    }
}
