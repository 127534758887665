import {EnumElement, Enum} from "../../../Enum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";

export class ParvoTestOutcome extends EnumElement {

    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        switch (subType) {
            case ParvoTestOutcomeEnum.PARVO_POSITIVE.view:
                outcomes.push(MedicalConditionEnum.PARVO.createCondition(ConditionStatusEnum.POSITIVE));
                break;
            case ParvoTestOutcomeEnum.PARVO_NEGATIVE.view:
                outcomes.push(MedicalConditionEnum.PARVO.createCondition(ConditionStatusEnum.NEGATIVE));
                break;
            default:
                outcomes.push(MedicalConditionEnum.PARVO.createCondition(ConditionStatusEnum.UNKNOWN));
                break;
        }

        return outcomes;
    }
}

export class ParvoTestOutcomeEnum extends Enum<ParvoTestOutcome> {

    static readonly PARVO_POSITIVE = new ParvoTestOutcome('PARVO_POSITIVE', 'Parvo positive');
    static readonly PARVO_NEGATIVE = new ParvoTestOutcome('PARVO_NEGATIVE', 'Parvo negative');

    static readonly ELEMENTS = [
        ParvoTestOutcomeEnum.PARVO_POSITIVE,
        ParvoTestOutcomeEnum.PARVO_NEGATIVE
    ];
    static readonly INSTANCE = new ParvoTestOutcomeEnum();

    private constructor() {
        super(ParvoTestOutcomeEnum.ELEMENTS);
    }
}
