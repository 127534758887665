import {EnumElement, Enum} from "../../Enum";

export class AnimalGender extends EnumElement {

    getSimpleGender() {
        switch (this) {
            case AnimalGenderEnum.FEMALE_NOT_SPAYED:
            case AnimalGenderEnum.FEMALE_SPAYED:
                return "Female";
            case AnimalGenderEnum.MALE_NOT_NEUTERED:
            case AnimalGenderEnum.MALE_NEUTERED:
                return "Male";
            default:
                return "Unknown";
        }
    }
}

export class AnimalGenderEnum extends Enum<AnimalGender> {

    static readonly FEMALE_NOT_SPAYED = new AnimalGender('FEMALE_NOT_SPAYED', 'Female (Not Spayed)');
    static readonly FEMALE_SPAYED = new AnimalGender('FEMALE_SPAYED', 'Female (Spayed)');
    static readonly MALE_NOT_NEUTERED = new AnimalGender('MALE_NOT_NEUTERED', 'Male (Not Neutered)');
    static readonly MALE_NEUTERED = new AnimalGender('MALE_NEUTERED', 'Male (Neutered)');

    static readonly ELEMENTS = [
        AnimalGenderEnum.FEMALE_NOT_SPAYED,
        AnimalGenderEnum.FEMALE_SPAYED,
        AnimalGenderEnum.MALE_NOT_NEUTERED,
        AnimalGenderEnum.MALE_NEUTERED
    ];
    static readonly INSTANCE = new AnimalGenderEnum();

    constructor() {
        super(AnimalGenderEnum.ELEMENTS);
    }
}
