import type { EntityId } from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";

// Part of PersonModel, saved in the db for text search and supporting current animal projection for the summary view
export interface IAnimalHistory<T> {
    animal: T;
    animalName: string;
    animalId: string;
    status: string;                     // AnimalStatusEnum
    start: Date;
    end: Date;
}

@Serializable()
export class AnimalHistory implements IAnimalHistory<EntityId> {

    @JsonProperty() animal: EntityId = null;
    @JsonProperty() animalName: string = '';
    @JsonProperty() animalId: string = '';
    @JsonProperty() status: string = '';                    // AnimalStatusEnum
    @JsonProperty() start: Date = null;
    @JsonProperty() end: Date = null;

    static getQueryFields(): string[] {
        return [
            'animal',
            'animalName',
            'animalId',
            'status',
            'start',
            'end'
        ]
    }
}

// This is a projection of an Animal & AnimalStatus that is used in PersonDetails and AgencyDetails.
export interface IAnimalHistoryFull {
    _id: string;
    name: string;
    status: string;             // AnimalStatusEnum
    availability: string;       // AnimalAvailabilityEnum
    start: Date;
    end: Date;
    note: string;
}

@Serializable()
export class AnimalHistoryFull implements IAnimalHistoryFull {

    @JsonProperty() _id: string = null;
    @JsonProperty() name: string = '';
    @JsonProperty() status: string = '';                    // AnimalStatusEnum
    @JsonProperty() availability: string = null;            // AnimalAvailabilityEnum
    @JsonProperty() start: Date = null;
    @JsonProperty() end: Date = null;
    @JsonProperty() note: string = '';

    static getQueryFields(): string[] {
        return [
            '_id',
            'name',
            'status',
            'availability',
            'start',
            'end',
            'note'
        ]
    }
}
