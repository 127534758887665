import {FilterBase} from './FilterBase';
import {IQuery} from "../ArpResource";

export class InstantFilter extends FilterBase {

    active = false;
    saved: any;

    constructor(stateKey) {
        super(stateKey);
    }

    observe(parent) {
        parent.observer.observe(this, "active", () => {
            parent.updateActive();
        });
    }

    isActive() {
        return this.active;
    }

    configureResource(resource: IQuery): void {
    }

    clearFilter() {
        this.active = false;
    }

    save() {
        this.saved = {
            active: this.active
        };
    }

    restore() {
        this.active = this.saved.active;
    }

    getStateKey() {
        return `${this.propertyName}`;
    }

    getState() {
        return  {
        };
    }

    setState(state) {
        this.active = true;
    }
}
