import {JsonProperty, Serializable} from "typescript-json-serializer";
import { Entity } from "../common/IEntity";
import type { EntityId, IEntity } from "../common/IEntity";

/*
    Setting up Transfers
        A destination admin will invite a source via email.
        A source admin will accept an invitation and the relationship will be established
        Either party can revoke the transfer relationship
        Each relationship is uni-directional.
        Source / Destination should be part of the Tenant entity

    Transfer Request:
        Source admin authorizes the Transfer request
        Each animal request needs to be accepted by a destination admin
        Need a new view for this, don't add unaccepted transfers in Animals till accepted
        Must be a root Entity (not in Tenant) as there will be hundreds of in/out
        Only exists in the destination tenant. Not used for history!

    Transfer History:
        We must be able to show the transfers in/out in a dedicated view.
        Transfer Out -> Query the animals for the status == TRANSFERRED_SPARKIE
        Transfer In -> Query the animals for the intake mode == TRANSFERRED_SPARKIE


    New Transfers Page (Details Page)
        Pending Transfers
            All unaccepted transfers
        Incoming Transfers
        Outgoing Transfers

 */

/**
 * Represents a request to transfer Animal data from the destination Tenant perspective.  Also serves as a
 * record of the transfer when status == accepted. Only exists in the Destination Tenant
 */
export interface ITransferRequest<T> extends IEntity<T> {

    // These are set by the Source Tenant
    sourceTenantId: T;
    sourceTenantName: string;   // For searching
    sourceAnimalId: T;
    sourceAnimalName: string;   // For searching, name at intake
    transferFileUrls: Array<string>;
    transferNotes: Array<Date>;

    // These are updated when accepted by the Destination Tenant
    transferStatus: string;     // Pending | Accepted | Rejected?
    acceptedBy: string;
    acceptedDate: Date;
    animalId: T;
    animalName: string;         // For searching?
}

@Serializable()
export class TransferRequest extends Entity implements ITransferRequest<EntityId> {
    @JsonProperty() sourceTenantId: EntityId = null;
    @JsonProperty() sourceTenantName: string = '';
    @JsonProperty() sourceAnimalId: EntityId = null;
    @JsonProperty() sourceAnimalName: string = '';
    @JsonProperty() transferFileUrls: Array<string> = [];
    @JsonProperty() transferNotes: Array<Date> = [];

    @JsonProperty() transferStatus: string = '';
    @JsonProperty() acceptedBy: string = '';
    @JsonProperty() acceptedDate: Date = null;
    @JsonProperty() animalId: EntityId = null;        // Destination tenant animal id, for linking
    @JsonProperty() animalName: string = '';                // Destination tenant animal name, for linking


    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'sourceTenantId',
            'sourceTenantName',
            'sourceAnimalId',
            'sourceAnimalName',
            'transferFileUrls',
            'transferNotes',

            'transferStatus',
            'acceptedBy',
            'acceptedDate',
            'animalId',
            'animalName',
        ]
    }
}

export function createDefaultTransferRequest<T extends ITransferRequest<any>>(): T {
    return new TransferRequest() as any as T;
}