import {AccessControl, Query, Permission} from 'accesscontrol'
import set from "lodash/set";
import get from "lodash/get";

import {UserRole, UserRoleEnum} from "../model/user/UserRoleEnum";
import {ModelResource, ModelResourceEnum} from "./ModelResourceEnum";

const MANAGER_UPDATE_RESOURCES: Array<ModelResource> = [
    ModelResourceEnum.AGENCY,
    ModelResourceEnum.ANIMAL,
    ModelResourceEnum.APPLICATION,
    ModelResourceEnum.DONATION,
    ModelResourceEnum.PERSON,
    ModelResourceEnum.TASK
];

const ADMIN_UPDATE_RESOURCES: Array<ModelResource> = [
    ModelResourceEnum.AGENCY,
    ModelResourceEnum.ANIMAL,
    ModelResourceEnum.APPLICATION,
    ModelResourceEnum.DONATION,
    ModelResourceEnum.PERSON,
    ModelResourceEnum.TASK,

    ModelResourceEnum.APPLICATION_TEMPLATE,
    ModelResourceEnum.TEMPLATE,
    ModelResourceEnum.TENANT,
    ModelResourceEnum.TRANSFER_REQUEST,
    ModelResourceEnum.USER,
];

const ADMIN_DELETE_RESOURCES: Array<ModelResource> = [
    ModelResourceEnum.AGENCY,
    ModelResourceEnum.ANIMAL,
    ModelResourceEnum.APPLICATION,
    ModelResourceEnum.DONATION,
    ModelResourceEnum.PERSON,
    ModelResourceEnum.TASK,

    ModelResourceEnum.APPLICATION_TEMPLATE,
    ModelResourceEnum.TEMPLATE,
    ModelResourceEnum.TRANSFER_REQUEST,
    ModelResourceEnum.USER,
];

const UserSelfEditableAttributes = [
    "*",
    "!active",
    "!role",
];


export function evaluateAttributes(permission: Permission, attributes: Array<string>): boolean {
    if (attributes == null) {
        return true;
    } else {
        return attributes.every((path) => {
            const test = set({}, path, true);
            let filtered = permission.filter(test);

            return get(filtered, path, false);
        });
    }
}

/**
 * Singleton that defines the Role Based Authorization Controls (RBAC) used by the Sparkie application.  Roles are
 * enforced in the Client by making hiding unauthorized UI elements.  Rules are also enforced by the Server's REST API,
 * so that any Client hacking will be ineffective, and just result in server errors.
 *
 * NOTE: The API we use has the concept of ownership.  But in sparkie there are multiple users in a tenant, so the
 * only time that we consider ownership is with the modification of user records.
 *
 * NOTE: For ownership to work the caller must check either the xxxOwn (if applicable) or the xxxAny
 */
export class SparkieAccessControl {

    private readonly accessControl: AccessControl = new AccessControl();

    constructor() {
        this.initialize()
    }

    initialize() {

        let allResources = ModelResourceEnum.INSTANCE.elements.map((it) => it.model);
        let managerUpdateResources = MANAGER_UPDATE_RESOURCES.map((it) => it.model)
        let adminUpdateResources = ADMIN_UPDATE_RESOURCES.map((it) => it.model)
        let adminDeleteResources = ADMIN_DELETE_RESOURCES.map((it) => it.model)

        //
        // Unauthenticated - Represents an unauthenticated user.  Used only to force the framework to commit
        //                   the denied grants.  No user will ever be assigned this role.
        //
        this.accessControl.deny(UserRoleEnum.UNAUTHENTICATED.model)
            .readAny(allResources)
            .updateAny(allResources)
            .createAny(allResources)
            .deleteAny(allResources);

        //
        // User - A user that can view all resources.  Unable to make changes to anything other than their
        //        own User account.
        //
        this.accessControl.grant(UserRoleEnum.USER.model)
            .readAny(allResources)
            .updateOwn(ModelResourceEnum.USER.model, UserSelfEditableAttributes);

        //
        // Manager - A user that has limited access (create, read, update) to all resources EXCEPT for
        //           Tenant settings.  Can't delete any resource.
        //
        this.accessControl.grant(UserRoleEnum.MANAGER.model)
            .readAny(allResources)
            .updateAny(managerUpdateResources)
            .createAny(managerUpdateResources)
            .updateOwn(ModelResourceEnum.USER.model, UserSelfEditableAttributes);

        //
        // Admin - Represents a principle of the Rescue that has full create, read, update & delete access
        //         to all resources.
        //
        this.accessControl.grant(UserRoleEnum.ADMIN.model)
            .readAny(allResources)
            .updateAny(adminUpdateResources)
            .createAny(adminUpdateResources)
            .deleteAny(adminDeleteResources)
            .updateOwn(ModelResourceEnum.USER.model, UserSelfEditableAttributes);

        this.accessControl.deny(UserRoleEnum.ADMIN.model)
            .deleteOwn(ModelResourceEnum.USER.model);

        //
        // Owner - Same as Admin, but can't be deleted
        //
        this.accessControl.grant(UserRoleEnum.OWNER.model)
            .readAny(allResources)
            .updateAny(adminUpdateResources)
            .createAny(adminUpdateResources)
            .deleteAny(adminDeleteResources)
            .updateOwn(ModelResourceEnum.USER.model, UserSelfEditableAttributes);

        this.accessControl.grant(UserRoleEnum.OWNER.model)
            .deleteOwn(ModelResourceEnum.USER.model);
    }

    can(role: UserRole): Query {
        return this.accessControl.can(role ? role.model : UserRoleEnum.UNAUTHENTICATED.model);
    }
}