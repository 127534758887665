import {IMedicalRecord, MedicalRecord} from "../common/IMedicalRecord";
import {IMiscRecord, MiscRecord} from "../common/IMiscRecord";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import type { EntityId } from "../common/IEntity";

export interface ITemplateItem<T> {
    medicalRecord: IMedicalRecord<T>;
    miscRecord: IMiscRecord<T>;
    basicRecord: string;
    applicationRecord: string;
}

@Serializable()
export class TemplateItem implements ITemplateItem<EntityId> {
    @JsonProperty() medicalRecord: MedicalRecord = null;
    @JsonProperty() miscRecord: MiscRecord = null;
    @JsonProperty() basicRecord: string = '';
    @JsonProperty() applicationRecord: string = '';

    static getQueryFields(): string[] {
        return [
            ...MedicalRecord.getQueryFields().map(it => `medicalRecord.${it}`),
            ...MiscRecord.getQueryFields().map(it => `miscRecord.${it}`),
            'basicRecord',
            'applicationRecord'
        ]
    }
}