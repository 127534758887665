import {EnumElement, Enum} from "../../Enum";

export class TemplateType extends EnumElement {}

export class TemplateTypeEnum extends Enum<TemplateType> {

    static readonly MEDICAL = new TemplateType('MEDICAL', 'Medical Record Template');
    static readonly MISC = new TemplateType('MISC', 'Training/Grooming Template');
    static readonly ANIMAL_TASK = new TemplateType('ANIMAL_TASK', 'Animal Tasks Template');
    static readonly PERSON_TASK = new TemplateType('PERSON_TASK', 'Person Tasks Template');
    static readonly APPLICATION_TASK = new TemplateType('APPLICATION_TASK', 'Application Tasks Template');

    static readonly ELEMENTS = [
        TemplateTypeEnum.MEDICAL,
        TemplateTypeEnum.MISC,
        TemplateTypeEnum.ANIMAL_TASK,
        TemplateTypeEnum.PERSON_TASK,
        TemplateTypeEnum.APPLICATION_TASK
    ];
    static readonly INSTANCE = new TemplateTypeEnum();

    private constructor() {
        super(TemplateTypeEnum.ELEMENTS);
    }
}
