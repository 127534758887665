import {buildKey, buildMap, IntegrationMetadata} from "../IntegrationMetadata";

export function catIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}

function translateBreed(sparkieBreed: string): string {
    let breedKey = buildKey(sparkieBreed);

    let petFinderBreed = catBreedMap.get(breedKey);

    if (petFinderBreed) {
        return petFinderBreed;
    } else {
        switch (sparkieBreed) {
            case "Tabby":
            case "Tuxedo":
                return "Domestic Mediumhair";
            default:
                return "";
        }
    }
}

function translateColor(sparkieColor: string): string {
    if (catColors.has(sparkieColor)) {
        return sparkieColor;
    }
    switch (sparkieColor) {
        case "Black":
            return "Black (All)";
        case "Black & White / Tuxedo":
            return "Black & White or Tuxedo";
        case "Cream Point":
        case "Cream / Ivory":
            return "Cream or Ivory";
        case "Blue Cream":
            return "Cream or Ivory (Mostly)";
        case "Blue Point":
        case "Smoke":
            return "Gray or Blue";
        case "Brown / Chocolate":
        case "Chocolate Point":
            return "Brown or Chocolate";
        case "Calico":
        case "Dilute Calico":
            return "Calico or Dilute Calico";
        case "Buff / Tan / Fawn":
            return "Tan or Fawn";
        case "Orange / Red":
        case "Orange & White":
            return "Orange or Red";
        case "Dilute Tortoiseshell":
            return "Tortoiseshell";
        case "Gray / Blue / Silver":
            return "Gray, Blue or Silver Tabby";
        case "Tabby (Brown / Chocolate)":
            return "Brown Tabby";
        case "Tabby (Gray / Blue / Silver)":
            return "Gray, Blue or Silver Tabby";
        case "Tabby (Tiger Striped)":
            return "Tiger Striped";
        case "Tabby (Orange / Red)":
            return "Orange or Red Tabby";
        case "Gray & White":
        case "Seal Point":
        case "Lilac Point":
        case "Flame Point":
        case "Buff & White":
            return "White (Mostly)";
        case "Tabby (Buff / Tan / Fawn)":
            return "Tan or Fawn Tabby";
        case "Tabby (Leopard / Spotted)":
            return "Spotted Tabby/Leopard Spotted";
        case "Torbie":
            return "Tortoiseshell";

        default:
            return "";
    }
}

const catBreeds = new Set<string>([
    "Abyssinian",
    "American Bobtail",
    "American Curl",
    "American Shorthair",
    "American Wirehair",
    "Balinese",
    "Bengal",
    "Birman",
    "Bombay",
    "British Shorthair",
    "Burmese",
    "Calico",
    "Chartreux",
    "Colorpoint Shorthair",
    "Cornish Rex",
    "Cymric",
    "Devon Rex",
    "Domestic Longhair",
    "Domestic Mediumhair",
    "Domestic Shorthair",
    "Egyptian Mau",
    "European Burmese",
    "Exotic",
    "Havana Brown",
    "Hemingway/Polydactyl",
    "Himalayan",
    "Japanese Bobtail",
    "Javanese",
    "Korat",
    "LaPerm",
    "Maine Coon",
    "Manx",
    "Munchkin",
    "Norwegian Forest Cat",
    "Ocicat",
    "Oriental",
    "Persian",
    "Polydactyl/Hemingway",
    "RagaMuffin",
    "Ragdoll",
    "Russian Blue",
    "Scottish Fold",
    "Selkirk Rex",
    "Siamese",
    "Siberian",
    "Singapura",
    "Snowshoe",
    "Somali",
    "Sphynx",
    "Tonkinese",
    "Turkish Angora",
    "Turkish Van",
]);

const catBreedMap = buildMap(catBreeds);

const catColors = new Set<string>([
    "Black (All)",
    "Cream or Ivory",
    "Cream or Ivory (Mostly)",
    "Spotted Tabby/Leopard Spotted",
    "Black (Mostly)",
    "Black & White or Tuxedo",
    "Brown or Chocolate",
    "Brown or Chocolate (Mostly)",
    "Brown Tabby",
    "Calico or Dilute Calico",
    "Gray or Blue",
    "Gray or Blue (Mostly)",
    "Gray, Blue or Silver Tabby",
    "Orange or Red",
    "Orange or Red (Mostly)",
    "Orange or Red Tabby",
    "Tan or Fawn",
    "Tan or Fawn (Mostly)",
    "Tan or Fawn Tabby",
    "Tiger Striped",
    "Tortoiseshell",
    "White",
    "White (Mostly)",
]);
