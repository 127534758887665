import {Address, IAddress} from "../common/IAddress";
import {Email, IEmail} from "../common/IEmail";
import {IPhone, Phone} from "../common/IPhone";
import {FileUpload, IHasFiles} from "../common/IFileUpload";
import {IHasPhotos, PhotoUpload} from "../common/IPhotoUpload";
import {IIntegration, Integration} from "./IIntegration";
import {IEntity, Entity, EntityId} from "../common/IEntity";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import {ILocaleSettings, LocaleSettings} from "./ILocaleSettings";
import {ISocialSettings, SocialSettings} from "./ISocialSettings";
import {INotificationSettings, NotificationSettings} from "./INotificationSettings";
import {ApplicationPortalSettings, IApplicationPortalSettings} from "./IApplicationPortalSettings";
import {IWebsitePortalSettings, WebsitePortalSettings} from "./IWebsitePortalSettings";
import {ITransferSource, TransferSource} from "./ITransferSource";
import {ITransferDestination, TransferDestination} from "./ITransferDestination";

export const SPARKIE_DEMO_ID = '5959b061d7accc0004757cb7';
export const SPARKIE_DEMO_KEY = 2;
export const SPARKIE_NORTH_ID = '56cbe413e4b036a44ac59743';
export const SPARKIE_NORTH_KEY = 1;
export const SPARKIE_SOUTH_ID = '5959b0a1db41e700047fa7fc';
export const SPARKIE_SOUTH_KEY = 7;

export interface ITenant<T> extends IEntity<T>, IHasPhotos, IHasFiles {
    // These are readonly for the clients
    tenantKey: number;
    logKey: string;         // Virtual function in the model
    active: boolean;
    nextAnimalId: number;
    nextApplicationId: number;
    nextUserId: number;

    // Below here is editable by the clients
    name: string;
    address : IAddress;

    email: Array<IEmail>;
    phone: Array<IPhone>;

    website: string;
    infoLink: string;
    adoptLink: string;
    fosterLink: string;
    donateLink: string;

    local: ILocaleSettings;
    speciesPreference: Array<string>;

    social: ISocialSettings;
    notifications: INotificationSettings;
    applicationPortal: IApplicationPortalSettings;
    websitePortal: IWebsitePortalSettings;
    integrations: Array<IIntegration>;

    transferSources: Array<ITransferSource<T>>;
    transferDestinations: Array<ITransferDestination<T>>;
}

export type ITenantEntityPartial<T> = Omit<ITenant<T>, 'tenantKey' | 'logKey' | 'nextAnimalId' | 'nextApplicationId' | 'nextUserId' | 'active'>

@Serializable()
export class TenantEntity extends Entity implements ITenantEntityPartial<EntityId> {

    @JsonProperty() readonly tenantKey: number;

    // Below here is editable by the clients
    @JsonProperty() name: string = '';
    @JsonProperty() address : Address = new Address();

    @JsonProperty({ type: Email }) email: Array<Email> = [];
    @JsonProperty({ type: Phone }) phone: Array<Phone> = [];

    @JsonProperty() website: string = '';
    @JsonProperty() infoLink: string = '';
    @JsonProperty() adoptLink: string = '';
    @JsonProperty() fosterLink: string = '';
    @JsonProperty() donateLink: string = '';

    @JsonProperty({ type: PhotoUpload }) photos: Array<PhotoUpload> = [];
    @JsonProperty({ type: FileUpload }) files: Array<FileUpload> = [];

    @JsonProperty() local: LocaleSettings = new LocaleSettings();
    @JsonProperty() speciesPreference: Array<string> = [];

    @JsonProperty() social: SocialSettings = new SocialSettings();
    @JsonProperty() notifications: NotificationSettings = new NotificationSettings();
    @JsonProperty() applicationPortal: ApplicationPortalSettings = new ApplicationPortalSettings();
    @JsonProperty() websitePortal: WebsitePortalSettings = new WebsitePortalSettings();
    @JsonProperty({ type: Integration }) integrations: Array<Integration> = [];

    @JsonProperty({ type: TransferSource }) transferSources: Array<ITransferSource<EntityId>> = [];
    @JsonProperty({ type: TransferDestination }) transferDestinations: Array<ITransferDestination<EntityId>> = [];


    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'tenantKey',
            'name',
            ...Address.getQueryFields().map(it => `address.${it}`),
            ...Email.getQueryFields().map(it => `email.${it}`),
            ...Phone.getQueryFields().map(it => `phone.${it}`),
            'website',
            'infoLink',
            'adoptLink',
            'fosterLink',
            'donateLink',
            'speciesPreference',
            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`),
            ...FileUpload.getQueryFields().map(it => `files.${it}`),
            ...LocaleSettings.getQueryFields().map(it => `local.${it}`),
            ...SocialSettings.getQueryFields().map(it => `social.${it}`),
            ...NotificationSettings.getQueryFields().map(it => `notifications.${it}`),
            ...ApplicationPortalSettings.getQueryFields().map(it => `applicationPortal.${it}`),
            ...WebsitePortalSettings.getQueryFields().map(it => `websitePortal.${it}`),
            ...Integration.getQueryFields().map(it => `integrations.${it}`),
            ...TransferSource.getQueryFields().map(it => `transferSources.${it}`),
            ...TransferDestination.getQueryFields().map(it => `transferDestinations.${it}`)
        ]
    }

    static getReadOnlyFields(): string[] {
        return [];
    }
}

@Serializable()
export class TenantDetails extends TenantEntity {

    static getQueryFields(): string[] {
        return [
            ...TenantEntity.getQueryFields(),
        ]
    }
}

