import {Decimal128} from "bson";
import {Money} from "../common/Money";
import {AnimalStatusEnum} from "./AnimalStatusEnum";
import {DonationTypeEnum} from "../donation/DonationTypeEnum";
import {orderBy} from "lodash";
import {IMedicalRecord} from "../common/IMedicalRecord";
import {MedicalRecordTypeEnum} from "./medical/MedicalRecordTypeEnum";
import {IMiscRecord} from "../common/IMiscRecord";
import {MiscRecordTypeEnum} from "./MiscRecordTypeEnum";
import {TrainingTypeEnum} from "./TrainingTypeEnum";
import {AnimalDetails} from "./IAnimal";

function validAmount(amount: Decimal128): boolean {
    if (amount) {
        try {
            let money = Money.fromDecimal128(amount);
            return !money.isZero();
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
}

/**
 * Consolidate all the financial records for an animal into a collection financeRecords: Array<IFinanceRecord>.
 *
 * NOTE:  Requires the following fields be fetched from the DB:
 *          guardians, medicalRecords, miscRecords, donations (joined from donations).
 *
 *          financeRecords

 * @param animalDetails
 */
export function processFinance(animalDetails: AnimalDetails) {

    animalDetails.financeRecords = [];

    // Summarize Status records
    for (let statusRecord of animalDetails.guardians) {
        let statusEnum = AnimalStatusEnum.INSTANCE.fromModel(statusRecord.status);

        if (statusEnum.hasAmount() && validAmount(statusRecord.amount)) {
            animalDetails.financeRecords.push({
                date: statusRecord.start,
                type: statusEnum === AnimalStatusEnum.ADOPTED ? "Income" : "Expense",
                details: AnimalStatusEnum.INSTANCE.fromModel(statusRecord.status).amountLabel(),
                amount: statusRecord.amount,
                provider: statusEnum.hasPerson() ? statusRecord.person : statusRecord.agency,
                providerModule: statusEnum.hasPerson() ? "personDetails" : "agencyDetails",
                providerName: statusEnum.hasPerson() ? statusRecord.personFullName : statusRecord.agencyName
            })
        }
    }

    // Summarize Medical records
    for (let medicalRecord of animalDetails.medicalRecords) {
        if (validAmount(medicalRecord.cost)) {
            animalDetails.financeRecords.push({
                date: medicalRecord.dateGiven,
                type: "Expense",
                details: getMedicalDescription(medicalRecord),
                amount: medicalRecord.cost,
                provider: medicalRecord.agency ? medicalRecord.agency : medicalRecord.person,
                providerModule: medicalRecord.agency ? "agencyDetails" : "personDetails",
                providerName: medicalRecord.agency ? medicalRecord.agencyName : medicalRecord.personFullName
            })
        }
    }

    // Summarize Misc records
    for (let miscRecord of animalDetails.miscRecords) {
        if (validAmount(miscRecord.cost)) {
            animalDetails.financeRecords.push({
                date: miscRecord.date,
                type: "Expense",
                details: getMiscDescription(miscRecord),
                amount: miscRecord.cost,
                provider: miscRecord.agency ? miscRecord.agency : miscRecord.person,
                providerModule: miscRecord.agency ? "agencyDetails" : "personDetails",
                providerName: miscRecord.agency ? miscRecord.agencyName : miscRecord.personFullName
            })
        }
    }

    // Summarize Donations
    for (let donation of animalDetails.donations) {

        if (DonationTypeEnum.hasDollarAmount(donation.type) && validAmount(donation.amount)) {
            animalDetails.financeRecords.push({
                date: donation.date,
                type: "Income",
                details: DonationTypeEnum.INSTANCE.fromModel(donation.type).view,
                amount: donation.amount,
                provider: donation.agency ? donation.agency : donation.person,
                providerModule: donation.agency ? "agencyDetails" : "personDetails",
                providerName: donation.agency ? donation.agencyName : donation.personFullName
            })
        }
    }

    animalDetails.financeRecords = orderBy(animalDetails.financeRecords, ['date'], ['desc']);
}

function getMedicalDescription<T>(item: IMedicalRecord<T>) : string {
    switch (item.category) {
        // Medication -> Medication Type -> Medication Brand - > Dosage
        case MedicalRecordTypeEnum.MEDICATION.model:
            if (item.subType) {
                return `${MedicalRecordTypeEnum.INSTANCE.fromModel(item.category).view}: ${item.type} (${item.subType})`;
            } else {
                return `${MedicalRecordTypeEnum.INSTANCE.fromModel(item.category).view}: ${item.type} (Generic)`;
            }

        // Vaccination -> Vaccine Type -> XXXXXX -> Rabies Tag
        case MedicalRecordTypeEnum.VACCINATION.model:
            return `${MedicalRecordTypeEnum.INSTANCE.fromModel(item.category).view}: ${item.type}`;

        // Procedure -> Procedure Type -> Outcome -> XXXXXX
        case MedicalRecordTypeEnum.PROCEDURE.model:
            return `${MedicalRecordTypeEnum.INSTANCE.fromModel(item.category).view}: ${item.type}`;

        // Other Medical -> XXXXXX -> XXXXXX -> Detail
        case MedicalRecordTypeEnum.OTHER.model:
            return `${MedicalRecordTypeEnum.INSTANCE.fromModel(item.category).view}: ${item.details}`;

        default:
            return "";
    }
}

function getMiscDescription<T>(item: IMiscRecord<T>) : string {
    const typeEnum = MiscRecordTypeEnum.INSTANCE.fromModel(item.type);

    switch (item.type) {
        case MiscRecordTypeEnum.TRAINING.model:
            const subTypeEnum = TrainingTypeEnum.INSTANCE.fromModel(item.subType);
            return `${typeEnum.view}: ${subTypeEnum.view}`;

        case MiscRecordTypeEnum.GROOMING.model:
            return `${typeEnum.view}`;

        default:
            return "";
    }
}


export function calculateIncome(animalDetails: AnimalDetails) : Money {
    let income = new Money(0);

    for (let record of animalDetails.financeRecords) {
        if (record.type === "Income") {
            income = income.plus(Money.fromDecimal128(record.amount));
        }
    }

    return income;
}

export function calculateExpenses(animalDetails: AnimalDetails) : Money {
    let expenses = new Money(0);

    for (let record of animalDetails.financeRecords) {
        if (record.type === "Expense") {
            expenses = expenses.plus(Money.fromDecimal128(record.amount));
        }
    }

    return expenses;
}

