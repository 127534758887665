import $ from 'jquery';

import {inject} from 'aurelia-framework';

import {Action} from '../actions/Action';
import {ArpLogger, ArpLogManager} from "..";
import {BusyState} from "./BusyState";

/**
 * Singleton for showing modal forms.
 */
@inject(Element)
export class ArpModal {

    title: string;
    content: any;
    actions: Array<Action>;
    leftActions: Array<Action>;
    cancelAction: Action;
    closeAction: Action;
    okAction: Action;
    customCancelAction: Action;
    spinning: boolean = false;
    modalSize: string;

    private NORMAL_WIDTH = "";
    private WIDE_WIDTH = "wide-modal";
    private element: Element;
    private logger: ArpLogger;
    private modal: any;         // This is a ref to root <div> with the modal class.

    constructor(element: Element) {
        this.element = element;
        this.logger = ArpLogManager.getLogger("arp-modal");
        this.cancelAction = new Action().withLabel("Cancel").withPerformCallback((action: Action) => this.hide());
        this.closeAction = new Action().withLabel("Close").withPerformCallback((action: Action) => this.hide());
        this.okAction = new Action().withLabel("Ok").withPerformCallback((action: Action) => this.hide());
    }

    /**
     * NOTE: This view is attached at application startup, not each time it is shown/hidden
     */
    attached() {
        this.callModal({show: false});

        $(this.modal).on('hidden.bs.modal', (e) => {
            this.resetContent();
        });

        // Occurs when the modal is fully shown (after CSS transitions have completed).
        // NOTE: Since the modal content returns a Promise this doesn't work as intended, the content area will not
        // be rendered at the time of the event!
        $(this.modal).on('shown.bs.modal', () => {
            let modalFocusElement = $(this.modal).find('[data-modalfocus]:first');

            if (modalFocusElement.length === 0) {
                let firstInputElement = $(this.modal).find('.modal-body').find('*').filter(':input:visible:first');
                firstInputElement.focus();
            } else {
                modalFocusElement.focus();
            }
        });

        // If a pushstate has previously happened and the back button is clicked, hide any modals.
        $(window).on('popstate', () => {
            this.hide();
        });
    }

    // TODO: Change this to pass the content Class & Model
    show(title: string, content: any, actions: Array<Action>, leftActions: Array<Action> = [], wideMode: boolean = false, customCancelAction=null) {
        this.title = title;
        this.content = content;
        this.actions = actions;
        this.leftActions = leftActions;
        this.customCancelAction = customCancelAction;

        this.modalSize = wideMode ? this.WIDE_WIDTH : this.NORMAL_WIDTH;
        this.spinning = false;

        if (this.content instanceof BusyState) {
            this.content.busyObserver = (busy) => {
                this.spinning = busy;
            }
        }

        this.logger.info("Show modal: " + this.title);

        this.callModal('show')
    }

    cancel() {
        if (this.customCancelAction) {
            this.customCancelAction.perform();
        } else {
            this.hide();
        }
    }

    hide() {
        this.logger.info("Hide modal: " + this.title);

        // Hide the modal, which will trigger the onHidden event and reset the modal
        this.callModal('hide');

        return true;
    }

    disableActions() {
        for (let action of this.actions) {
            action.withEnabled(false);
        }
        for (let action of this.leftActions) {
            action.withEnabled(false);
        }
    }

    resetContent() {
        this.title = null;
        this.content = null;
        this.actions = null;
        this.leftActions = null;
        this.customCancelAction = null;
        this.spinning = false;
    }

    private callModal(fn: any) {
        let theModal = $(this.modal);
        (<any>theModal).modal(fn);
    }
}
