import {Enum, EnumElement} from "../../Enum";

export class FosterHas extends EnumElement {}

export class FosterHasEnum extends Enum<FosterHas> {

    static readonly DOGS = new FosterHas('DOGS', 'Dog(s)');
    static readonly CATS = new FosterHas('CATS', 'Cat(s)');
    static readonly YOUNG_CHILDREN = new FosterHas('YOUNG_CHILDREN', 'Children ages < 3');
    static readonly CHILDREN = new FosterHas('CHILDREN', 'Children ages 3-12');
    static readonly TEENAGERS = new FosterHas('TEENAGERS', 'Children ages 12+');
    static readonly ROOMMATES = new FosterHas('ROOMMATES', 'Roommates');

    static readonly ELEMENTS = [
        FosterHasEnum.DOGS,
        FosterHasEnum.CATS,
        FosterHasEnum.YOUNG_CHILDREN,
        FosterHasEnum.CHILDREN,
        FosterHasEnum.TEENAGERS,
        FosterHasEnum.ROOMMATES
    ];
    static readonly INSTANCE = new FosterHasEnum();

    constructor() {
        super(FosterHasEnum.ELEMENTS);
    }
}
