
// TODO: REMOVE JQUERY!!!
import $ from 'jquery';

import {autoinject, customAttribute} from 'aurelia-framework';

import {Observer} from '../Observer';
import {Action} from "../index";

/**
 * Used to bind an action to a button element:
 *
 * <button action.bind="action" title="${action.label}" class="btn btn-default btn-xs no-print" type="button" ></button>
 */
@customAttribute('action')
@autoinject()
export class ArpActionAttribute {
    private element: Element;
    private observer: Observer;
    private elementType: string;
    private value: Action;

    constructor(element: Element, observer: Observer) {
        this.element = element;
        this.observer = observer;
    }

    bind(bindingContext, overrideContext) {
        this.elementType = $(this.element).prop('tagName').toUpperCase();

        if (this.value === null) {
            throw new Error("Invalid action attribute");
        }
        
        this.observer.observe(this.value, 'enabled', () => this.updateEnabled());
        this.observer.observe(this.value, 'visible', () => this.updateVisible());
        this.observer.observe(this.value, 'label', () => this.updateLabel());
        this.observer.observe(this.value, 'glyph', () => this.updateGlyph());
        this.observer.observe(this.value, 'icon', () => this.updateIcon());
        this.observer.observe(this.value, 'id', () => this.updateTestAttribute());


        $(this.element).click(() => {
           this.value.perform();
        });
    }

    attached() {
        this.updateEnabled();
        this.updateVisible();
        this.updateLabel();
        this.updateGlyph();
        this.updateIcon();
        this.updateTestAttribute();
    }

    updateEnabled() {
        if (this.value.enabled) {
            $(this.element).removeClass('disabled');
        } else {
            $(this.element).addClass('disabled');
        }
    }

    updateVisible() {
        if (this.value.visible) {
            $(this.element).show();
        } else {
            $(this.element).hide();
        }
    }

    updateLabel() {
        if (!this.value.label) {
            return;
        }

        if (this.elementType === "BUTTON") {
            $(this.element).html(this.value.label);
        } else if (this.elementType === "DIV") {
            $(this.element).html(this.value.label);
        } else if (this.elementType === "A") {
            // if the anchor has no child nodes then just set the text directly
            if ($(this.element).children().length === 0) {
                $(this.element).text(this.value.label);
            } else {
                let firstSpan = $(this.element).children('span').first();
                if (firstSpan) {
                    $(firstSpan).text(this.value.label);
                }
            }
        } else if (this.elementType === "SPAN") {
            $(this.element).html(this.value.label);
        } else {
            throw new Error("Action can't update label on " + this.elementType);
        }
    }

    updateGlyph() {
        if (!this.value.glyph) {
            return;
        }

        if (this.elementType === "BUTTON") {
            // <span class="glyphicon glyphicon-plus"/>
            $(this.element).html(`<span class="glyphicon ${this.value.glyph}"/>`);
        } else {
            throw new Error("Action can't update glyph on " + this.elementType);
        }
    }

    updateIcon() {
        if (!this.value.icon) {
            return;
        }

        if (this.elementType === "BUTTON" && this.value.label) {
            $(this.element).html(`<i class="fa ${this.value.icon} arp-i-shim"/></i>`).append(this.value.label);
        } else if (this.elementType === "BUTTON") {
            $(this.element).html(`<i class="fa ${this.value.icon}"/></i>`);
        } else {
            throw new Error("Action can't update icon on " + this.elementType);
        }
    }

    private updateTestAttribute() {
        $(this.element).attr("data-test-id", this.value.id);
    }
}

