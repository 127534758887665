import {Container} from "aurelia-framework";
import {SessionState} from "../auth/SessionState";
import {ViewStateManager} from "arp-framework";
import set from "lodash/set";
import get from "lodash/get";

/**
 * Class that provides access to the browser history view state.
 */
export class ArpViewState {

    private sessionState: SessionState;     // Singleton
    private vsm: ViewStateManager;          // Singleton

    constructor() {
        this.vsm = Container.instance.get(ViewStateManager);
        this.sessionState = Container.instance.get(SessionState);
    }

    save(path: string, value: any) {

        // Get the current state
        let currentState = this.vsm.getBrowserState();

        // Append the new bits
        set(currentState, this.getKey(path), value);

        // Resave
        this.vsm.replaceBrowserState(currentState);
    }

    restore(path: string) : any {

        // queuedViewState is set into the vsm before executing a direct navigation event from the dashboard
        // NOTE: queuedViewState is not known to the browser history (yet).
        let queuedViewState = this.vsm.dequeueViewState();

        if (queuedViewState) {
            this.vsm.replaceBrowserState(queuedViewState);
        }

        // pushState is the state that is stored in the browsers history
        const state = this.vsm.getBrowserState();

        return get(state, this.getKey(path));
    }

    getKey(path: string) {
        return `${this.sessionState.activeTenant.tenantKey}-${this.sessionState.activeUser.userKey}-${path}`;
    }
}
