import {EnumElement, Enum} from "../../Enum";

export class DonationChannel extends EnumElement {}

export class DonationChannelEnum extends Enum<DonationChannel> {

    static readonly ONLINE_CAMPAIGN = new DonationChannel('ONLINE_CAMPAIGN', 'Online Campaign');
    static readonly ADOPTION_EVENT = new DonationChannel('ADOPTION_EVENT', 'Adoption Event');
    static readonly FUNDRAISING_EVENT = new DonationChannel('FUNDRAISING_EVENT', 'Fundraising Event');
    static readonly OTHER = new DonationChannel('OTHER', 'Other');

    static readonly ELEMENTS = [
        DonationChannelEnum.ONLINE_CAMPAIGN,
        DonationChannelEnum.ADOPTION_EVENT,
        DonationChannelEnum.FUNDRAISING_EVENT,
        DonationChannelEnum.OTHER
    ];
    static readonly INSTANCE = new DonationChannelEnum();

    private constructor() {
        super(DonationChannelEnum.ELEMENTS);
    }
}