import {Enum, EnumElement} from "../../Enum";

export class TransportCarSize extends EnumElement {}

export class TransportCarSizeEnum extends Enum<TransportCarSize> {

    static readonly SEDAN = new TransportCarSize('SEDAN', 'Sedan');
    static readonly VAN = new TransportCarSize('VAN', 'Van');
    static readonly SUV = new TransportCarSize('SUV', 'SUV');

    static readonly ELEMENTS = [
        TransportCarSizeEnum.SEDAN,
        TransportCarSizeEnum.VAN,
        TransportCarSizeEnum.SUV
    ];
    static readonly INSTANCE = new TransportCarSizeEnum();

    constructor() {
        super(TransportCarSizeEnum.ELEMENTS);
    }
}