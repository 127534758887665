import {FilterBase} from './FilterBase';
import {Enum, EnumElement} from "@sparkie/shared-model/src";
import {IQuery} from "../ArpResource";
import {isArray, isFunction } from 'lodash';

export class EnumListFilter<T extends EnumElement> extends FilterBase {

    selectedElements: Array<string> = [];       // Model values
    enumeration: Enum<T>;

    constructor(propertyName: string, enumeration: Enum<T>, initializer?: any) {
        super(propertyName);

        this.enumeration = enumeration;

        // TODO: Document what this does!
        if (initializer) {
            if (isFunction(initializer)) {
                for (let enumElement of this.enumeration.elements) {
                    if (initializer(enumElement)) {
                        this.selectedElements.push(enumElement.model);
                    }
                }
            } else if (isArray(initializer)) {
                Array.prototype.push.apply(this.selectedElements, initializer);
            } else {
                this.selectedElements.push(initializer);
            }
        }
    }

    isActive() : boolean {
        return this.selectedElements.length > 0;
    }

    clearFilter() : void {
        
        // Clear array without breaking observation
        if (this.selectedElements.length > 0) {
            this.safeClearArray(this.selectedElements);
        }
    }
    
    observe(parent: any) {
        parent.observer.observeArray(this.selectedElements, (changes) => {
            parent.updateActive();
        });
    }

    configureResource(resource: IQuery) {

        resource.withPropertyIn(this.propertyName, this.selectedElements);
    }

    getStateKey() : string {
        return `${this.propertyName}.${this.enumeration.constructor.name}`;
    }

    getState() : any {
        return  {
            selectedElements: this.selectedElements.slice(0)
        };
    }

    setState(state: any) {
        this.safeClearArray(this.selectedElements);
        Array.prototype.push.apply(this.selectedElements, state.selectedElements);
    }

    save() {
        this.saved = this.selectedElements.slice(0);
    }

    restore() {
        this.safeClearArray(this.selectedElements);
        Array.prototype.push.apply(this.selectedElements, this.saved);
    }

    // getSelectedElement(index: number) {
    //     return this.enumeration.fromModel(this.selectedElements[index]);
    // }
}

