import {Entity, EntityId, IEntity} from "@sparkie/shared-model/src";
import {assignWith, get, isArray, isDate, isEqual, isNull, isString, isUndefined, set } from "lodash";
import cloneDeepWith from "lodash/cloneDeepWith";

export function refreshEntity(entity: Entity, json: any) {
    let source = cloneDeepWith(json);

    refreshObject(entity, source);
}

function refreshObject(target: any, source: any) {
    let changed = !isEqual(source, target);

    if (changed) {
        for (let key of getKeys(source)) {
            refreshValue(target, source, key);
        }

        for (let key of getKeys(target)) {
            if (source[key] === undefined) {
//                    console.log(`refreshObject: remove ${key} from ${JSON.stringify(target)}`);
                delete target[key];
            }
        }
    }

    // Enable this if we think refresh isn't working
    // if (!isEqual(source, target)) {
    //     console.log(`refreshObject: failed`);
    // }
}

function getKeys(instance: any) {
    return instance ? Object.keys(instance) : [];
}

function refreshValue(target: any, source: any, key: any) {

    let targetValue = get(target, key);
    let sourceValue = get(source, key);

    let changed = !isEqual(sourceValue, targetValue);

    if (changed) {
//            console.log(`refreshValue: change ${key} from ${JSON.stringify(targetValue)} to ${JSON.stringify(sourceValue)}`);

        switch (typeof(sourceValue)) {
            case 'string':
            case 'number':
            case 'boolean':
                set(target, key, sourceValue);
                break;

            case 'object':
                if (isUndefined(targetValue) || isNull(targetValue)) {
                    set(target, key, sourceValue);
                } else if (isNull(sourceValue)) {
                    set(target, key, sourceValue);
                } else if (isString(sourceValue)) {
                    set(target, key, sourceValue);
                } else if (isDate(sourceValue)) {
                    set(target, key, sourceValue);
                } else if (isArray(sourceValue)) {
                    refreshArray(targetValue, sourceValue);
//                        console.log(`refreshValue: array ${key} is now ${JSON.stringify(target[key])}`);
                } else {
                    // Recursion here!!!
                    refreshObject(targetValue, sourceValue);
                    // Recursion here!!!
                }
                break;

            case 'undefined':
            case 'function':
            case 'symbol':
            default:
                console.log(`Cant refresh ${key} with type ${typeof(sourceValue)}`);
                break;
        }
    }
}

function refreshArray(target: Array<any>, source: Array<any>) {
    // source -> target

    if (isUndefined(target)) {
        target = [];
    }

    // Delete case handled here target = [a,b,c] src = [a, c]
    if (source.length < target.length) {
        target.splice(source.length);
    }

    // Adds and refreshes are handled here
    for (let sourceIndex = 0; sourceIndex < source.length; sourceIndex++) {
        let sourceElement = source[sourceIndex];

        if (sourceIndex >= target.length) {
            // No target element so make an new index
            target.push(sourceElement);
        } else if (typeof(sourceElement) === 'object') {
            // Recursion here
            refreshObject(target[sourceIndex], sourceElement);
            // Recursion here
        } else {
            // Observation will break with direct replacement, so make sure to use a function to update!
            target.splice(sourceIndex, 1, sourceElement);
        }
    }
}

function getLogString(obj: any) {
    return isUndefined(obj) || isNull(obj) || isUndefined(obj._id) ? "__new__" : obj._id;
}

/**
 * This class is just a wrapper for a JSON entity
 *
 * TODO: Make this class utils that operate on IEntity instances.
 * TODO: See here: http://choly.ca/post/typescript-json/
 */
export class ArpEntity implements IEntity<EntityId> {

    _id: string;            // TODO: Move to IEntity

    createdAt: Date;        // TODO: Move to IEntity
    updatedAt: Date;        // TODO: Move to IEntity

    constructor(that: any) {
        assignWith(this, cloneDeepWith(that));
    }

    /**
     * Refresh this entity with the data values from a json response.
     *
     * NOTE: The ArpEntity is used by the aurelia binding implementation.  Thus there are observers of all
     * the properties and sub-objects contained in this instance.  Thus we can't replace the properties and
     * sub-objects.  We must copy the values from the json response into the exiting structure of this instance.
     *
     * @param json
     */
    refresh(json: any) {
        let source = cloneDeepWith(json);

        refreshObject(this, source);
    }

    toString() {
        return getLogString(this);
    }
}