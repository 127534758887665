/**
 * Clear the contents of an array in a manner that doesn't break observation callbacks.
 *
 * @param anArray
 */
export function safeClearArray(anArray: any[]) {
    if (anArray.length > 0) {
        anArray.splice(0, anArray.length);
    }
}

/**
 * Adds a value to an array only if it doesn't already exist, emulating a Set<T>.
 *
 * @param anArray
 * @param value
 */
export function addIfMissing<T>(anArray: T[], value: T) {
    if (!anArray.includes(value)) {
        anArray.push(value);
    }
}

export async function mapAsync<I, O = I>(arr: Array<I>, mapFn: (it: I) => Promise<O>): Promise<Array<O>> {
    const result: Array<O> = [];

    for await (const it of arr) {
        const r = await mapFn(it);
        result.push(r);
    }

    return result;
}

