import $ from 'jquery';
import EXIF from 'exif-js/exif';
import {bindable, inject, customElement} from 'aurelia-framework';

@customElement("arp-image")
@inject(Element)
export class ArpImageCustomElement {

    @bindable src: string;
    @bindable alt: string;
    @bindable mode: string;    // this is the style to use: arp-table-image | arp-summary-image
    private element: Element;

    constructor(element) {
        this.element = element;
    }

    imageLoad() {
        let imageElement: HTMLImageElement = $(this.element).find("img").get(0) as any as HTMLImageElement;

        EXIF.getData(imageElement.src, function() {
            let orientation = EXIF.getTag(this, "Orientation");
            ArpImageCustomElement.orientImageUsingTransform(imageElement, orientation);
        });
    }

    static orientImageUsingTransform(imageElement: HTMLImageElement, exifOrientation: number) {

        switch (exifOrientation) {
            case 1:     // 0
            default:
                $(imageElement).css('transform', 'rotate(0deg)');
                break;
            case 8:     // 90
                $(imageElement).css('transform', 'rotate(270deg)');
                break;
            case 3:     // 180
                $(imageElement).css('transform', 'rotate(180deg)');
                break;
            case 6:     // 270
                $(imageElement).css('transform', 'rotate(90deg)');
                break;
        }
    }
}
