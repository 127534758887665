import {EnumElement, Enum} from "../../Enum";

export class PaymentMethod extends EnumElement {}

export class PaymentMethodEnum extends Enum<PaymentMethod> {

    static readonly CASH = new PaymentMethod('CASH', 'Cash');
    static readonly CHECK = new PaymentMethod('CHECK', 'Check');
    static readonly CREDIT_CARD = new PaymentMethod('CREDIT_CARD', 'Credit Card');
    static readonly PAYPAL = new PaymentMethod('PAYPAL', 'PayPal');
    static readonly VENMO = new PaymentMethod('VENMO', 'Venmo');
    static readonly REVOLUT = new PaymentMethod('REVOLUT', 'Revolut');
    static readonly ZELLE = new PaymentMethod('ZELLE', 'Zelle');

    static readonly ELEMENTS = [
        PaymentMethodEnum.CASH,
        PaymentMethodEnum.CHECK,
        PaymentMethodEnum.CREDIT_CARD,
        PaymentMethodEnum.PAYPAL,
        PaymentMethodEnum.VENMO,
        PaymentMethodEnum.REVOLUT,
        PaymentMethodEnum.ZELLE
    ];
    static readonly INSTANCE = new PaymentMethodEnum();

    private constructor() {
        super(PaymentMethodEnum.ELEMENTS);
    }
}
