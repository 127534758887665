import set from "lodash/set";
import trim from "lodash/trim";
import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import isArray from "lodash/isArray";
import forEach from "lodash/forEach";
import includes from "lodash/includes";


export const scalar_operator_map = new Map([
    [ 'GT', '$gt' ],
    [ '$gt', 'GT' ],
    [ 'GTE', '$gte' ],
    [ 'LT', '$lt' ],
    [ 'LTE', '$lte' ],
    [ 'EQ', '$eq' ],
    [ 'NE', '$ne' ],
    [ 'IN', '$in' ],
    [ 'NIN', '$nin' ],
    [ 'EXISTS', '$exists' ],
    [ 'REGEX', '$regex'],
    [ 'OPTIONS', '$options']
]);

export const array_operator_map = new Map([
    [ 'AND', '$and' ],
    [ 'OR', '$or' ],
    [ 'NOT', '$not' ],          // Is this only for arrays?
    [ 'ELEM_MATCH', '$elemMatch' ]
]);

export const all_operator_map = new Map([...scalar_operator_map, ...array_operator_map])
export const all_operator_reverse_map = new Map()
all_operator_map.forEach((v, k) => {
    all_operator_reverse_map.set(v, k);
})
export function toMongoOperator(gqlOperator: string): string {
    return all_operator_map.get(gqlOperator);
}

export function toGqlOperator(mongoOperator: string): string {
    return all_operator_reverse_map.get(mongoOperator);
}

export const gql_scalar_operators = [...scalar_operator_map.keys()];
export const gql_array_operators = [...array_operator_map.keys()];
export const gql_all_operators = [...gql_scalar_operators, ...gql_array_operators];

export function toGQL(src: any): any {
    if (isPlainObject(src)) {
        for (let [key, value] of Object.entries(src)) {
            delete src[key];

            if (key.startsWith('$')) {
                key = toGqlOperator(key);
            }

            set(src, key, toGQL(value));
        }
    } else if (isArray(src)) {
        forEach(src, (val, index) => {
            src[index] = toGQL(val);
        });
    }

    return src;
}

export function fromGQL(src: any): any {
    if (isPlainObject(src)) {
        for (let [key, value] of Object.entries(src)) {
            if (includes(gql_all_operators, key)) {
                delete src[key];
                key = toMongoOperator(key);
            }
            src[key] = fromGQL(value);       // TODO: fromGQL???
        }
    } else if (isArray(src)) {
        forEach(src, (val, index) => {
            src[index] = fromGQL(val);
        });
    }

    return src;
}
