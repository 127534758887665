import {Enum, EnumElement} from "../../Enum";

export class PersonStatus extends EnumElement {}

/**
 * Used in Person history to show Animal actions from the perspective of the Person.
 */
export class PersonStatusEnum extends Enum<PersonStatus> {

    static readonly ADOPTED = new PersonStatus('ADOPTED', 'Adopted');
    static readonly FOSTER = new PersonStatus('FOSTER', 'Fostered');
    static readonly IN_TRANSPORT = new PersonStatus('IN_TRANSPORT', 'Transported');
    static readonly RETURNED = new PersonStatus('RETURNED', 'Returned');
    static readonly TRIAL_ADOPTION = new PersonStatus('TRIAL_ADOPTION', 'Trial adoption');
    static readonly OTHER = new PersonStatus('OTHER', 'Other');

    static readonly ELEMENTS = [
        PersonStatusEnum.ADOPTED,
        PersonStatusEnum.FOSTER,
        PersonStatusEnum.IN_TRANSPORT,
        PersonStatusEnum.RETURNED,
        PersonStatusEnum.TRIAL_ADOPTION,
        PersonStatusEnum.OTHER
    ];
    static readonly INSTANCE = new PersonStatusEnum();

    constructor() {
        super(PersonStatusEnum.ELEMENTS);
    }
}
