import { ImageBuffer, JPEG_MIME_TYPE } from "./ImageBuffer";
import { toArrayData, toBlob } from "./image_utils";

/**
 * The quality of the JPEG image.
 * 
 * See here for image quailty: https://squoosh.app/editor
 */
const JPEG_QUALITY = 0.8;

/**
 * Converts an image to JPEG format.
 *
 * @param imageBuffer - The image buffer to be converted. It contains the image data, MIME type, and optional EXIF data.
 * @returns A promise that resolves to a new `ImageBuffer` containing the JPEG image data.
 *
 * @remarks
 * If the input image is already in JPEG format, the function returns the original image buffer.
 * The function creates a new image bitmap from the input image data, draws it onto a canvas, and then converts the canvas content to a JPEG blob.
 * The JPEG blob is then converted back to an array buffer to create the new `ImageBuffer`.
 */
export async function convertImageToJpeg(imageBuffer: ImageBuffer): Promise<ImageBuffer> {
    if (imageBuffer.mimeType === JPEG_MIME_TYPE) {
        return imageBuffer;
    }

    const imageBlob = new Blob([new DataView(imageBuffer.data)], { type: imageBuffer.mimeType });
    const newImage = await createImageBitmap(imageBlob);

    const canvas = document.createElement("canvas");
    canvas.width = newImage.width;
    canvas.height = newImage.height;

    canvas.getContext("2d").drawImage(newImage, 0, 0);

    const blob = await toBlob(canvas, JPEG_MIME_TYPE, JPEG_QUALITY);
    const data = await toArrayData(blob);

    return new ImageBuffer(data, JPEG_MIME_TYPE);
}

