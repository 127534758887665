import {IReportConverter} from "@sparkie/shared-model/src";

export class MicrochipConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {

        if (modelInstance.microchip.number) {
            return `${modelInstance.microchip.number} (${modelInstance.microchip.company})`;
        } else {
            return '';
        }
    }
}

