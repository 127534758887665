import {SortDirectionEnum, SortDirection} from './SortDirectionEnum';
import {IQuery} from "../ArpResource";
import {ArpViewState} from "../ArpViewState";
import {Enum, EnumElement} from "@sparkie/shared-model/src";
import forEach from 'lodash/forEach';
import get from 'lodash/get';

/**
 * Model that controls the sorting of a table.  Consists of a SortTerm for each column.
 */
export class ArpSort {

    private readonly terms: Array<SortTerm> = [];
    private readonly viewState: ArpViewState;

    private selectedTerm: SortTerm = null;
    private defaultTerm: SortTerm = null;
    private changeMarker: number = 0;       // Indirectly used by observers

    constructor(viewState?: ArpViewState) {
        this.viewState = viewState;
    }

    addDefaultTerm(term: SortTerm) : SortTerm {
        this.addTerm(term);
        this.activateTerm(term);

        this.defaultTerm = term;

        return term;
    }

    addTerm(term: SortTerm) : SortTerm {
        term.sortModel = this;
        this.terms.push(term);

        return term;
    }

    get sortActive() {
        if (this.selectedTerm === undefined) {
            return false;
        } else if (this.selectedTerm === null) {
            return false;
        } else {
            return this.selectedTerm.sortOrder != SortDirectionEnum.NONE
        }
    }

    termClicked(term: SortTerm) {
        if (term === this.selectedTerm) {
            term.toggleSortOrder();
        } else {
            this.activateTerm(term);
        }

        this.saveState();
    }

    private activateTerm(term: SortTerm) {

        if (this.selectedTerm !== term) {
            forEach(this.terms, (term: SortTerm) => {
                term.sortOrder = SortDirectionEnum.NONE;
            });

            if (term) {
                term.sortOrder = term.defaultSortOrder;
            }

            this.selectedTerm = term;
        }
    }

    updateSort(searchActive: boolean) {
        forEach(this.terms, (term: SortTerm) => {
            term.sortOrder = SortDirectionEnum.NONE;
        });

        if (searchActive) {
            this.selectedTerm = null;
        } else {
            this.defaultTerm.sortOrder =  this.defaultTerm.defaultSortOrder;
            this.activateTerm(this.defaultTerm);
        }

        this.saveState();
    }

    configureResource(resource: IQuery, searchActive: boolean) {

        if (this.sortActive) {
            this.selectedTerm.configureResource(resource);

            // Can't order by both a sort term and search weighting.
            resource.withWeightedSort(false);
        } else {
            resource.withWeightedSort(searchActive);
        }
    }

    saveState() {
        if (this.viewState) {
            this.viewState.save('sortData',  {
                sortProperty: get(this, "selectedTerm.sortName"),
                sortDirection: get(this, "selectedTerm.sortOrder.model")
            });
        }
        this.changeMarker = this.changeMarker + 1;
    }

    restoreState() {

        if (this.viewState) {
            let sortData: any = this.viewState.restore('sortData');

            if (sortData) {
                for (let term of this.terms) {
                    if (term.sortName === sortData.sortProperty) {
                        term.sortOrder = SortDirectionEnum.INSTANCE.fromModel(sortData.sortDirection);
                        this.selectedTerm = term;
                    } else {
                        term.sortOrder = SortDirectionEnum.NONE;
                    }
                }
            }
        }
    }
}

export abstract class SortTerm {
    sortModel: ArpSort;
    readonly defaultSortOrder: SortDirection;
    readonly sortName: string;

    sortOrder: SortDirection;

    protected constructor(sortName: string, defaultSortOrder: SortDirection = SortDirectionEnum.ASCENDING) {
        this.sortName = sortName;
        this.defaultSortOrder = defaultSortOrder;
        this.sortOrder = SortDirectionEnum.NONE;
    }

    abstract configureResource(resource: IQuery);

    toggleSortOrder() {

        switch (this.sortOrder) {

            case SortDirectionEnum.NONE:
                this.sortOrder = SortDirectionEnum.ASCENDING;
                break;

            case SortDirectionEnum.ASCENDING:
                this.sortOrder = SortDirectionEnum.DESCENDING;
                break;

            case SortDirectionEnum.DESCENDING:
                this.sortOrder = SortDirectionEnum.NONE;
                break;
        }
    }

    termClicked() {
        this.sortModel.termClicked(this);
    }
}

export class PropertySortTerm extends SortTerm {

    readonly propertyName: string;

    constructor(propertyName: string, defaultSortOrder: SortDirection = SortDirectionEnum.ASCENDING) {

        super(propertyName, defaultSortOrder);

        this.propertyName = propertyName;
    }

    configureResource(resource: IQuery) {

        switch (this.sortOrder) {

            case SortDirectionEnum.NONE:
                break;

            case SortDirectionEnum.ASCENDING:
                let ascendingSort = {};
                ascendingSort[this.propertyName] = 1;
                resource.withSort(ascendingSort);
                break;

            case SortDirectionEnum.DESCENDING:
                let descendingSort = {};
                descendingSort[this.propertyName] = -1;
                resource.withSort(descendingSort);
                break;
        }
    }
}

/**
 * Normally enumerated properties are sorted by their text values in the database.  However enums that represent
 * a state progression (like an application) prefer to be sorted by the progression.  We do this by projecting the
 * ordinal value, and then sorting on the projected ordinal value.
 */
export class EnumPropertySortTerm extends PropertySortTerm {
    private readonly enumeration: Enum<EnumElement>;

    constructor(propertyName: string, enumeration: Enum<EnumElement>, defaultSortOrder: SortDirection = SortDirectionEnum.ASCENDING) {

        super(propertyName, defaultSortOrder);

        this.enumeration = enumeration;
    }

    configureResource(resource: IQuery) {

        switch (this.sortOrder) {

            case SortDirectionEnum.NONE:
                break;

            case SortDirectionEnum.ASCENDING:
                resource.withEnumSort(this.propertyName, this.sortOrder, this.enumeration);
                break;

            case SortDirectionEnum.DESCENDING:
                resource.withEnumSort(this.propertyName, this.sortOrder, this.enumeration);
                break;
        }
    }
}
