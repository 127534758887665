import {Decimal128} from "bson";
import {Weight} from "@sparkie/shared-model/src";
import {WeightUnitsEnum} from "@sparkie/shared-model/src";

export class ArpWeightFormatValueConverter {

    toView(value: Decimal128, units = WeightUnitsEnum.IMPERIAL.model) {
        if (value) {
            let weight = Weight.fromDecimal128(value);

            switch (units) {
                case WeightUnitsEnum.METRIC.model:
                    return weight.toMetricString();
                default:
                case WeightUnitsEnum.IMPERIAL.model:
                    return weight.toImperialString();
            }
        } else {
            return "";
        }
    }
}
