import {UserEntity} from "@sparkie/shared-model/src";

export class AuthorizationError extends Error {

    constructor(user: UserEntity, resource: string, action: string) {

        let message = `User ${user.username} with role ${user.role} does not have authorization to ${action} resource ${resource}`;

        super(message);

        this.name = this.constructor.name;
    }
}
