import {NavigationLocation} from "../NavigationLocation";
import {ClientReportSpec} from "./ClientReportSpec";

export class ReportBar {

    private readonly reportSpecs: Array<ClientReportSpec> = [];
    private readonly navigationLocation: NavigationLocation;

    constructor(navigationLocation: NavigationLocation) {
        this.navigationLocation = navigationLocation;
    }

    addReport(report: ClientReportSpec) {
        report.navigationLocation = this.navigationLocation;

        this.reportSpecs.push(report);
    }

    clickReport(report: ClientReportSpec) {
        report.show();
    }
}
