import { EventAggregator } from "aurelia-event-aggregator";
import { Container } from "aurelia-framework";
import { AuthenticationError } from "../auth/AuthenticationError";
import { AuthorizationError } from "../auth/AuthorizationError";
import { GraphQLError, WebApiError } from "../arp-framework/WebApi";
import { SessionManager } from "../auth/SessionManger";
import { ArpLogManager } from "../arp-framework";
import { ArpLogger } from "../arp-framework";
import { ViewStateManager } from "../arp-framework";
import { stringify } from "safe-stable-stringify";
import { showReloadRequired } from "arp-framework/views/ReloadMessage";

/**
 * Class that handle errors in the client.  Errors can occur
 *
 * AuthenticationError - user login session is no longer active.  Need to switch to login app mode
 * AuthorizationError - user is not authorized to perform the given action on a resource
 * WebApiError - There was an error with a REST API call
 * Error - The client threw an exception somewhere.
 *
 * Exceptions can be called:
 *
 * - while performing a router navigation
 * - while processing a http response (with or without nav)
 * - while in a promise chain
 *
 */
export class ErrorHandler {
    private readonly logger: ArpLogger;

    private errorState: any = null;

    constructor() {
        this.logger = ArpLogManager.getLogger(`error-handler`);
        this.configureEventSubscriptions();
    }

    queueErrorState(errorState: any) {
        this.errorState = errorState;
    }

    dequeueErrorState(): any {
        let errorState = this.errorState;
        this.errorState = null;

        return errorState;
    }

    hasErrorState(): boolean {
        return this.errorState !== null;
    }

    configureEventSubscriptions() {
        const eventAggregator = Container.instance.get(EventAggregator);
        eventAggregator.subscribe("router:navigation:processing", this.navigationProcessing.bind(this));
        eventAggregator.subscribe("router:navigation:error", this.navigationError.bind(this));
        eventAggregator.subscribe("router:navigation:complete", this.navigationComplete.bind(this));

        window.addEventListener("onerror", this.onerror.bind(this));
        window.addEventListener("unhandledrejection", this.unhandledrejection.bind(this));
        window.addEventListener("rejectionhandled", this.rejectionhandled.bind(this));
    }

    navigationProcessing(event: any) {
        this.logger.debug(`router:navigation:processing: ${event.instruction.fragment}`);
    }

    navigationError(event: any) {
        this.logger.debug(`router:navigation:error: ${event.instruction.fragment}: ${event.result.output}`);
    }

    navigationComplete(event: any) {
        this.logger.debug(`router:navigation:complete: ${event.instruction.fragment}: ${event.result.output}`);

        if (event.result.output) {
            this.processError(event.result.output);
        }
    }

    onerror(event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
        this.processError(error || event);
    }

    unhandledrejection(event: PromiseRejectionEvent) {
        return this.processError(event.reason || event);
    }

    rejectionhandled(event: PromiseRejectionEvent) {
        return this.processError(event.reason || event);
    }

    processError(error: any) {
        if (error instanceof AuthenticationError) {
            this.logger.warn("AuthenticationError");

            const sessionManager = Container.instance.get(SessionManager);
            sessionManager.reset();
            sessionManager.showLogin();
        } else if (error instanceof AuthorizationError) {
            const ae = error as Error;

            this.logger.error(`AuthorizationError: ${ae.message}`, stringifyError(ae));

            this.showErrorDetailsPage(error);
        } else if (error instanceof WebApiError) {
            this.logger.info(error.asLogMessage(), stringifyError(error));

            this.showErrorDetailsPage(error);
        } else if (error instanceof GraphQLError) {
            this.logger.info(error.asLogMessage(), stringifyError(error));

            this.showErrorDetailsPage(error);
        } else if (error instanceof Error) {
            // This happens when the server is upgraded and the chunk hashes change
            if (isLoadError(error)) {
                this.logger.info(error.message, stringifyError(error));
                showReloadRequired();
            } else {
                this.logger.error(error.message, stringifyError(error));
                this.showErrorDetailsPage(error);
            }
        } else {
            this.logger.error(`Unknown Error: ${stringifyError(error)}`);

            this.showErrorDetailsPage(error);
        }
    }

    showErrorDetailsPage(error: any) {
        this.errorState = error;

        let router = Container.instance.get(ViewStateManager).router;
        if (router) {
            router.navigateToRoute("errorDetails");
        }
    }
}

export function stringifyError(err: any): string {
    const properties = new Set([...Object.getOwnPropertyNames(err), "message", "arguments", "type", "name", "stack", "componentStack"]);

    return stringify(err, Array.from(properties));
}

// TODO: This seems to miss a lot of loading errors.
const loadErrors: Array<string> = ["Loading chunk", "Manifest request to"];

function isLoadError(error: Error) {
    if (error.name === "ChunkLoadError") {
        return true;
    }
    
    for (let loadError in loadErrors) {
        if (error.message.startsWith(loadError)) {
            return true;
        }
    }

    return false;
}
