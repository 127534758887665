import {EnumElement, Enum} from "../../../Enum";

export class GuineaPigCharacteristics extends EnumElement {}

export class GuineaPigCharacteristicsEnum extends Enum<GuineaPigCharacteristics> {

    static readonly HOUSE_TRAINED = new GuineaPigCharacteristics('HOUSE_TRAINED', 'House Trained');
    static readonly SOCIAL = new GuineaPigCharacteristics('SOCIAL', 'Social');
    static readonly SHY = new GuineaPigCharacteristics('SHY', 'Shy');
    static readonly COMES_FOR_TREATS = new GuineaPigCharacteristics('COMES_FOR_TREATS', 'Comes for treats');
    static readonly LIKES_BEING_STROKED = new GuineaPigCharacteristics('LIKES_BEING_STROKED', 'Likes being stroked');
    static readonly SPECIAL_NEEDS = new GuineaPigCharacteristics('SPECIAL_NEEDS', 'Special Needs');

    static readonly ELEMENTS = [
        GuineaPigCharacteristicsEnum.HOUSE_TRAINED,
        GuineaPigCharacteristicsEnum.SOCIAL,
        GuineaPigCharacteristicsEnum.SHY,
        GuineaPigCharacteristicsEnum.COMES_FOR_TREATS,
        GuineaPigCharacteristicsEnum.LIKES_BEING_STROKED,
        GuineaPigCharacteristicsEnum.SPECIAL_NEEDS
    ];
    static readonly INSTANCE = new GuineaPigCharacteristicsEnum();

    private constructor() {
        super(GuineaPigCharacteristicsEnum.ELEMENTS);
    }
}
