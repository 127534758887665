import Autolinker from 'autolinker';

import {autoinject, customAttribute} from 'aurelia-framework';

@customAttribute('arp-autolink')
@autoinject()
export class ArpAutolinkAttribute {

    private element: Element;

    constructor(element: Element) {
        this.element = element;
    }

    attached() {
        var autolinker = new Autolinker( { newWindow: false } );
        this.element.innerHTML = autolinker.link(this.element.innerHTML );
    }
}

