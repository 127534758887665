import assignWith from "lodash/assignWith";
import cloneDeepWith from "lodash/cloneDeepWith";
import {IMedicalRecord, MedicalRecord} from "../common/IMedicalRecord";
import {IMiscRecord, MiscRecord} from "../common/IMiscRecord";
import {FileUpload, IHasFiles} from "../common/IFileUpload";
import {IHasPhotos, PhotoUpload} from "../common/IPhotoUpload";
import {IHasNotes, Note} from "../common/INote";
import { Entity } from "../common/IEntity";
import type { EntityId, IEntity } from "../common/IEntity";
import {AnimalStatusRecord, IAnimalStatusRecord} from "./IAnimalStatusRecord";
import {IWeightRecord, WeightRecord} from "./IWeightRecord";
import {MedicalConditionStatus} from "./IMedicalConditionStatus";
import {DonationDetails, DonationSummary} from "../donation/IDonation";
import {TaskDetails} from "../task/ITask";
import {FinanceRecord} from "./IFinanceRecord";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import {AnimalIntake, IAnimalIntake} from "./IAnimalIntake";
import {AnimalCompatibilities, IAnimalCompatibilities} from "./IAnimalCompatibilities";
import {AnimalProfile, IAnimalProfile} from "./IAnimalProfile";
import {AnimalMicrochip, IAnimalMicrochip} from "./IAnimalMicrochip";
import {AnimalMedical, IAnimalMedical} from "./IAnimalMedical";
import {AnimalBonding, IAnimalBonding} from "./IAnimalBonding";
import {PersonSummary} from "../person/IPerson";
import {VideoLink} from "../common/IVideoLink";

export interface IAnimal<T> extends IEntity<T>, IHasNotes, IHasPhotos, IHasFiles {

    animalId: string;                 // 'RNNNN'
    name: string;
    adoptedName: string;
    dateOfBirth: Date;
    gender: string;                   // AnimalGenderEnum
    species: string;                  // AnimalSpeciesEnum
    size: string;                     // Either DogSizeEnum, CatSizeEnum, or RabbitSizeEnum
    breed: string;                    // Primary breed
    breed2: string;                   // Secondary breed
    color: string;
    color2: string;
    coat: string;
    characteristics: Array<string>;        // AnimalCharacteristicsEnum

    compatibility: IAnimalCompatibilities;
    profile: IAnimalProfile;
    intake: IAnimalIntake<T>;
    microchip: IAnimalMicrochip<T>;
    medical: IAnimalMedical<T>;
    bonding: IAnimalBonding<T>;

    guardians: Array<IAnimalStatusRecord<T>>;
    medicalRecords: Array<IMedicalRecord<T>>;
    miscRecords: Array<IMiscRecord<T>>;
    weightRecords: Array<IWeightRecord>;

    tags: Array<string>;
    videoLinks: Array<VideoLink>;
}

Serializable()
export class AnimalEntity extends Entity implements IAnimal<EntityId> {

    @JsonProperty() animalId: string = '';                 // 'RNNNN'
    @JsonProperty() name: string = '';
    @JsonProperty() adoptedName: string = '';
    @JsonProperty() dateOfBirth: Date = null;
    @JsonProperty() gender: string = '';                   // AnimalGenderEnum
    @JsonProperty() species: string = '';                  // AnimalSpeciesEnum
    @JsonProperty() size: string = '';                     // Either DogSizeEnum, CatSizeEnum, or RabbitSizeEnum
    @JsonProperty() breed: string = '';                    // Primary breed
    @JsonProperty() breed2: string = '';                   // Secondary breed
    @JsonProperty() color: string = '';
    @JsonProperty() color2: string = '';
    @JsonProperty() coat: string = '';
    @JsonProperty() characteristics: Array<string> = [];        // AnimalCharacteristicsEnum

    @JsonProperty() compatibility: AnimalCompatibilities = new AnimalCompatibilities();
    @JsonProperty() profile: AnimalProfile = new AnimalProfile();
    @JsonProperty() intake: AnimalIntake = new AnimalIntake();
    @JsonProperty() microchip: AnimalMicrochip = new AnimalMicrochip();
    @JsonProperty() medical: AnimalMedical = new AnimalMedical();
    @JsonProperty() bonding: AnimalBonding = new AnimalBonding();

    @JsonProperty({ type: AnimalStatusRecord }) guardians: Array<AnimalStatusRecord> = [];
    @JsonProperty({ type: MedicalRecord }) medicalRecords: Array<MedicalRecord> = [];
    @JsonProperty({ type: MiscRecord }) miscRecords: Array<MiscRecord> = [];
    @JsonProperty({ type: WeightRecord }) weightRecords: Array<WeightRecord> = [];

    // TODO: How to relate a medical record to a file upload
    @JsonProperty({ type: PhotoUpload }) photos: Array<PhotoUpload> = [];
    @JsonProperty({ type: FileUpload }) files: Array<FileUpload> = [];
    @JsonProperty() tags: Array<string> = [];
    @JsonProperty({ type: Note }) notes: Array<Note> = [];

    @JsonProperty({ type: VideoLink }) videoLinks: Array<VideoLink> = [];

    // NOTE: This is calculated and projected by the animal query, not stored in the DB
//    readonly currentGuardian: IAnimalStatusRecord = null;

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'animalId',
            'name',
            'adoptedName',
            'dateOfBirth',
            'gender',
            'species',
            'size',
            'breed',
            'breed2',
            'color',
            'color2',
            'coat',
            'characteristics',
            ...AnimalCompatibilities.getQueryFields().map(it => `compatibility.${it}`),
            ...AnimalProfile.getQueryFields().map(it => `profile.${it}`),
            ...AnimalIntake.getQueryFields().map(it => `intake.${it}`),
            ...AnimalMicrochip.getQueryFields().map(it => `microchip.${it}`),
            ...AnimalMedical.getQueryFields().map(it => `medical.${it}`),
            ...AnimalBonding.getQueryFields().map(it => `bonding.${it}`),
            ...MedicalConditionStatus.getQueryFields().map(it => `medical.conditions.${it}`),
            ...AnimalStatusRecord.getQueryFields().map(it => `guardians.${it}`),
            ...MedicalRecord.getQueryFields().map(it => `medicalRecords.${it}`),
            ...MiscRecord.getQueryFields().map(it => `miscRecords.${it}`),
            ...WeightRecord.getQueryFields().map(it => `weightRecords.${it}`),

            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`),
            ...FileUpload.getQueryFields().map(it => `files.${it}`),
            'tags',
            ...Note.getQueryFields().map(it => `notes.${it}`),
            ...VideoLink.getQueryFields().map(it => `videoLinks.${it}`)
        ]
    }
}

@Serializable()
export class AnimalDetails extends AnimalEntity {

    // NOTE: These are calculated and projected by the animal query, not stored in the DB
    @JsonProperty() readonly currentGuardian: AnimalStatusRecord;
    @JsonProperty({ type: DonationSummary }) readonly donations: Array<DonationSummary> = [];

    // There are fetched by the front end for now
    @JsonProperty({ type: TaskDetails }) tasks: Array<TaskDetails> = [];
    @JsonProperty() hasAdoptionApplicationTemplate: boolean = false;
    @JsonProperty() hasFosterApplicationTemplate: boolean = false;
    @JsonProperty() currentPerson: PersonSummary = null;

    // This is calculated by the front end and doesn't exist in the GQL or DB.
    @JsonProperty({ type: FinanceRecord }) financeRecords: Array<FinanceRecord> = [];

    static createInstance(data: any): AnimalDetails {
        return assignWith(new AnimalDetails(), cloneDeepWith(data));
    }

    static getQueryFields(): string[] {
        return [
            ...AnimalEntity.getQueryFields(),
            ...AnimalStatusRecord.getQueryFields().map(it => `currentGuardian.${it}`),
            ...TaskDetails.getQueryFields().map(it => `tasks.${it}`),
            ...DonationDetails.getQueryFields().map(it => `donations.${it}`)
        ]
    }
}

//
// AnimalSummary
//
//
// Visible fields: Photo (requires species), name,  intake date,  status, guardian
// Sortable
// let baseProps = ['animalId', 'photos.smallThumbnailUrl', 'species', 'name', 'adoptedName'];
// let statusProps = ['intake.date', 'currentGuardian.status'];
// let medicalProps = ['gender', 'medical.conditions.condition', 'medical.conditions.status', 'medicalRecords.category', 'microchip.number'];

// REST query: select=animalId,photos,species,name,adoptedName,intake.date,currentGuardian
export type IAnimalSummaryPartial = Omit<AnimalEntity,
    'createdAt' |
    'updatedAt' |
    'dateOfBirth' |
    'gender' |
    'size' |
    'breed' |
    'breed2' |
    'color' |
    'color2' |
    'coat' |
    'characteristics' |
    'compatibility' |
    'profile' |
    'microchip' |
    'medical' |
    'bonding' |
    'guardians' |
    'medicalRecords' |
    'miscRecords' |
    'weightRecords' |
    'files' |
    'tags' |
    'notes' |
    "videoLinks"
    >

@Serializable()
export class AnimalSummary extends Entity implements IAnimalSummaryPartial {
    @JsonProperty() animalId: string = '';                 // 'RNNNN'
    @JsonProperty() name: string = '';
    @JsonProperty() adoptedName: string = '';
    @JsonProperty() species: string = '';                  // AnimalSpeciesEnum

    @JsonProperty() intake: AnimalIntake = new AnimalIntake();

    @JsonProperty({ type: PhotoUpload }) photos: Array<PhotoUpload> = [];

    // NOTE: This is calculated and projected by the animal query, not stored in the DB
    @JsonProperty() readonly currentGuardian: AnimalStatusRecord = null;

    // 'animalId,species,name,adoptedName,intake.date,currentGuardian,photos'
    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'animalId',
            'name',
            'adoptedName',
            'species',
            'intake.date',
            ...AnimalStatusRecord.getQueryFields().map(it => `currentGuardian.${it}`),
            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`)
        ]
    }
}

export type IAnimalDownloadPartial = Omit<AnimalEntity,
    'createdAt' |
    'updatedAt' |
    'dateOfBirth' |
    'gender' |
    'size' |
    'breed' |
    'breed2' |
    'color' |
    'color2' |
    'coat' |
    'characteristics' |
    'compatibility' |
    'profile' |
    'microchip' |
    'medical' |
    'guardians' |
    'medicalRecords' |
    'miscRecords' |
    'weightRecords' |
    'files' |
    'photos' |
    'tags' |
    'notes' |
    "videoLinks"
    >

@Serializable()
export class AnimalDownload extends Entity implements IAnimalDownloadPartial {
    @JsonProperty() animalId: string = '';                 // 'RNNNN'
    @JsonProperty() name: string = '';
    @JsonProperty() adoptedName: string = '';
    @JsonProperty() dateOfBirth: Date = null;
    @JsonProperty() gender: string = '';                   // AnimalGenderEnum
    @JsonProperty() species: string = '';                  // AnimalSpeciesEnum
    @JsonProperty() size: string = '';                     // Either DogSizeEnum, CatSizeEnum, or RabbitSizeEnum
    @JsonProperty() breed: string = '';                    // Primary breed

    @JsonProperty() intake: AnimalIntake = new AnimalIntake();

    @JsonProperty() microchip: AnimalMicrochip = new AnimalMicrochip();
    @JsonProperty() medical: AnimalMedical = new AnimalMedical();
    @JsonProperty() bonding: AnimalBonding = new AnimalBonding();

    // NOTE: This is calculated and projected by the animal query, not stored in the DB
    @JsonProperty() readonly currentGuardian: AnimalStatusRecord = null;

    @JsonProperty() tags: Array<string> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'animalId',
            'name',
            'adoptedName',
            'species',
            'dateOfBirth',
            'gender',
            'size',
            'breed',
            'intake.date',
            'intake.mode',
            ...AnimalMicrochip.getQueryFields().map(it => `microchip.${it}`),
            ...AnimalBonding.getQueryFields().map(it => `bonding.${it}`),

            'medical.health',
            ...MedicalConditionStatus.getQueryFields().map(it => `medical.conditions.${it}`),

            ...AnimalStatusRecord.getQueryFields().map(it => `currentGuardian.${it}`),
            'tags'
        ]
    }
}

export function createDefaultAnimal<T extends IAnimal<any>>(): T {
    return new AnimalEntity() as any as T;
}

