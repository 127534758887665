import {bindable, computedFrom, customElement} from 'aurelia-framework';

@customElement("arp-email")
export class ArpEmailCustomElement {

    @bindable email;

    constructor() {
    }

    @computedFrom('email')
    get type(){
        if (this.email.type) {
            return `(${this.email.type})`;
        } else {
            return "";
        }
    }
}
