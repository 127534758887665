import {Decimal128} from "bson";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IWeightRecord {
    date: Date;
    weightInOunces: Decimal128;     // NOTE: This is treated as Ounces or Grams base on the tenant default units
}

@Serializable()
export class WeightRecord implements IWeightRecord {
    @JsonProperty() date: Date = null;
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    weightInOunces: Decimal128 = null;     // NOTE: This is treated as Ounces or Grams base on the tenant default units

    static getQueryFields(): string[] {
        return [
            'date',
            'weightInOunces'
        ]
    }
}