import {ContactPosition} from "./ContactPositionEnum";
import {JsonProperty, Serializable} from "typescript-json-serializer";
import type { EntityId } from "../common/IEntity";

export interface IContact<T> {
    person: T;

    personFullName: string;
    personLastName: string;

    position: ContactPosition;
}

@Serializable()
export class Contact implements IContact<EntityId> {
    @JsonProperty() person: EntityId = null

    @JsonProperty() personFullName: string = ''
    @JsonProperty() personLastName: string = ''

    @JsonProperty() position: ContactPosition = null;       // TODO: Not a string?

    static getQueryFields(): string[] {
        return [
            'person',
            'personFullName',
            'personLastName',
            'position'
        ]
    }
}
