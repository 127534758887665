import moment from "moment";
import {IReportConverter} from "./IReportConverter";


export class DateConverter implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {
        return formatDate(modelValue);
    }
}

export function formatDate(date: Date): string {
    return date == null ? "" : moment(date).format("YYYY-MM-DD HH:mm:ss");
}