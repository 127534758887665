import {Entity, EntityId, IEntity} from "../common/IEntity";
import {IPersonName, PersonName} from "../person/IPersonName";
import {IHasPhotos, PhotoUpload} from "../common/IPhotoUpload";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IUser<T> extends IEntity<T>, IHasPhotos {

    tenantKey: number;
    userKey: number;           // This is not unique across all tenants!

    active: boolean;

    username: string;         // This must be a working email, TODO: should this be unique?
    role: string;             // UserRoleEnum
    lastLogin: Date;
    name: IPersonName;

    password?: string;        // This only exists in the interface, it's not defined in the db schema
    hash?: string;
    salt?: string;

    resetPasswordToken?: string;
    resetPasswordExpires?: Date;
}

export type IUserEntityPartial<T> = Omit<IUser<T>, 'password' | 'hash' | 'salt'>

@Serializable()
export class UserEntity extends Entity implements IUserEntityPartial<EntityId> {

    @JsonProperty() tenantKey: number = 0;
    @JsonProperty() userKey: number = 0;           // This is not unique across all tenants!

    @JsonProperty() active: boolean = true;

    @JsonProperty() username: string = '';
    @JsonProperty() role: string = '';
    @JsonProperty() lastLogin: Date = null;
    @JsonProperty() name: PersonName = new PersonName();     // Sort on last, display full!

    @JsonProperty() resetPasswordToken?: string = '';
    @JsonProperty() resetPasswordExpires?: Date = null;

    @JsonProperty({ type: PhotoUpload }) photos: Array<PhotoUpload> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            'tenantKey',
            'userKey',
            'active',
            'username',
            'role',
            'lastLogin',
            ...PersonName.getQueryFields().map(it => `name.${it}`),
            'resetPasswordToken',
            'resetPasswordExpires',
            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`)
        ]
    }

    // Readonly fields from the API perspective
    static getReadOnlyFields(): string[] {
        return [
            'tenantKey',
            'userKey',
            'lastLogin',
            ...PersonName.getReadOnlyFields().map(it => `name.${it}`), 
            'password',
            'resetPasswordToken',
            'resetPasswordExpires',
            ...PhotoUpload.getQueryFields().map(it => `photos.${it}`)
        ];
    }
}

//
// UserDetails
//

@Serializable()
export class UserDetails extends UserEntity {
    static getQueryFields(): string[] {
        return [
            ...UserEntity.getQueryFields()
        ]
    }
}

//
// UserSummary
//

// select=photos,name,username,role,active,lastLogin
export type IUserSummary<T> = Omit<IUser<T>, 'tenantKey' | 'userKey' | 'password' | 'hash' | 'salt' >

@Serializable()
export class UserSummary extends Entity implements IUserSummary<EntityId> {
    @JsonProperty() name: PersonName = new PersonName();
    @JsonProperty() username: string = '';
    @JsonProperty() active: boolean = true;
    @JsonProperty() lastLogin: Date = null;
    @JsonProperty() role: string = '';
    @JsonProperty({ type: PhotoUpload }) photos: Array<PhotoUpload> = [];

    static getQueryFields(): string[] {
        return [
            ...Entity.getQueryFields(),
            ...PersonName.getQueryFields().map(it => `name.${it}`),     // Sort on last, display full!
            'username',
            'role',
            'active',           // Filter on active
            'lastLogin',

            'photos.smallThumbnailUrl',
        ]
    }
}
