import type { EntityId } from "../common/IEntity";
import {Decimal128} from "bson";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface IMiscRecord<T> {
    date: Date;
    type: string;         // MiscRecordTypeEnum
    subType: string;
    cost: Decimal128;
    details: string;

    person?: T;
    personFullName?: string;
    personLastName?: string;

    agency?: T;
    agencyName?: string;
}

@Serializable()
export class MiscRecord implements IMiscRecord<EntityId> {
    @JsonProperty() date: Date = null;
    @JsonProperty() type: string = '';         // MiscRecordTypeEnum
    @JsonProperty() subType: string = '';
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    cost: Decimal128 = null;
    @JsonProperty() details: string = '';

    // TODO: optional?
    @JsonProperty() person?: EntityId = null;
    @JsonProperty() personFullName?: string = '';
    @JsonProperty() personLastName?: string = '';

    // TODO: optional?
    @JsonProperty() agency?: EntityId = null;
    @JsonProperty() agencyName?: string = '';

    static getQueryFields(): string[] {
        return [
            'date',
            'type',
            'subType',
            'cost',
            'details',
            'person',
            'personFullName',      // Read-only
            'personLastName',      // Read-only
            'agency',
            'agencyName'      // Read-only
        ]
    }

    static getReadOnlyFields(): string[] {
        return [
            'personFullName',      // Read-only
            'personLastName',      // Read-only
            'agencyName'      // Read-only
        ]
    }
}

export function createDefaultMiscRecord<T>(): IMiscRecord<T> {
    return new MiscRecord() as any as IMiscRecord<T>;
}
