
import {IReportConverter} from "@sparkie/shared-model/src";

export class ObjectConverter implements IReportConverter {

    private readonly converterFunction: (instance: any) => string;

    constructor(converterFunction: (instance: any) => string) {
        this.converterFunction = converterFunction;
    }

    toView(modelValue: any, modelInstance: any) : string {
        return this.converterFunction(modelValue);
    }
}

