import {JsonProperty, Serializable} from "typescript-json-serializer";
import type { EntityId } from "../common/IEntity";

export interface IAnimalBonding<T> {
    animal: T;
    animalName: string;
    animalRescueId: string;
}

@Serializable()
export class AnimalBonding implements IAnimalBonding<EntityId> {
    @JsonProperty() animal: EntityId = null;
    @JsonProperty() animalName: string = '';
    @JsonProperty() animalRescueId: string = '';

    static getQueryFields(): string[] {
        return [
            'animal',
            'animalName',
            'animalRescueId'
        ];
    }
}