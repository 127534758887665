import {BindingEngine, PropertyObserver, CollectionObserver, Disposable, inject, transient} from 'aurelia-framework';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import set from 'lodash/set';

/**
 * Observation helper.  Uses the standard Aurelia observation framework so no polyfill is required.
 * Keeps track of subscriptions so you can unobserve or unobserveAll.
 */
@inject(BindingEngine)
@transient()
export class Observer {
    private bindingEngine: BindingEngine;
    private subscriptions: Map<Object, any>;

    constructor(bindingEngine: BindingEngine) {
        this.bindingEngine = bindingEngine;

        this.subscriptions = new Map();     // Key is the object being observed, value is a set of observers
    }

    bind(subjectInstance: Object, sourceProperty: string, targetInstance: Object, targetProperty: string) {
        this.observe(subjectInstance, sourceProperty, (newValue) => {
            set(targetInstance, targetProperty, newValue);
        });

        // Initial value
        let currentValue = get(subjectInstance, sourceProperty);
        set(targetInstance, targetProperty, currentValue);
    }

    observe(subjectInstance: Object, propertyNames: any, callback: (newValue: any, oldValue: any) => void) {

        forEach(isArray(propertyNames) ? propertyNames : [propertyNames], (propertyName) => {

            let observer: PropertyObserver;

            // TODO: Revisit this when Aurelia is upgraded and supports observation by path
            if (propertyName.indexOf('.') > 0) {

                // support foo.bar.propertyName style path observation
                let tokens = propertyName.split('.');

                let instanceProperty = tokens.splice(-1, 1);     // propertyName
                let instancePath = tokens.join('.');            // foo.bar
                let instanceAtPath = get(subjectInstance, instancePath);

                observer = this.bindingEngine.propertyObserver(instanceAtPath, instanceProperty);
            } else {
                observer = this.bindingEngine.propertyObserver(subjectInstance, propertyName);
            }

            let disposable: Disposable = observer.subscribe(callback);
            this.addSubscription(subjectInstance, disposable);
        });
    }

    // TODO: Rename to observeCollection
    observeArray(array: Array<any> | Map<any, any>, callback: (changeRecords: any) => void) {
        let observer: CollectionObserver = this.bindingEngine.collectionObserver(array);
        let disposable: Disposable = observer.subscribe(callback);

        this.addSubscription(array, disposable);
    }

    unObserve(instance: Object) {
        this.removeSubscription(instance);
    }

    unObserveAll() {
        for (let entry of this.subscriptions) {
            for (let disposable of entry[1]) {
                disposable.dispose();
            }
        }
        this.subscriptions.clear();
    }

    addSubscription(subjectInstance: Object, disposable: Disposable) {
        if (this.subscriptions.has(subjectInstance)) {
            let disposableSet = this.subscriptions.get(subjectInstance);
            disposableSet.add(disposable);
        } else {
            this.subscriptions.set(subjectInstance, new Set([disposable]));
        }
    }

    removeSubscription(subjectInstance: Object) {

        let disposableSet = this.subscriptions.get(subjectInstance);

        for (let disposable of disposableSet) {
            disposable.dispose();
        }

        this.subscriptions.delete(subjectInstance);
    }

    /**
     * Clear the contents of an array in a manner that doesn't break observation callbacks
     *
     * @param anArray
     */
    safeClearArray(anArray: any[]) {
        if (anArray.length > 0) {
            anArray.splice(0, anArray.length);
        }
    }

    /**
     * Reset the contents of an array with new contents in a manner that doesn't break observation callbacks
     * 
     * @param anArray
     * @param newContents
     */
    safeAssignArray(anArray: any[], newContents: any[]) {
        this.safeClearArray(anArray);

        if (newContents.length) {
            Array.prototype.push.apply(anArray, newContents);
        }
    }
}
