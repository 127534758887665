import moment from 'moment';
import {IResource} from "./IResource";
import {Enum, EnumElement} from "@sparkie/shared-model/src";
import {SortDirection, SortDirectionEnum} from "./views/SortDirectionEnum";
import {assign, isArray, isString } from 'lodash';

// TODO: This needs to replace the current ResourceFilter everywhere!
export interface QueryModifier {
    
    /**
     * Configure the query to apply the filter.
     *
     * @param query the query to configure
     */
    configureQuery(query: IQuery);
}

export interface IQuery {
    withId(id: string): this
    withSelectProperties(selectProperties: string) : this
    withGQLProperties(gqlProperties: string[]): this;

    withLimit(limit: number) : this
    withSkip(skip: number) : this
    withSort(sortProperty: any) : this      // TODO: Narrow down the sortProperty type
    withEnumSort(sortProperty: string, sortDirection: SortDirection, enumeration: Enum<EnumElement>) : this

    withWeightedSort(weightedSort: boolean) : this
    withGroup(groupProperty: any) : this
    withAggregate(aggregate: any) : this

    withPropertyIn(propertyPath: string, arrayOfStrings: Array<string>) : this
    // withPropertyAllOf(propertyPath: string, arrayOfStrings: Array<string>) : this

    withPropertyEqual(propertyPath: string, propertyValue: any) : this
    withPropertyNotEqual(propertyPath: string, propertyValue: any) : this
    withPropertyNull(propertyPath: string) : this
    withPropertyNotNull(propertyPath: string) : this
    withDatePropertyBefore(propertyPath: string, last: Date) : this
    withDatePropertyBetween(propertyPath: string, first: Date, last: Date) : this
    withDatePropertyAfter(propertyPath: string, first: Date) : this
    // withArrayPropertyNotEmpty(propertyPath: string, ) : this
    withTextQuery(value: string) : this

    withOrTerms(termArray: any[]) : this
    withAndTerms(termArray: any[]) : this
    withPropertyFilter(propertyPath: string, filterTerm: any) : this

    //
    withTextQuery(value: string) : this
}

/**
 * Abstraction of a REST resource, which live in the URI
 * 
 * scheme:[//[user:password@]host[:port]][/]path[?query][#fragment]
 * 
 * The resource consists of the path and query parts (fragment is unused).
 * 
 * NOTE:  The query part does not correlate directly to a mongoose query.
 * 
 * Supported resource query parameters are:
 * 
 *      select:  the fields to return in the REST response
 *      query: a query/filter defining the desired results
 *      limit: limit the number of documents returned
 *      skip: allows paging into a large dataset
 *      sort: the order in which to return results
 */
export class ArpResource implements IResource, IQuery {

    private readonly resource: string;
    private readonly application: string;
    private readonly version: string;
    private id: string;
    private action: string;
    private queryParameters: Map<string, string>;       // TODO: Map?
    private filterTerms: any;           // TODO: Map?
    private readonly sortTerms: any = {};
    private readonly projection: any = {};
    private weightedSort: boolean;

    //
    constructor(resource: string) {
        this.resource = resource;
        this.application = "/api";
        this.version = "v2";
        this.id = "";
        this.action = "";
        this.queryParameters = new Map();
        this.filterTerms = new Map();     // Key is the property, value is either a single object, or an array of objects that will be OR'd together
    }

    withAction(action: string) : this {
        this.action = action;
        return this;
    }

    withQueryParameter(key: string, value: any) : this {
        if (isString(value)) {
            this.queryParameters.set(key, encodeURIComponent(value));
        } else {
            this.queryParameters.set(key, this.asSafeJsonString(value));
        }
        return this;
    }

    //
    // IQuery
    //

    withId(id: string) : this {
        this.id = id;
        return this;
    }

    withSelectProperties(selectProperties: string) : this {
        this.queryParameters.set("select", selectProperties);
        return this;
    }

    withGQLProperties(gqlProperties: string[]): this {
        throw new Error("WTF");
    }

    withLimit(limit: number) : this {
        this.queryParameters.set('limit', limit.toString());
        return this;
    }

    withSkip(skip: number) : this {
        this.queryParameters.set('skip', skip.toString());
        return this;
    }

    withSort(sortProperty: any) : this {
        assign(this.sortTerms, sortProperty);
        return this;
    }

    withEnumSort(sortProperty: string, sortDirection: SortDirection, enumeration: Enum<EnumElement>) : this {
        let sortEnumProperty = `${sortProperty}_enum`;
        let projection = {
           [sortProperty]: 1,
           [sortEnumProperty]: { $indexOfArray: [ enumeration.elements.map( (it) => { return it.model }), `$${sortProperty}` ] }
        };
        this.withProject(projection);
        let sort = {
            [sortEnumProperty]: sortDirection === SortDirectionEnum.ASCENDING ? 1 : -1
        };
        this.withSort(sort);
        return this;
    }

    private withProject(projection: any): this {
        assign(this.projection, projection);
        return this;
    }

    withWeightedSort(weightedSort: boolean) : this {
        this.weightedSort = weightedSort;
        return this;
    }

    withGroup(groupProperty: any) : this {
        this.queryParameters.set('group', JSON.stringify(groupProperty));
        return this;
    }

    withAggregate(aggregate: any) : this {
        this.queryParameters.set('aggregate', this.asSafeJsonString(aggregate));
        return this;
    }

    withPropertyIn(propertyPath: string, arrayOfStrings: Array<string>) : this {
        // { property: { $in: [ "P", "D" ] } }
        let filter = { $in: arrayOfStrings };
        return this.withPropertyFilter(propertyPath, filter);
    }

    // withPropertyAllOf(propertyPath: string, arrayOfStrings: Array<string>) : this {
    //     // { property: { $all: [ "P", "D" ] } }
    //     let filter = { $all: arrayOfStrings };
    //     return this.withPropertyFilter(propertyPath, filter);
    // }

    withPropertyEqual(propertyPath: string, propertyValue: any) : this {
        let filter = { $eq: propertyValue };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyMatch(propertyPath: string, propertyValue: any) : this {
        let filter = { regex: propertyValue };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyNotEqual(propertyPath: string, propertyValue: any) : this {
        let filter = { $ne: propertyValue };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyNull(propertyPath: string) : this {
        let filter = { $eq: null };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withPropertyNotNull(propertyPath: string) : this {
        let filter = { $ne: null };
        return this.withPropertyFilter(propertyPath, filter);
    }

    withDatePropertyBefore(propertyPath: string, last: Date) : this {

        // { property: { '$gte': new Date('3/1/2014'), '$lte': new Date('3/16/2014') } }
        let filter = {
            $gte: moment().subtract(20, "years").toDate(),
            $lte: last
        };

        return this.withPropertyFilter(propertyPath, filter);
    }

    withDatePropertyBetween(propertyPath: string, first: Date, last: Date) : this {

        // { property: { '$gte': new Date('3/1/2014'), '$lte': new Date('3/16/2014') } }
        let filter = {
            $gte: first,
            $lte: last
        };

        return this.withPropertyFilter(propertyPath, filter);
    }

    withDatePropertyAfter(propertyPath: string, first: Date) : this {

        let filter = {
            $gte: first
        };

        return this.withPropertyFilter(propertyPath, filter);
    }

    // withArrayPropertyNotEmpty(propertyPath: string, ) : this {
    //     let filter = { $exists: true, $ne: [] };
    //
    //     return this.withPropertyFilter(propertyPath, filter);
    // }

    withTextQuery(value: string) : this {
        /*
        TODO: Sort by score (but work with ArpSort also?)
         db.articles.find(
             { $text: { $search: "cake" } },
             { score: { $meta: "textScore" } }
             ).sort( { score: { $meta: "textScore" } } )
         */

        let escapedValue = this.escapeTerm(value);

        let filter = { $search: escapedValue };
        return this.withPropertyFilter('$text', filter);
    }

    withOrTerms(termArray: any[]) : this {
        return this.withPropertyFilter('$or', termArray);
    }

    withAndTerms(termArray: any[]) : this {
        return this.withPropertyFilter('$and', termArray);
    }

    // If multiple filter terms exist this is an AND operation
    withPropertyFilter(propertyPath: string, filterTerm: any) : this {

        let filterObject: any = {};
        filterObject[propertyPath] = filterTerm;

        if (this.filterTerms.has(propertyPath)) {
            let existingTerm = this.filterTerms.get(propertyPath);

            if (isArray(existingTerm)) {
                existingTerm.push(filterObject);
            } else {
                let filterArray = [existingTerm];
                filterArray.push(filterObject);
                this.filterTerms.set(propertyPath, filterArray);
            }
        } else {
            this.filterTerms.set(propertyPath, filterObject);
        }

        return this;
    }

    get resourcePath() : string {
        let pathElements = [this.application, this.version, this.resource];

        return this.buildResourcePath(pathElements);
    }

    private buildResourcePath(pathElements: any[]) : string {
        if (this.id) {
            pathElements.push(this.id);
        }

        if (this.action) {
            pathElements.push(this.action);
        }

        let path = pathElements.join('/');

        let queryTerms = [];

        if (this.filterTerms.size > 0) {
            let filters = [];

            for (let filterObject of Array.from(this.filterTerms.values())) {

                if (isArray(filterObject)) {
                    // Need to build $or subquery
                    let wrapper: any = {};
                    wrapper['$and'] = filterObject;
                    filters.push(wrapper);
                } else {
                    filters.push(filterObject);
                }
            }

            let filterString = JSON.stringify(filters);
            let queryString = `{"$and":${filterString}}`;
            let encodedFilter = encodeURIComponent(queryString);

            queryTerms.push(`query=${encodedFilter}`);
        }

        if (this.projection != null && Object.keys(this.projection).length > 0) {
            this.queryParameters.set('projection', JSON.stringify(this.projection));
        }

        if (this.weightedSort || Object.keys(this.sortTerms).length > 0) {
            let finalSort = {};

            // The weighted search needs to be first!
            if (this.weightedSort) {
                assign(finalSort, { score: { $meta: "textScore" } });
            }

            assign(finalSort, this.sortTerms);

            this.queryParameters.set('sort', JSON.stringify(finalSort));
        }

        if (this.queryParameters.size > 0) {
            for (let entry of Array.from(this.queryParameters.entries())) {
                queryTerms.push(`${entry[0]}=${entry[1]}`);
            }
        }

        if (queryTerms.length > 0) {
            return [path, queryTerms.join('&')].join('?');
        } else {
            return path;
        }
    }

    private escapeTerm(term: string) : string {
        switch (term) {
            case 'true':
            case 'false':
                return `"${term}"`;

            default:
                return term;
        }
    }

    private asSafeJsonString(value: any) : string {
        let json = JSON.stringify(value);
        let encoded = encodeURIComponent(json);

        return encoded;
    }
}
