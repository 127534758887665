import {autoinject, transient, bindable, customElement} from 'aurelia-framework';

@transient()
@autoinject()
@customElement("arp-status-indicator")
export class ArpStatusIndicatorCustomElement {

    @bindable ok = false;
    icon;
    color;
    private element: Element;

    constructor(element: Element) {
        this.element = element;
    }

    attached() {
        this.okChanged();
    }

    okChanged() {
        if (this.ok) {
            this.icon = 'glyphicon-ok';
            this.color = 'arp-highlight';
        } else {
            this.icon = 'glyphicon-remove';
            this.color = 'arp-warning';
        }
    }
}
