import {bindable, autoinject, customAttribute, Container} from 'aurelia-framework';

import {Observer} from '../Observer';

@customAttribute('arp-sort')
@autoinject()
export class ArpSortAttribute {

    @bindable sortTerm;
    private element: HTMLElement;
    private observer: Observer;
    private iconElement: HTMLElement;
    
    constructor(element: Element) {
        this.element = element as HTMLElement;
        this.observer = Container.instance.get(Observer);
    }

    bind(bindingContext, overrideContext) {

        this.observer.observe(this.sortTerm, 'sortOrder', (newValue, oldValue) => this.update());

        this.element.addEventListener('click', event => {
            this.sortTerm.termClicked();
        });
        this.element.style.cursor = "pointer";
        this.element.style.userSelect = "none";
        this.element.style.whiteSpace = "nowrap";
    }

    unbind() {
        this.observer.unObserveAll();
    }
    
    attached() {
        this.iconElement = document.createElement("i");
        // this.element.appendChild(this.iconElement);

        // See https://snook.ca/archives/html_and_css/icon-wrap-after-text
        const span = document.createElement("span");
        span.textContent = '\u{2060}';
        span.appendChild(this.iconElement);

        this.element.appendChild(span);

        this.update();
    }

    update() {

        const icon = this.sortTerm.sortOrder.getFontAwesomeIcon();

        this.iconElement.className = `fa ${icon} fa-fw`;
    }
}
