import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ILocaleSettings {
    weightUnits: string;        // WeightUnitsEnum
    currencyUnits: string;      // CurrencyUnitsEnum
}

@Serializable()
export class LocaleSettings implements ILocaleSettings {
    @JsonProperty() weightUnits: string = '';
    @JsonProperty() currencyUnits: string = '';

    static getQueryFields(): string[] {
        return [
            'weightUnits',
            'currencyUnits'
        ]
    }
}

