import {EnumElement, Enum} from "../../../Enum";

export class ConditionStatus extends EnumElement {}

export class ConditionStatusEnum extends Enum<ConditionStatus> {

    static readonly POSITIVE = new ConditionStatus('POSITIVE', 'Positive');
    static readonly NEGATIVE = new ConditionStatus('NEGATIVE', 'Negative');
    static readonly UNKNOWN = new ConditionStatus('UNKNOWN', 'Not yet tested/unknown');

    static readonly ELEMENTS = [
        ConditionStatusEnum.POSITIVE,
        ConditionStatusEnum.NEGATIVE,
        ConditionStatusEnum.UNKNOWN
    ];
    static INSTANCE = new ConditionStatusEnum();

    private constructor() {
        super(ConditionStatusEnum.ELEMENTS);
    }
}
