import {EnumElement, Enum} from "../../../Enum";
import {IMedicalConditionStatus} from "../IMedicalConditionStatus";
import {MedicalConditionEnum} from "./MedicalConditionEnum";
import {ConditionStatusEnum} from "./ConditionStatusEnum";

export class RespiratoryCheckOutcome extends EnumElement {
    static getOutcomes(subType: string, outcomes: Array<IMedicalConditionStatus>) {

        let kennelCoughOutcome = ConditionStatusEnum.UNKNOWN;
        let upperRespiratoryOutcome = ConditionStatusEnum.UNKNOWN;
        let pneumoniaOutcome = ConditionStatusEnum.UNKNOWN;
        let otherUpperRespiratoryOutcome = ConditionStatusEnum.UNKNOWN;

        switch (subType) {
            case RespiratoryCheckOutcomeEnum.POSITIVE_KENNEL_COUGH.view:
                kennelCoughOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case RespiratoryCheckOutcomeEnum.POSITIVE_UPPER_RESPIRATORY_INFECTION.view:
                upperRespiratoryOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case RespiratoryCheckOutcomeEnum.POSITIVE_PNEUMONIA.view:
                pneumoniaOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case RespiratoryCheckOutcomeEnum.POSITIVE_OTHER_RESPIRATORY_INFECTION.view:
                otherUpperRespiratoryOutcome = ConditionStatusEnum.POSITIVE;
                break;

            case RespiratoryCheckOutcomeEnum.NEGATIVE.view:
                kennelCoughOutcome = ConditionStatusEnum.NEGATIVE;
                upperRespiratoryOutcome = ConditionStatusEnum.NEGATIVE;
                pneumoniaOutcome = ConditionStatusEnum.NEGATIVE;
                otherUpperRespiratoryOutcome = ConditionStatusEnum.NEGATIVE;
                break;

            default:
                break;
        }

        outcomes.push(MedicalConditionEnum.KENNEL_COUGH.createCondition(kennelCoughOutcome));
        outcomes.push(MedicalConditionEnum.UPPER_RESPIRATORY_INFECTION.createCondition(upperRespiratoryOutcome));
        outcomes.push(MedicalConditionEnum.PNEUMONIA.createCondition(pneumoniaOutcome));
        outcomes.push(MedicalConditionEnum.RESPIRATORY_INFECTION_OTHER.createCondition(otherUpperRespiratoryOutcome));
    }
}

export class RespiratoryCheckOutcomeEnum extends Enum<RespiratoryCheckOutcome> {

    static readonly POSITIVE_KENNEL_COUGH = new RespiratoryCheckOutcome('POSITIVE_KENNEL_COUGH', 'Positive (Kennel Cough)');
    static readonly POSITIVE_UPPER_RESPIRATORY_INFECTION = new RespiratoryCheckOutcome('POSITIVE_UPPER_RESPIRATORY_INFECTION', 'Positive (Upper Respiratory Infection)');
    static readonly POSITIVE_PNEUMONIA = new RespiratoryCheckOutcome('POSITIVE_PNEUMONIA', 'Positive (Pneumonia)');
    static readonly POSITIVE_OTHER_RESPIRATORY_INFECTION = new RespiratoryCheckOutcome('POSITIVE_OTHER_RESPIRATORY_INFECTION', 'Positive (Other Respiratory Infection)');
    static readonly NEGATIVE = new RespiratoryCheckOutcome('NEGATIVE', 'Negative');

    static readonly ELEMENTS = [
        RespiratoryCheckOutcomeEnum.POSITIVE_KENNEL_COUGH,
        RespiratoryCheckOutcomeEnum.POSITIVE_UPPER_RESPIRATORY_INFECTION,
        RespiratoryCheckOutcomeEnum.POSITIVE_PNEUMONIA,
        RespiratoryCheckOutcomeEnum.POSITIVE_OTHER_RESPIRATORY_INFECTION,
        RespiratoryCheckOutcomeEnum.NEGATIVE
    ];
    static readonly INSTANCE = new RespiratoryCheckOutcomeEnum();

    private constructor() {
        super(RespiratoryCheckOutcomeEnum.ELEMENTS);
    }
}
