import type { EntityId } from "../common/IEntity";
import {JsonProperty} from "typescript-json-serializer";

/**
 * Data for Tenants to which we support transferring animals
 */
export interface ITransferDestination<T> {

    invitationId: string;           // id of invitation
    tenantId: T;                    // Destination tenant id
    tenantName: string;             // Destination tenant name
    adminUsername: string;          // Username of Admin in Destination tenant (The inviter)
    approved: Date;                 // Date the destination was approved
    approvedBy: string;             // Admin that approved the invite
}

export class TransferDestination implements ITransferDestination<EntityId> {

    @JsonProperty() invitationId: string = '';
    @JsonProperty() tenantId: EntityId = null;
    @JsonProperty() tenantName: string = '';
    @JsonProperty() adminUsername: string = '';
    @JsonProperty() approved: Date = null;
    @JsonProperty() approvedBy: string = '';

    static getQueryFields(): string[] {
        return [
            'invitationId',
            'tenantId',
            'tenantName',
            'adminUsername',
            'approved',
            'approvedBy',
        ]
    }
}