
import {Enum, EnumElement} from "../../Enum";

export class PersonAvailability extends EnumElement {}

/**
 * Used to show the roles a Person is available to perform.  Shows in PersonDetails and PersonDashboard.
 */
export class PersonAvailabilityEnum extends Enum<PersonAvailability> {

    static readonly ADOPT = new PersonAvailability('ADOPT', 'Adopt');
    static readonly FOSTER = new PersonAvailability('FOSTER', 'Foster');
    static readonly VOLUNTEER = new PersonAvailability('VOLUNTEER', 'Volunteer');
    static readonly TRANSPORT = new PersonAvailability('TRANSPORT', 'Transport');
    static readonly TRAIN = new PersonAvailability('TRAIN', 'Train');
    static readonly GROOM = new PersonAvailability('GROOM', 'Groom');

    static readonly ELEMENTS = [
        PersonAvailabilityEnum.ADOPT,
        PersonAvailabilityEnum.FOSTER,
        PersonAvailabilityEnum.VOLUNTEER,
        PersonAvailabilityEnum.TRANSPORT,
        PersonAvailabilityEnum.TRAIN,
        PersonAvailabilityEnum.GROOM
    ];
    static readonly INSTANCE = new PersonAvailabilityEnum();

    private constructor() {
        super(PersonAvailabilityEnum.ELEMENTS);
    }
}
