import {EnumElement, Enum} from "../../../Enum";

export class DogSize extends EnumElement {}

/**
 * From PetfinderDog Size Recommendations
 *      Small: 25 lbs and under (11 kg)
 *      Medium: 26–60 lbs (12 – 27 kg)
 *      Large: 61–100 lbs (28 – 45 kg)
 *      Extra Large: 101 lbs or more (46 kg)
 */
export class DogSizeEnum extends Enum<DogSize> {

    static readonly EXTRA_SMALL = new DogSize('EXTRA_SMALL', 'Extra Small (< 15 pounds)');
    static readonly SMALL = new DogSize('SMALL', 'Small (15-30 pounds)');
    static readonly MEDIUM = new DogSize('MEDIUM', 'Medium (31-50 pounds)');
    static readonly LARGE = new DogSize('LARGE', 'Large (51-70 pounds)');
    static readonly EXTRA_LARGE = new DogSize('EXTRA_LARGE', 'Extra Large (70+ pounds)');

    static readonly ELEMENTS = [
        DogSizeEnum.EXTRA_SMALL,
        DogSizeEnum.SMALL,
        DogSizeEnum.MEDIUM,
        DogSizeEnum.LARGE,
        DogSizeEnum.EXTRA_LARGE
    ];
    static readonly INSTANCE = new DogSizeEnum();

    private constructor() {
        super(DogSizeEnum.ELEMENTS);
    }
} 

export class DogSizeMetric extends EnumElement {}

export class DogSizeMetricEnum extends Enum<DogSizeMetric> {

    static readonly EXTRA_SMALL = new DogSizeMetric('EXTRA_SMALL', 'Extra Small (< 7 kg)');
    static readonly SMALL = new DogSizeMetric('SMALL', 'Small (7-14 kg)');
    static readonly MEDIUM = new DogSizeMetric('MEDIUM', 'Medium (15-22 kg)');
    static readonly LARGE = new DogSizeMetric('LARGE', 'Large (23-32 kg)');
    static readonly EXTRA_LARGE = new DogSizeMetric('EXTRA_LARGE', 'Extra Large (33+ kg)');

    static readonly ELEMENTS = [
        DogSizeMetricEnum.EXTRA_SMALL,
        DogSizeMetricEnum.SMALL,
        DogSizeMetricEnum.MEDIUM,
        DogSizeMetricEnum.LARGE,
        DogSizeMetricEnum.EXTRA_LARGE
    ];
    static readonly INSTANCE = new DogSizeMetricEnum();

    private constructor() {
        super(DogSizeMetricEnum.ELEMENTS);
    }
}

