import padStart from "lodash/padStart";
import {Decimal128} from "bson";

/**
 * Represent Money as a data type
 */
export class Money {

    private readonly amountInCents: number;

    constructor(amountInCents: number) {
        this.amountInCents = Math.floor(amountInCents);
    }

    plus(that: Money) : Money {
        return new Money(this.amountInCents + that.amountInCents);
    }

    minus(that: Money) : Money {
        return new Money(this.amountInCents - that.amountInCents);
    }

    isZero() : boolean {
        return this.amountInCents === 0;
    }

    isNegative() : boolean {
        return this.amountInCents < 0;
    }

    isPositive() : boolean {
        return this.amountInCents > 0;
    }

    toDecimal128() : Decimal128 {
        return Decimal128.fromString(this.toString());
    }

    toNumber() : number {
        return this.amountInCents / 100;
    }

    toString() : string {
       return this.format();
    }

    format(currencySymbol = '') : string {
        let dollars = Math.abs(Math.trunc(this.amountInCents / 100));
        let cents = Math.abs(this.amountInCents % 100);
        let sign = this.isNegative() ? "-" : "";

        return `${sign}${currencySymbol}${dollars.toString()}.${padStart(cents.toString(), 2, '0')}`;
    }

    static fromString(amount: string) : Money {
        return Money.fromDecimal128(Decimal128.fromString(amount));
    }

    static fromNumber(amount: number) : Money {
        return new Money(amount * 100);
    }

    static fromDecimal128(amount: Decimal128) : Money {
        let amountString = amount.toString();

        let tokens = amountString.split(".");
        let dollars = parseInt(tokens[0], 10);

        let cents = 0;

        if (tokens.length === 2) {
            cents = parseInt(tokens[1], 10);

            // Handle 1.6 without the trailing 0
            if (tokens[1].length === 1) {
                cents = cents * 10;
            }
        }

        if (cents < 100) {
            return new Money((dollars * 100) + cents);
        } else {
            throw new Error("Money can't have fractional cents: " + amountString);
        }
    }

    static isValid(amount: Decimal128) : boolean {

        try {
            // This will throw if not valid
            Money.fromDecimal128(amount);
        } catch (e) {
            return false;
        }

        return true;
    }

    static isValidAndPositive(amount: Decimal128) : boolean {

        try {
            // This will throw if not valid
            let money = Money.fromDecimal128(amount);
            return money.isPositive();
        } catch (e) {
            return false;
        }
    }

    static isPositive(amount: Decimal128) : boolean {

        try {
            // This will throw if not valid
            let money = Money.fromDecimal128(amount);
            return money.isPositive();
        } catch (e) {
            return false;
        }
    }
}
