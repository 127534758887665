
export class FileTransfer {

    static transferBuffer(buffer, type, filename) {
        let blob = new Blob([buffer], { "type": type });

        FileTransfer.transferBlob(blob, filename)
    }

    static transferBlob(blob, filename) {
        let href = window.URL.createObjectURL(blob);

        let link = document.createElement('a');

        if (link.download !== undefined) { // feature detection

            link.setAttribute("href", href);
            link.setAttribute("download", this.sanitize(filename, '-'));

        } else if ((navigator as any).msSaveBlob) { // IE 10+

            // Note there is an bug with Edge where the filename is ignored, but a fix is pending
            // so we don't address here
            link.setAttribute("href", "#");
            (navigator as any).msSaveBlob(blob, this.sanitize(filename, '-'));

        } else {
            // Browser not supported!
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static transferLink(href, filename) {
        let link = document.createElement('a');

        link.setAttribute("href", href);
        link.setAttribute("download", this.sanitize(filename, '-'));

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static viewLink(href: string) {
        let link = document.createElement('a');

        link.setAttribute("href", href);
        link.setAttribute("target", "_blank");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    /**
     * Replaces characters in strings that are illegal/unsafe for filenames.
     * Unsafe characters are either removed or replaced by a substitute set
     * in the optional `options` object.
     *
     * Illegal Characters on Various Operating Systems
     * / ? < > \ : * | "
     * https://kb.acronis.com/content/39790
     *
     * Unicode Control codes
     * C0 0x00-0x1f & C1 (0x80-0x9f)
     * http://en.wikipedia.org/wiki/C0_and_C1_control_codes
     *
     * Reserved filenames on Unix-based systems (".", "..")
     * Reserved filenames in Windows ("CON", "PRN", "AUX", "NUL", "COM1",
     * "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
     * "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", and
     * "LPT9") case-insensitively and with or without filename extensions.
     *
     * Capped at 255 characters in length.
     * http://unix.stackexchange.com/questions/32795/what-is-the-maximum-allowed-filename-and-folder-size-with-ecryptfs
     *
     * @param  {String} input   Original filename
     * @param  {Object} options {replacement: String}
     * @return {String}         Sanitized filename
     */
    static sanitize(input, replacement) {
        const illegalRe = /[\/\?<>\\:\*\|":]/g;
        const controlRe = /[\x00-\x1f\x80-\x9f]/g;
        const reservedRe = /^\.+$/;
        const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
        const windowsTrailingRe = /[\. ]+$/;

        var sanitized = input
            .replace(illegalRe, replacement)
            .replace(controlRe, replacement)
            .replace(reservedRe, replacement)
            .replace(windowsReservedRe, replacement)
            .replace(windowsTrailingRe, replacement);

        return sanitized;
    }
}