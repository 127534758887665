import {isNull, isUndefined } from "lodash";

/**
 * Simple class that converts between yes/no display strings and a boolean value
 */
export class ArpYesNoFormatValueConverter {

    /**
     *
     * @param value - the boolean value from the model
     *
     * @returns {*}
     */
    public toView(value: boolean) : string {
        if (isUndefined(value) || isNull(value)) {
            return "";
        } else {
            return value ? "Yes" : "No";
        }
    }

    public fromView(value: string) : boolean {
        return value.toLowerCase() === "yes";
    }
}
