import {IntegrationMetadata} from "../IntegrationMetadata";

export function guineaPigIntegrationMetadata(): IntegrationMetadata {
    return new IntegrationMetadata(translateBreed, translateColor);
}

function translateBreed(sparkieBreed: string): string {

    // Petfinder doesn't do breeds.  The species is all they support.
    return "Guinea Pig";
}

function translateColor(sparkieColor: string): string {
    if (colors.has(sparkieColor)) {
        return sparkieColor;
    }
    switch (sparkieColor) {
        case "Lilac":
            return "Champagne";

        case "Silver Marten":
            return "Blue / Gray";

        case "Roan":
        case "Dalmatian":
        case "Himalayan":
            return "White";

        default:
            return "";
    }
}

// NOTE: These are really small animal colors!
const colors = new Set<string>([
    "Agouti",
    "Black",
    "Tortoiseshell",
    "Blue / Gray",
    "Brown / Chocolate",
    "Calico",
    "White",
    "Cream",
    "Orange / Red",
    "Sable",
    "Tan",
    "Albino",
    "Black Sable",
    "Champagne",
    "Cinnamon",
    "White (Dark-Eyed)"
]);
