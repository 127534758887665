
export class ActionBase {
    type: string;           // action or separator
    enabled: boolean;
    visible: boolean;
    hasAnchor: boolean;

    constructor(type: string) {
        this.type = type;
        this.enabled = true;
        this.visible = true;
        this.hasAnchor = true;
    }
}

