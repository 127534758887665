import {ArpViewState} from "arp-framework";
import {AppRouter} from "aurelia-router";
import {BrowserHistory} from "aurelia-history-browser";

export class ViewStateManager {

    private _activeViewState: ArpViewState;
    private _queuedViewState: ArpViewState;
    private _router: AppRouter;

    constructor() {
    }

    /**
     * Set the active view state so that scroll bar location can be maintained in the view state.
     *
     * NOTE: This is called by the active view (list/details) when it is attached, and reset to null
     * when the view is detached.
     *
     * @param {ArpViewState} viewState
     */
    setActiveViewState(viewState: ArpViewState) {
        this._activeViewState = viewState;
    }

    getActiveViewState() : ArpViewState {
        return this._activeViewState;
    }

    /**
     * Queue a view state to be used when a new view is launched.  Used by the dashboard to "push" view state
     * (filters, etc) to a view that is launching.
     *
     * NOTE: There is probably a better way of doing this with Aurelia now...
     *
     * @param {ArpViewState} viewState
     */
    queueViewState(viewState: ArpViewState | any) {
        this._queuedViewState = viewState;
    }

    /**
     * Remove and return the last queued view state.  Called by a view when it is launched to see if there were any
     * view state to use at initialization.
     *
     * @returns {ArpViewState}
     */
    dequeueViewState(): ArpViewState {
        let viewState = this._queuedViewState;
        this._queuedViewState = undefined;

        return viewState;
    }

    resetViewState() {
        this._activeViewState = null;
        this._queuedViewState = null;
    }


    getBrowserState() {
        return (this.browserHistory as any).history.state;
    }

    replaceBrowserState(state: any) {
        this.domHistory.replaceState(state, "");
    }

    get browserHistory() : BrowserHistory {
        return this.router.history;
    }

    get domHistory() : History {
        return (this.router.history as any).history as History;
    }

    set router(router: AppRouter) {
        if (!router.isRoot) {
            throw new Error("Where is my router!")
        }

        this._router = router;
    }

    get router(): AppRouter {
        return this._router;
    }
}
