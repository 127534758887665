import {
    getCumulativeDaysAvailable,
    IAnimal,
    IReportConverter
} from "@sparkie/shared-model/src";

export class DaysAvailableConverter<T> implements IReportConverter {

    constructor() {
    }

    toView(modelValue: any, modelInstance: any) : string {

        const animal = modelInstance as IAnimal<T>;
        const cumulativeDaysAvailable = getCumulativeDaysAvailable(animal);
        return cumulativeDaysAvailable.toString();
    }
}

