
export class ArpIntegerFormatValueConverter {

    toView(value: number) {
        if (value !== null && value !== undefined) {
            return value.toString();
        } else {
            return "";
        }
    }

    fromView(value: string) {

        return parseInt(value);
    }
}
