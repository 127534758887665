import {Decimal128} from "bson";
import {JsonProperty, Serializable} from "typescript-json-serializer";

export interface ITrainerDetails {
    daysAvailable: Array<string>;       // DaysOfWeekEnum
    timeAvailable: Array<string>;       // TimeOfDayEnum
    specialities: Array<string>;        // TrainerSpecialitiesEnum
    location: Array<string>;            // PersonLocationEnum
    hourlyRate: Decimal128;
}

@Serializable()
export class TrainerDetails implements ITrainerDetails {

    @JsonProperty() daysAvailable: Array<string> = [];       // DaysOfWeekEnum
    @JsonProperty() timeAvailable: Array<string> = [];       // TimeOfDayEnum
    @JsonProperty() specialities: Array<string> = [];        // TrainerSpecialitiesEnum
    @JsonProperty() location: Array<string> = [];            // PersonLocationEnum
    @JsonProperty({ onDeserialize: Decimal128.fromString } )
    hourlyRate: Decimal128 = null;

    static getQueryFields(): string[] {
        return [
            'daysAvailable',
            'timeAvailable',
            'specialities',
            'location',
            'hourlyRate'
        ]
    }
}