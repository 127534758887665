import map from "lodash/map";
import {EnumElement, Enum} from "../../../Enum";
import {HeartwormTestOutcome, HeartwormTestOutcomeEnum} from "./HeartwormTestOutcomeEnum";
import {FecalParasiteTestOutcome, FecalParasiteTestOutcomeEnum} from "./FecalParasiteTestOutcomeEnum";
import {FelvFivComboTestOutcome, FelvFivComboTestOutcomeEnum} from "./FelvFivComboTestOutcomeEnum";
import {FourDxTestOutcome, FourDxTestOutcomeEnum} from "./FourDxTestOutcomeEnum";
import {ParvoTestOutcome, ParvoTestOutcomeEnum} from "./ParvoTestOutcomeEnum";
import {SkinScrapingTestOutcome, SkinScrapingTestOutcomeEnum} from "./SkinScrapingTestOutcomeEnum";
import {RingwormTestOutcome, RingwormTestOutcomeEnum} from "./RingwormTestOutcomeEnum";
import {FelvTestOutcome, FelvTestOutcomeEnum} from "./FelvTestOutcomeEnum";
import {FivTestOutcome, FivTestOutcomeEnum} from "./FivTestOutcomeEnum";
import {RespiratoryCheckOutcome, RespiratoryCheckOutcomeEnum} from "./RespiratoryCheckOutcome";
import {FelineTripleTestOutcome, FelineTripleTestOutcomeEnum} from "./FelineTripleTestOutcomeEnum";

export class MedicalProcedure extends EnumElement {

    static getOutcomes(test: string) : Array<string>{
        switch (test) {
            case MedicalProcedureEnum.HEARTWORM_TEST.view:
                return map(HeartwormTestOutcomeEnum.ELEMENTS, (it: HeartwormTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.FECAL_PARASITE_TEST.view:
                return map(FecalParasiteTestOutcomeEnum.ELEMENTS, (it: FecalParasiteTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.FELV_FIV_COMBO_TEST.view:
                return map(FelvFivComboTestOutcomeEnum.ELEMENTS, (it: FelvFivComboTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.FELV_TEST.view:
                return map(FelvTestOutcomeEnum.ELEMENTS, (it: FelvTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.FIV_TEST.view:
                return map(FivTestOutcomeEnum.ELEMENTS, (it: FivTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum._4DX_TEST.view:
                return map(FourDxTestOutcomeEnum.ELEMENTS, (it: FourDxTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.PARVO_TEST.view:
                return map(ParvoTestOutcomeEnum.ELEMENTS, (it: ParvoTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.RINGWORM_TEST.view:
                return map(RingwormTestOutcomeEnum.ELEMENTS, (it: RingwormTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.SKIN_SCRAPING.view:
                return map(SkinScrapingTestOutcomeEnum.ELEMENTS, (it: SkinScrapingTestOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.RESPIRATORY_CHECK.view:
                return map(RespiratoryCheckOutcomeEnum.ELEMENTS, (it: RespiratoryCheckOutcome) => {
                    return it.view;
                });
            case MedicalProcedureEnum.FELINE_TRIPLE_TEST.view:
                return map(FelineTripleTestOutcomeEnum.ELEMENTS, (it: FelineTripleTestOutcome) => {
                    return it.view;
                });

            case MedicalProcedureEnum.BLOODWORK.view:
            case MedicalProcedureEnum.DENTAL.view:
            case MedicalProcedureEnum.EAR_EXAM.view:
            case MedicalProcedureEnum.EUTHANASIA.view:
            case MedicalProcedureEnum.MICROCHIP_IMPLANT.view:
            case MedicalProcedureEnum.SPAY.view:
            case MedicalProcedureEnum.NEUTER.view:
            case MedicalProcedureEnum.OFFICE_EXAM.view:
            default:
                 return [];
        }
    }
}

export class MedicalProcedureEnum extends Enum<MedicalProcedure> {

    static readonly BLOODWORK = new MedicalProcedure('BLOODWORK', 'Bloodwork');
    static readonly DENTAL = new MedicalProcedure('DENTAL', 'Dental');
    static readonly EAR_EXAM = new MedicalProcedure('EAR_EXAM', 'Ear exam');
    static readonly EUTHANASIA = new MedicalProcedure('EUTHANASIA', 'Euthanasia');
    static readonly FECAL_PARASITE_TEST = new MedicalProcedure('FECAL_PARASITE_TEST', 'Fecal parasite test');
    static readonly FELV_FIV_COMBO_TEST = new MedicalProcedure('FELV_FIV_COMBO_TEST', 'FeLV/FIV combo test');
    static readonly FELV_TEST = new MedicalProcedure('FELV_TEST', 'FeLV test');
    static readonly FIV_TEST = new MedicalProcedure('FIV_TEST', 'FIV PCR test');
    static readonly _4DX_TEST = new MedicalProcedure('_4DX_TEST', '4DX test');
    static readonly HEARTWORM_TEST = new MedicalProcedure('HEARTWORM_TEST', 'Heartworm test');
    static readonly MICROCHIP_IMPLANT = new MedicalProcedure('MICROCHIP_IMPLANT', 'Microchip implant');
    static readonly NEUTER = new MedicalProcedure('NEUTER', 'Neuter');
    static readonly OFFICE_EXAM = new MedicalProcedure('OFFICE_EXAM', 'Office exam');
    static readonly PARVO_TEST = new MedicalProcedure('PARVO_TEST', 'Parvo test');
    static readonly RINGWORM_TEST = new MedicalProcedure('RINGWORM_TEST', 'Ringworm culture');
    static readonly SKIN_SCRAPING = new MedicalProcedure('SKIN_SCRAPING', 'Skin scraping');
    static readonly FELINE_TRIPLE_TEST = new MedicalProcedure('FELINE_TRIPLE_TEST', 'Feline Triple test');
    static readonly SPAY = new MedicalProcedure('SPAY', 'Spay');
    static readonly RESPIRATORY_CHECK = new MedicalProcedure('RESPIRATORY_CHECK', 'Respiratory Check');

    static readonly ELEMENTS = [
        MedicalProcedureEnum.BLOODWORK,
        MedicalProcedureEnum.DENTAL,
        MedicalProcedureEnum.EAR_EXAM,
        MedicalProcedureEnum.EUTHANASIA,
        MedicalProcedureEnum.FECAL_PARASITE_TEST,
        MedicalProcedureEnum.FELV_FIV_COMBO_TEST,
        MedicalProcedureEnum.FELV_TEST,
        MedicalProcedureEnum.FIV_TEST,
        MedicalProcedureEnum._4DX_TEST,
        MedicalProcedureEnum.HEARTWORM_TEST,
        MedicalProcedureEnum.MICROCHIP_IMPLANT,
        MedicalProcedureEnum.NEUTER,
        MedicalProcedureEnum.OFFICE_EXAM,
        MedicalProcedureEnum.PARVO_TEST,
        MedicalProcedureEnum.RINGWORM_TEST,
        MedicalProcedureEnum.SKIN_SCRAPING,
        MedicalProcedureEnum.FELINE_TRIPLE_TEST,
        MedicalProcedureEnum.SPAY,
        MedicalProcedureEnum.RESPIRATORY_CHECK
    ];
    static readonly INSTANCE = new MedicalProcedureEnum();

    private constructor() {
        super(MedicalProcedureEnum.ELEMENTS);
    }
}
