import {ReportSpec} from "@sparkie/shared-model/src";
import {FileTransfer} from "../FileTransfer";
import {NavigationLocation} from "../NavigationLocation";


export class ClientReportSpec extends ReportSpec {

    navigationLocation: NavigationLocation;

    constructor(title: string) {
        super(title);
    }

    generateCsvReport(entities: Array<any>) {
        let buffer = this.buildCsvReport(entities);
        let contentType = "text/csv;charset=utf8;";
        FileTransfer.transferBuffer(buffer, contentType, `${this.title}.csv`);
    }

    generateCsvReportGQL(json: any) {
        let buffer = this.buildCsvReportGQL(json);
        let contentType = "text/csv;charset=utf8;";
        FileTransfer.transferBuffer(buffer, contentType, `${this.title}.csv`);
    }
}