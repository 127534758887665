import { EnumElement, Enum } from "../../Enum";

export class TrainingType extends EnumElement { }

export class TrainingTypeEnum extends Enum<TrainingType> {

    static readonly ASSESSMENT = new TrainingType('ASSESSMENT', 'Initial assessment');
    static readonly FOLLOW_UP = new TrainingType('FOLLOW_UP', 'Follow up assessment');
    static readonly ONE_ON_ONE_SESSION = new TrainingType('ONE_ON_ONE_SESSION', '1:1 session');
    static readonly GROUP_SESSION = new TrainingType('GROUP_SESSION', 'Group session');

    static readonly ELEMENTS = [
        TrainingTypeEnum.ASSESSMENT,
        TrainingTypeEnum.FOLLOW_UP,
        TrainingTypeEnum.ONE_ON_ONE_SESSION,
        TrainingTypeEnum.GROUP_SESSION
    ];
    static readonly INSTANCE = new TrainingTypeEnum();

    private constructor() {
        super(TrainingTypeEnum.ELEMENTS);
    }
}
