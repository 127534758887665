import {Enum, EnumElement} from "../../Enum";

export class VolunteerInterests extends EnumElement {}

export class VolunteerInterestsEnum extends Enum<VolunteerInterests> {

    static readonly ADMIN_OFFICE = new VolunteerInterests('ADMIN_OFFICE', 'Admin/Office');
    static readonly DOG_HANDLING = new VolunteerInterests('DOG_HANDLING', 'Animal Handling');
    static readonly EDUCATION_OUTREACH = new VolunteerInterests('EDUCATION_OUTREACH', 'Education/Outreach');
    static readonly EVENTS = new VolunteerInterests('EVENTS', 'Events');
    static readonly FUNDRAISING = new VolunteerInterests('FUNDRAISING', 'Fundraising');
    static readonly MARKETING_SOCIAL_MEDIA = new VolunteerInterests('MARKETING_SOCIAL_MEDIA', 'Marketing/Social Media');

    static readonly ELEMENTS = [
        VolunteerInterestsEnum.ADMIN_OFFICE,
        VolunteerInterestsEnum.DOG_HANDLING,
        VolunteerInterestsEnum.EDUCATION_OUTREACH,
        VolunteerInterestsEnum.EVENTS,
        VolunteerInterestsEnum.FUNDRAISING,
        VolunteerInterestsEnum.MARKETING_SOCIAL_MEDIA
    ];
    static readonly INSTANCE = new VolunteerInterestsEnum();

    private constructor() {
        super(VolunteerInterestsEnum.ELEMENTS);
    }
}
