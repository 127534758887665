import {IQuery} from "../ArpResource";

/**
 * Base class that defines the required interface for Filters that work with the
 * arp-filter-bar component.
 */
export abstract class FilterBase {

    propertyName: string;
    saved: any;

    protected constructor(propertyName: string) {
        this.propertyName = propertyName;
    }

    abstract observe(parent: any) : void;

    abstract isActive() : boolean;

    abstract clearFilter() : void;

    abstract configureResource(resource: IQuery) : void;

    abstract save() : void;

    abstract restore() : void;

    abstract getStateKey() : string;

    abstract getState() : any;

    abstract setState(state: any) : void;

    safeClearArray(array) : void {
        // Clear array without breaking observation
        array.splice(0, array.length);
    }
}

