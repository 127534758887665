import {EnumElement, Enum} from "../../Enum";

export class AnimalCompatibility extends EnumElement {}

export class AnimalCompatibilityEnum extends Enum<AnimalCompatibility> {

    static readonly GOOD_WITH = new AnimalCompatibility('GOOD_WITH', 'Good With');
    static readonly NOT_GOOD_WITH = new AnimalCompatibility('NOT_GOOD_WITH', 'Not Good With');
    static readonly UNKNOWN = new AnimalCompatibility('UNKNOWN', 'Unknown');

    static readonly ELEMENTS = [
        AnimalCompatibilityEnum.GOOD_WITH,
        AnimalCompatibilityEnum.NOT_GOOD_WITH,
        AnimalCompatibilityEnum.UNKNOWN
    ];
    static readonly INSTANCE = new AnimalCompatibilityEnum();

    private constructor() {
        super(AnimalCompatibilityEnum.ELEMENTS);
    }
}
