import $ from 'jquery';

import {autoinject, customElement, bindable} from 'aurelia-framework';

@customElement("arp-filter-result")
@autoinject()
export class ArpFilterResult {

    @bindable query;
    @bindable description;

    private waitingImageUrl: string = "app/images/ajax-loader.gif";
    private element: Element;

    constructor(element: Element) {
        this.element = element;

        $(this.element).click((event) => this.query.navigate());
    }
}
