import isArray from "lodash/isArray";

export class ArpArrayFormatValueConverter {

    toView(value) {

        if (!value) {
            return "";
        } else if (isArray(value)) {
            return value.join(', ');
        } else {
            return value.toString();
        }
    }
}
